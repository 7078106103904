import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IntegralUIGrid } from '@lidorsystems/integralui-web/bin/integralui/components/integralui.grid';
import { IntegralUICommonService } from '@lidorsystems/integralui-web/bin/integralui/services/integralui.common.service';
import { CategoryService } from '../service/category.service';
import { FormService } from '../service/form.service';
import { FormfieldService } from '../service/formfield.service';
import { IndustryService } from '../service/industry.service';
import { TemplateService } from '../service/template.service';
import { UtilitiesService } from '../service/utility.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-stepertest',
  templateUrl: './stepertest.component.html',
  styleUrls: ['./stepertest.component.css']
})
export class StepertestComponent implements OnInit {
  selectedValue: string = "avs";
  disableSelect = new FormControl(false);
  // foods: Food[] = [
  //   {value: 'steak-0', viewValue: 'yes'},
  //   {value: 'pizza-1', viewValue: 'No'},

  // ];
  frmdrop:boolean=false;
  isLinear = false;
  firstFormGroup: FormGroup;
  CategoryForm: FormGroup;
  secondFormGroup: FormGroup;
  secondFormGroup1: FormGroup;
  thiredFormGroup: FormGroup;
  thiredFormGroup1: FormGroup;
  fourthFormGroup: FormGroup
  addeditedthirdForm:boolean=false;
   fifthFormGroup:  FormGroup
  addedited:boolean=false;
  IndustryChange:any;
  constructor(private _formBuilder: FormBuilder, private _industry: IndustryService, private _utility: UtilitiesService,
    private _form:FormService, private _formField:FormfieldService,private route: ActivatedRoute,private _Template:TemplateService,
    private _Category: CategoryService, private commonService?: IntegralUICommonService,private toastr?: ToastrService

  ) {
    this.addeditedthirdForm=false;
this.frmdrop=true;
this.addedited=false;

  }
  IndustryId:any;
  CategoryName:any='';
  ngOnInit() {
   // this.getCategoryFormData();
    this.IndustryId = this.route.snapshot.paramMap.get("IndustryId");
    console.log(this.IndustryId);
    this.IndustryChange=this.IndustryId;
    //this.CategoryName= "Test"
    this.getIndustryBYId(this.IndustryChange);

    this.getFormId();
  //  var a= (<HTMLInputElement>document.getElementById('Name0')).value;
  //  console.log(a)
  //  var b= (<HTMLInputElement>document.getElementById('Name1')).value;
  //  console.log(b)
   // this.CategoryForm.get('IndustryId').setValue(this.IndustryId);
//this.onloadTabSelection();
    this.getIndustry();
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.createCategoryForm();
    this.secondFormGroup1 = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    
    });
    this.secondFormGroup = this._formBuilder.group({
      Name: [''],
      Description: [''],
      // FormId: ['']
      // Order: ['']
    });
    this.thiredFormGroup1 = this._formBuilder.group({
       thiredCtrl: ['', Validators.required],
     
      });
    this.thiredFormGroup = this._formBuilder.group({
    // thiredCtrl: ['', Validators.required],
     FieldName: ['',Validators.required],
     Description: [''],
     FieldType: [''],
     Form: [''],
     FormId:['']
    
    });
    this.fourthFormGroup = this._formBuilder.group({
      fourthCtrl: ['', Validators.required]
    });
    this.fifthFormGroup = this._formBuilder.group({
      fifthCtrl: ['', Validators.required],
      Publish:['']
    });
   // throw new Error('Method not implemented.');
  }

  onIndustryChange(e){
    console.log(e.value);
        this.getIndustryBYId(e.value);
      }
      selectedIndustryForPreview:any='';
  getIndustryBYId(id){
    this._industry.getIndustryById(id).subscribe(data =>{
      console.log(data);
      console.log(data[0].Name);
      this.selectedIndustryForPreview=data[0].Name;
    })
  }


  getFormId(){
    this._form.getFormId().subscribe(data=>{
      console.log(data);
    })
  }
 

  createCategoryForm() {
    this.CategoryForm = this._formBuilder.group({
      Id: [''],
      CategoryName: ['', Validators.required],
      IndustryId: ['', Validators.required],
      Description: [''],
      CatIcon: [''],
      CreatedBy: [1],
      UpdatedBy: [1],
    });
  }

  url: any;
  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result;
        console.log(this.url);
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  IndustryList: any;
  getIndustry() {
    this._industry.getIndustryDtl().subscribe(data => {
      console.log(data);
      this.IndustryList = data;
    })
  }

  // onSaveCategory() {
  //   if(typeof this.url === 'undefined')
  //   {
  //     this.url = ''
  //   }
  //   this.CategoryForm.patchValue({
  //     CatIcon: this.url,
  //   });
   
  //   console.log(this.CategoryForm.value);
    
  //   if (this.CategoryForm.valid) {
    
  //     this._Category.createCategory(this.CategoryForm.value)
  //       .subscribe(data => {
  //         console.log(data);
      
  //         this.createTemplate();
  //         // this.addedited = false;
  //         var x = (<HTMLInputElement>document.getElementById('snackbar'));
  //         x.innerText = 'Category Created Sucessfully';
  //         this._utility.SuccessToast();

  //       })
  //   }
  //   else {
  //     const invalid = [];
  //     const controls = this.CategoryForm.controls;
  //     for (const name in controls) {
  //       if (controls[name].invalid) {
  //         invalid.push(name);
  //         var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
  //         x.innerText = 'Please Enter ' + invalid[0] + '';
  //         this._utility.ErrorToast1();
  //       }
  //     }
  //     return invalid;
  //   }
  // }

  onSaveCategory() {
    if(typeof this.url === 'undefined')
    {
      this.url = ''
    }
    this.CategoryForm.patchValue({
      CatIcon: this.url,
    });
   
    console.log(this.CategoryForm.value);
    
    if (this.CategoryForm.valid) {
      console.log(this.CategoryForm.get('CategoryName').value)
      this._Category.getCategorySearch(this.CategoryForm.get('CategoryName').value).subscribe(data=>{
        
        if(data.length == 0)
        {
           this._Category.createCategory(this.CategoryForm.value)
          .subscribe(data => {
          console.log(data);
          this.createTemplate();
         // this.saveSecondForm();
         // this.saveThirdForm();
          // this.addedited = false;
          // var x = (<HTMLInputElement>document.getElementById('snackbar'));
          // x.innerText = 'Category Created Sucessfully';
          // this._utility.SuccessToast();

        })
        }
        else{
         
        }
      })
     
    }
    else {
      const invalid = [];
      const controls = this.CategoryForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
          var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
          x.innerText = 'Please Enter ' + invalid[0] + '';
          this._utility.ErrorToast1();
        }
      }
      return invalid;
    }
  }
  onClearAll() {
    this.CategoryForm.reset();
  }
selectedTemplated:Number;
createTemplate(){
  var postData={
   // Name:"Default",
    Name:"Base Template",
    Category:(<HTMLInputElement>document.getElementById('CategoryName')).value,
    IsPublish : false,
    CreatedBy :  1,
    UpdatedBy :  1,
  }
  console.log(postData);
  this._Template.createTemplate(postData).subscribe(data=>{
    console.log(data);
    this.selectedTemplated=data["post"].TemplateId;

    console.log(data["post"].TemplateId);
    this.saveSecondForm();
  })
}

  refresh() {
    //debugger;
    this.CategoryForm.reset();
    window.location.reload();
    //  this._router.navigateByUrl('/category//miscellaneous/not-found', { skipLocationChange: true }).then(() =>
    //  this._router.navigate(["/category"]));

    console.log('refresh');
  }

  validateNumber(event: any) {
    this._utility.validateNumber(event);
  }
DefaultFormData=[];
APIFormId=[];
TabValue :string;
TabForm :Number;
  onsaveForm(){
    console.log(this.secondFormGroup.value);

     var data=this.secondFormGroup.get('Name').value;
     console.log(this.DefaultFormData.length)
    if(this.DefaultFormData.length==0){
      this.TabValue =this.secondFormGroup.get('Name').value;
      this.TabForm = 1
      this.DefaultFormData.push(this.secondFormGroup.value);
      this._form.getFormId().subscribe(data=>{
        console.log(data);
        if(data.length==0){
          this.DefaultFormData[0].FormId=1;
          this.APIFormId.push(1);
          
        }else{
          this.DefaultFormData[0].FormId=data[0].FormId+1;
          this.APIFormId.push(data[0].FormId+1);
        }
       
      })
     
     }else {
    var test=this.DefaultFormData[this.DefaultFormData.length-1].FormId+1;
     console.log(test);
     console.log(this.DefaultFormData.length);
     var defaultength=this.DefaultFormData.length;
     this.APIFormId.push(test);
      this.DefaultFormData.push(this.secondFormGroup.value);
      this.DefaultFormData[defaultength].FormId=test;
     
    
     }
     console.log(this.DefaultFormData);
     //console.log(this.DefaultFormData.length)
  //   for(var i=0;i<=this.DefaultFormData.length;i++){

  // console.log(i);
  //    if(i==0){
       
  //     this.DefaultFormData.push(this.secondFormGroup.value);
  //     this.DefaultFormData[i].FormId=36;
  //    }else{

  //      this.DefaultFormData.push(this.secondFormGroup.value);
  //      this.DefaultFormData[i].FormId=36+i;
  //      console.log(this.DefaultFormData);
  //    }
  //   }
// if(data1==''){
//   this._form.getFormId().subscribe(data=>{
//     console.log(data);
//     this.secondFormGroup.patchValue({
//       FormId:data[0].FormId,
//     })
//     // this.thiredFormGroup.patchValue({
//     //   Form: this.dynamicVal,
//     //   })
//     console.log(this.secondFormGroup.value);
//   })
  
// }
    // if(data=!'' && data !=undefined && this.secondFormGroup.valid){
    //   console.log(this.DefaultFormData);
    //   this.DefaultFormData.push(this.secondFormGroup.value);
    //   console.log(this.DefaultFormData);
    // }else{
    //   var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
    //   x.innerText = 'Please Provide data to post';
    //   this._utility.ErrorToast1();
    // }
    // this.DefaultFormData.push(this.secondFormGroup.value);
    // console.log(this.DefaultFormData);
  }
cancelDefaultForm(){
  this.secondFormGroup.reset();
}

//

selectedData:any;
OnSelectRow(e,data){
  this.addedited=true;
  this.isUpdate=true;
  console.log(e);
  this.selectedData=data;
  console.log(data);
  console.log(e.target.value);
  // (<HTMLInputElement>document.getElementById('Name')).value=data[0].Name;
  // (<HTMLInputElement>document.getElementById('Description')).value=data[0].Description;

  this.secondFormGroup.get("Name").setValue(data.Name);
  this.secondFormGroup.get("Description").setValue(data.Description)
}
isUpdate:boolean=false;
OnUpdate(){
  var data=this.selectedData;
  console.log(data);
  data.Name= this.secondFormGroup.get("Name").value;
  data.Description=this.secondFormGroup.get("Description").value;
  this.isUpdate=false;
  this.secondFormGroup.reset();
  this.addedited=false;
}
OnDelete(e,data){
  console.log(data);
  console.log(this.DefaultFormData)
 // this.FormFieldData.splice(e,1);
 this.DefaultFormData.splice(e, 1);
 console.log(this.DefaultFormData)
}
dynamicVal :String;
dynamicFormId : Number;
noOfTab:boolean=false;
onloadTabSelection(data,formId){
// debugger;
console.log(data)
console.log(formId)

console.log(this.FormFieldData)

var centers = this.AllFormFieldData.filter(element => {
return element.Form == data;
})
this.FormFieldData =centers
console.log(this.FormFieldData);
console.log(data);
this.dynamicVal = data;
this.noOfTab=data;
this.dynamicFormId=formId;
// for(var i=0;i<=this.DefaultFormData.length;i++){
// this.noOfTab=true;
// //this.DefaultFormData[i].Name;
// //this.noOfTab=(<HTMLInputElement>document.getElementById('tabName'));
// console.log(this.noOfTab);

// }
}
// noOfTab:boolean=false;
// onloadTabSelection(data){
//  // debugger;
//  console.log(data);
//  this.noOfTab=data;
// // for(var i=0;i<=this.DefaultFormData.length;i++){
// //   this.noOfTab=true;
// //   //this.DefaultFormData[i].Name;
// //   //this.noOfTab=(<HTMLInputElement>document.getElementById('tabName'));
// //   console.log(this.noOfTab);
  
// // }
// }
selectedFormId=[];

saveSecondForm(){
  this._Template.getTemplateId().subscribe(data=>{
    console.log(data);
    // console.log('Template:'  +data.length);
    // if(data.length==0){
    //   this.selectedTemplated=1;
    // }else{
    //   console.log('Test:' +data[0].TemplateId)
    //   this.selectedTemplated=data[0].TemplateId+1;
    // }
   console.log(this.selectedTemplated)
    for(var i=0;i<this.DefaultFormData.length;i++){
      console.log(this.DefaultFormData[i]);
      console.log(this.selectedTemplated)
      var res=this.DefaultFormData[i];
      var json_arr = {};
      json_arr["Id"] = null;
      json_arr["Title"] = res.Name;
      json_arr["TemplateId"] = this.selectedTemplated;
      json_arr["Description"] = res.Description;
      json_arr["CreatedBy"] = 1;
      json_arr["UpdatedBy"] = 1
      json_arr["IsActive"] = true;
  console.log(json_arr);
      this._form.createForm(json_arr).subscribe(data=>{
        console.log(data);
       this.selectedFormId.push(data["post"].FormId);
       console.log(this.selectedFormId);
        // var x = (<HTMLInputElement>document.getElementById('snackbar'));
        // x.innerText = 'Form Data Save Sucessfully';
        // this._utility.SuccessToast();
      })

      if(this.DefaultFormData.length-1 ==i)
      {
        this.saveThirdForm();
      }
    }

  })

}



selectedTab:boolean=true;
DemoFormFieldData=[];
onThirdFormTest(){
  console.log(this.thiredFormGroup.value);
  this.DemoFormFieldData.push(this.thiredFormGroup.value);
  console.log( this.DemoFormFieldData);
}

cancelDefaultFormField(){
  this.thiredFormGroup.reset();
}

FormFieldData=[];
AllFormFieldData=[];
onsaveFormField(){

console.log(this.dynamicVal)
this.thiredFormGroup.patchValue({
Form: this.dynamicVal,
})

this.thiredFormGroup.patchValue({
  FormId: this.dynamicFormId,
  })

console.log(this.thiredFormGroup.value);
var data=this.thiredFormGroup.get('FieldName').value;
var data1=this.thiredFormGroup.get('FieldType').value;
if(data !='' && data!=undefined && data1 !='' && data1!=undefined){
this.FormFieldData.push(this.thiredFormGroup.value);
this.AllFormFieldData.push(this.thiredFormGroup.value);
console.log( this.FormFieldData);

}else{
var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
x.innerText = 'Please Provide data to post';
this._utility.ErrorToast1();
}


// this.thiredFormGroup.patchValue({
// secondCtrl: true,
// });
}

// FormFieldData=[];
// onsaveFormField(){
//   console.log(this.thiredFormGroup.value);
//   //  var a= (<HTMLInputElement>document.getElementById('Name0')).value;
//   //  console.log(a);
//   //  var b= (<HTMLInputElement>document.getElementById('Name1')).value;
//   //  console.log(b);
//   var data=this.thiredFormGroup.get('FieldName').value;
//   var data1=this.thiredFormGroup.get('FieldType').value;
// if(data !='' && data!=undefined && this.thiredFormGroup.valid){
//   this.FormFieldData.push(this.thiredFormGroup.value);
//   console.log( this.FormFieldData);
// }else{
//   var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
//   x.innerText = 'Please Provide data to post';
//   this._utility.ErrorToast1();
// }
 

//   // this.thiredFormGroup.patchValue({
//   //   secondCtrl: true,
//   // });
// }


saveThirdForm(){
  
  for(var i=0;i<this.AllFormFieldData.length;i++){
   
      var mappingId =false;
      if(i == 0)
      {
        mappingId = true;
      }   
    console.log(this.AllFormFieldData[i]);
    var res=this.AllFormFieldData[i];
    var json_arr = {};
    json_arr["Id"] = null;
    json_arr["Label"] = res.FieldName;
    json_arr["FieldType"] = res.FieldType;
    json_arr["Description"] = res.Description;
    json_arr["Form"] = res.Form;
    json_arr["FormId"] = res.FormId;
    json_arr["CreatedBy"] = 1;
    json_arr["UpdatedBy"] = 1
    json_arr["IsActive"] = true;
    json_arr["MappingField"] = mappingId;
    console.log(json_arr);
   console.log(this.AllFormFieldData[i])
    this._formField.createFormfield(json_arr).subscribe(data=>{
      console.log(data);

      this.toastr.success('Template Created Successfully')
      // var x = (<HTMLInputElement>document.getElementById('snackbar'));
      // x.innerText = 'Template Created Successfully';
      // this._utility.SuccessToast();
    })
  }
}

selectedFormFieldData:any;
OnSelectRowFormField(e,data){
  this.addeditedthirdForm=true;
  console.log(e);
  this.selectedFormFieldData=data;
  console.log(data);
  console.log(e.target.value);
  // (<HTMLInputElement>document.getElementById('Name')).value=data[0].Name;
  // (<HTMLInputElement>document.getElementById('Description')).value=data[0].Description;

  this.thiredFormGroup.get("FieldName").setValue(data.FieldName);
  this.thiredFormGroup.get("FieldType").setValue(data.FieldType);
  this.thiredFormGroup.get("Description").setValue(data.Description);
}

OnDeleteformField(e,data){
  console.log(data);
  console.log(this.FormFieldData)
 // this.FormFieldData.splice(e,1);
 this.FormFieldData.splice(e, 1);
 console.log(this.FormFieldData)
}

OnUpdateFormField(){
  var data=this.selectedFormFieldData;
  console.log(data);
  data.FieldName= this.thiredFormGroup.get("FieldName").value;
  data.FieldType=this.thiredFormGroup.get("FieldType").value;
  data.Description=this.thiredFormGroup.get("Description").value;
  this.addeditedthirdForm=false;
  this.thiredFormGroup.reset();
}

focusOutFunction()
{
  
  this._Category.checkDuplicateCategory(this.CategoryForm.get("CategoryName").value).subscribe(data =>{
    console.log(data['posts'].length)
    if(data['posts'].length != 0)
    {
      this.CategoryForm.patchValue({
        CategoryName: '',
      });
      var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
      x.innerText = 'This Category Name Already Existed';
      this._utility.ErrorToast1();
    }
    
  })
  
}



steperSecondStep(){
  this.secondFormGroup1.patchValue({
    secondCtrl: 1,
  });
  this.secondFormGroup1.patchValue({
    secondCtrl: 1,
  });
}
backsteperSecondStep(){
  this.secondFormGroup1.patchValue({
    secondCtrl: '',
  });
}

onsteperthird(){
  this.thiredFormGroup1.patchValue({
    thiredCtrl: 1,
  });
}
backonsteperthird(){
  this.thiredFormGroup1.patchValue({
    thiredCtrl: '',
  });
}
//
FormatData =[];
ExampleData =[];
onsteperforth(){
  this.fourthFormGroup.patchValue({
    fourthCtrl: 1,
  });
  console.log(this.fifthFormGroup.value)
  this._Category.getAPI(this.selectedTemplated,this.APIFormId).subscribe(data =>{
    console.log(this.selectedTemplated)
    console.log(this.APIFormId)
    this.FormatData = data["Formats"];
    this.ExampleData = data["Eaxmples"];
    console.log(this.FormatData)
    console.log(this.ExampleData)
  })
}
copy(inputElement) {
  inputElement.select();
  document.execCommand('copy');
  inputElement.setSelectionRange(0, 0);
}
backsteperforthStep(){
  this.fourthFormGroup.patchValue({
    fourthCtrl: '',
  });
}
//
onsteperfifth(){
  this.fifthFormGroup.patchValue({
    fifthCtrl: 1,
  });
}
backsteperfifthStep(){
  this.fifthFormGroup.patchValue({
    fifthCtrl: '',
  });
}

Description:any;
categoryInfo=[];
CategoryName1:any;
getCategoryFormData(){
  console.log(this.CategoryForm.value);
  this.categoryInfo.push(this.CategoryForm.value);
  console.log(this.categoryInfo)
  this.CategoryName=this.categoryInfo[0].CategoryName;

  this.CategoryName1=(<HTMLInputElement>document.getElementById('CategoryName')).value 
 this.Description=(<HTMLInputElement>document.getElementById('Description')).value
 // this.categoryInfo=this.CategoryForm.value;
// categoryName=(<HTMLInputElement>document.getElementById('CategoryName')).value
// //this.CategoryForm.get('CategoryName1').value;
// //(<HTMLInputElement>document.getElementById('CategoryName')).value
// Industry=(<HTMLInputElement>document.getElementById('IndustryId')).value
// Description=(<HTMLInputElement>document.getElementById('Description')).value
//(<HTMLInputElement>document.getElementById('Description1')).value
}
//DemoFormFieldData=[];
formfields:any;


}
