<div style="margin-top: 75px;"></div>
<section class="setting"> 
  <div class="bg-white " style="height: 45px;"> 
<div class="sec-tab">   
 <ul class="nav" role="tablist" style="margin-bottom: 0px;height: 50px;">
    <li class="nav-item">
      <button id="button" routerLink="/industry" (click)="click1();onChangeRibon()" style="color: #E6800E;">         
        <img src="../../assets/img/Templates icon.png" height="18" width="22"  style="margin-right: 5px;" class="img"> Templates</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/OrganisationOverview" (click)="clickonConfiguration();onChangeconfig();" data-toggle="pill" href="#config" >
         <img src="../../assets/img/{{configicon}}" height="18" width="22"  style="margin-right: 5px;"class="img" > Configurations</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/MasterOverview" (click)="clickMaster();onChangeMaster();" data-toggle="pill" href="#master">
        <img src="../../assets/img/{{masterIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Masters</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/ApiOverview" (click)="clickApi();onChangeApi();"  data-toggle="pill" href="#api">
        <img src="../../assets/img/{{apiIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> API's</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/BlockchainOverview" (click)="clickBlockChain();onChangeBlockChain();"  data-toggle="pill" href="#blkchain">
        <img src="../../assets/img/{{blockChainIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> Blockchain</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/BillingOverview" (click)="clickBilling();onChangebilling();"  data-toggle="pill" href="#billing">
        <img src="../../assets/img/{{billingIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Billing</button>
    </li>
  </ul>
</div>
</div>
</section>
<div>
  <section class="indst sec-tab">
    <div class=" tab-content">
      <div class="card">
       
        <!-- Main in Template -->
        <div class="card-body" style="background-color: whitesmoke;" *ngIf="!addedited">
          <div class="row" style="margin-top: -17px;margin-left: -17px; margin-bottom: 10px;background-color: white">
            <div>
            
              <a routerLink="/industry" style="font-size: 13px;padding-left: 20px;">{{SelectedCategoryName}} </a><span style="margin-left: 1px;margin-right: 1px; font-size: large;">/</span>
              <a routerLink="/category/{{IndustryId}}" style="font-size: 13px;">Category</a>
              <!-- <a routerLink="/template">Template</a> -->
            </div>
          </div>
          <!--  -->
          <input type="text" (keyup)="onKeyUp($event)" name="search" placeholder="Search.." style="width:93.5%">
          <!-- <button type="save" style="margin-left: 20px; height: 30px;" class="btn btn-save">See More</button> -->
          <div class="pull-right addnew" (click)="refresh();onClearAll();addedited=false;this.isSave=false;"
            *ngIf="addedited" title="Back to Industry">
            <i style="color: #E6800E; font-size: x-large;" class="fa fa-home"></i>

          </div>
          <!-- <div class="pull-right addnew" style="margin-right: 10px;" (click)="addeditedCategory=false;"
              *ngIf="!addeditedCategory" title="Add to Category">
              <img src="../../assets/img/Plus.png" hight="15" width="15">
  
            </div> -->
          <div class="row">
            <div class="com-md-12">
              <div class="row" style="margin-bottom:15px ;margin-top: -10px;">

                <nav *ngFor="let data of LoanCAtegoryList" style="margin-left: 19px;margin-right: -10px;">
                  <!-- <button (click)="getTemplateByCategory(data.CategoryName)" id="button" class="btn js-click" mat-button>{{data.CategoryName}}</button> -->
                  <div *ngIf="selectedString==''">
                    <div class="card card1 text-center">
                      <a routerLink="/template/{{data.CategoryName}}/{{IndustryId}}/{{SelectedCategoryName}}">
                        <div class="card-body" style="margin-left: -5px;margin-top: -5px;">
                          <div class="btn" title="Industry Category">
                            <img src="{{data.CatIcon}}" hight="64px" width="64px" alt="Cat Icon">
                            <!-- <img src="../../assets/img/loanloan.png" hight="80px" width="80px"> -->
                            <p>{{data.CategoryName}}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </nav>
                <nav *ngFor="let data of filterdata" style="margin-left: -5px;margin-right: -10px;">
                  <div *ngIf="selectedString!=''">
                    <div class="card card1 text-center">
                      <a routerLink="/template/{{data.CategoryName}}">
                        <div class="card-body" style="margin-left: -5px;margin-top: -5px;">
                          <div class="btn" *ngIf="!addedited" title="Industry"
                            (click)="addedited=true;getIndustrybyid(data.Id);">
                            <img src="{{data.CatIcon}}" hight="80px" width="80px">
                            <p>{{data.CategoryName}}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </nav>
                <nav style="margin-left: 12px;">
                  <div class="card card1 tpxext-center">
                    <div class="card-body" routerLink="/stepertest/{{IndustryId}}"><!-- data-toggle="modal" data-target=".bd-example-modal-lg"-->
                      <div class="btn"
                        style="color: #E6800E ;font-size: 16px;font-family: open sans-serif; margin-top: 12%;"
                        *ngIf="!addedited" title="Create  Template">
                        <!--(click)="addedited=true;"-->
                        <img style="margin-left: 15px;" src="../../assets/img/Plus.png" hight="50" width="50">
                      </div>
                    </div>

                  </div>
                </nav>

              </div>
            </div>
          </div>
        </div>

        <!-- ribbion -->
        <div *ngIf="isRibbon">
          <section class="sec-bg">
            <div class="tab-content">
              <div id="template" class="container tab-pane active"><br>
                <div class="d-flex bd-highlight mb-3">
                  <div class=" bd-highlight ">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/industry">
                        <img  src="../../assets/img/indsry-icon.png" alt="">
                        <!-- <i class="fa fa-industry sec-icon" aria-hidden="true"></i> -->
                        <div class="card-body">
                          <p class="card-text">Industry</p>
                        </div>
                      </button>             
                    </div>
                    </div>         
                </div>
               
              </div>
              <div id="config" class="container tab-pane fade"><br>
                
                <div class="d-flex bd-highlight mb-3">
                  <div class="bd-highlight">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/organisation">
                        <img src="../../assets/img/org-icon.png" alt="">
                        <!-- <i class="fas fa-sitemap sec-icon"></i> -->
                      <div class="card-body">
                        <p class="card-text">Organization</p>
                      </div>
                      </button>              
                    </div>           
                  </div>
                  <div class="bd-highlight">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/branch">
                        <img src="../../assets/img/branch-icon.png" alt="">
                        <!-- <i class="fas fa-code-branch sec-icon"></i> -->
                      <div class="card-body">
                        <p class="card-text">Branch</p>
                      </div>
                      </button>              
                    </div>           
                  </div>
                  <div class="bd-highlight">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/role">
                        <img src="../../assets/img/role-icon.png" alt="">
                        <!-- <i class="fab fa-critical-role sec-icon"></i> -->
                        <div class="card-body">
                          <p class="card-text">Role</p>
                        </div>
                      </button>             
                    </div>           
                  </div>
                  <div class="bd-highlight">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/user">
                        <img src="../../assets/img/user-icon.png" alt="">
                        <!-- <i class="fa fa-user sec-icon" aria-hidden="true"></i> -->
                        <div class="card-body">
                          <p class="card-text">User</p>
                        </div>
                      </button>             
                    </div>           
                  </div>          
                </div>
               
              </div>
              <div id="master" class="container tab-pane fade"><br>
                <div class="d-flex bd-highlight mb-3">
                  <div class="p-2 bd-highlight">Master</div>         
                </div>             
              </div>
              <div id="api" class="container tab-pane fade"><br>
                <div class="d-flex bd-highlight mb-3">
                  <div class="p-2 bd-highlight">Api</div>         
                </div>
                     
              </div>
              <div id="blkchain" class="container tab-pane fade"><br>
                <div class="d-flex bd-highlight mb-3">
                  <div class="p-2 bd-highlight">Blockchain</div>         
                </div>              
              </div>
              <div id="billing" class="container tab-pane fade"><br><div class="d-flex bd-highlight mb-3">
                <div class="p-2 bd-highlight">Billing</div>         
              </div>
                     
              </div>
            </div>

            <div class="row" style="float:right;margin:15px">
              <button  routerLink="/category/{{IndustryId}}/{{SelectedCategoryName}}" class="btn-save rounded mt-2 ml-2">Back</button>
            </div>
          </section>
         
         
        </div>
      </div>
    </div>
    <div id="snackbar"></div>
    <div id="snackbarerror"></div>

  </section>
</div>
<!--Category Modal -->
<div class="modal fade" id="CategoryModalCenter" tabindex="-1" role="dialog" aria-labelledby="CategoryModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" style="margin-top: -0px;" id="exampleModalLongTitle"><img
            src="../../assets/img/backarrow.png" data-dismiss="modal" aria-label="Close" height="12" width="14"> New
          Category</h6>
        <div type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span style="color: #E6800E;" (click)="onClearAll();" aria-hidden="true">&times;</span>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="CategoryForm" autocomplete="off">

          <div class="row" style="margin-left:20px">
            <div class="col-sm-12">
              <div class="card-body">
                <div class="form-group card-text row">
                  <label for="CategoryName" class="col-sm-12 col-lg-3 col-form-label text-right">
                    Category Name<sup class="star-style"><i class="fa fa-star" aria-hidden="true"></i></sup>:</label>
                  <div class="col-sm-12 col-lg-9">
                    <input type="text" class="form-control" formControlName="CategoryName" id="CategoryName"
                      placeholder="Enter Category Name">
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="form-group card-text row">
                  <label class="col-sm-12 col-lg-3  col-form-label text-right">
                    Industry<sup class="star-style"><i class="fa fa-star" aria-hidden="true"></i> </sup>:</label>
                  <div class="col-sm-12 col-lg-9">
                    <select class="form-control" style="width: 235px;" formControlName="IndustryId" id="IndustryId">
                      <option>Select Industry</option>
                      <option *ngFor="let data of IndustryList" value="{{data.Id}}">{{data.Name}}</option>
                    </select>
                    <!-- <input type="text" class="form-control"
                      formControlName="IndustryId" id="IndustryId" placeholder="Enter IndustryId"> -->
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="form-group card-text row">
                  <label for="Description" class="col-sm-12 col-lg-3 col-form-label text-right">
                    Description<sup class="star-style"><i class="fa fa-star" aria-hidden="true"></i></sup>:</label>
                  <div class="col-sm-12 col-lg-9">

                    <textarea style="min-width: 235px;" class="form-control1" rows="5" formControlName="Description"
                      id="Description" placeholder=" Description"></textarea>
                  </div>
                </div>
              </div>
              <div class="card-body" style="margin-left: 28%;margin-bottom: -40px;margin-top: -20px;">
                <form id="file-upload-form" class="uploader">
                  <input id="file-upload" type="file" (change)="readUrl($event)" #UploadFileInput name="fileUpload"
                    accept="image/*" style="display: none;" />

                  <label for="file-upload" *ngIf="!url" id="file-drag" class="labelimg mt-1">
                    <img id="file-image" src="../../assets/img/uplaod.png" alt="Preview">

                    <div id="start" class="text-center">

                      <div>Select a file or drag here</div>

                      <span id="file-upload-btn" class="btn mt-2 btn-save">Select a file</span>
                    </div>
                    <div id="response" class="hidden">
                      <div id="messages"></div>

                    </div>
                    <img [src]="url" (change)="readUrl($event)">
                  </label>
                  <img [src]="url" (change)="readUrl($event)">
                  <!-- <img [src]="imagebyId" (change)="readUrl($event)"> -->

                </form>
              </div>
            </div>


          </div>


          <div class="d-flex justify-content-end mt-4 mb-4 card-body" style="margin-right: 55px;">
            <button type="save" (click)="onClearAll()" class="btn btn-clear mr-3">Clear All</button>
            <button type="save" (click)="onSaveCategory()" data-dismiss="modal" aria-label="Close"
              class="btn btn-save">Save</button>
            <!-- <button type="save" (click)="onUpdate(CategoryForm.value);" *ngIf="isUpdate"
                          class="btn btn-save">Update</button> -->
          </div>
        </form>
      </div>

    </div>
  </div>
</div>


<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" style="margin-top: -0px;" id="exampleModalLongTitle"><img
            src="../../assets/img/backarrow.png" data-dismiss="modal" aria-label="Close" height="12" width="14"> Default Template</h6>
        <div type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span style="color: #E6800E;" (click)="onClearAll();" aria-hidden="true">&times;</span>
        </div>
      </div>
      <div class="modal-body">
    <h1>Model content</h1>
    <mat-horizontal-stepper #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Template</ng-template>
          <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-6">
            <mat-form-field>                  
              <mat-label>Name</mat-label>
              <input matInput placeholder="Name" formControlName="firstCtrl" required>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-12 col-lg-12 col-xl-6 Descr">
            <mat-form-field class="">                 
              <!-- <mat-label>Description</mat-label> -->
              <textarea matInput placeholder="Description..."></textarea>
            </mat-form-field> 
          </div>
        </div>                               
          <div>
            <button class="btn-save rounded mt-2" mat-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <!-- #docregion label -->
      <mat-step [stepControl]="secondFormGroup" label="Form">
      <!-- #enddocregion label -->
      <!-- <div class="d-flex"> -->
        <div class="p-2 formfields">
          <form [formGroup]="secondFormGroup">
            <div class="d-flex flex-row-reverse formfields6">
              <div class="p-2 mt-3">
                <button class="btn btn-light rounded justify-content-end  mt-2 mr-3" mat-button>
                  <i class="fa fa-plus" style="color: #e6800e;"aria-hidden="true"></i></button>
              </div> 
            </div>
            <div class="row ">
              <div class="col-12 col-md-12 col-lg-12 col-xl-5 formfields1">
                <mat-form-field class="mr-4">
                  <mat-label>Name </mat-label>
                  <input matInput formControlName="secondCtrl" placeholder="Name"
                         required>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-5 formfields2">
                <mat-form-field class="mr-4 Descr">
                  <!-- <mat-label>Description</mat-label> -->
                  <textarea matInput placeholder="Description..."></textarea>                   
                </mat-form-field>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-2  formfields5">
                <mat-form-field class="mr-4">
                  <mat-label>Order</mat-label>
                  <input type="number" matInput formControlName="secondCtrl" placeholder="Order"
                         required>
                </mat-form-field> 
              </div>
            </div>
            <div class="row ">                   
              <!-- <div class="col-12 col-md-12 col-lg-12 col-xl-4 formfields3">
                <mat-form-field class="mr-4" appearance="fill">
                  <mat-label>Is dependent</mat-label>
                  <mat-select name="food">
                    <mat-option *ngFor="let food of foods" [value]="food.value">
                      {{food.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
              <!-- <div class="col-12 col-md-12 col-lg-12 col-xl-4  formfields4">
                <mat-form-field class="mr-4" appearance="fill">
                  <mat-label>Dependent Form</mat-label>
                  <mat-select [disabled]="disableSelect.value">                                               
                    <mat-option value="option3">Gold loan</mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
             
             <!--button-->
            </div>           
                                               
            <div>
              <button class="btn-save rounded mt-2" mat-button matStepperPrevious>Back</button>
              <button class="btn-save rounded mt-2 ml-2" mat-button matStepperNext>Next</button>
            </div>
          </form>
        </div>
               
      <!-- </div>  -->
       
      </mat-step>
      <!--3rd-->
      <mat-step [stepControl]="thiredFormGroup" label="Form fields">    
          <form [formGroup]="thiredFormGroup">
            <div class="verti-form mt-4">
              <div class="row ">
                <div class="col-sm-12 col-md-2 col-lg-2 mt-5 verti-form1">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-toggle="tab" href="#loan" role="tab" aria-controls="home">Loan Form</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#emi" role="tab" aria-controls="profile">Emi Form</a>
                    </li>
                   
                  </ul>
                </div>
              
                <div class="col-sm-12 col-md-10 col-lg-10 verti-form2">
                  <div class="tab-content">
                    <div class="tab-pane active" id="loan" role="tabpanel">
                      
                        <!--dflex-->
                <div class="">
                  <div class="">
                    <div class="d-flex flex-row-reverse formfields6">                             
                      <button class="btn btn-light rounded justify-content-end  mr-3" mat-button>
                        <i class="fa fa-plus" style="color: #e6800e;"aria-hidden="true"></i></button>                            
                  </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 loan">
                        <mat-form-field class="mr-4" >
                          <mat-label>Field name</mat-label>
                          <input matInput placeholder="Field name">                               
                        </mat-form-field>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 loan1">  
                        <mat-form-field class="mr-4 Descr">
                          <!-- <mat-label>Description</mat-label> -->
                         <textarea matInput placeholder="Description..."></textarea>                              
                        </mat-form-field>                             
                   
                      </div>
                    </div>
                                        
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 loan2">
                      <mat-form-field class="mr-4" appearance="fill">
                        <mat-label>Field type</mat-label>
                        <mat-select [disabled]="disableSelect.value">
                          <mat-option value="option1">Text</mat-option>  
                          <mat-option value="option1">Numeric</mat-option>                       
                          <mat-option value="option3">Alpha numeric</mat-option>
                          <mat-option value="option3">Date</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 loan3">
                      <mat-form-field class="mr-4">
                        <mat-label>Order</mat-label>
                        <input type="number" matInput placeholder="Order">                               
                      </mat-form-field>
                    </div>
                    <!-- <div class="col-sm-12 col-md-12 col-lg-1 loan4">
                      <div class="mt-4">
                        <button class="btn btn-light rounded justify-content-end  mt-2 mr-3" mat-button>
                          <i class="fa fa-plus" style="color: #e6800e;"aria-hidden="true"></i></button>
                      </div>
                    </div> -->
                  </div>                       
                   
                  </div>
                   
                </div>
                <!--end-->
                       
                                                  
                    </div>
                    <div class="tab-pane" id="emi" role="tabpanel">
                      <div class="d-flex flex-row-reverse formfields6">
                        <div class="">
                          <button class="btn btn-light rounded justify-content-end  mr-3" mat-button>
                            <i class="fa fa-plus" style="color: #e6800e;"aria-hidden="true"></i></button>
                        </div> 
                      </div>
                      <div class="">
                        <div class="">
                         
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 loan">
                              <mat-form-field class="mr-4" >
                                <mat-label>Field name</mat-label>
                                <input matInput placeholder="Field name">                               
                              </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 loan1">  
                              <mat-form-field class="mr-4 Descr">
                                <!-- <mat-label>Description</mat-label> -->
                               <textarea matInput placeholder="Description..."></textarea>                              
                              </mat-form-field>                             
                         
                            </div>
                          </div>
                                              
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 loan2">
                            <mat-form-field class="mr-4" appearance="fill">
                              <mat-label>Field type</mat-label>
                              <mat-select [disabled]="disableSelect.value">
                                <mat-option value="option1">Text</mat-option>  
                                <mat-option value="option1">Numeric</mat-option>                       
                                <mat-option value="option3">Alpha numeric</mat-option>
                                <mat-option value="option3">Date</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 loan3">
                            <mat-form-field class="mr-4">
                              <mat-label>Order</mat-label>
                              <input type="number" matInput placeholder="Order">                               
                            </mat-form-field>
                          </div>
                          <!-- <div class="col-sm-12 col-md-12 col-lg-12 col-xl-1 loan4">
                            <div class="mt-4">
                              <button class="btn btn-light rounded justify-content-end  mt-2 mr-3" mat-button>
                                <i class="fa fa-plus" style="color: #e6800e;"aria-hidden="true"></i></button>
                            </div>
                          </div> -->
                        </div>                       
                         
                        </div>
                        <!-- <div class="mt-5">
                          <button class="btn btn-light rounded justify-content-end  mt-2 mr-3" mat-button>
                            <i class="fa fa-plus" style="color: #e6800e;"aria-hidden="true"></i></button>
                        </div>  -->
                      </div>
                    </div>                         
                  </div>
                </div>
              </div>            
            
          </div>
        
            
            <div>
              <button class="btn-save rounded mt-2" mat-button matStepperPrevious>Back</button>
              <button class="btn-save rounded mt-2 ml-2" mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
      
        <!--fourth-->
        <mat-step [stepControl]="fourthFormGroup" label="Datavalues">    
          <form [formGroup]="fourthFormGroup">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput formControlName="fourthCtrl" placeholder=""
                     required>
            </mat-form-field>   
  
            <div>
              <button class="btn-save rounded mt-2" mat-button matStepperPrevious>Back</button>
              <button class="btn-save rounded mt-2 ml-2" mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>  
      <mat-step>
        <ng-template matStepLabel>Preview</ng-template>
        <p>You are now done.</p>
        <div>
          <button mat-button matStepperPrevious class="btn-save">Edit</button>
          <!-- <button class="btn-save rounded mt-2 ml-2" mat-button (click)="stepper.reset()">Reset</button> -->
          <button class="btn-save rounded mt-2 ml-2" mat-button>Submit</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
    </div>
    </div>
  </div>
</div>