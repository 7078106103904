<div style="margin-top: 75px;"></div>

<p>formfieldtest works!</p>
<div class="container-fluid">
    <div class="container">
        <div class="card">
            <div class="card-header">Form Field</div>
            <div class="card-body">
                <form [formGroup]="usersForm" (ngSubmit)="onSubmit()" #myForm="ngForm">
                    <ng-container *ngFor="let userFormGroup of usersForm.controls.users.value; let i = index">
                      <div [formGroup]="userFormGroup">
                        <label>
                          First name:
                          <input type="text" formControlName="firstName">
                        </label>
                        <label>
                          Last name:
                          <input type="text" formControlName="lastName">
                        </label>
                        <label>
                          Email:
                          <input type="text" formControlName="email">
                        </label>
                        <label>
                            <button (click)="onSubmit()">Save</button>
                          <button (click)="removeFormControl(i)">remove formGroup</button>
                        </label>
                      </div>
                    </ng-container>
                  </form>
            </div>
            <div class="card-footer">
                <button (click)="addFormControl()">add new user formGroup</button>
            </div>
        </div>
    </div>
</div>

  
