import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AutocompleteService } from '../service/autocomplete.service';
import { CategoryService } from '../service/category.service';
import { IndustryService } from '../service/industry.service';
import { TemplateService } from '../service/template.service';
import { UtilitiesService } from '../service/utility.service';
interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  isRibbon:boolean=false;
  CategoryForm:FormGroup;
  addedit:boolean=false;
  // templateForm: FormGroup;
  addedited:boolean =false;
  isUpdate:boolean=false;
  isSave:boolean=true;
  title = 'branch';
  mySubscription;
  IndustryId:any;
  templateForm: FormGroup;
  //
  selectedValue: string ="avs"; 
  disableSelect = new FormControl(false);
  
  foods: Food[] = [
    {value: 'steak-0', viewValue: 'yes'},
    {value: 'pizza-1', viewValue: 'No'},
   
  ];
  isLinear:boolean =true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thiredFormGroup: FormGroup;
  fourthFormGroup: FormGroup
  // fifthFormGroup:  FormGroup
  // firstFormGroup: FormGroup;
  // secondFormGroup: FormGroup;
  isEditable = false;
  constructor( private _formbuilder: FormBuilder,private _router: Router,private _autocompleteService:AutocompleteService,
    private _industry:IndustryService,private route: ActivatedRoute,private _Category:CategoryService,
    private _formBuilder:FormBuilder,
    private _utility:UtilitiesService,private _Template:TemplateService) {
      this.createForm();
      this.isRibbon=false;
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.mySubscription = this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
    // Trick the Router into believing it's last link wasn't previously loaded
        this._router.navigated = false;
        }
      });
     }
     ngOnDestroy(){
      console.log('app ngOnDestroy')
      if (this.mySubscription) {
       this.mySubscription.unsubscribe();
      }
    }
    createForm() {
      this.CategoryForm = this._formbuilder.group({
        Id: [''],
      CategoryName: ['', Validators.required],
      IndustryId:['', Validators.required],
      Description: [''],
      CatIcon: [''],
      CreatedBy: [1],
      UpdatedBy: [1],
      });
    }
  
    SelectedCategoryName:any;
  ngOnInit() {
    this.IndustryId = this.route.snapshot.paramMap.get("Id");
    console.log(this.IndustryId);
    this.SelectedCategoryName = this.route.snapshot.paramMap.get("Name");
    console.log(this.SelectedCategoryName);
this.getCategoryByIndustryId(this.IndustryId);
this.getIndustry();
    this.CategoryForm.get('IndustryId').setValue(this.IndustryId);
    //
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thiredFormGroup = this._formBuilder.group({
      thiredCtrl: ['', Validators.required]
    });
    this.fourthFormGroup = this._formBuilder.group({
      fourthCtrl: ['', Validators.required]
    });
  }

    //get Category by Industry Id
    selectedIndustryId:any;
    LoanCAtegoryList:any;
    getCategoryByIndustryId(id){
      console.log(id);
      this.selectedIndustryId=id;
      this._Category.getCategoryByIndustryId(id)
      .subscribe(data => {
        console.log(data);
        this.LoanCAtegoryList=data;
      })
  
    }
  IndustryList:any;
getIndustry()
{
  this._industry.getIndustryDtl().subscribe(data=>{
    console.log(data);
    this.IndustryList=data;
  })
}

  onSave(){
    console.log(this.templateForm.value);
    if (this.templateForm.valid) {


      this._Template.createTemplate(this.templateForm.value)
      .subscribe(data=>{
      console.log(data);
      this.addedited=false;
      var x = (<HTMLInputElement>document.getElementById('snackbar'));
      x.innerText = 'Template Created Sucessfully';
      this._utility.SuccessToast();
      
      setTimeout(() => {
      this.refresh();
      }, 2000);
      
      })
      
      }
      else {
      const invalid = [];
      const controls = this.templateForm.controls;
      for (const name in controls) {
      if (controls[name].invalid) {
      invalid.push(name);
      var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
      x.innerText = 'Please Enter ' + invalid[0] + '';
      this._utility.ErrorToast1();
      }
      }
      return invalid;
      }

  }

  onUpdate(e){
    console.log(e);
    if (this.templateForm.valid) {
      this._Template.UpdateTemplate(e).subscribe(data=>{
     console.log(data);
     var x = (<HTMLInputElement>document.getElementById('snackbar'));
       x.innerText = 'Template Updated Sucessfully';
       this._utility.SuccessToast();
       //this._router.navigate(["/branch"]);
       setTimeout(() => {
         this.refresh();
       }, 2000);
       //this.refresh();
     
      
      this.isUpdate=false;
      this.isSave=true;
      this.addedited=false;
     //his.BranchForm.reset
     }, (err) => {
       var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
       x.innerText = 'An Error Occured' + err.error.toString();
       this._utility.ErrorToast1();
     })
     }
     else {
     const invalid = [];
     const controls = this.templateForm.controls;
     for (const name in controls) {
     if (controls[name].invalid) {
       invalid.push(name);
       var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
       x.innerText = 'Please Enter ' + invalid[0] + '';
       this._utility.ErrorToast1();
     }
     }
     return invalid;
     }

  }
  onSaveCategory(){
    this.CategoryForm.patchValue({
      CatIcon: this.url,
    });
    // this.CategoryForm.patchValue({
    //   IndustryId: this.selectedIndustryId,
    // });
    console.log(this.CategoryForm.value);
    //console.log(e);
    // var postdata={
    //   CategoryName: (<HTMLInputElement>document.getElementById('CategoryName')).value,
    //   IndustryId:(<HTMLInputElement>document.getElementById('IndustryId')).value,
    //   Description: (<HTMLInputElement>document.getElementById('Description')).value
   
    // }
    // console.log(postdata);
    if (this.CategoryForm.valid) {
      this._Category.createCategory(this.CategoryForm.value)
        .subscribe(data => {
          console.log(data);
          this.addedited = false;
          var x = (<HTMLInputElement>document.getElementById('snackbar'));
          x.innerText = 'Category Created Sucessfully';
          this._utility.SuccessToast();

          setTimeout(() => {
            this.refresh();
          }, 2000);

        })

    }
    else {
      const invalid = [];
      const controls = this.CategoryForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
          var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
          x.innerText = 'Please Enter ' + invalid[0] + '';
          this._utility.ErrorToast1();
        }
      }
      return invalid;
    }
  }
  
  CategoryByIdList:any;
  getCategoryById(id){
    this._Category.getCategoryById(id).subscribe(
      data =>{
        console.log(data);
        this.CategoryByIdList=data;
      }
    )

  }

  onClearAll(){
    this.CategoryForm.reset();
  }

  refresh() {
//debugger;
this.CategoryForm.reset();
   window.location.reload();
  //  this._router.navigateByUrl('/category//miscellaneous/not-found', { skipLocationChange: true }).then(() =>
  //  this._router.navigate(["/category"]));

      console.log('refresh');
  }

  validateNumber(event: any) {
    this._utility.validateNumber(event);
  }
  url: any;
  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result;
        console.log(this.url);
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  text: any = '';
  selectedString = '';
  onKeyUp(x) { // appending the updated value to the variable 
    console.log(x.target.value);
    this.text += x.target.value + ' | ';
    this.selectedString = (x.target.value).toString();
    console.log(this.selectedString);
    this.getCategorySearch(this.selectedString);
  }
  filterdata: any;
  getCategorySearch(name) {
    this._Category.getCategorySearch(name).subscribe(data => {
      console.log(data);
      this.filterdata = data;
    })
  }
  // ribbon
  //#region robbon
  iconColor= 'Templates icon1.png'
  configicon='setting.png';
  masterIcon='data-icon.svg';
  apiIcon='api.png';
  blockChainIcon='blockchain.png';
  billingIcon='Billing-icon.svg';
 
  click1(){
    this.iconColor='Templates icon.png';
    console.log('color');
  }

  clickonConfiguration(){
    this.configicon='Configuration icon.png';
    console.log('color');
  }
  clickMaster(){
    this.masterIcon='data icon.png';
    console.log('color');
  }
  clickApi(){
    this.apiIcon='API icon.png';
    console.log('color');
  }
  clickBlockChain(){
    this.blockChainIcon='Blockchain icon.png';
    console.log('color');
  }
  clickBilling(){
    this.billingIcon='Billing icon.png';
    console.log('color');
  }


  onChangeRibon(){
    // this.iconColor= 'as.png';
    this.configicon='setting.png';
    this.masterIcon='data-icon.svg';
    this.apiIcon='api.png';
    this.blockChainIcon='blockchain.png';
    this.billingIcon='Billing-icon.svg';
  }

  onChangeconfig(){
      this.iconColor= 'Templates icon1.png';
      // this.configicon='setting.png';
      this.masterIcon='data-icon.svg';
      this.apiIcon='api.png';
      this.blockChainIcon='blockchain.png';
      this.billingIcon='Billing-icon.svg';
  }
  onChangeMaster(){
    this.iconColor= 'Templates icon1.png';
    this.configicon='setting.png';
    // this.masterIcon='data-icon.svg';
    this.apiIcon='api.png';
    this.blockChainIcon='blockchain.png';
    this.billingIcon='Billing-icon.svg';
}
onChangeApi(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  // this.apiIcon='api.png';
  this.blockChainIcon='blockchain.png';
  this.billingIcon='Billing-icon.svg';
}
onChangeBlockChain(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  this.apiIcon='api.png';
  // this.blockChainIcon='blockchain.png';
  this.billingIcon='Billing-icon.svg';
}
onChangebilling(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  this.apiIcon='api.png';
  this.blockChainIcon='blockchain.png';
  // this.billingIcon='Billing-icon.svg';
}

  //#endregion ribbon
}
