<div style="margin-top:90px !important">

    <p>
      tem works!
    </p>
    <!-- <div class="card-body">                                                
      <label>Search :</label>
      <div class="ng-autocomplete col-sm-12 col-lg-8 border p-1">
        <ng-autocomplete #ngAutoCompleteReactive
                         [data]="SearchReactive"
                         formControlName="name"
                         [initialValue]=""
                         [placeholder]="placeholder"
                         [searchKeyword]="keyword"
                         historyIdentifier="countriesReactiveList"
                         [historyHeading]="historyHeading"
                         historyListMaxNumber="3"
                         notFoundText="Not found"
                         [itemTemplate]="itemTemplateReactive"
                         [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>
  
        <ng-template #itemTemplateReactive let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>
  
        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
      
  
  
      
  </div> -->
  <div><!--[(ngModel)]="text"-->
    <label>Search :</label>&nbsp;&nbsp;
    <input type="text"  (keyup)="onKeyUp($event)"> 
  </div>
  <!-- <p>{{text}}</p> -->
  </div>
  <div>
    <div class="row">
   <!-- <ng-container> -->
    <div *ngFor="let test of SearchReactive">
      <div *ngIf="selectedString=='' || selectedString== test.TemplateName">
      <!-- <ng-container>
        <h5>Template Name :</h5>
        {{test.TemplateName}}
      </ng-container> -->
      <div class="container-fluid">
        <div class="card text-center">
            <!-- <div class="card-header">
                Template Search
              </div> -->
            <button><img class="card-img-top" src="/assets/img/templates.png" alt="Card image cap">         
                <div class="card-body">
                <h5 class="card-title">Template : {{test.TemplateName}}</h5>                     
                </div>
            </button> 
          <!-- <div class="card-header">
            Template Name :  {{test.TemplateName}}
          </div>
          <div class="card-body">
           <img src="../../assets/img/templates.png" hight="150" width="150">
          </div> -->
         
        </div>
        <!-- <div class="col-md-6">
            <div class="card align-items-center p-4">            
                    <button><img class="card-img-top" src="/assets/img/templates.png" alt="Card image cap">         
                         <div class="card-body">
                         <h5 class="card-title">Template : {{test.TemplateName}}</h5>                     
                         </div>
                     </button> 
               </div>         
         </div> -->
    </div>
      <!-- <div class="card text-center">
        <div class="card-header">
          Template Name :  {{test.TemplateName}}
        </div>
        <div class="card-body">
         
        </div>
       
      </div> -->
      </div>
      </div>
   <!-- </ng-container> -->
  </div>
  <div *ngIf="filterdata.length>=6">
    <button type="save" class="btn btn-save">See More</button>
  </div>
  </div>
  
  
  
  
  
  