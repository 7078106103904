<div class="container-login100">
  
    <div class="col-md-4 login-form-1">
        <h2 style="font-family: open sans-serif"><b>Welcome!</b></h2>
        <h4 style="font-family: open sans-serif;font-size: 20px;">Login into Partner Portal</h4>
        <br/>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="User Name *"  style="font-family: open sans-serif"/>
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required" style="font-family: open sans-serif">Username is required</div>
                                </div>
            </div>
            <div class="form-group">
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password *"  style="font-family: open sans-serif"/>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required" style="font-family: open sans-serif">Password is required</div>
                </div>
            </div>
            <div class="form-group">
                <a href="#" class="ForgetPwd" style="float: right;padding-bottom: 10px;font-family: open sans-serif">Forget Password?</a>
            </div>
            <div class="form-group">
                <button  class="btn btn-primary btn-lg btn-block" style="background-color: #1F3978;font-family: open sans-serif">Login</button>
                <div style="text-align: center;margin-top: 10px;" *ngIf="loginError"><span style="color: red;font-weight: bold;font-family: sans-serif;">Invalid Login !!!</span></div>
            </div>
        </form>
    </div>
    <div class="col-md-5 login-form-2">
        <img  class="image1" src="../../assets/img/Homeicon.png" style="width: 100%;height: 100%;">
    </div>
    <div id="snackbar"></div>
    <div id="snackbarerror"></div>
  </div>
  