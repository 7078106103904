<section class="Home-page">
  <nav class="navbar navbar-expand-lg navbar-light bg fixed-top">
    <a class="navbar-brand logo">
        <img src="/assets/img/logo-latest.png">  &nbsp;&nbsp;<span style="font-size: 20px;color: white;">QLDB explore</span> 
    </a>
  </nav>
    </section>
<section style="margin-top: 70px;">
<div style="margin-left:0px;">
    <!-- <div class="row"> -->
        <h2 style="margin-left: 20px; margin-bottom:5px">Transaction Details</h2>
        <div class="card" style="margin-left:15px;margin-right:15px;">
            <div class="card-body">
                <mat-tab-group style="color:green">
                    <mat-tab  label="Overview">
                        <table class="table" style="background-color: white;margin-bottom: 3rem;">
                            <thead>
                              <tr>
                                <!-- <th colspan="2"> <span style="color: red;">[ This is a <b>Ropsten Testnet </b> transaction only ]</span></th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                              
                                <td>
                                    <label><i class="fa fa-question-circle" ></i>Transaction ID:</label>
                                </td>
                                <td><label>{{Trans}} </label><i class="fa fa-copy "  style="margin-left: 5px;"></i></td>
                                
                              </tr>
                              <tr>
                              
                                <td>
                                    <label><i class="fa fa-question-circle" ></i>Transaction Hash:</label>
                                </td>
                                <td><label>{{Hash}} </label><i class="fa fa-copy "  style="margin-left: 5px;"></i></td>
                                
                              </tr>
                              <tr>
                                <td><label><i class="fa fa-question-circle"></i>Status:</label></td>
                                <td><label style="color: green;background-color: rgb(220, 247, 220);" class="btn"><i class="fa fa-check-circle" style="margin-right: 5px;"></i>Success</label></td>
                              </tr>
                              <tr>
                                <td><label><i class="fa fa-question-circle" ></i> Block:</label></td>
                                <td><label>
                                    {{Block}}</label></td>
                              </tr>
                              <tr>
                                <td><label> <i class="fa fa-question-circle" ></i> Timestamp:</label></td>
                                <td><label><i class="fa fa-clock-o" aria-hidden="true"></i> {{Date| date:'dd-MM-YYYY hh:mm'}} <i class="fa fa-copy " style="margin-left: 5px;" ></i></label></td>
                              </tr>
                              <!-- <tr>
                                <td><label><i class="fa fa-question-circle" ></i> From:</label></td>
                                <td><label style="color:lightgreen">0xd08955271f455c67bd06bdc96ad2cefbedbd894a </label><i class="fa fa-copy " style="margin-left: 5px;" ></i></td>
                              </tr>
                              <tr>
                                <td><label> <i class="fa fa-question-circle" ></i> To:</label></td>
                                <td>Contract<label style="color:lightgreen;margin-left: 2px;"> 0x8f2772e828bfb06816da58330e71cfaa6c98d4f1 <i class="fa fa-check-circle" style="color:green"></i> </label><i class="fa fa-copy "  style="margin-left: 5px;"></i></td>
                              </tr>
                              <tr>
                                <td><label> <i class="fa fa-question-circle" ></i> Value:</label></td>
                                <td><label  class="btn btn-light">0 Amazon QLDB</label> ($0.00)</td>
                              </tr> -->
                             
                              <!-- <tr *ngIf="!displayMore" >
                                  <td colspan="2"> <button class="btn" (click)="displayMore=true">Click to See More <i class="fa fa-arrow-down " style="margin-left: 5px;" ></i></button></td>
                              </tr>
                              <tr *ngIf="displayMore">
                                <td><label> <i class="fa fa-question-circle" ></i> Transaction Fee:</label></td>
                                <td>0.00111118 Amazon QLDB<label class="btn">($0.00)</label></td>
                              </tr>
                              <tr *ngIf="displayMore">
                                <td><label> <i class="fa fa-question-circle" ></i> Gas Price:</label></td>
                                <td><label>0.00000002 Amazon QLDB (20 Gwei)</label></td>
                              </tr>
                              <tr *ngIf="displayMore">
                                <td><label> <i class="fa fa-question-circle" ></i> Gas Limit:</label></td>
                                <td><label> 290,000</label></td>
                              </tr>
                              <tr *ngIf="displayMore">
                                <td><label> <i class="fa fa-question-circle" ></i> Gas Used by Transaction:</label></td>
                                <td><label>55,559 (19.16%)</label></td>
                              </tr>
                              <tr *ngIf="displayMore">
                                <td><label> <i class="fa fa-question-circle" ></i> Nonce :</label></td>
                                <td><label>12675 </label></td>
                              </tr>
                              <tr *ngIf="displayMore">
                                <td><label> <i class="fa fa-question-circle" ></i> Input Data:</label></td>
                                <td><label></label></td>
                              </tr> -->
                            </tbody>

                                <button *ngIf="displayMore" class="btn" (click)="displayMore=false">Click to See Less <i class="fa fa-arrow-up " style="margin-left: 5px;" ></i></button>
                            <!-- </div> -->
                          </table>
                
                    </mat-tab>
                    <!-- <mat-tab label="State">
                        <table class="table" style="background-color: white;margin-bottom: 3rem;">
                            <thead>
                               
                              <tr>
                                <td colspan="5"> <label class="btn " style="background-color: whitesmoke;">Advanced</label> <span style="color:lightslategray" >A set of information that represents the current state is updated when a transaction takes place on the network. The below is a summary of those changes :</span></td>
                               
                              </tr>
                              <tr>
                                  <th></th>
                                  <th>Address</th>
                                  <th>Before</th>
                                  <th>After</th>
                                  <th>State Diffrence</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                              <td><label></label></td>
                              <td><label>0x77d1ca4b0cd084e5a42</label></td>
                              <td><label>4,721.874939592849404581 Amazon QLDB </label></td>
                              <td><label>4,721.876050772849404581 Amazon QLDB </label></td>
                              <td><label>0.00111118</label></td>
                            
                              </tr>
                              <tr>
                                <td >
                                    <button class="btn" *ngIf="!displayMore2" (click)="displayMore2=true"> <i class="fa fa-arrow-down " style="margin-left: 5px;" ></i></button>
                                    <button class="btn" *ngIf="displayMore2" (click)="displayMore2=false"> <i class="fa fa-arrow-up " style="margin-left: 5px;" ></i></button>
                                </td>
                                 <td colspan="4"><label>0xd08955271f455c67bd06bdc96ad2cefbedbd894a</label></td>
                               
                              
                                </tr>
                                <tr *ngIf="!displayMore2">
                                    <td><label></label></td>
                                    <td><label>0xd08955271f455c67bd</label></td>
                                    <td><label>4.29522061 Amazon QLDB</label></td>
                                    <td><label>4.29410943 Amazon QLDB</label></td>
                                    <td><label>0.00111118</label></td>
                                
                                </tr>
                                <tr *ngIf="displayMore2">
                                    <td colspan="5">
                                        <div class="card">
                                            <div class="card-header">
                                              Featured
                                            </div>
                                            <div class="card-body">
                                              <h5 class="card-title">Special title treatment</h5>
                                              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                              <a href="#" class="btn btn-primary">Go somewhere</a>
                                            </div>
                                          </div>
                                    </td>
                                   
                                </tr>
                             
                            </tbody>
                          
                              
                           
                          </table>
                    </mat-tab> -->
                  
                  </mat-tab-group>
             
            </div>
        </div>
    
</div>




</section>
<nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
  <div class="d-flex" style="width: 100%;">
    <div class="mr-auto pt-3">
      <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
  </div>
      <div class="pt-3">
        <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
     </div>
      <div class="pt-1 logo-ft ">
        <img src="/assets/img/TRST001.jpeg">
         <!-- <img src="/assets/img/footer-logo.png"> -->
      </div>
  </div>
  
</nav>
<!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
  <div class="d-flex" style="width: 100%;">
      <div class="mr-auto pt-3">
          <p class="ft-text"> &copy;  2021  TRST01. All Rights Reserved</p>                   
      </div>
  </div>
  
</nav> -->