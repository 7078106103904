<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.css" />
<div style="margin-top: 70px;"></div>
<section>
  <mat-horizontal-stepper linear="true" #stepper>
    <!--labelPosition="bottom" [linear]="isLinear" [editable]="isEditable"-->
    <mat-step [stepControl]="CategoryForm" [completed]="false"  label="Category">
      <form [formGroup]="CategoryForm">
        <ng-template matStepLabel>
          Category
        </ng-template>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>Category Name</mat-label>
              <input matInput placeholder="Category Name" id="CategoryName" formControlName="CategoryName"   required>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>Industry</mat-label>
              <mat-select [disabled]="disableSelect.value" (selectionChange)='onIndustryChange($event)' [(ngModel)]="IndustryChange" id="IndustryId" formControlName="IndustryId">
                <mat-option *ngFor="let data of IndustryList"  value="{{data.Id}}" >{{data.Name}}</mat-option>
                <!-- <mat-option value="Education">Education</mat-option>                        -->

              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field style="margin-top: -10px;" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea matInput id="Description" rows="1" formControlName="Description"></textarea>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <form id="file-upload-form" class="uploader">
              <input id="file-upload" type="file" (change)="readUrl($event)" #UploadFileInput name="fileUpload"
                accept="image/*" style="display: none;" />

              <label for="file-upload" *ngIf="!url" id="file-drag" class="labelimg mt-1">
                <img id="file-image" src="../../assets/img/uplaod.png" height="32" width="32" alt="Preview">

                <div id="start" class="text-center">

                  <!-- <div>Select a file or drag here</div> -->

                  <!-- <span id="file-upload-btn" class="btn mt-2 btn-save">Select a file</span> -->
                </div>
                <!-- <div id="response" class="hidden">
                <div id="messages"></div>

              </div> -->
                <!-- <img [src]="url" height="64" width="64" (change)="readUrl($event)"> -->
              </label>
              <img [src]="url" *ngIf="url" height="64" width="64" (change)="readUrl($event)">
              <!-- <img [src]="imagebyId" (change)="readUrl($event)"> -->

            </form>
          </div>
          <div class="d-flex justify-content-end mt-4 mb-4 card-body" style="margin-right: 55px;">
            <!-- <button type="save" (click)="onClearAll()" class="btn btn-outline-primary" >Clear All</button> -->
            <!-- <button type="save" (click)="onSaveCategory()" class="btn-save rounded mt-2">Save</button> -->

          </div>
        </div>
        <div>
          <!-- <button type="save" style="margin-right: 10px;" (click)="onSaveCategory()" mat-button matStepperNext class="btn-save rounded mt-2">Save</button> -->
          <button class="btn-save rounded mt-2" (click)="getCategoryFormData();" mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup1" [completed]="false" label="Form">
      <ng-template matStepLabel>
        Form
      </ng-template>
      <form [formGroup]="secondFormGroup">
        <div class="row ">
          <div class="col-md-5">
            <mat-form-field class="mr-4" appearance="outline">
              <mat-label>Name </mat-label>
              <input matInput formControlName="Name" id="Name" placeholder="Name" required>
            </mat-form-field>
          </div>
          <div class="col-md-5">
            <mat-form-field class="mr-4" appearance="outline">
              <mat-label>Description</mat-label>
              <input matInput id="Description" formControlName="Description">
            </mat-form-field>
          </div>
          <div class="col-md-2" style="margin-top: 6px;">
            <button class="btn btn-light mt-2 mr-3" *ngIf="!isUpdate"
              (click)="onsaveForm();cancelDefaultForm();addedited=true;" mat-button>
              <i class="fa fa-plus" style="color: #e6800e;" aria-hidden="true"></i></button>
            <button class="btn btn-primary"  style="margin-top: 6px;" *ngIf="isUpdate" (click)="OnUpdate()" mat-button>Update</button>
          </div>
        </div>
        <div class="row ">
          <div id="table-wrapper">
            <div id="table-scroll">
              <table id="customers" *ngIf="DefaultFormData.length !=0">
                <thead class="thead">
                  <tr>
                    <th></th>
                    <th>Form Name</th>
                    <th>Description</th>
                    <th>Sl. No</th>
                  </tr>
                </thead>
                <tbody class="scrollist">
                  <tr *ngFor="let data of DefaultFormData;let i=index;">
                    <td><button style="margin-right: 8px;" class="btn btn-primary" (click)="cancelDefaultForm();OnSelectRow($event,data)">
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button class="btn btn-danger" (click)="OnDelete(i,data)">
                        <i class="fa fa-remove"></i>
                      </button>
                    </td>
                    <td>{{data.Name}}</td>
                    <td>{{data.Description}}</td>
                    <td>{{i+1}}</td>
                  </tr>
                </tbody>
    
              </table>
            </div>
          </div>

        </div>

        <div>
          <button class="btn-save rounded mt-2" style="margin-right: 10px;" mat-button matStepperPrevious>Back</button>
          <!-- <button class="btn-save rounded mt-2"  style="margin-right: 10px;" (click)="saveSecondForm();steperSecondStep();" mat-button matStepperNext>Save</button> -->
          <button class="btn-save rounded mt-2 ml-2"  style="margin-right: 10px;" (click)="steperSecondStep();onloadTabSelection(TabValue,TabForm);" mat-button matStepperNext>Next</button>
          <!--(click)="onsaveForm();"-->
        </div>
      </form>
      <!-- </div> -->

      <!-- </div>  -->

    </mat-step>
    <mat-step [stepControl]="thiredFormGroup1" [completed]="false"  label="Form Field">
      <ng-template matStepLabel>
        Form Field
      </ng-template>
      <form [formGroup]="thiredFormGroup">

        <tabset class="tab" style="max-height: 250px;">
          <div *ngFor="let data of DefaultFormData; let i=index">
            <tab heading="{{data.Name}}" (selectTab)="onloadTabSelection(data.Name,data.FormId);selectedTab=true;" #tabName
              class="tabs__item">
              <div style="margin-top: -0px;" *ngIf="data.Name==noOfTab">
                <div class="row" style="margin: 10px;">
                  <div class="col-md-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Field Name </mat-label>
                      <input matInput formControlName="FieldName" id="Name" placeholder="Name" required>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Field type</mat-label>
                      <mat-select [disabled]="disableSelect.value" id="FieldType" formControlName="FieldType" required>
                        <mat-option value="Text">Text</mat-option>
                        <mat-option value="Integer">Integer</mat-option>
                        <mat-option value="Decimal">Decimal</mat-option>
                        <mat-option value="Date">Date</mat-option>
                        <mat-option value="DateTime">DateTime</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline">
                      <mat-label>Description</mat-label>
                      <input matInput id="Description" formControlName="Description">
                    </mat-form-field>
                  </div>
                  <div class="col-md-2" style="margin-top: 6px;">
                    <button class="btn btn-light rounded justify-content-end  mt-2 mr-3 pull-right"
                    (click)="onsaveFormField();cancelDefaultFormField();" *ngIf="!addeditedthirdForm" mat-button>
                    <i class="fa fa-plus" style="color: #e6800e;" aria-hidden="true"></i></button>
                  <button class="btn btn-primary pull-right" style="margin-top: 6px;" *ngIf="addeditedthirdForm" (click)="OnUpdateFormField()"
                    mat-button>Update</button>
                  </div>
                </div>

                <div class="row " style="margin: 8px;">
                  <div id="table-wrapper">
                    <div id="table-scroll">
                  <table id="customers" *ngIf="FormFieldData.length !=0">
                    <thead class="thead">
                      <tr>
                        <th></th>
                        <th>Field Name</th>
                        <th>FieldType</th>
                        <th>Description</th>
                        <th>Sl. No</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of FormFieldData;let i=index;">
                        <td>
                          <button style="margin-right: 8px;" class="btn btn-primary"
                            (click)="cancelDefaultFormField();OnSelectRowFormField($event,data)">
                            <i class="fa fa-pencil"></i>
                          </button>
                          <button class="btn btn-danger" (click)="OnDeleteformField(i,data)">
                            <i class="fa fa-remove"></i>
                          </button>
                        </td>
                        <td>{{data.FieldName}}</td>
                        <td>{{data.FieldType}}</td>
                        <td>{{data.Description}}</td>
                        <td>{{i+1}}</td>
                      </tr>
                    </tbody>

                  </table>

                  </div>
                  </div>
                </div>

              </div>


            </tab>
          </div>
          <!-- <button class="btn btn-primary" (click)="onThirdFormTest()"> Save Third Form Data</button> -->
          <!-- <tab heading="Test 22" style="max-width: 750px;" class="tabs__item"
                              style="border: #d8d6d6;border-style: solid;border-width: thin; width:100%">
                              <h2>Welcome to new Tab 111</h2>
          
                          </tab> -->
        </tabset>
   
        <div>
          <button class="btn-save rounded mt-2"  style="margin-right: 10px;" mat-button matStepperPrevious>Back</button>
          <!-- <button class="btn-save rounded mt-2"  style="margin-right: 10px;" (click)="saveThirdForm();onsteperthird();" mat-button matStepperNext>Save</button> -->
          <button (click)="onsteperthird();" class="btn-save rounded mt-2" mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="fourthFormGroup" [completed]="false"  label="Preview">
      <!-- <p>You are in Preview</p> -->
      <ng-template matStepLabel>
        Preview
      </ng-template>
      <form [formGroup]="fourthFormGroup"> 
        <!-- <ng-template matStepLabel>Preview</ng-template> -->
        <div class="container  Preview">      
          <div class="pre-box">
            <div class="ctgr-1 sec-br pb-4"> 
              <h3>Category</h3>
              <div class="row">
                <div class="col-md-3" style="text-align: right;"> Category Name :</div>
                <div class="col-md-3" style="text-align: left;"> {{CategoryName1}}</div>
                <div class="col-md-3" style="text-align: right;"> Industry :</div>
                <div class="col-md-3" style="text-align: left;"> {{selectedIndustryForPreview}}</div>
                <div class="col-md-3" style="text-align: right;"> Description :</div>
                <div class="col-md-3" style="text-align: left;"> {{Description}}</div>
                <div class="col-md-3" style="text-align: right;"></div>
                <div class="col-md-3">
                  <div class="catg-img">
                    <img [src]="url" height="64" width="64" (change)="readUrl($event)">
                  </div>
                </div>
              
              </div>
              <!-- <div class="row mb-3">              
              <div class="col-sm-12 col-lg-6">               
                <div class="row">
                  <div class="col-sm-6 col-md-3 catg-lable">
                    Category Name:
                  </div>
                  <div class="col-sm-6 col-md-9 text-left">
                
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6">
                <div class="row">
                  <div class="col-sm-6 col-md-3 catg-lable">
                    Industry:
                  </div>
                  <div class="col-sm-6 col-md-9 text-left">
                    {{selectedIndustryForPreview}}
                  </div>
                </div>
              </div>
              </div>
              <div class="row">
              <div class="col-sm-12 col-lg-6">
                <div class="row">
                  <div class="col-sm-12 col-md-3 catg-lable">
                    Description:
                  </div>
                  <div class="col-sm-12 col-md-9 text-left">
                    {{Description}}
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6">
                <div class="row">
                  <div class="col-sm-6 col-md-3 catg-lable">
                    Icon :
                  </div>
                  <div class="col-sm-6 col-md-9 text-left">
                    <div class="catg-img">
                      <img [src]="url" height="64" width="64" (change)="readUrl($event)">
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
             <!--rowend-->
            
            <!-- </div>             -->
          </div> <!--ctgry-end--->   
          <!--form--start-->
          <!-- <div class="forminfo sec-br">
            <h3>Form Information</h3>            
              <div style="margin-top: -8px; margin-bottom: 10px;" class="row  ml-2 mr-2 table-wrapper-scroll-y my-custom-scrollbar">
                  <div id="table-wrapper">
                    <div id="table-scroll">
                      <table id="customers" *ngIf="DefaultFormData.length !=0">
                        <thead class="thead">
                          <tr>
                          
                            <th>Form Name</th>
                            <th>Description</th>
                            <th>Sl. No</th>
                          </tr>
                        </thead>
                        <tbody class="scrollist">
                          <tr *ngFor="let data of DefaultFormData;let i=index;">
                            <td>{{data.Name}}</td>
                            <td>{{data.Description}}</td>
                            <td>{{i+1}}</td>
                          </tr>
                        </tbody>
            
                      </table>
                    </div>
                  </div>
              </div>            
          </div>   -->
          <!--end--> 
          <!--3rd-form-field---> 
          <div *ngFor="let dd of DefaultFormData">
            <div class="card">
            <div class="card-header">
            {{dd.Name}}
            </div>
            <div class="card-body">
            <table id="customers">
            <thead class="thead">
            <tr>
            <!-- <th>Form</th> -->
            <th>Field Name</th>
            <th>FieldType</th>
            <th>Description</th>
            <th>Sl. No</th>
            </tr>
            </thead>
            <tbody *ngFor="let data of AllFormFieldData;let i=index;">
            <tr *ngIf="data.Form==dd.Name" style="margin-bottom: 5px;">
            <!-- <td>{{data.Form}}</td> -->
            <td>{{data.FieldName}}</td>
            <td>{{data.FieldType}}</td>
            <td>{{data.Description}}</td>
            <td>{{i+1}}</td>
            </tr>
            
            </tbody>
            
            </table>
            </div>
            </div>
            
            </div>
          <!-- <div class="formfield sec-br">
            <h3>Form Field Information</h3>
            <div class="row ml-5 mb-3 mr-5 table-wrapper-scroll-y my-custom-scrollbar">
            <table id="customers" *ngIf="FormFieldData.length !=0">
              <thead class="thead">
                <tr> 
                  <th>Sr.No</th>                                                                               
                  <th>Field Name</th>
                  <th>Field Type</th>
                  <th>Description</th>                 
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of AllFormFieldData;let i=index;">
                  <td>{{i+1}}</td>
                  <td>{{data.FieldName}}</td>
                  <td>{{data.FieldType}}</td>
                  <td>{{data.Description}}</td>
                  <td>                                          
                    <button class="btn pencil" (click)="cancelDefaultFormField();OnSelectRowFormField($event,data)">
                        <i class="fa fa-pencil"></i>
                      </button>
                        <button class="btn delete" (click)="OnDeleteformField(i,data)">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </div> -->
        </div>
        </div> 

        <div style="margin-bottom: 25px;">
          <button class="btn-save rounded mt-2"  style="margin-right: 10px;" mat-button matStepperPrevious>Back</button>
          <!-- <button class="btn-save rounded mt-2"  style="margin-right: 10px;" (click)="onSaveCategory();saveSecondForm();saveThirdForm();" mat-button matStepperNext>Finish</button> -->
          <button class="btn-save rounded mt-2"  style="margin-right: 10px;" (click)="onSaveCategory();" mat-button matStepperNext>Finish</button>
          <button (click)="onsteperforth();" class="btn-save rounded mt-2" mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="fifthFormGroup" [completed]="false" label="API">
      <ng-template matStepLabel>API</ng-template>
      <!-- <p>You are now API.</p> -->
      <form [formGroup]="fifthFormGroup"> 
        <div class="container-fluit">
          <div class="card">
            <div class="card-header">API Format</div>
            <div class="card-body" style="height: 150px;overflow-y: scroll;">
              <div *ngFor="let data of FormatData;">
                <!-- <ul>{{data | prettyprint}}</ul> -->
                <ul [innerHTML]="data | prettyprint"></ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">API Example</div>
            <div class="card-body" style="height: 150px;overflow-y: scroll;">
              
              <div *ngFor="let data of ExampleData;let i=index" #inputUrl>
                <!-- <ul>{{data | prettyprint}}</ul> -->
                <ul [innerHTML]="data | prettyprint"></ul>
              </div>
              <!-- <div><button mat-button color="primary" >Copy</button></div> -->
            </div>
          </div>
        </div>
        <div>
          <button class="btn-save rounded mt-2"  style="margin-right: 10px;" mat-button matStepperPrevious>Back</button>
          <!-- <button class="btn-save rounded mt-2"  style="margin-right: 10px;" (click)="onsteperfifth();" mat-button matStepperNext>Save</button> -->
          <button routerLink="/category/{{IndustryId}}/{{CategoryName}}" class="btn-save rounded mt-2" mat-button matStepperNext>Done</button>
        </div>
      </form>
    </mat-step>
    <!-- <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <p>You are now done.</p>
      <div>
        <button class="btn-save rounded mt-2 ml-2" style="margin-right: 8px;" (click)="backsteperfifthStep();" mat-button matStepperPrevious>Back</button>
         <button class="btn-save rounded mt-2 ml-2" mat-button (click)="stepper.reset()">Reset</button><button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious class="btn-save">Edit</button>
       
        <button class="btn-save rounded mt-2 ml-2" mat-button>Submit</button>
      </div>
    </mat-step> -->
  </mat-horizontal-stepper>
  <div id="snackbar"></div>
  <div id="snackbarerror"></div>
</section>