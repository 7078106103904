<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.css" />
<div style="margin-top: 75px;"></div>
<section class="setting"> 
  <div class="bg-white " style="height: 45px;"> 
<div class="sec-tab">   
 <ul class="nav" role="tablist" style="margin-bottom: 0px;height: 50px;">
    <li class="nav-item">
      <button id="button" routerLink="/industry" (click)="click1();onChangeRibon()" style="color: #E6800E;">         
        <img src="../../assets/img/Templates icon.png" height="18" width="22"  style="margin-right: 5px;" class="img"> Templates</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/OrganisationOverview" (click)="clickonConfiguration();onChangeconfig();" data-toggle="pill" href="#config" >
         <img src="../../assets/img/{{configicon}}" height="18" width="22"  style="margin-right: 5px;"class="img" > Configurations</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/MasterOverview" (click)="clickMaster();onChangeMaster();" data-toggle="pill" href="#master">
        <img src="../../assets/img/{{masterIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Masters</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/ApiOverview" (click)="clickApi();onChangeApi();"  data-toggle="pill" href="#api">
        <img src="../../assets/img/{{apiIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> API's</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/BlockchainOverview" (click)="clickBlockChain();onChangeBlockChain();"  data-toggle="pill" href="#blkchain">
        <img src="../../assets/img/{{blockChainIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> Blockchain</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/BillingOverview" (click)="clickBilling();onChangebilling();"  data-toggle="pill" href="#billing">
        <img src="../../assets/img/{{billingIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Billing</button>
    </li>
  </ul>
</div>
</div>
</section>
<section>
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E6800E" type = "line-scale-pulse-out-rapid" [fullScreen] = "true">
  </ngx-spinner>
<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="firstFormGroup"  [completed]="false">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Category</ng-template>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field appearance="outline">
            <mat-label>Category Name</mat-label>
            <input matInput placeholder="Category Name" id="CategoryName" formControlName="CategoryName" required>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="mr-4" appearance="outline">
            <mat-label>Industry</mat-label>
            <mat-select [disabled]="disableSelect.value" (selectionChange)='onIndustryChange($event)' [(ngModel)]="IndustryChange" id="IndustryId" formControlName="IndustryId">
              <mat-option *ngFor="let data of IndustryList"  value="{{data.Id}}" >{{data.Name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="mr-4" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput id="Description" rows="2" formControlName="Description"></textarea>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <form id="file-upload-form" class="uploader">
            <input id="file-upload" type="file" (change)="readUrl($event)" #UploadFileInput name="fileUpload"
              accept="image/*" style="display: none;" />

            <label for="file-upload" *ngIf="!url" id="file-drag" class="labelimg mt-1">
              <img id="file-image" src="../../assets/img/uplaod.png" height="32" width="32"  alt="Preview">

              <div id="start" class="text-center">
              </div>
            </label>
            <img [src]="url" *ngIf="url" height="32" width="32"  (change)="readUrl($event)">
          </form>
        </div>
      </div>
      <div>
        <button class="btn-save rounded mt-2" (click)="getCategoryFormData();"  mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <!-- #docregion label -->
  <mat-step [stepControl]="secondFormGroup"  [completed]="false" label="Form">
    <!-- #enddocregion label -->
    <form [formGroup]="secondFormGroup">
      <div class="row ">
        <div class="col-md-5">
          <mat-form-field class="mr-4" appearance="outline">
            <mat-label>Name </mat-label>
            <input matInput formControlName="Name" id="Name" placeholder="Name" >
          </mat-form-field>
        </div>
        <div class="col-md-5">
          <mat-form-field class="mr-4" appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput id="Description" formControlName="Description">
          </mat-form-field>
        </div>
        <div class="col-md-2" style="margin-top: 6px;">
          <button class="btn btn-light rounded justify-content-end  mt-2 mr-3" *ngIf="!isUpdate"
            (click)="onsaveForm();cancelDefaultForm();addedited=true;" mat-button>
            <i class="fa fa-plus" style="color: #e6800e;" aria-hidden="true"></i></button>
          <button class="btn btn-primary" style="margin-top: 6px;" *ngIf="isUpdate" (click)="OnUpdate()" mat-button>Update</button>
        </div>
      </div>
      <div class="row ">
        <div id="table-wrapper">
          <div id="table-scroll">
            <table id="customers" *ngIf="DefaultFormData.length !=0">
              <thead class="thead">
                <tr>
                  <!-- <th style="width: 30px;"></th>
                  <th style="width: 30px;"></th> -->
                  <th style="width: 100px;"></th>
                  <th>Form Name</th>
                  <th>Description</th>
                  <th>Sl. No</th>
                </tr>
              </thead>
              <tbody class="scrollist">
                <tr *ngFor="let data of DefaultFormData;let i=index;let last = last;" (click)="rowSelect(i)" [class.active]="i == HighlightRow">
                  <!-- <td>
                    <button class="btn btn-success" *ngIf="i==0" [disabled]='true' (click)="moveUp(data, i);"><i class="fa fa-arrow-up"></i></button>
                    <button class="btn btn-success" *ngIf="i!=0" [disabled]='false' (click)="moveUp(data, i);"><i class="fa fa-arrow-up"></i></button>
                </td> -->
                <!-- <td>
                  <button class="btn btn-success" *ngIf="last" [disabled]='true' (click)="moveDown(data, i);"><i class="fa fa-arrow-down"></i></button>
                  <button class="btn btn-success"  *ngIf="!last" [disabled]='false'  (click)="moveDown(data, i);"><i class="fa fa-arrow-down"></i></button>
              </td> -->
                  <td><button class="btn btn-primary" style="margin-right: 8px;" (click)="cancelDefaultForm();OnSelectRow($event,data)">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-danger" (click)="OnDelete(i,data)">
                      <i class="fa fa-remove"></i>
                    </button>
                  </td>
                  <td>{{data.Name}}</td>
                  <td>{{data.Description}}</td>
                  <td>{{i+1}}</td>
                </tr>
              </tbody>
  
            </table>
             <div style="float: right;" *ngIf="DefaultFormData.length !=0" class="mt-5">
              <button class="btn btn-success mr-3" (click)="moveUp(rowIndex);"><i class="fa fa-arrow-up"></i></button>
              <button class="btn btn-success" (click)="moveDown(rowIndex);"><i class="fa fa-arrow-down"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
        <button class="btn-save rounded mt-2 ml-2" (click)="onloadTabSelection(TabValue,TabForm)"  mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup"  [completed]="false" label="Form Fields">
   
    <form [formGroup]="thirdFormGroup">
      <tabset class="tab" style="max-height: 250px;">
        <div *ngFor="let data of DefaultFormData; let i=index">
          <tab heading="{{data.Name}}" (selectTab)="onloadTabSelection(data.Name,data.FormId);selectedTab=true;" #tabName
            class="tabs__item">
            <div style="margin-top: -0px;" *ngIf="data.Name==noOfTab">
              <div class="row" style="margin: 10px;">
                <div class="col-md-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Field Name </mat-label>
                    <input matInput formControlName="FieldName" id="Name" placeholder="Name" required>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Field Type</mat-label>
                    <mat-select [disabled]="disableSelect.value" id="FieldType" formControlName="FieldType" required>
                      <mat-option value="Text">Text</mat-option>
                      <mat-option value="Integer">Integer</mat-option>
                      <mat-option value="Decimal">Decimal</mat-option>
                      <mat-option value="Date">Date</mat-option>
                      <mat-option value="DateTime">DateTime</mat-option>
                      <mat-option value="LatLong">LatLong</mat-option>
                      <mat-option value="Mobile">MobileNo</mat-option>
                      <mat-option value="LatLong">LatLong</mat-option>
                      <mat-option value="Image">Image</mat-option>
                      <mat-option value="pdf">PDF</mat-option>
                      <mat-option value="txt">Txt</mat-option>
                      <mat-option value="doc">MS Word</mat-option>
                      <mat-option value="xls">MS Excel</mat-option>
                     
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput id="Description" formControlName="Description">
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-form-field appearance="outline">
                    <mat-label>Key Type</mat-label>
                    <mat-select [disabled]="disableSelect.value" id="KeyType" formControlName="KeyType">
                      <mat-option value="PrimaryKey">Primary Key</mat-option>
                      <mat-option value="MappingKey">Mapping Key</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- <mat-checkbox appearance="outline" color='primary' style="padding: 30px;" formControlName="PrimaryKey">PK</mat-checkbox>
                  <mat-checkbox appearance="outline" color='primary' formControlName="MappingKey">MK</mat-checkbox> -->
                </div>
                <div class="col-md-2" style="margin-top: 6px;">
                  <button class="btn btn-light rounded justify-content-end  mt-2 mr-3 pull-right"
                  (click)="onsaveFormField();cancelDefaultFormField();" *ngIf="!addeditedthirdForm" mat-button>
                  <i class="fa fa-plus" style="color: #e6800e;" aria-hidden="true"></i></button>
                <button class="btn btn-primary pull-right" style="margin-top: 6px;" *ngIf="addeditedthirdForm" (click)="OnUpdateFormField()"
                  mat-button>Update</button>
                </div>
              </div>

              <div class="row " style="margin: 7px;">
                <div id="table-wrapper">
                  <div id="table-scroll">
                <table id="customers" *ngIf="FormFieldData.length !=0">
                  <thead class="thead">
                    <tr>
                  <!-- <th style="width: 30px;"></th>
                  <th style="width: 30px;"></th> -->
                  <th style="width: 100px;"></th>
                      <th>Field Name</th>
                      <th>FieldType</th>
                      <th>Description</th>
                      <th>Sl. No</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of FormFieldData;let i=index;let last=last" (click)="rowSelect(i)" [class.active]="i == HighlightRow">
                      <!-- <td>
                        <button class="btn btn-success" *ngIf="i==0" [disabled]='true' (click)="moveUp1(data, i);"><i class="fa fa-arrow-up"></i></button>
                        <button class="btn btn-success" *ngIf="i!=0" [disabled]='false' (click)="moveUp1(data, i);"><i class="fa fa-arrow-up"></i></button>
                    </td>
                    <td>
                      <button class="btn btn-success" *ngIf="last" [disabled]='true' (click)="moveDown1(data, i);"><i class="fa fa-arrow-down"></i></button>
                      <button class="btn btn-success"  *ngIf="!last" [disabled]='false'  (click)="moveDown1(data, i);"><i class="fa fa-arrow-down"></i></button>
                  </td> -->
                      <td>
                        <button class="btn btn-primary" style="margin-right: 8px;"
                          (click)="cancelDefaultFormField();OnSelectRowFormField($event,data)">
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button class="btn btn-danger" (click)="OnDeleteformField(i,data)">
                          <i class="fa fa-remove"></i>
                        </button>
                      </td>
                      <td>{{data.FieldName}}</td>
                      <td>{{data.FieldType}}</td>
                      <td>{{data.Description}}</td>
                      <td>{{i+1}}</td>
                    </tr>
                  </tbody>

                </table>
                <div style="float: right;" *ngIf="FormFieldData.length !=0" class="mt-5">
                  <button class="btn btn-success mr-3" (click)="moveUp1(rowIndex);"><i class="fa fa-arrow-up"></i></button>
                  <button class="btn btn-success" (click)="moveDown1(rowIndex);"><i class="fa fa-arrow-down"></i></button>
                </div>
                </div>
                </div>
              </div>

            </div>


          </tab>
        </div>
      </tabset>
      <div>
        <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
        <button class="btn-save rounded mt-2 ml-2" (click)="preview()"  mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="fourthFormGroup"  [completed]="false" label="Preview">
    <form [formGroup]="fourthFormGroup"> 
      <ng-template matStepLabel>Preview</ng-template>
      <div class="container  Preview">      
        <div class="pre-box">
          <div class="ctgr-1 sec-br"> 
            <h3>Category</h3>
            <div class="row" style="margin-bottom: -20px;">
              <div class="col-md-3" style="text-align: right;"> Category Name :</div>
              <div class="col-md-3" style="text-align: left;"> {{CategoryName1}}</div>
              <div class="col-md-3" style="text-align: right;"> Industry :</div>
              <div class="col-md-3" style="text-align: left;"> {{selectedIndustryForPreview}}</div>
              <div class="col-md-3" style="text-align: right;"> Description :</div>
              <div class="col-md-3" style="text-align: left;"> {{Description}}</div>
              <div class="col-md-3" style="text-align: right;">Icon :</div>
              <div class="col-md-3">
                <div class="catg-img">
                  <img [src]="url" height="64" width="64" (change)="readUrl($event)">
                </div>
              </div>
            
            </div>
            <!-- <div class="row mb-3">              
            <div class="col-sm-12 col-lg-6">               
              <div class="row">
                <div class="col-sm-6 col-md-3 catg-lable">
                  Category Name:
                </div>
                <div class="col-sm-6 col-md-9 text-left">
                  {{data.CategoryName}}
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-6">
              <div class="row">
                <div class="col-sm-6 col-md-3 catg-lable">
                  Industry:
                </div>
                <div class="col-sm-6 col-md-9 text-left">
                  {{selectedIndustryForPreview}}
                </div>
              </div>
            </div>
            </div>
            <div class="row">
            <div class="col-sm-12 col-lg-6">
              <div class="row">
                <div class="col-sm-12 col-md-3 catg-lable">
                  Description:
                </div>
                <div class="col-sm-12 col-md-9 text-left">
                  {{data.Description}}
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-6">
              <div class="row">
                <div class="col-sm-6 col-md-3 catg-lable">
                </div>
                <div class="col-sm-6 col-md-9 text-left">
                  <div class="catg-img">
                    <img [src]="url" height="64" width="64" (change)="readUrl($event)">
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div> 
        <div *ngFor="let dd of DefaultFormData">
          <div class="card">
          <div class="card-header">
          {{dd.Name}}
          </div>
          <div class="card-body">
          <table id="customers">
          <thead class="thead">
          <tr>
          <!-- <th>Form</th> -->
          <th>Field Name</th>
          <th>FieldType</th>
          <th>Description</th>
          <th>Sl. No</th>
          </tr>
          </thead>
          <tbody *ngFor="let data of AllFormFieldData;let i=index;">
          <tr *ngIf="data.Form==dd.Name" style="margin-bottom: 5px;">
          <!-- <td>{{data.Form}}</td> -->
          <td>{{data.FieldName}}</td>
          <td>{{data.FieldType}}</td>
          <td>{{data.Description}}</td>
          <td>{{data.Idd}}</td>
          <!-- <td>{{i+1}}</td> -->
          </tr>
          
          </tbody>
          
          </table>
          </div>
          </div>
          
          </div>
      </div>
      </div> 

      <div style="margin-bottom: 25px;">
        <button class="btn-save rounded mt-2"  style="margin-right: 10px;" mat-button matStepperPrevious>Back</button>
        <button class="btn-save rounded mt-2"  style="margin-right: 10px;" (click)="onSaveCategory();" mat-button matStepperNext>Create Template & Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step label="API"  [completed]="false">
    <ng-template matStepLabel>API</ng-template>
    <form> 
      <div class="container-fluit">
        <div class="card">
          <div class="card-header">API Format</div>
          <div class="card-body" style="height: 150px;overflow-y: scroll;">
            <div *ngFor="let data of FormatData;">
              <ul [innerHTML]="data | prettyprint"></ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">API Example</div>
          <div class="card-body" style="height: 150px;overflow-y: scroll;">
            
            <div *ngFor="let data of ExampleData;let i=index" #inputUrl>
              <ul [innerHTML]="data | prettyprint"></ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button class="btn-save rounded mt-2 ml-2"  mat-button matStepperPrevious>Back</button>
        <button routerLink="/category/{{IndustryId}}/{{selectedIndustryForPreview}}" class="btn-save rounded mt-2 ml-2" mat-button matStepperNext>Done</button>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
</section>