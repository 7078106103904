import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AutocompleteService } from '../service/autocomplete.service';
import { BranchService } from '../service/branch.service';
import { OrganizationService } from '../service/organization.service';
import { RoleService } from '../service/Role.service';
import { UserService } from '../service/user.service';
import { UtilitiesService } from '../service/utility.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  Role :string;
  Organisationaccess : boolean;
  Useraccess : boolean;

  UserForm: FormGroup;
  addedited:boolean =false;
  isUpdate:boolean=false;
  isSave:boolean=true;
  title = 'user';
  mySubscription;
  
  constructor( private _formbuilder: FormBuilder,private _router: Router,private _user:UserService,
    private _role:RoleService,private _autocompleteService:AutocompleteService, private location :Location ,
    private _utility:UtilitiesService, private _Branch: BranchService,private _Org:OrganizationService) {
      this.createForm();

      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.mySubscription = this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
    // Trick the Router into believing it's last link wasn't previously loaded
        this._router.navigated = false;
        }
      });
    }

  ngOnInit() {
    this.getautoComplete2();
    this.getautoComplete1();
    this.getautoComplete();
    this.getOrgList();
    this.getUserDtl();
    this.getRoleList();
    this.getBranchList();
    console.log(localStorage.getItem('Role'))
    if(this.Role == 'user')
    {
          this.Organisationaccess= false;
          this.Useraccess =false;
    }
  }

  goBack(){
    this.location.back();
  }
  ngOnDestroy(){
    console.log('app ngOnDestroy')
    if (this.mySubscription) {
     this.mySubscription.unsubscribe();
    }
  }
  createForm() {
    this.UserForm = this._formbuilder.group({
      Id: [''],
      OrganisationId : [''],
      OrganisationName : [''],
      BranchId :[''],
      BranchName : [''],
      UserName : ['',Validators.required],
      Password : ['',Validators.required],
      FirstName : ['',Validators.required],
      MiddleName : [''],
      LastName : ['',Validators.required],
      Email : ['',Validators.email],
      name: [{value: '', disabled: false}, Validators.required],
      name1: [{value: '', disabled: false}, Validators.required],
      name2: [{value: '', disabled: false}, Validators.required],
      ContactNumber : ['',Validators.required],
      RoleId : [''],
      RoleName : [''],
      CreatedBy : [''],
      UpdatedBy : [''],
   
    });
  }

  // get Name() { return this.UserForm.get('Name'); }
  // get Code() { return this.UserForm.get('Code'); }
  // get Name() { return this.UserForm.get('Name'); }
  // get Name() { return this.UserForm.get('Name'); }
  UserListList:any='';
  getUserDtl(){
    this._user.getUserDetails().subscribe(data=>{
      this.UserListList=data;
      console.log(this.UserListList);
    
    })
  }

  editUserForm:any;

  getUserbyid(id:any){
    this.isUpdate=true;
    this.isSave=false;
    this.addedited =true;
    this._user.getUserById(id).subscribe(data=>{
    this.editUserForm = data[0];
    console.log(data);
    data[0].name='';
    data[0].name1='';
    data[0].name2='';
      this.UserForm.setValue(data[0]);
      this.UserForm.patchValue({
        name:
        {value:data[0].OrganisationId, name:data[0].OrganisationName }
        });
        //
        this.UserForm.patchValue({
          name1:
          {value:data[0].BranchId, name:data[0].BranchName }
          });
          //
          this.UserForm.patchValue({
            name2:
            {value:data[0].RoleId, name:data[0].RoleName }
            });
    })


  }

OrgList:any=0;
  getOrgList(){
    this._Org.getOrganizationDetails().subscribe(data => {
      this.OrgList=data;
      console.log(this.OrgList);
    })
  }

  BranchList:any;
  
  getBranchList(){
    this._Branch.getBranchDetails().subscribe(data=>{
      
      this.BranchList=data;
      console.log(this.BranchList);
    })

  }
RoleList:any;
  getRoleList(){
    this._role.getRole('603ca6e7b5ae6739b8dd77e9').subscribe(data=>{

      this.RoleList=data;
      console.log(this.RoleList);
    })

  }

  setOrgListDtl(){

  }


  orgName:any='';
  onSave(){
   // console.log(this.UserForm.value);
   // console.log(this.UserForm.get('name').value);
    var autocompdata = this.UserForm.get('name').value;
    // console.log(autocompdata.id)
    // console.log(autocompdata.name)
    this.UserForm.patchValue({
      OrganisationName: autocompdata.name,
    });
    this.UserForm.patchValue({
      OrganisationId: autocompdata.id,
    });
   // console.log(this.UserForm.get('name1').value);
    var autocompdata1 = this.UserForm.get('name1').value;
    // console.log(autocompdata1.id)
    // console.log(autocompdata1.name)
    this.UserForm.patchValue({
      BranchName: autocompdata1.name,
    });
    this.UserForm.patchValue({
      BranchId:autocompdata1.id,
    });

   // console.log(this.UserForm.get('name2').value);
    var autocompdata2 = this.UserForm.get('name2').value;
    // console.log(autocompdata2.id)
    // console.log(autocompdata2.name)
    this.UserForm.patchValue({
      RoleName:autocompdata2.name,
    });
    this.UserForm.patchValue({
      RoleId: autocompdata2.id,
    });
    if (this.UserForm.valid) {
   // console.log(this.UserForm.value);
//    this._Org.getOrgById(this.UserForm.get('OrganisationId').value).subscribe( data =>{
//     console.log(data[0].Name);
//     this.orgName=data[0].Name;
//     this.UserForm.patchValue({
//       OrganisationName: data[0].Name,
//  });
//  this.UserForm.patchValue({
//   OrganisationId: data[0].Id,
// });
// this._Branch.getBranchById(this.UserForm.get('BranchId').value).subscribe( p=>{
//   console.log(p[0].Name);

//   this.UserForm.patchValue({
//     BranchName: p[0].Name,
// });
// this.UserForm.patchValue({
//   BranchId: p[0].Id,
// });
// this._role.getRole(this.UserForm.get('RoleId').value).subscribe(rs=>{
// console.log(rs[0].Name);
// this.UserForm.patchValue({
//   RoleName: rs[0].Name,
// });
// this.UserForm.patchValue({
//   RoleId: rs[0].Id,
// });
// });
// });
// });
console.log(this.UserForm.value)
 
      this._user.createUser(this.UserForm.value)
      .subscribe(data=>{
        console.log(data);
        this.addedited=false;
        var x = (<HTMLInputElement>document.getElementById('snackbar'));
     x.innerText = 'User Created Sucessfully';
     this._utility.SuccessToast();
  
     setTimeout(() => {
       this.refresh();
     }, 2000);
      

 
}, (err) => {
  var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
  x.innerText = 'An Error Occured' + err.error.toString();
  this._utility.ErrorToast1();
})
}
else {
const invalid = [];
const controls = this.UserForm.controls;
for (const name in controls) {
if (controls[name].invalid) {
  invalid.push(name);
  var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
  x.innerText = 'Please Enter ' + invalid[0] + '';
  this._utility.ErrorToast1();
}
}
return invalid;
}

  }

  onUpdate(e){
    console.log(e);
     // console.log(this.UserForm.value);
   // console.log(this.UserForm.get('name').value);
   var autocompdata = this.UserForm.get('name').value;
   // console.log(autocompdata.id)
   // console.log(autocompdata.name)
   this.UserForm.patchValue({
     OrganisationName: autocompdata.name,
   });
   this.UserForm.patchValue({
     OrganisationId: autocompdata.id,
   });
  // console.log(this.UserForm.get('name1').value);
   var autocompdata1 = this.UserForm.get('name1').value;
   // console.log(autocompdata1.id)
   // console.log(autocompdata1.name)
   this.UserForm.patchValue({
     BranchName: autocompdata1.name,
   });
   this.UserForm.patchValue({
     BranchId:autocompdata1.id,
   });

  // console.log(this.UserForm.get('name2').value);
   var autocompdata2 = this.UserForm.get('name2').value;
   // console.log(autocompdata2.id)
   // console.log(autocompdata2.name)
   this.UserForm.patchValue({
     RoleName:autocompdata2.name,
   });
   this.UserForm.patchValue({
     RoleId: autocompdata2.id,
   });
    if (this.UserForm.valid) {
   this._user.UpdateUser(e).subscribe(data=>{
  console.log(data);
  var x = (<HTMLInputElement>document.getElementById('snackbar'));
    x.innerText = 'User Updated Sucessfully';
    this._utility.SuccessToast();
    //this._router.navigate(["/user"]);
    setTimeout(() => {
      this.refresh();
    }, 2000);
    //this.refresh();
  
   
   this.isUpdate=false;
   this.isSave=true;
   this.addedited=false;
  //his.UserForm.reset
  }, (err) => {
    var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
    x.innerText = 'An Error Occured' + err.error.toString();
    this._utility.ErrorToast1();
  })
  }
  else {
  const invalid = [];
  const controls = this.UserForm.controls;
  for (const name in controls) {
  if (controls[name].invalid) {
    invalid.push(name);
    var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
    x.innerText = 'Please Enter ' + invalid[0] + '';
    this._utility.ErrorToast1();
  }
  }
  return invalid;
  }

  }


  onClearAll(){
    this.UserForm.reset();
  }

  refresh() {
//debugger;
this.UserForm.reset();
 //  window.location.reload();
   this._router.navigateByUrl('/user//miscellaneous/not-found', { skipLocationChange: true }).then(() =>
   this._router.navigate(["/user"]));

      console.log('refresh');
  }

  validateNumber(event: any) {
    this._utility.validateNumber(event);
  }

  //auto complite
    // auto complite data list
    countriesReactive:[];

    public placeholder: string = 'Enter the Org. Name';
    public keyword = 'name';
    public historyHeading: string = 'Recently selected';
    
    
    getautoComplete()
    {
    this._autocompleteService.getOrganizationDetails().subscribe(data =>{
    console.log(data)
    this.countriesReactive = data;
    })
    }


     //auto complite Branch
    // auto complite data list
    countriesReactive1:[];

    public placeholder1: string = 'Enter the Branch Name';
    public keyword1 = 'name';
    public historyHeading1: string = 'Recently selected';
    
    
    getautoComplete1()
    {
    this._autocompleteService.getBranchDetails().subscribe(data =>{
    console.log(data)
    this.countriesReactive1 = data;
    })
    }

      //auto complite Role
    // auto complite data list
    countriesReactive2:[];

    public placeholder2: string = 'Enter the Role Name';
    public keyword2 = 'name';
    public historyHeading2: string = 'Recently selected';
    
    
    getautoComplete2()
    {
    this._autocompleteService.getRoleDetails().subscribe(data =>{
    console.log(data)
    this.countriesReactive2 = data;
    })
    }

}