<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
<nav class="navbar navbar-expand-lg navbar-light bg fixed-top" style="padding: 0px !important;">
  <img src="../assets/img/image@2x.png" style="width: 100%;height: 100px;" />
</nav>
</div>
</div>
<div class="container-fluid" style="padding: 0px !important;margin-top: 101px;">
  <div class="card col-md-6" style="padding: 0px !important;">
    
    <div class="card-body" style="padding: 0px !important;">
      <!-- <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divPersonalDetails">
        <div class="card-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: white;"><img src="../assets/img/kapilchit.png" width="60px" height="60px" /></div>
        <div class="card-body" style="padding: 0px;padding-left: 10px;">
          <div class="table-responsive-sm">
            <table class="table" style="font-family: open sans-serif;padding: 0px;" >
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td colspan="1" rowspan="2" style="width: 100px;"><img src="https://etgdocstorage.s3.ap-south-1.amazonaws.com/MaheshBank/Icons/CustImage.jpg" style="width: 100px;height: 100px;"/></td>
                  <td style="white-space: normal !important;text-align: justify;padding: 0px;padding-top: 15px;font-weight: bold;">{{PersonName}}</td>
                </tr>
                <tr>
                  <div class="card" style="padding: 5px !important;height: 70px;background-color: #ebe8e6;width: 120px;margin-left:0px ;">
                  <div class="row justify-content-center">
                    <div class="col-md-12" style="padding-left: 40px;">Ticket No</div>
                    <div class="col-md-12" style="font-size: 35px;padding-top: 10px;font-weight: bold;padding-left: 60px;">{{PersonTicket}}</div>
                  </div>
                </div>
                </tr>
                <tr>
                  <td colspan="2" style="white-space: normal !important;text-align: justify;padding: 0px;"><i class="fa fa-id-card" style="margin-right: 5px;color: lightgray;"></i>{{PersonPAN}}</td>
                </tr>
                
                <tr>
                  <td colspan="2" style="padding: 0px;"><i class="fa fa-mobile" style="margin-right: 5px;color: lightgray;font-size: 25px;padding-top: 5px;"></i> {{PersonContactNo}} {{'/ '+PersonAltContactNo}}</td>
                </tr>
                <tr>
                  <td colspan="2" style="white-space: normal !important;text-align: justify;word-break: break-word;padding: 0px;padding-top: 5px;"><i class="fa fa-envelope" style="margin-right: 5px;color: lightgray;"></i>{{PersonEmail}}</td>
                </tr>
                <tr>
                  <td colspan="2" style="white-space: normal !important;word-break: break-word;padding: 0px;padding-top: 5px;"><i class="fa fa-address-card" style="margin-right: 5px;color: lightgray;"></i>{{PersonAddress}}</td>
                </tr>
              
              </tbody>
            </table>
          </div>
          
        </div>
      </div> -->
      <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divPersonalDetails">
        <div class="card-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: white;"><img src="../assets/img/kapilchit.png" width="60px" height="60px" /></div>
        <div class="card-body" style="padding-top: 3px;padding-bottom: 3px;">
          <div class="table-responsive-sm">
            <table class="table" style="font-family: open sans-serif;padding: 0px;" >
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td colspan="1" rowspan="4" style="width: 100px;padding-left: 0px;" >
                    <img src="../assets/img/PP.jpg" width="100px" height="100px" id="div1"/>
                    <!-- <img src="https://etgdocstorage.s3.ap-south-1.amazonaws.com/MaheshBank/Icons/CustImage.jpg" style="width: 100px;height: 100px;"/> -->
                  </td>
                  <td style="white-space: normal !important;text-align: justify;padding: 0px;padding-top: 2px;font-weight: bold;padding-left: 5px;"><i class="fa fa-user" style="margin-right: 5px;color: lightgray;font-size: 20px;"></i><span style="padding-left: 9px;">{{PersonName}}</span></td>
                </tr>
                <tr>
                  <td style="white-space: normal !important;text-align: justify;padding: 0px;padding-top: 6px;padding-left: 5px;"><i class="fa fa-id-card" style="margin-right: 5px;color: lightgray;"></i><span style="padding-left: 2px;">{{PersonPAN}}</span></td>
                </tr>
                
                <tr>
                  <td style="padding: 0px;padding-top: 6px;padding-left: 5px;"><i class="fa fa-phone" style="color: lightgray;font-size: 21px;"></i> <span style="padding-left: 10px;">{{PersonContactNo}} {{PersonAltContactNo}}</span></td>
                </tr>
                <tr>
                  <td style="white-space: normal !important;text-align: justify;word-break: break-word;padding: 0px;padding-top: 6px;padding-left: 5px;"><i class="fa fa-envelope" style="margin-right: 5px;color: lightgray;"></i><span style="padding-left: 5px;">{{PersonEmail}}</span></td>
                </tr>
                <tr>
                  <td colspan="2" style="white-space: normal !important;word-break: break-word;padding: 0px;padding-top: 10px;"><i class="fa fa-address-card" style="margin-right: 5px;color: lightgray;"></i> {{PersonAddress}}</td>
                </tr>
              
              </tbody>
            </table>
          </div>
          <div class="card col-md-3" style="padding: 5px !important;height: 70px;background-color: #ffb366;">
          <div class="row justify-content-center">
              <div class="col-md-12" style="text-align: center;">Ticket No</div>
              <div class="col-md-12" style="text-align: center;font-size: 35px;padding-top: 10px;font-weight: bold;">{{PersonTicket}}</div>
          </div>
        </div>
        </div>
      </div>
      <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divChitDetails">
        <div class="card-header" style="text-align: center;font-size: 18px;font-weight: bold;">Chit Details</div>
        <div class="card-body" style="padding-top: 3px;padding-bottom: 3px;">
       
            <div class="table-responsive-sm">
              <table class="table" style="font-family: open sans-serif">
                <thead>
                </thead>
                <tbody>
                  <tr>
                    <td style="white-space: nowrap;">PSO No</td>
                    <td>:</td>
                    <td style="white-space: normal !important;text-align: justify;">{{ChitPSONo}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;">Chit Comm date</td>
                    <td>:</td>
                    <td>{{ChitCommdate}}</td>
                  </tr>
                  <tr>
                    <td>Termination date</td>
                    <td>:</td>
                    <td style="white-space: normal !important;text-align: justify;">{{ChitTerminationdate}}</td>
                  </tr>
                  <tr>
                    <td>Chit value</td>
                    <td>:</td>
                    <td style="white-space: normal !important;word-break: break-word;">{{Chitvalue}}</td>
                  </tr>
                  <tr>
                    <td>Group</td>
                    <td>:</td>
                    <td>{{ChitGroup}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;">Group members</td>
                    <td>:</td>
                    <td>{{ChitMembers}}</td>
                  </tr>
                  <tr>
                    <td>Chit status</td>
                    <td>:</td>
                    <td style="white-space: normal !important;text-align: justify;word-break: break-word;">{{ChitStatus}}</td>
                  </tr>
                  <tr>
                    <td>Ongoing month</td>
                    <td>:</td>
                    <td style="white-space: normal !important;text-align: justify;word-break: break-word;">{{ChitOngoingMonth}}</td>
                  </tr>
                  <tr>
                    <td>Last installment</td>
                    <td>:</td>
                    <td style="white-space: normal !important;text-align: justify;word-break: break-word;">{{LastInstall}}</td>
                  </tr>
                  <tr>
                    <td>Paid on</td>
                    <td>:</td>
                    <td style="white-space: normal !important;text-align: justify;word-break: break-word;">{{PaidOn}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          
        </div>
      </div>
      <p style="font-style: italic;font-size:12px;text-align: center;margin-bottom: 60px;" *ngIf="pclick">Verified on TRST01 cloud <a href="{{VerifyLink}}" target="_blank" style="color: #1f3978;font-size: 12px;">Blockchain</a> Platform.<br> 
        For More Details <a
        style="color: #EA8039;font-style: italic;font-size:12px;" (click)="EventTable()" >click here</a> & For Ledger <a  style="color: #EA8039;font-style: italic;font-size:12px;" (click)="innerClicked()" >click here</a> </p>
        <div class="card" *ngIf="innerEventtable">
          <div class="card-header"
          style="text-align: center;background-color: #fff;font-weight: bold;font-size: 16px;">
          <span style="float: left;padding-top: 20px;"><i class="fa fa-reply" style="font-size: 20px;padding: 3px;"  (click)="back()"></i></span><img src="../assets/img/kapilchit.png" width="60px" height="60px" style="margin-right: 44px;" />
          <!-- <div class="card-header"
          style="text-align: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;"><img
              src="../assets/img/back.png" style="width: 19px;height: 16px;"  (click)="back()"/>  -->
      </div>
         <div style="text-align: center;padding-top: 10px;font-weight: bold;">Chit Details</div>
          <div class="card" style="margin:10px ;border: 1px solid #EA8039;">
            <table class="table" style="font-family: open sans-serif;text-align: center;">
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td style="padding: 0px;padding-left: 10px;padding-top: 10px;text-align: left;width: 220px;font-weight: bold;color: #1E3978;">PSO Approval</td><td><a href="https://trst01.in/verifyqldb/19efd7dc-b868-4d2a-8fc4-c570f3aa7df6" target="_blank" ><img src="../assets/img/verify.png" style="width: 30px;height: 20px;"/></a></td>
                </tr>
                <tr>
                  <td style="padding: 0px;text-align: left;padding-left: 10px;"><span>Sub-registrar, RajendraNagar, Hyderabad</span></td><td style="padding: 0px;"><span style="">29 May 2016</span></td>
                </tr>
                
              </tbody>
            </table>
        </div>
         
        <div class="card" style="margin:10px ;margin-top: 10px;border: 1px solid #EA8039;">
          <table class="table" style="font-family: open sans-serif;text-align: center;">
            <thead>
            </thead>
            <tbody>
              <tr>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;text-align: left;width: 220px;font-weight: bold;color: #1E3978;">Chit Commencement </td> <td ><a href="https://trst01.in/verifyqldb/19efd7dc-b868-4d2a-8fc4-c570f3aa7df6" target="_blank" ><img src="../assets/img/verify.png" style="width: 30px;height: 20px;"/></a></td>
              </tr>
              <tr>
                <td style="padding: 0px;text-align: left;padding-left: 10px;"><span>Financial Dist. Hydeabad</span></td><td style="padding: 0px;"><span style="">31 May 2016</span></td>
              </tr>
              
            </tbody>
          </table>
      </div>
      <div class="card" style="margin:10px ;margin-top: 10px;border: 1px solid #EA8039;">
        <table class="table" style="font-family: open sans-serif;text-align: center;">
          <thead>
          </thead>
          <tbody>
            <tr>
              <td style="padding: 0px;padding-left: 10px;padding-top: 10px;text-align: left;width: 220px;font-weight: bold;color: #1E3978;">Bid Amount</td> <td ><a href="https://trst01.in/verifyqldb/19efd7dc-b868-4d2a-8fc4-c570f3aa7df6" target="_blank" ><img src="../assets/img/verify.png" style="width: 30px;height: 20px;"/></a></td>
            </tr>
            <tr>
              <td style="padding: 0px;text-align: left;padding-left: 10px;"><span>Rs.4,00,000</span></td><td style="padding: 0px;"><span style="">30 Aug 2016</span></td>
            </tr>
            
          </tbody>
        </table>
    </div>
    <div class="card" style="margin:10px ;margin-top: 10px;border: 1px solid #EA8039;">
      <table class="table" style="font-family: open sans-serif;text-align: center;">
        <thead>
        </thead>
        <tbody>
          <tr>
            <td style="padding: 0px;padding-left: 10px;padding-top: 10px;text-align: left;width: 220px;font-weight: bold;color: #1E3978;">Last EMI</td><td ><a href="https://trst01.in/verifyqldb/19efd7dc-b868-4d2a-8fc4-c570f3aa7df6" target="_blank" ><img src="../assets/img/verify.png" style="width: 30px;height: 20px;"/></a></td>
          </tr>
          <tr>
            <td style="padding: 0px;text-align: left;padding-left: 10px;"><span>Rs.9,900 | Ref:KKBK00086386</span></td><td style="padding: 0px;"><span style="">31 May 2016</span></td>
          </tr>
          
        </tbody>
      </table>
  </div>
    <!-- <p style="text-align: center;" *ngIf="eventclick"><a  style="color: #EA8039;font-style: italic;font-size:12px;" (click)="innerClicked()" >click here</a><span style="font-size: 12px;"> for Ledger</span></p> -->
    </div>
    <div class="card" *ngIf="innerLedgerTable">
      <div class="card-header"
      style="text-align: center;background-color: #fff;font-weight: bold;font-size: 16px;">
      <span style="float: left;padding-top: 20px;"><i class="fa fa-reply" style="font-size: 20px;padding: 3px;"  (click)="backinner()"></i></span><img src="../assets/img/kapilchit.png" width="60px" height="60px" style="margin-right: 44px;" />
      <!-- <i class="fa fa-reply" style="font-size: 20px;padding: 3px;"  (click)="backinner()"></i> -->
  </div>
      <!-- <div class="card-header"
      style="text-align: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;"><img
          src="../assets/img/back.png" style="width: 19px;height: 16px;"  (click)="backinner()"/> Back
    </div> -->
    <div class="card"   style="padding:0px !important;margin: 0px;"> 
      <div class="card-body" style="padding:0px !important;padding-top: 0px !important;">
        <table class="table" style="font-family: open sans-serif">
          <thead>
            <th colspan="3" style="text-align: center;padding: 10px;">Ledger Details</th>
          </thead>
           <tbody>
            <ng-container *ngFor="let chitdocs of LedgerDocumentData let i=index">
              <tr style=" border-bottom: 1px solid !important; " *ngFor="let docs of chitdocs ">
                 <td style="padding: 0px;padding-left:10px;padding-top: 10px;"><span style="font-weight: bold;">{{docs.V1}}</span><p>{{docs.V2}}</p></td>
                 <td style="font-weight: bold;" [ngStyle]="{'color': docs.V4}"><span>{{docs.V3}}</span> <span [ngStyle]="{'color': docs.V4}"> {{docs.V6}}</span> </td>
                 <td style="font-weight: bold;" [ngStyle]="{'color': docs.V4}"><span>{{docs.V5}}</span> <span [ngStyle]="{'color': docs.V4}"> {{docs.V7}}</span> </td>
               </tr>
              </ng-container>
            </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
  <!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
      <div class="d-flex" style="width: 100%;">
          <img src="../assets/img/trst01footer.png" style="width: 100%;" />
      </div>
    </nav> -->
    <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
      <div class="d-flex" style="width: 100%;">
          <div class="mr-auto pt-3">
              <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
          </div>
          <div class="pt-3">
           <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
         </div>
          <div class="pt-1 logo-ft ">
            <img src="/assets/img/TRST001.jpeg">
          </div>
      </div>
    </nav>
  </div>
</div>