import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  //_apiurl: string = 'https://etgarage.in/api';
  _apiurl: string = apiurl;
  constructor(private _http: HttpClient) { }

  getMobile(QRCode)
  {
    //return this._http.get(this._apiurl+'/Loan/GetData/'+QRCode);
    return this._http.get(this._apiurl+'/ClientReference/GetData/'+QRCode);
  }

  sendOTP(mobilenumber)
  {
    return this._http.get(this._apiurl+'/OTP/SendOTP/'+mobilenumber);
  }

  VerifyOTP(mobilenumber,OTP)
  {
    return this._http.get(this._apiurl +'/OTP/VerifyOTP/' + mobilenumber + '/'+OTP);
  }
  getBlockChainDataDetails(QRCode) {

    return this._http.get(this._apiurl+'/Document/getDocument/' + QRCode);

  }
}