import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BranchService {
   //_apiurl: string = 'https://etgarage.in/api';
   _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }

  getBranchDetails(){
    return this._http.get<{posts:any}>(this._apiurl+'/Branch/GetBranch').pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id:post._id,
          Name :post.Name,
          Code : post.Code,
          Organisation : post.Organisation,
          ContactPerson : post.ContactPerson,
          ContactNumber : post.ContactNumber,
          Email : post.Email,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy,
         
       

        };
      })
    }));
  }

getBranchById(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Branch/GetBranch/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            Id:post._id,
            OrganisationId:post.OrganisationId,
            Name :post.Name,
            Code : post.Code,
            Organisation : post.Organisation,
            ContactPerson : post.ContactPerson,
            ContactNumber : post.ContactNumber,
            Email : post.Email,
            CreatedBy : post.CreatedBy,
            UpdatedBy : post.UpdatedBy,
            
  
          };
        })
      }));

}

  createBranch(Branch: any) {
    return this._http.post(this._apiurl+'/Branch/postBranch', Branch);
  }

  UpdateBranch(Branch: any) {
    return this._http.put(this._apiurl+'/Branch/updateBranch', Branch);
  }

 
}
