import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { StandardizedLandingpageService } from '../service/StandardizedLandingpage.service';
import * as moment from 'moment';
import _, { map } from 'underscore';
import { KeyValue } from '@angular/common';
import { castNumber } from '@amcharts/amcharts4/core';

@Component({
  selector: 'app-standardized-landingpage',
  templateUrl: './standardized-landingpage.component.html',
  styleUrls: ['./standardized-landingpage.component.css'],
})
export class StandardizedLandingpageComponent implements OnInit {
  //For Verification OTP
  hidediv: boolean;
  hidedivForLandingpage: boolean;
  MobileNo: number;
  verifyButton: boolean;
  QRCodeVal: string;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  //For Landingpage
  frmdrop: boolean;
  loanDtl: boolean;
  docDtl: boolean;
  assetDtl: boolean;
  approvalsDtl: boolean;
  eventDtl: boolean;
  CustomerName: string;
  FatherName: string;
  EMIStartsOn: Date;
  Email: string;
  GoldLoanAccountNo: number;
  LoanAccountNo: number;
  EMIAmount: number;
  GrossWeight: string;
  Interest: string;
  LoanAmount: number;
  MembershipNo: number;
  NetWeight: number;
  PeriodInDays: number;
  TokenNo: number;
  TotalWeight: string;
  pdfUrl: any;
  pdfAadhar: any;
  pdfPan: any;
  pdfValutor: any;
  approvedBy: string;
  status: string;
  UpdatedDate: string;
  ValuationOfGoldPer10Gms: string;
  images = [];
  pdfs = [];
  checkpdf = [];
  Events: any;
  val: string;
  obj = {};
  CustImage: string;

  LoanDetails: Boolean;
  DocDetails: Boolean;
  AssetDetails: Boolean;
  ApporvedDetails: Boolean;
  EventDetails: Boolean;
  tab: any = 'tab1';
  tab1: any;
  tab2: any;
  tab3: any;
  tab4: any;
  bolAadhar: boolean;
  bolPan: boolean;
  bolValutor: boolean;
  bolfUrl: boolean;
  blockChainResult = [];
  res: any;
  DocumentData: any;
  HeaderResult = [];
  Header: any;
  AllItems: any;
  AllForm:any;
  AllKeyValue = [];
  Dynamicicon: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '',
  };
  constructor(
    private _service: StandardizedLandingpageService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) {
    this.frmdrop = true;
    this.loanDtl = true;
  }

  ngOnInit(): void {
    this.hidediv = true;
    this.hidedivForLandingpage = false;
    this.verifyButton = false;
    let qrcode = this.route.snapshot.params.QRCode;
    let URLName = this.route.snapshot.params.Name;
    //this.QRCodeVal = res[1];
    this.QRCodeVal = qrcode;
    if (this.QRCodeVal != '') {
      console.log(qrcode);
      console.log(URLName);
      this._service.getMobile(qrcode).subscribe((data) => {
        console.log(data)
        console.log(data['posts'][0].ClientID);
        this._service
          .getOrgById(data['posts'][0].ClientID)
          .subscribe((data1) => {
            console.log(data1[0].URLName);
            if (data1[0].URLName == URLName) {
              console.log(data1[0].Icon);
              this.Dynamicicon = data1[0].Icon;
            }
          });
      });
    }
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };
  showSuccess(MobileNo) {
    this.toastr.success('OTP is sent to this ' + MobileNo + ' mobile number');
  }
  showVerify(message) {
    this.toastr.success(message);
  }
  onOtpChange(OTP) {
    if (OTP === '') {
      this.verifyButton = false;
    } else {
      this.verifyButton = true;
    }
  }

  VerifyOTP(OTP) {
    this.hidediv = false;
    this.hidedivForLandingpage = true;
    this.LoanDetails = true;

    this._service.getBlockChainDataDetails(this.QRCodeVal).subscribe((data) => {
     console.log(data)
      this.LoanDetails = true;
      this.bolfUrl = true;
      this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl( data['posts']['fileurl'] );
      this.bolfUrl = true;
      this.bolAadhar = false;
      this.bolPan = false;
      this.bolValutor = false;
     
      if(data['posts']['childqrcodes'].length ==0)
      {
        if(data['posts'].hasOwnProperty('parentqrcode')==true)
        {
 //Child QRCodes
 let AllData = [];
 var ParentQRCode = data['posts']['parentqrcode'].uniqueid
 this._service.getBlockChainDataDetails(ParentQRCode).subscribe((Parentdata) => {
   for ( let i = 0; i < Parentdata['posts']['childblockchaintriggers'].length; i++ ) {
     let obj = {};
     let DocArrData = [];
     let FilterData = [];
     let Docarr = Parentdata['posts']['childblockchaintriggers'][i].blockchaindata.split('||');
     var datearr = Parentdata['posts']['childblockchaintriggers'][i].blockchaintimestamp.split(' ');
     if (i == 0) { let Docarr = Parentdata['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
       for (let k = 0; k < Docarr.length; k++) {
         obj = {};
         var index = Docarr[k].indexOf(':');
         var Key = Docarr[k].substring(0, index);
         var Value = Docarr[k].substring(index + 1, Docarr[k].length);
         obj = { Key: Key, Separator: ':', Value: Value };
         DocArrData.push(obj);
       }
       console.log(DocArrData);
       this.DocumentData = DocArrData;
       DocArrData = [];
     }
     for (let k = 0; k < Docarr.length; k++) {
       obj = {};
       var index = Docarr[k].indexOf(':');
       var Key = Docarr[k].substring(0, index);
       var Value = Docarr[k].substring(index + 1, Docarr[k].length);
       obj[Key] = Value;
       DocArrData.push(obj);
       if (Docarr.length - 1 == k) {
         var dateformat = datearr[1].split('/');
         var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
         var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
         var objDate = {};
         objDate['Updated On'] = dateUTC1;
         DocArrData.push(objDate);
         var objVerify = {};
         objVerify['Verify'] = Parentdata['posts']['childblockchaintriggers'][i].blockchainurl;
         DocArrData.push(objVerify);
         //this.AllItems = DocArrData;
         AllData.push( DocArrData.reduce(function (result, current) {
             return Object.assign(result, current);
           }, {}));
          
           if (Parentdata['posts']['childblockchaintriggers'].length - 1 == i) {
             for ( let j = 0; j < data['posts']['childblockchaintriggers'].length; j++ ) {
               let obj = {};
               let DocArrData = [];
               let FilterData = [];
               let Docarr = data['posts']['childblockchaintriggers'][j].blockchaindata.split('||');
               var datearr = data['posts']['childblockchaintriggers'][j].blockchaintimestamp.split(' ');
               for (let s = 0; s < Docarr.length; s++) {
                 obj = {};
                 var index = Docarr[s].indexOf(':');
                 var Key = Docarr[s].substring(0, index);
                 var Value = Docarr[s].substring(index + 1, Docarr[s].length);
                 obj[Key] = Value;
                 DocArrData.push(obj);
                 if (Docarr.length - 1 == s) {
                   var dateformat = datearr[1].split('/');
                   var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                   var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
                   var objDate = {};
                   objDate['Updated On'] = dateUTC1;
                   DocArrData.push(objDate);
                   var objVerify = {};
                   objVerify['Verify'] = data['posts']['childblockchaintriggers'][j].blockchainurl;
                   DocArrData.push(objVerify);
                   //this.AllItems = DocArrData;
                   AllData.push( DocArrData.reduce(function (result, current) {
                       return Object.assign(result, current);
                     }, {}));
                    
                     if (data['posts']['childblockchaintriggers'].length - 1 == j) {
                       var val = _.keys( _.countBy(AllData, function (data) { return data.Form; }));
                       this.AllItems = AllData;
                       console.log(this.AllItems)
                        for (let mk = 0; mk < val.length; mk++) {
                         obj = {};
                         var index = AllData.findIndex(p => p.Form == val[mk]);
                         obj = { Key: val[mk],Value: index };
                         this.AllKeyValue.push(obj)
                         if(val.length-1 == mk)
                         {
                           this.AllForm = this.AllKeyValue
                         }
                        }
                     }
                 }
               }
             }
           }
       }
     }
   }
  
 });
        }
        else{
          let AllData = [];
          for ( let j = 0; j < data['posts']['childblockchaintriggers'].length; j++ ) {
            let obj = {};
            let DocArrData = [];
            let FilterData = [];
            let Docarr = data['posts']['childblockchaintriggers'][j].blockchaindata.split('||');
            var datearr = data['posts']['childblockchaintriggers'][j].blockchaintimestamp.split(' ');
            for (let s = 0; s < Docarr.length; s++) {
              obj = {};
              var index = Docarr[s].indexOf(':');
              var Key = Docarr[s].substring(0, index);
              var Value = Docarr[s].substring(index + 1, Docarr[s].length);
              obj[Key] = Value;
              DocArrData.push(obj);
              if (Docarr.length - 1 == s) {
                var dateformat = datearr[1].split('/');
                var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
                var objDate = {};
                objDate['Updated On'] = dateUTC1;
                DocArrData.push(objDate);
                var objVerify = {};
                objVerify['Verify'] = data['posts']['childblockchaintriggers'][j].blockchainurl;
                DocArrData.push(objVerify);
                //this.AllItems = DocArrData;
                AllData.push( DocArrData.reduce(function (result, current) {
                    return Object.assign(result, current);
                  }, {}));
                 
                  if (data['posts']['childblockchaintriggers'].length - 1 == j) {
                    var val = _.keys( _.countBy(AllData, function (data) { return data.Form; }));
                    this.AllItems = AllData;
                    console.log(this.AllItems)
                     for (let mk = 0; mk < val.length; mk++) {
                      obj = {};
                      var index = AllData.findIndex(p => p.Form == val[mk]);
                      obj = { Key: val[mk],Value: index };
                      this.AllKeyValue.push(obj)
                      if(val.length-1 == mk)
                      {
                        this.AllForm = this.AllKeyValue
                      }
                     }
                  }
              }
            }
          }
        }
      }
      else
      {
        let AllData = [];
        for ( let i = 0; i < data['posts']['childblockchaintriggers'].length; i++ ) {
          let obj = {};
          let DocArrData = [];
          let FilterData = [];
          let Docarr = data['posts']['childblockchaintriggers'][i].blockchaindata.split('||');
          var datearr = data['posts']['childblockchaintriggers'][i].blockchaintimestamp.split(' ');
          for (let k = 0; k < Docarr.length; k++) {
            obj = {};
            var index = Docarr[k].indexOf(':');
            var Key = Docarr[k].substring(0, index);
            var Value = Docarr[k].substring(index + 1, Docarr[k].length);
            obj[Key] = Value;
            DocArrData.push(obj);
            if (Docarr.length - 1 == k) {
              var dateformat = datearr[1].split('/');
              var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
              var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
              var objDate = {};
              objDate['Updated On'] = dateUTC1;
              DocArrData.push(objDate);
              var objVerify = {};
              objVerify['Verify'] = data['posts']['childblockchaintriggers'][i].blockchainurl;
              DocArrData.push(objVerify);
              AllData.push( DocArrData.reduce(function (result, current) {
                  return Object.assign(result, current);
                }, {}));
               
                if (data['posts']['childblockchaintriggers'].length - 1 == i) {
                 console.log(AllData)
                 for(let j=0;j<data['posts']['childqrcodes'].length;j++)
                 {
                  this._service.getBlockChainDataDetails(data['posts']['childqrcodes'][j].uniqueid).subscribe((Childdata) => {
                    
                    for ( let a = 0; a < Childdata['posts']['childblockchaintriggers'].length; a++ ) {
                      let obj = {};
                      let DocArrData = [];
                      let FilterData = [];
                      let Docarr = Childdata['posts']['childblockchaintriggers'][a].blockchaindata.split('||');
                      var datearr = Childdata['posts']['childblockchaintriggers'][a].blockchaintimestamp.split(' ');
                      
                      for (let b = 0; b < Docarr.length; b++) {
                        obj = {};
                        var index = Docarr[b].indexOf(':');
                        var Key = Docarr[b].substring(0, index);
                        var Value = Docarr[b].substring(index + 1, Docarr[b].length);
                        obj[Key] = Value;
                        DocArrData.push(obj);
                        if (Docarr.length - 1 == b) {
                          var dateformat = datearr[1].split('/');
                          var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                          var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
                          var objDate = {};
                          objDate['Updated On'] = dateUTC1;
                          DocArrData.push(objDate);
                          var objVerify = {};
                          objVerify['Verify'] = Childdata['posts']['childblockchaintriggers'][a].blockchainurl;
                          DocArrData.push(objVerify);
                          AllData.push( DocArrData.reduce(function (result, current) {
                              return Object.assign(result, current);
                            }, {}));
                           
                            if (Childdata['posts']['childblockchaintriggers'].length - 1 == a) {
                              
                              if(data['posts']['childqrcodes'].length-1 ==j)
                              {
                                var val = _.keys( _.countBy(AllData, function (data) { return data.Form; }));
                                this.AllItems = AllData;
                                console.log(this.AllItems)
                                 for (let mk = 0; mk < val.length; mk++) {
                                  obj = {};
                                  var index = AllData.findIndex(p => p.Form == val[mk]);
                                  obj = { Key: val[mk],Value: index };
                                  this.AllKeyValue.push(obj)
                                  if(val.length-1 == mk)
                                  {
                                    this.AllForm = this.AllKeyValue
                                  }
                                 }
                              }
                            }
                        }
                      }
                    }
                  });
                 }
                }
            }
          }
        }
      }
      // this.LoanDetails = true;
      // this.bolfUrl = true;
      // console.log(data['posts']);
      // console.log(data['posts']['fileurl']);
      // this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl( data['posts']['fileurl'] );
      // this.bolfUrl = true;
      // this.bolAadhar = false;
      // this.bolPan = false;
      // this.bolValutor = false;
      // let AllData = [];
      // for ( let i = 0; i < data['posts']['childblockchaintriggers'].length; i++ ) {
      //   let obj = {};
      //   let DocArrData = [];
      //   let FilterData = [];
      //   let Docarr = data['posts']['childblockchaintriggers'][i].blockchaindata.split('||');
      //   var datearr = data['posts']['childblockchaintriggers'][i].blockchaintimestamp.split(' ');
      //   if (i == 0) { let Docarr = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
      //     for (let k = 0; k < Docarr.length; k++) {
      //       obj = {};
      //       var index = Docarr[k].indexOf(':');
      //       var Key = Docarr[k].substring(0, index);
      //       var Value = Docarr[k].substring(index + 1, Docarr[k].length);
      //       obj = { Key: Key, Separator: ':', Value: Value };
      //       DocArrData.push(obj);
      //     }
      //     console.log(DocArrData);
      //     this.DocumentData = DocArrData;
      //     DocArrData = [];
      //   }
      //   for (let k = 0; k < Docarr.length; k++) {
      //     obj = {};
      //     var index = Docarr[k].indexOf(':');
      //     var Key = Docarr[k].substring(0, index);
      //     var Value = Docarr[k].substring(index + 1, Docarr[k].length);
      //     obj[Key] = Value;
      //     DocArrData.push(obj);
      //     if (Docarr.length - 1 == k) {
      //       var dateformat = datearr[1].split('/');
      //       var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
      //       var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
      //       var objDate = {};
      //       objDate['Updated On'] = dateUTC1;
      //       DocArrData.push(objDate);
      //       var objVerify = {};
      //       objVerify['Verify'] = data['posts']['childblockchaintriggers'][i].blockchainurl;
      //       DocArrData.push(objVerify);
      //       this.AllItems = DocArrData;
      //       AllData.push( DocArrData.reduce(function (result, current) {
      //           return Object.assign(result, current);
      //         }, {}));
             
      //         if (data['posts']['childblockchaintriggers'].length - 1 == i) {
      //           console.log(AllData)
      //           var val = _.keys( _.countBy(AllData, function (data) { return data.Form; }));
      //        // this.AllForm = val
      //         this.AllItems = AllData;
      //        // console.log(this.AllForm);
      //            for (let m = 0; m < val.length; m++) {
      //             obj = {};
      //             var index = AllData.findIndex(p => p.Form == val[m]);
      //             obj = { Key: val[m],Value: index };
      //             this.AllKeyValue.push(obj)
      //             if(val.length-1 == m)
      //             {
      //               this.AllForm = this.AllKeyValue
      //             }
      //            }
      //         }
      //     }
      //   }
      // }
    });
  }

  //WithOut OTP
  // VerifyOTP(OTP) {

  //     this.hidediv = false;
  //     this.hidedivForLandingpage = true;
  //     this.LoanDetails = true;

  //     this._service.getBlockChainDataDetails(this.QRCodeVal).subscribe(data => {
  //         this.LoanDetails = true;
  //         this.bolfUrl=true;
  //         console.log(data["posts"])
  //         console.log(data["posts"]["fileurl"])
  //         this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(data["posts"]["fileurl"]);
  //         this.bolfUrl=true;
  //         this.bolAadhar=false;
  //         this.bolPan = false;
  //         this.bolValutor = false;
  //         for (let i = 0; i < data["posts"]["childblockchaintriggers"].length; i++) {
  //             let obj = {};
  //             let DocArrData =[];
  //             if (i == 0) {
  //                let Docarr = data["posts"]["childblockchaintriggers"][i].blockchaindata.split('||');
  //                for(let k=0;k<Docarr.length;k++)
  //                {
  //                  obj = {};
  //                   var index = Docarr[k].indexOf(":");
  //                   var Key = Docarr[k].substring(0,index);
  //                   var Value = Docarr[k].substring(index+1,Docarr[k].length);
  //                   obj ={"Key":Key,"Separator":':',"Value":Value}
  //                   DocArrData.push(obj)
  //                }
  //                 console.log(DocArrData)
  //                 this.DocumentData =DocArrData
  //             }
  //             else {
  //                 let obj ={};
  //                 var datearr = data["posts"]["childblockchaintriggers"][i].blockchaintimestamp.split(' ');
  //                 var dateformat = datearr[1].split('/');
  //                 var dateUTC = new Date(dateformat[2] + '-' + dateformat[1] + "-" + dateformat[0] + " " + datearr[0] + " UTC");
  //                 console.log(dateUTC)
  //                 var dateUTC1 =moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
  //                  let arr = data["posts"]["childblockchaintriggers"][i].blockchaindata.split('||')

  //                  if(i ==  (data["posts"]["childblockchaintriggers"].length -1))
  //                  {
  //                      debugger;
  //                     let Headerarr =[];
  //                     console.log(i)
  //                     for(let j=0;j<arr.length;j++)
  //                     {
  //                         debugger;
  //                        var index = arr[j].indexOf(":");
  //                        Headerarr[j] = arr[j].substring(0,index);

  //                     }
  //                     Headerarr.push("Updated On")
  //                     Headerarr.push("Verify")
  //                     this.HeaderResult.push(Headerarr)
  //                  }

  //                  for(let j=0;j<arr.length;j++)
  //                  {
  //                     var index = arr[j].indexOf(":");
  //                     arr[j] = arr[j].substring(index+1,arr[j].length);

  //                  }
  //                //  console.log(Headerarr)
  //                  arr.push(dateUTC1)
  //                  arr.push(data["posts"]["childblockchaintriggers"][i].blockchainurl)

  //                  this.blockChainResult.push(arr)

  //             }
  //         }
  //         this.res = this.blockChainResult;
  //         this.Header = this.HeaderResult;
  //         console.log(this.Header)
  //     })

  //     }

  //With OTP
  // VerifyOTP(OTP) {
  //    // this.MobileNo = 9160143927;
  //     //let otpvalue =this.ngOtpInputRef.otpForm.value.ctrl_0+this.ngOtpInputRef.otpForm.value.ctrl_1+this.ngOtpInputRef.otpForm.value.ctrl_2+this.ngOtpInputRef.otpForm.value.ctrl_3+this.ngOtpInputRef.otpForm.value.ctrl_4+this.ngOtpInputRef.otpForm.value.ctrl_5;
  //     let otpvalue = this.ngOtpInputRef.otpForm.value.ctrl_0 + this.ngOtpInputRef.otpForm.value.ctrl_1 + this.ngOtpInputRef.otpForm.value.ctrl_2 + this.ngOtpInputRef.otpForm.value.ctrl_3;
  //     console.log(this.QRCodeVal)
  //     this._service.VerifyOTP(this.MobileNo, otpvalue).subscribe(data => {

  //         console.log(data["posts"].message)

  //         if (data["posts"].message == "OTP verified success") {
  //             this.showVerify(data["posts"].message)
  //             this.hidediv = false;
  //             this.hidedivForLandingpage = true;
  //             this.LoanDetails = true;

  //             this._service.getBlockChainDataDetails(this.QRCodeVal).subscribe(data => {
  //                 this.LoanDetails = true;
  //                 this.bolfUrl=true;
  //                 console.log(data["posts"])
  //                 console.log(data["posts"]["fileurl"])
  //                 this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(data["posts"]["fileurl"]);
  //                 this.bolfUrl=true;
  //                 this.bolAadhar=false;
  //                 this.bolPan = false;
  //                 this.bolValutor = false;
  //                 for (let i = 0; i < data["posts"]["childblockchaintriggers"].length; i++) {
  //                     let obj = {};
  //                     let DocArrData =[];
  //                     if (i == 0) {
  //                        let Docarr = data["posts"]["childblockchaintriggers"][i].blockchaindata.split('||');
  //                        for(let k=0;k<Docarr.length;k++)
  //                        {
  //                          obj = {};
  //                           var index = Docarr[k].indexOf(":");
  //                           var Key = Docarr[k].substring(0,index);
  //                           var Value = Docarr[k].substring(index+1,Docarr[k].length);
  //                           obj ={"Key":Key,"Separator":':',"Value":Value}
  //                           DocArrData.push(obj)
  //                        }
  //                         console.log(DocArrData)
  //                         this.DocumentData =DocArrData
  //                     }
  //                     else {
  //                         let obj ={};
  //                         var datearr = data["posts"]["childblockchaintriggers"][i].blockchaintimestamp.split(' ');
  //                         var dateformat = datearr[1].split('/');
  //                         var dateUTC = new Date(dateformat[2] + '-' + dateformat[1] + "-" + dateformat[0] + " " + datearr[0] + " UTC");
  //                          let arr = data["posts"]["childblockchaintriggers"][i].blockchaindata.split('||')

  //                          if(i ==  (data["posts"]["childblockchaintriggers"].length -1))
  //                          {
  //                              debugger;
  //                             let Headerarr =[];
  //                             console.log(i)
  //                             for(let j=0;j<arr.length;j++)
  //                             {
  //                                 debugger;
  //                                var index = arr[j].indexOf(":");
  //                                Headerarr[j] = arr[j].substring(0,index);

  //                             }
  //                             Headerarr.push("Updated On")
  //                             Headerarr.push("Verify")
  //                             this.HeaderResult.push(Headerarr)
  //                          }

  //                          for(let j=0;j<arr.length;j++)
  //                          {
  //                             var index = arr[j].indexOf(":");
  //                             arr[j] = arr[j].substring(index+1,arr[j].length);

  //                          }
  //                        //  console.log(Headerarr)
  //                          arr.push(dateUTC)
  //                          arr.push(data["posts"]["childblockchaintriggers"][i].blockchainurl)

  //                          this.blockChainResult.push(arr)

  //                     }
  //                 }
  //                 this.res = this.blockChainResult;
  //                 this.Header = this.HeaderResult;
  //                 console.log(this.Header)
  //             })
  //         }
  //         else {
  //             this.showVerify(data["posts"].message)
  //         }

  //     })

  // }

  clickLoanDtl() {
    this.loanDtl = true;
    this.docDtl = false;
    this.assetDtl = false;
    this.approvalsDtl = false;
    this.eventDtl = false;
  }

  clickdocDtlDtl() {
    this.loanDtl = false;
    this.docDtl = true;
    this.assetDtl = false;
    this.approvalsDtl = false;
    this.eventDtl = false;
  }
  clickassetDtlDtl() {
    this.loanDtl = false;
    this.docDtl = false;
    this.assetDtl = true;
    this.approvalsDtl = false;
    this.eventDtl = false;
  }
  clickapprovalsDtlDtl() {
    this.loanDtl = false;
    this.docDtl = false;
    this.assetDtl = false;
    this.approvalsDtl = true;
    this.eventDtl = false;
  }
  clickeventDtlDtl() {
    this.loanDtl = false;
    this.docDtl = false;
    this.assetDtl = false;
    this.approvalsDtl = false;
    this.eventDtl = true;
  }

  refresh1() {
    this.loanDtl = false;
    this.docDtl = false;
    this.assetDtl = false;
    this.approvalsDtl = false;
    this.eventDtl = false;
  }
  onLoanDetails() {
    this.tab = 'tab1';
    this.LoanDetails = true;
    this.DocDetails = false;
    this.AssetDetails = false;
    this.ApporvedDetails = false;
    this.EventDetails = false;
  }
  onDocDetails() {
    this.tab = 'tab2';
    this.LoanDetails = false;
    this.DocDetails = true;
    this.AssetDetails = false;
    this.ApporvedDetails = false;
    this.EventDetails = false;
  }
  onAssetDetails() {
    this.tab = 'tab3';
    this.LoanDetails = false;
    this.DocDetails = false;
    this.AssetDetails = true;
    this.ApporvedDetails = false;
    this.EventDetails = false;
  }
  onEventDetails() {
    this.tab = 'tab4';
    this.LoanDetails = false;
    this.DocDetails = false;
    this.AssetDetails = false;
    this.ApporvedDetails = false;
    this.EventDetails = true;
  }
  onApprovedtDetails() {
    this.LoanDetails = false;
    this.DocDetails = false;
    this.AssetDetails = false;
    this.ApporvedDetails = true;
    this.EventDetails = true;
  }
}
