import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './Settings/Settings.component';
import { ConfigurationComponent } from './Configuration/Configuration.component';
import { ProfileComponent } from './Profile/Profile.component';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { OrganisationComponent } from './organisation/organisation.component';
import { BranchComponent } from './branch/branch.component';
import { RoleComponent } from './role/role.component';
import { IndustryComponent } from './industry/industry.component';
import { TemComponent } from './tem/tem.component';
import { TemplatesearchComponent } from './templatesearch/templatesearch.component';
import { StepertestComponent } from './stepertest/stepertest.component';
import { TemplateComponent } from './template/template.component';
import { CategoryComponent } from './category/category.component';
import { InlinetabletestComponent } from './inlinetabletest/inlinetabletest.component';
import { TabtestComponent } from './tabtest/tabtest.component';
import { FormfieldtestComponent } from './formfieldtest/formfieldtest.component';
import {MaheshBankComponent} from './mahesh-bank/mahesh-bank.component'
import {DashboardComponent} from './dashboard/dashboard.component';
import{StepperOverviewComponent} from './stepper-overview/stepper-overview.component';
import{TemplateOverviewComponent} from './template-overview/template-overview.component';
import { RibbonComponent } from './ribbon/ribbon.component';
import{OrganisationOverviewComponent} from './Organisation-overview/Organisation-overview.component'
import{MasterOverviewComponent} from './master-overview/master-overview.component';
import{ApiOverviewComponent} from './api-overview/api-overview.component';
import{BlockchainOverviewComponent} from './blockchain-overview/blockchain-overview.component';
import{BillingOverviewComponent} from './billing-overview/billing-overview.component';
import {StandardizedLandingpageComponent} from './standardized-landingpage/standardized-landingpage.component';
import {PolimerasComponent} from './polimeras/polimeras.component';
import{CoWINComponent} from './CoWIN/CoWIN.component';
import{ChitfundComponent} from './chitfund/chitfund.component';
import{PolimeraasComponent} from './polimeraas/polimeraas.component';
import{KapilchitsComponent} from './kapilchits/kapilchits.component';
import{TenetComponent} from './tenet/tenet.component'
import{TenetCBPComponent} from './tenet-CBP/tenet-CBP.component';
import {DonatekartComponent} from './Donatekart/Donatekart.component';
import { VerifyqldbComponent } from './verifyqldb/verifyqldb.component';
import {CMSComponent} from './CMS/CMS.component';
import {VerifyhyperledgeComponent} from './verifyhyperledge/verifyhyperledge.component';
import {PanchratanComponent} from './panchratan/panchratan.component';
const routes: Routes = [{ path: 'setting', component: SettingsComponent },
{ path: 'configuration', component: ConfigurationComponent },
{ path: 'profile', component: ProfileComponent },
{ path: 'login', component: LoginComponent },
{ path: 'user', component: UserComponent },
{ path: 'organisation', component: OrganisationComponent },
{ path: 'branch', component: BranchComponent },
{ path: 'role', component: RoleComponent },
{ path: 'industry', component: IndustryComponent },
{path:'',component:LoginComponent},
{ path: 'tem', component: TemComponent },
{ path: 'templatesearch', component: TemplatesearchComponent },
//{ path: 'stepertest/:IndustryId', component: StepertestComponent },
//{ path: 'template/:CategoryName/:Id/:Industry', component: TemplateComponent },
{ path: 'category/:Id/:Name', component: CategoryComponent },
{ path: 'inlinetabletest', component: InlinetabletestComponent },
{ path: 'tabtest', component: TabtestComponent },
{ path: 'formfieldtest', component: FormfieldtestComponent },
{ path: 'MaheshBank/:QRCode', component: MaheshBankComponent },
{ path: 'MaheshBank', component: MaheshBankComponent },
{path:'Dashboard',component:DashboardComponent},
{path:'stepertest/:IndustryId',component:StepperOverviewComponent},
{ path:'template/:CategoryName/:Id/:Industry', component: TemplateOverviewComponent },
{path:'ribbon',component:RibbonComponent},
{path:'OrganisationOverview',component:OrganisationOverviewComponent},
{path:'MasterOverview',component:MasterOverviewComponent},
{path:'ApiOverview',component:ApiOverviewComponent},
{path:'BlockchainOverview',component:BlockchainOverviewComponent},
{path:'BillingOverview',component:BillingOverviewComponent},
{ path: 'LandingPage/:Name/:QRCode', component: StandardizedLandingpageComponent },
//{ path: 'Polimeraas/:QRCode', component: PolimerasComponent },
{ path: 'cowin', component: CoWINComponent },
{path:'chitfund/:Name/:QRCode',component:ChitfundComponent},
{ path: 'Polimeraas/:QRCode', component: PolimeraasComponent },
{ path: 'kapilchits/:QRCode', component: KapilchitsComponent },
{path:'tenet/:QRCode',component:TenetComponent},
{path:'tenetCBP/:QRCode',component:TenetCBPComponent},
{path:'donatekart/:QRCode',component:DonatekartComponent},
{path:'verifyqldb/:QRCode',component:VerifyqldbComponent},
{path:'csm',component:CMSComponent},
{path:'verifyhyperledge/:DCNumber',component:VerifyhyperledgeComponent},
{path:'panchratan/:QRCode',component:PanchratanComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
