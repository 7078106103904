import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OrganizationService } from '../service/organization.service';
import { UtilitiesService } from '../service/utility.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.css']
})
export class OrganisationComponent implements OnInit {
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
  OrganisationForm: FormGroup;
  createOrganisation :boolean
  isUpdate:boolean=false;
  isSave:boolean=true;
  imagebyId: any;
  constructor(private _formbuilder: FormBuilder,private _router: Router,
    private location :Location ,
    private _utility:UtilitiesService,
    private _Org:OrganizationService) {
    this.createForm();

   }

  ngOnInit() {
    this.createOrganisation =false;
    this.getOrgDtl();
  }
//Validators.pattern("\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}"
  createForm() {
    this.OrganisationForm = this._formbuilder.group({
      Id:[''],
      Name: ['', Validators.required],
      Code: ['',Validators.required],
      Address: ['', Validators.required],
      Building: [''],
      Location: ['', Validators.required],
      PIN: ['', Validators.required],
      State: ['', Validators.required],
      City: ['', Validators.required],
      GST: ['', Validators.required],
      HeadOfficeCode: [''],
      Industry: ['', Validators.required],
      ContactPerson: [''],
      ContactNumber: ['', Validators.required],
      PrimaryContact: [''],
      Email: [''],
      Category: [''],
      CreatedBy: ['1'],
      UpdatedBy: ['1'],
      Desc:[''],
      Icon: [''],
      URLName:['']
    });
  }


  goBack(){
    this.location.back();
  }
  orgList:any='';
  getOrgDtl(){
    this._Org.getOrganizationDetails().subscribe(data=>{
      this.orgList=data;
      console.log(this.orgList);
     // console.log(JSON.stringify(this.orgList));
    })
  }
  url: any;
  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result;
        console.log(this.url);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  editOrganisationForm:any;
  getOrgbyid(id:any){
    console.log(id);
    this.isUpdate=true;
    this.isSave=false;
    this.createOrganisation =true;
    this._Org.getOrgById(id).subscribe(data=>{
    this.editOrganisationForm = data[0];
    console.log(data);
   // this.OrganisationForm.get('ContactPerson').setValue('nav');
      this.OrganisationForm.setValue(data[0]);
    })


  }

  onUpdate(e){
  console.log(e);
  if (this.OrganisationForm.valid) {
 this._Org.UpdateOrganization(e).subscribe(data=>{
console.log(data);
var x = (<HTMLInputElement>document.getElementById('snackbar'));
  x.innerText = 'Organization Updated Sucessfully';
  this._utility.SuccessToast();
  this._router.navigate(["/organisation"]);
  setTimeout(() => {
    this.refresh();
  }, 2000);

 
//  this.isUpdate=false;
//  this.isSave=true;
 this.createOrganisation=false;
//his.OrganisationForm.reset
}, (err) => {
  var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
  x.innerText = 'An Error Occured' + err.error.toString();
  this._utility.ErrorToast1();
})
}
else {
const invalid = [];
const controls = this.OrganisationForm.controls;
for (const name in controls) {
if (controls[name].invalid) {
  invalid.push(name);
  var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
  x.innerText = 'Please Enter ' + invalid[0] + ' To Process ';
  this._utility.ErrorToast1();
}
}
return invalid;
}
  }

  onSave()
  {
    this.OrganisationForm.patchValue({
      Icon: this.url,
    });
    var createpostdata = {
      Id: this.OrganisationForm.get('Id').value,
      OrganisationName: this.OrganisationForm.get('Name').value,
      OrganisationCode: this.OrganisationForm.get('Code').value,
      Address:this.OrganisationForm.get('Address').value,
      Building:this.OrganisationForm.get('Building').value,
      Location:this.OrganisationForm.get('Location').value,
      PIN:this.OrganisationForm.get('PIN').value,
      City: this.OrganisationForm.get('City').value,
      State: this.OrganisationForm.get('State').value,
      Industry:this.OrganisationForm.get('Industry').value,
      Category : this.OrganisationForm.get('Category').value,
      PrimaryContact:this.OrganisationForm.get('PrimaryContact').value,
      ContactNumber:this.OrganisationForm.get('ContactNumber').value,
      HeadOfficeCode: this.OrganisationForm.get('HeadOfficeCode').value,
      GST:this.OrganisationForm.get('GST').value,
      ContactPerson:this.OrganisationForm.get('ContactPerson').value,
      Email:this.OrganisationForm.get('Email').value,
      CreatedBy :this.OrganisationForm.get('CreatedBy').value,
      UpdatedBy :this.OrganisationForm.get('UpdatedBy').value,
      Desc :this.OrganisationForm.get('Desc').value,
      URLName :this.OrganisationForm.get('URLName').value,
      Icon :this.OrganisationForm.get('Icon').value
    }
   console.log(createpostdata)
  // console.log(this.OrganisationForm.value);
  //  var postdata={
    
  //     OrganisationName : "Mahesh Bank 2",
  //     OrganisationCode :"MB0011",
  //     Address : "Jubilee Hills",
  //     Building : "SB Jubilee",
  //     Location : "Hyd",
  //     PIN : "500089",
  //     City : "Hyd",
  //     State : "Telangana",
  //     Industry : "Banking",
  //     Category : "Cat1",
  //     PrimaryContact : 9160143927,
  //     ContactNumber : 9160143927,
  //     Email : "abc@gmail.com",
  //     GST : "GST001",
  //     HeadOfficeCode : "HO001",
  //     CreatedBy : 1,
  //     UpdatedBy : 1,
   
      
  //  }
  if (this.OrganisationForm.valid) {
   this._Org.createOrganization(createpostdata)
   .subscribe(data=>{
     console.log(data);
     this.createOrganisation=false;
     var x = (<HTMLInputElement>document.getElementById('snackbar'));
  x.innerText = 'Organization Created Sucessfully';
  this._utility.SuccessToast();
  this._router.navigate(["/organisation"]);
  setTimeout(() => {
    this.refresh();
  }, 2000);
    }, (err) => {
      var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
      x.innerText = 'An Error Occured' + err.error.toString();
      this._utility.ErrorToast1();
    })
}
else {
  const invalid = [];
  const controls = this.OrganisationForm.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
      var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
      x.innerText = 'Please Enter ' + invalid[0] + ' To Process ';
      this._utility.ErrorToast1();
    }
  }
  return invalid;
}

  }

  onClearAll(){
    this.OrganisationForm.reset();
  }

  refresh() {
//debugger;
this.OrganisationForm.reset();
   window.location.reload();
   this._router.navigateByUrl('/organisation//miscellaneous/not-found', { skipLocationChange: true }).then(() =>
   this._router.navigate(["/organisation"]));

      console.log('refresh');
  }

  ErrorToast1() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbarerror");
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
  }

  validateNumber(event: any) {
    this._utility.validateNumber(event);
  }

}