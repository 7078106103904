<nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
  <div class="d-flex" style="width: 100%;">
    <div class="mr-auto pt-3">
      <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
  </div>
      <div class="pt-3">
        <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
     </div>
      <div class="pt-1 logo-ft ">
        <img src="/assets/img/TRST001.jpeg">
         <!-- <img src="/assets/img/footer-logo.png"> -->
      </div>
  </div>
  
</nav>
