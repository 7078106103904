import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
declare var $: any;
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  // private componentMethodCallSource = new Subject<any>();
 // _apiurl: string = apiurl + 'KeyValue';
  gender: any[];


  constructor( private route: Router, private _http: HttpClient) {
  }

//   AuthHead: HttpHeaders = new HttpHeaders({
//     'Authorization': "Bearer " + sessionStorage.getItem("SITT")
//   });


  // componentMethodCalled$ = this.componentMethodCalled$.asObservable();

  validateMobileNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;
   // const reg = /^[7|8|9]\d{9,9}$/g
   ///(7|8|9)\d{9}$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }
  
  decimalValidator = function (event: any) {
    if ((event.charCode != 46 || event.target.value.indexOf('.') != -1) && (event.charCode < 48 || event.charCode > 57)) {
      if ((event.charCode != 46 || event.target.value.indexOf('.') != -1)) {
      }
      event.preventDefault();
    }
    if (event.target.value.indexOf('.') > -1 && (event.target.value.split('.')[1].length > 2)) {
      event.preventDefault();
    }
  }


  twoDecimalValidator = function (event: any) {
    if ((event.charCode != 46 || event.target.value.indexOf('.') != -1) && (event.charCode < 48 || event.charCode > 57)) {
      if ((event.charCode != 46 || event.target.value.indexOf('.') != -1)) {
      }
      event.preventDefault();
    }
    if (event.target.value.indexOf('.') > -1 && (event.target.value.split('.')[1].length > 1)) {
      event.preventDefault();
    }
  }


  currencyValidator = function (event: any) {
    if ((event.charCode != 46 || event.target.value.indexOf('.') != -1) && (event.charCode < 48 || event.charCode > 57)) {
      if ((event.charCode != 46 || event.target.value.indexOf('.') != -1)) {
      }
      event.preventDefault();
    }
    if (event.target.value.indexOf('.') > -1 && (event.target.value.split('.')[1].length > 1)) {
      event.preventDefault();
    }
  }
  
  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{1,2})?$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }
  ErrorToast1() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbarerror");
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
  }
  
  SuccessToast() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
  }

  SuccessToast1() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar1");

    // Add the "show" class to DIV
    x.className = "show";


    //setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
  }

  access: Subject<any>;
  accessRights: object = {};
  dataTables = function (titles) {
 
    var mytable = $("#datatable").DataTable({
      //  "dom": "<'mdl-grid row'<'mdl-cell mdl-cell--12-col'f>>"+
      //  "<'mdl-grid row dt-table'<'mdl-cell mdl-cell--12-col'tr>>" +
      //  "<'mdl-grid row'<'mdl-cell mdl-cell--12-col'p>>",
      // dom: "<'row'<'col-sm-8'B><'col-sm-1 text-center'><'col-sm-3 pull-right'f>>" +
      //   "<'row'<'col-sm-12'tr>>" +
      //   "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      // buttons: [
      //   { extend: 'pageLength', className: 'print' },
      //   { extend: 'csv', text: "<img width='15' height='15' src='assets/images/csvpng.png'>", titleAttr: 'CSV', title: titles, className: 'csv' },
      //   { extend: 'excel', text: '<i class="fa fa-file-excel-o" aria-hidden="true"></i>', titleAttr: 'Excel', title: titles, className: 'excel' },
      //   {
      //     extend: 'pdf', text: '<i class="fa fa-file-pdf-o" aria-hidden="true"></i>', titleAttr: 'pdf', title: titles, className: 'pdf',
      //     orientation: 'portrait',
      //     pageSize: 'TABLOID'
      //   },
      //   { extend: 'print', text: '<i class="fa fa-print" aria-hidden="true"></i>', titleAttr: 'Print', title: titles, className: 'print' },
      // ],
    dom:'bfrtip',
      "bResponsive": true,
      "bLengthChange": false,
      "bAutoWidth": true,
      "bScrollCollapse": true,
      "bPaginate": true,
      "bDestroy": true,
      "ordering": true,
      "bFilter": true,
      // "sScrollY": 400,
      "bInfo": false,
      "bStateSave": false,
      pageLength:10
    })
    var excel = $("div.dt-buttons").find(".excel");
    excel.addClass(excel);
    var csv = $("div.dt-buttons").find(".csv");
    csv.addClass(csv);
    var pdf = $("div.dt-buttons").find(".pdf");
    pdf.addClass(pdf);
    var print = $("div.dt-buttons").find(".print");
    print.addClass(print);
    mytable.columns.adjust().draw();
  }



}

