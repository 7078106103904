<div style="margin-top: 75px;"></div>
<section class="setting"> 
  <div class="bg-white " style="height: 45px;"> 
<div class="sec-tab">   
 <ul class="nav" role="tablist" style="margin-bottom: 0px;height: 50px;">
    <li class="nav-item">
      <button id="button" routerLink="/industry" (click)="click1();onChangeRibon()" style="color: #E6800E;">         
        <img src="../../assets/img/Templates icon.png" height="18" width="22"  style="margin-right: 5px;" class="img"> Templates</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/OrganisationOverview" (click)="clickonConfiguration();onChangeconfig();" data-toggle="pill" href="#config" >
         <img src="../../assets/img/{{configicon}}" height="18" width="22"  style="margin-right: 5px;"class="img" > Configurations</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/MasterOverview" (click)="clickMaster();onChangeMaster();" data-toggle="pill" href="#master">
        <img src="../../assets/img/{{masterIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Masters</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/ApiOverview" (click)="clickApi();onChangeApi();"  data-toggle="pill" href="#api">
        <img src="../../assets/img/{{apiIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> API's</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/BlockchainOverview" (click)="clickBlockChain();onChangeBlockChain();"  data-toggle="pill" href="#blkchain">
        <img src="../../assets/img/{{blockChainIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> Blockchain</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/BillingOverview" (click)="clickBilling();onChangebilling();"  data-toggle="pill" href="#billing">
        <img src="../../assets/img/{{billingIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Billing</button>
    </li>
  </ul>
</div>
</div>
</section>
<div>
  <section class="indst sec-tab"> 
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E6800E" type = "line-scale-pulse-out-rapid" [fullScreen] = "true">
      <!-- <p style="color: red" > Loading... </p> -->
    </ngx-spinner>
    <!-- <ngx-spinner></ngx-spinner> -->
      <div class="  tab-content">
          <div class="card">
            
              <div class="card-body" style="background-color: whitesmoke;" *ngIf="!addedited && !isRibbon">
                <div class="row" style="margin-top: -17px;margin-left: -17px; margin-bottom: 10px;background-color: white" >
                    <div>
                      <!-- <a routerLink="/industry">Home</a><span style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span> -->
                      <a routerLink="/industry"  style="font-size: 13px;padding: 10px;padding-left: 20px;">Industry</a> 
                      <!-- <a routerLink="/category">Category</a><span style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span>
                      <a routerLink="/template">Template</a> -->
                    </div>
                </div>
                  <!-- <label>Search :</label>&nbsp;&nbsp;
                  <input type="text"  (keyup)="onKeyUp($event)">  -->
                  <!-- <div class="row"> -->
                    <input type="text" (keyup)="onKeyUp($event)" name="search" placeholder="Search.." style="width: 93.3%;">
                    <!-- <button type="save" style="margin-left: 20px; height: 30px;width: 14%;" class="btn btn-save">See More</button> -->
                  <!-- </div> -->
   
                  <div class="row" style="margin-left: -10px;margin-bottom: 50px;">
                      
                      <div *ngFor="let data of IndustryList">
                          <div *ngIf="selectedString==''"><!--|| selectedString== data.Name-->
                          <div class="card card1 text-center">
                            <a routerLink="/category/{{data.Id}}/{{data.Name}}">
                              <div class="card-body" style="margin-left: -5px;margin-top: -5px;">
                                  <button class="btn" *ngIf="!addedited"  title="Industry" (click)="addedited=true;getIndustrybyid(data.Id);getCategoryByIndustryId(data.Id);">
                                      <img src="{{data.Icon}}" hight="80px" width="80px">
                                   
                                  </button>
                                  {{data.Name}}
                              </div>
                             
                            </a>
                          </div>
                          </div>
                      </div>
                      <div *ngFor="let data of filterdata">
                          <div *ngIf="selectedString!=''">
                          <div class="card card1 text-center">
                              <div class="card-body" style="margin-left: -5px;margin-top: -5px;">
                                  <div class="btn" *ngIf="!addedited" title="Industry" (click)="addedited=true;getIndustrybyid(data.Id);">
                                      <img src="{{data.Icon}}" hight="80px" width="80px">
                                      <p>{{data.Name}}</p>
                                  </div>
                              </div>
  
                          </div>
                          </div>
                      </div>
  
                      <div class="card card1 text-center">
                          <div class="card-body">
                              <div class="btn"
                                  style="color: #E6800E ;font-size: 16px;font-family: open sans-serif; margin-top: 12%;"
                                  *ngIf="!addedited" title="Create  Template" data-toggle="modal" data-target="#exampleModalCenter"><!--(click)="addedited=true;"-->
                                 <img src="../../assets/img/Plus.png" hight="50" width="50" >
                          </div>
                          </div>
  
                      </div>
                  </div>
  
              </div>
            
            
            <div *ngIf="isRibbon">
              <section class="sec-bg">
                <div class="tab-content">
                  <div id="template" class="container tab-pane active"><br>
                    <!-- <div class="d-flex bd-highlight mb-3">
                      <div class=" bd-highlight ">
                        <div class="card card1 sec-card-box mr-3">
                          <button class="btn" routerLink="/industry">
                            <img  src="../../assets/img/indsry-icon.png" alt="">
                           
                            <div class="card-body">
                              <p class="card-text">Industry</p>
                            </div>
                          </button>             
                        </div>
                        </div>         
                    </div> -->
                   
                  </div>
                  <div id="config" class="container tab-pane fade"><br>
                    
                    <div class="d-flex bd-highlight mb-3">
                      <div class="bd-highlight">
                        <div class="card card1 sec-card-box mr-3">
                          <button class="btn" routerLink="/organisation">
                            <img src="../../assets/img/org-icon.png" alt="">
                            <!-- <i class="fas fa-sitemap sec-icon"></i> -->
                          <div class="card-body">
                            <p class="card-text">Organization</p>
                          </div>
                          </button>              
                        </div>           
                      </div>
                      <div class="bd-highlight">
                        <div class="card card1 sec-card-box mr-3">
                          <button class="btn" routerLink="/branch">
                            <img src="../../assets/img/branch-icon.png" alt="">
                            <!-- <i class="fas fa-code-branch sec-icon"></i> -->
                          <div class="card-body">
                            <p class="card-text">Branch</p>
                          </div>
                          </button>              
                        </div>           
                      </div>
                      <div class="bd-highlight">
                        <div class="card card1 sec-card-box mr-3">
                          <button class="btn" routerLink="/role">
                            <img src="../../assets/img/role-icon.png" alt="">
                            <!-- <i class="fab fa-critical-role sec-icon"></i> -->
                            <div class="card-body">
                              <p class="card-text">Role</p>
                            </div>
                          </button>             
                        </div>           
                      </div>
                      <div class="bd-highlight">
                        <div class="card card1 sec-card-box mr-3">
                          <button class="btn" routerLink="/user">
                            <img src="../../assets/img/user-icon.png" alt="">
                            <!-- <i class="fa fa-user sec-icon" aria-hidden="true"></i> -->
                            <div class="card-body">
                              <p class="card-text">User</p>
                            </div>
                          </button>             
                        </div>           
                      </div>          
                    </div>
                   
                  </div>
                  <div id="master" class="container tab-pane fade"><br>
                    <div class="d-flex bd-highlight mb-3">
                      <div class="p-2 bd-highlight">Master</div>         
                    </div>             
                  </div>
                  <div id="api" class="container tab-pane fade"><br>
                    <div class="d-flex bd-highlight mb-3">
                      <div class="p-2 bd-highlight">Api</div>         
                    </div>
                         
                  </div>
                  <div id="blkchain" class="container tab-pane fade"><br>
                    <div class="d-flex bd-highlight mb-3">
                      <div class="p-2 bd-highlight">Blockchain</div>         
                    </div>              
                  </div>
                  <div id="billing" class="container tab-pane fade"><br><div class="d-flex bd-highlight mb-3">
                    <div class="p-2 bd-highlight">Billing</div>         
                  </div>
                         
                  </div>
                </div>

                <div class="row" style="float:right;margin:15px">
                  <button  routerLink="/industry" class="btn-save rounded mt-2 ml-2">Back</button>
                </div>
              </section>
             
             
            </div>
  
          </div>
      </div>
      <div id="snackbar"></div>
        <div id="snackbarerror"></div>
  
        <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header" style="max-height: 47px;">
            <h5 class="modal-title" style="margin-top: -0px;" id="exampleModalLongTitle"><img src="../../assets/img/backarrow.png"  data-dismiss="modal" aria-label="Close" height="12" width="14"> New Industry </h5>
            <button type="button"  class="close" (click)="onClearAll();" data-dismiss="modal" aria-label="Close">
              <span style="color: #E6800E;" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="industryForm" autocomplete="off">
            <div class="row">
            <div class="col-sm-12">
            <div class="card-body pad-card-body">
            <div class="form-group card-text row">
            <label for="usr" class="col-sm-12 col-lg-4 col-form-label text-right">
            Industry Name<sup class="star-style"><i class="fa fa-star" aria-hidden="true"></i></sup>:</label>
            <div class="col-sm-12 col-lg-8">
            <input type="text" class="form-control popup-input" formControlName="Name" id="name" placeholder="Enter Industry Name">
            </div>
            </div>
            </div>
            <div class="card-body pad-card-body">
            <div class="form-group card-text row">
            <label for="usr" class="col-sm-12 col-lg-4 col-form-label text-right">
            Description<sup class="star-style"><i class="fa fa-star"
            aria-hidden="true"></i></sup>:</label>
            <div class="col-sm-12 col-lg-8">
            <textarea class="form-control1 popup-textarea" rows="" formControlName="Description" id="comment" placeholder=" Enter Description"></textarea>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div class="row pad-card-body">
            <div class="col-sm-4" style="margin-right: 0px;"></div>
            <div class="col-sm-2">
            <div class="">
            <form id="file-upload-form" class="uploader text-left">
            <input id="file-upload" type="file" (change)="readUrl($event)" #UploadFileInput name="fileUpload" accept="image/*" style="display: none;" />
            <label for="file-upload" *ngIf="!url" id="file-drag" class="labelimg">
            <img id="file-image" src="../../assets/img/uplaod.png" height="27" width="27" alt="Preview">
            <div id="response" class="hidden">
            <div id="messages"></div>
            </div>
            </label>
            <img [src]="url" *ngIf="url" height="27" width="27" (change)="readUrl($event)">
            <img [src]="imagebyId" height="27" width="27" *ngIf="imagebyId" (change)="readUrl($event)">
            </form>
            </div>
            </div>
            <div class="col-sm-12 col-md-6">
            <div class="">
            <button type="save" (click)="onClearAll()" class="btn btn-clear mr-3">Clear All</button>
            <button type="save" (click)="onSave()" data-dismiss="modal" aria-label="Close" *ngIf=" isSave" class="btn btn-save">Save</button>
            <button type="save" (click)="onUpdate(industryForm.value);" *ngIf="isUpdate"
            class="btn btn-save">Update</button>
            </div>
            </div>
            </div>
            </form>
            </div>
      
        </div>
      </div>
    </div>
          <!--Update Modal -->
  <div class="modal fade" id="updateModalCenter" tabindex="-1" role="dialog" aria-labelledby="updateModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" style="margin-top: -0px;" id="exampleModalLongTitle"><img src="../../assets/img/backarrow.png"  data-dismiss="modal" aria-label="Close" height="12" width="14"> Update Industry</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span style="color: #E6800E;" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="industryForm" autocomplete="off">
              <!-- <div class="row">
                <div class="col-sm-12">
                  <div class="card-header h4-style">
                    <h3>Industrial Master Screen</h3>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col-sm-6 pt-2">
                  <div class="card-body">
                    <div class="form-group card-text pb-1 row">
                      <label class="col-sm-12 col-lg-3  col-form-label text-right">
                        Code<sup class="star-style"><i class="fa fa-star pr-1" aria-hidden="true"></i> </sup>:</label>
                      <div class="col-sm-12 col-lg-9 border p-1">
                        <input type="text" class="form-control" formControlName="Code" id="code" placeholder="Enter Code">
                      </div>
                    </div>
                  </div>
  
                  <div class="card-body">
                    <div class="form-group pb-1 card-text row">
                      <label for="usr" class="col-sm-12 col-lg-3 col-form-label text-right">
                        Name<sup class="star-style"><i class="fa fa-star pr-1" aria-hidden="true"></i></sup>:</label>
                      <div class="col-sm-12 col-lg-9 border p-1">
                        <input type="text" class="form-control" formControlName="Name" id="name" placeholder="Enter Name">
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="form-group pb-3 card-text row">
                      <label for="usr" class="col-sm-12 col-lg-3 col-form-label text-right">
                        Description<sup class="star-style"><i class="fa fa-star pr-1"
                            aria-hidden="true"></i></sup>:</label>
                      <div class="col-sm-12 col-lg-9 border p-1">
                        <!--  <input type="text" class="form-control" id="usr" placeholder="Enter Name"> -->
                        <textarea class="form-control1" rows="5" formControlName="Description" id="comment" placeholder=" Description"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="col-sm-6 pt-2">
                  <div class="card-body">
                    <!--   <div class="form-group pb-1 card-text row">        
                        <label for="usr"  class="col-sm-12 col-lg-3 col-form-label text-right">
                          Upload:</label>
                        <div class="col-sm-12 col-lg-9 border">
                      
                     <div class="input-group" style="padding: 2px 0px 2px 0px;margin-left: -10px;">
                        <div class="custom-file">
    
                          <input type="file" class="custom-file-input" id="inputGroupFile01" placeholder="Upload">
                          <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                        </div>
                    </div>
                      </div> 
                      </div>  -->
                    <form id="file-upload-form" class="uploader">
                      <input id="file-upload" type="file"  (change)="onFileSelect($event)" #UploadFileInput name="fileUpload" accept="image/*" style="display: none;" />
  
                      <label for="file-upload" id="file-drag" class="labelimg mt-1">
                        <img id="file-image" src="../../assets/img/uplaod.png" alt="Preview">
                        <div id="start" class="text-center">
                          <!--   <i class="fa fa-download" aria-hidden="true"></i> -->
                          <div>Select a file or drag here</div>
                          <!-- <div id="notimage" class="hidden">Please select an image</div> -->
                          <span id="file-upload-btn" class="btn mt-2 btn-save">Select a file</span>
                        </div>
                        <div id="response" class="hidden">
                          <div id="messages"></div>
                          <!--  <progress class="progress" id="file-progress" value="0">
            <span>0</span>%
          </progress> -->
                        </div>
                      </label>
                    </form>
                  </div>
                </div>
              </div>
  
              <!-- <div class="d-flex justify-content-end mt-4 mb-4 card-body">
                <button type="save" class="btn btn-clear mr-3">Clear All</button>
                <button type="save" class="btn btn-save">Save</button>
              </div> -->
              <div class="d-flex justify-content-end mt-4 mb-4 card-body">
                <button type="save" (click)="onClearAll()" class="btn btn-clear mr-3">Clear All</button>
                <button type="save" (click)="onSave()" *ngIf=" isSave" class="btn btn-save">Save</button>
                <button type="save" (click)="onUpdate(industryForm.value);" *ngIf="isUpdate"
                  class="btn btn-save">Update</button>
              </div>
            </form>
          </div>
    
        </div>
      </div>
    </div>

           <!--Category Modal -->
  <div class="modal fade" id="CategoryModalCenter" tabindex="-1" role="dialog" aria-labelledby="CategoryModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" style="margin-top: -0px;" id="exampleModalLongTitle"><img src="../../assets/img/backarrow.png"  data-dismiss="modal" aria-label="Close" height="12" width="14"> New Category</h6>
          <div type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span style="color: #E6800E;" aria-hidden="true">&times;</span>
          </div>
        </div>
        <div class="modal-body">
          <form [formGroup]="CategoryForm" autocomplete="off">
         
            <div class="row"style="margin-left:20px" >
              <div class="col-sm-12">
                <div class="card-body">
                  <div class="form-group card-text row">
                    <label for="CategoryName" class="col-sm-12 col-lg-3 col-form-label text-right">
                      Category Name<sup class="star-style"><i class="fa fa-star" aria-hidden="true"></i></sup>:</label>
                    <div class="col-sm-12 col-lg-9">
                      <input type="text" class="form-control" formControlName="CategoryName" id="CategoryName" placeholder="Enter Category Name">
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="form-group card-text row">
                    <label class="col-sm-12 col-lg-3  col-form-label text-right">
                      Industry<sup class="star-style"><i class="fa fa-star" aria-hidden="true"></i> </sup>:</label>
                    <div class="col-sm-12 col-lg-9">
                      <input type="text" class="form-control" readonly [value]="selectedIndustryId" formControlName="IndustryId" id="IndustryId" placeholder="Enter IndustryId">
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="form-group card-text row">
                    <label for="Description" class="col-sm-12 col-lg-3 col-form-label text-right">
                      Description<sup class="star-style"><i class="fa fa-star"
                          aria-hidden="true"></i></sup>:</label>
                    <div class="col-sm-12 col-lg-9">

                      <textarea style="min-width: 235px;"  class="form-control1" rows="5" formControlName="Description" id="Description" placeholder=" Description"></textarea>
                    </div>
                  </div>
                </div>
              </div>
             
           
            </div>

         
            <div class="d-flex justify-content-end mt-4 mb-4 card-body" style="margin-right: 55px;">
              <button type="save" (click)="onClearAll()" class="btn btn-clear mr-3">Clear All</button>
              <button type="save" (click)="onSaveCategory()"   data-dismiss="modal" aria-label="Close" class="btn btn-save">Save</button>
              <!-- <button type="save" (click)="onUpdate(CategoryForm.value);" *ngIf="isUpdate"
                class="btn btn-save">Update</button> -->
            </div>
          </form>
        </div>
    
      </div>
    </div>
  </div>
  </section>

  <!-- Ribbon Model -->

  <div class="modal fade" id="RibbonModalCenter" tabindex="-1" role="dialog" aria-labelledby="RibbonModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" style="margin-top: -0px;" id="exampleModalLongTitle"><img src="../../assets/img/backarrow.png"  data-dismiss="modal" aria-label="Close" height="12" width="14"> New Category</h6>
          <div type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span style="color: #E6800E;" aria-hidden="true">&times;</span>
          </div>
        </div>
        <div class="modal-body">
         
        </div>
    
      </div>
    </div>
  </div>

 
  </div>