import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiurl,apiurldonatekart } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DonatekartService {

  _apiurl: string = apiurl;
  _apiurldonatekart: string = apiurldonatekart;
  constructor(private _http: HttpClient) { }
  
getBlockChainDataDetails(QRCode) {

  return this._http.get(this._apiurl+'/Document/getDocument/' + QRCode);

}

getDataById(Id)
{
     return this._http.get(this._apiurldonatekart+'/Donors/getDonorsByDonorId/' + Id);
    //return this._http.get(this._apiurldonatekart+'/Donors/getDonorsByDonorId/' + Id);
}
}
