<p>
  Configuration works!
</p>
<section class="setting">    
  <div class="container bg-white"> 
    <div class="row m-3">
      <div class="col">
         <div class="card align-items-center p-4">            
                 <button><img class="card-img-top p-1" src="/assets/img/org-1.png" alt="Card image cap">         
                      <div class="card-body">
                      <h5 class="card-title">Organization</h5>
                      <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>              -->
                      </div>
                  </button> 
            </div>         
      </div>
      <div class="col">
          <div class="card align-items-center p-4">            
              <button><img class="card-img-top p-1" src="/assets/img/branch-1.png" alt="Card image cap">         
                   <div class="card-body">
                   <h5 class="card-title">Branch</h5>
                   <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>              -->
                   </div>
               </button> 
         </div>
      </div>
     
    </div>
    <!--second-row-->
    <div class="row m-3">
      <div class="col ">
        <div class="card align-items-center p-4">            
            <button><img class="card-img-top p-1" src="/assets/img/role-1.png" alt="Card image cap">         
                 <div class="card-body">
                 <h5 class="card-title">Role</h5>
                 <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>              -->
                 </div>
             </button> 
       </div>
    </div>
      <div class="col">
         <div class="card align-items-center p-4">            
                 <button><img class="card-img-top" src="/assets/img/user-1.png" alt="Card image cap">         
                      <div class="card-body">
                      <h5 class="card-title">User</h5>
                      <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>              -->
                      </div>
                  </button> 
            </div>         
      </div>
     
    </div>
    <!--3rd-row-->
   
  </div>
  </section>
  