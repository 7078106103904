import { Component, OnInit } from '@angular/core';
import {TenetService} from '../Service/tenet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {CommonService} from '../Service/common.service';
@Component({
  selector: 'app-tenet',
  templateUrl: './tenet.component.html',
  styleUrls: ['./tenet.component.css']
})
export class TenetComponent implements OnInit {
 divtenet:boolean=true;
 pclick:boolean=true;
 divDoc:boolean=false;
 Name:any;
 Age:any;
 Registeredon:any;
 Collectedon:any;
 Reportedon:any;
 SpecimanType:any;
 SARS:any;
 Ngene:any;
 ORF1ab:any;
 Method:any;
 TID:any;
 LabReport:any;
 VerifyLink:any;
 constructor( private _service: TenetService,
  private route: ActivatedRoute,
  private domSanitizer: DomSanitizer,
  private _common:CommonService) { }

  ngOnInit() {
    let qrcode = this.route.snapshot.params.QRCode;
    if (qrcode != '') {
      this._service.getBlockChainDataDetails(qrcode).subscribe((data) => {
        console.log(data);
        var index = data['posts']['childblockchaintriggers'].length;
        if(index == 0)
        {
          this.VerifyLink = data['posts']['blockchainurl'];
        }
        else
        {
          this.VerifyLink = data['posts']['childblockchaintriggers'][index-1].blockchainurl
        }
       
        for(let i=0;i<data['posts']['childblockchaintriggers'].length;i++)
        {
          let Docarr = data['posts']['childblockchaintriggers'][i].blockchaindata.split('||');
          for(let k=0;k<Docarr.length;k++)
          {
            var index = Docarr[k].indexOf(":");
            var Key = Docarr[k].substring(0,index);
            var Value = Docarr[k].substring(index+1,Docarr[k].length);
            if(Key == "Name")
            {
                this.Name = Value
            }
            if(Key == "Age")
            {
                this.Age = Value
            }
            if(Key == "Registered on")
            {
                this.Registeredon = Value
            }
            if(Key == "Collected on")
            {
                this.Collectedon = Value
            }
            if(Key == "Reported on")
            {
                this.Reportedon = Value
            }
            if(Key == "Speciman Type")
            {
                this.SpecimanType = Value
            }
            if(Key == "SARS-CoV-2-RNA DETECTION")
            {
                this.SARS = Value
            } 
            if(Key == "N gene")
            {
                this.Ngene = Value
            } 
            if(Key == "ORF 1ab")
            {
                this.ORF1ab = Value
            } 
            if(Key == "Method")
            {
                this.Method = Value
            } 
            if(Key == "TID/SID")
            {
                this.TID = Value
            } 
            if(Key == "Lab Report")
            {
               var img = 'https://docs.google.com/viewerng/viewer?url='+Value+'&embedded=true'
               this.LabReport =this.domSanitizer.bypassSecurityTrustResourceUrl(img)
              //  var json_arr = {};
              //  json_arr["base64"] = Value
              //  this._common.base64ToPDF(json_arr).subscribe(data=>{
              //   var img = 'https://docs.google.com/viewerng/viewer?url='+data+'&embedded=true'
              //   this.LabReport =this.domSanitizer.bypassSecurityTrustResourceUrl(img)
              //  })
              } 
          }
        }
      });
      }
  }
  labdetails()
  {
    this.divDoc = true;
    this.divtenet =false;
    this.pclick = false;
  }
  back()
  {
    this.divDoc = false;
    this.divtenet =true;
    this.pclick = true;
  }
}
