<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
<nav class="navbar navbar-expand-lg navbar-light bg fixed-top" style="padding: 0px !important;">
  <img src="../assets/img/image@2x.png" style="width: 100%;height: 100px;" />
</nav>
</div>
</div>
<!-- <div class="content" style="vertical-align: middle;padding-top: 200px;padding-left: 20px;padding-right: 20px;" *ngIf="hidediv">
  <div class="card col d-flex justify-content-center text-center">
    <p class="a-o-i">Enter Verification Code</p>
    <ng-otp-input #ngOtpInput [config]="config" (onInputChange)="onOtpChange($event)"></ng-otp-input><br/>
    <button type="button" class="btn btn-primary" [disabled]="!verifyButton" (click)="VerifyOTP($event)">Verify</button>
  </div>
  </div> -->
  <div class="content"  *ngIf="hidediv" style="padding: 0px !important;margin-top: 101px;">
    <div class="card col d-flex justify-content-center text-center">
      <img src="../../assets/img/code.png" style="width: 60px;height: 60px; display: block;margin-left: auto; margin-right: auto;">
      <p class="a-o-i" style="padding-top: 10px;">Verification Code</p>
      <p class="a-o-i2" style="margin-top: 20px;color:#5a5656;">Please Enter the Verification Code sent to <span style="color:#222222;font-size: 18px;font-weight: 600;">{{mobile}}</span></p> 
      <p style="margin-top: 20px;"></p>
      <ng-otp-input #ngOtpInput [config]="config" (onInputChange)="onOtpChange($event)"></ng-otp-input><br/>
      <p style="margin-top: 10px;"></p>
      <p style="font-size: 14px;color:#5a5656;font-weight: 300;">Didn't receive an OTP ?</p>
      <a style="font-size: 18px;color:#222222;font-weight: 600;" (click)="resendOTP()"><u>Resend OTP?</u></a>
      <p style="padding-bottom: 20px;"></p>
      <button type="button" class="btn btn-primary" [disabled]="!verifyButton" (click)="LatestVerifyOTP($event)" >Submit</button>
      <p style="padding-bottom: 30px;"></p>
    </div>
    </div>
    <!-- here -->
  
    <div class="content"  *ngIf="hidefornextverification && !hidedivForLandingpage" style="padding: 0px !important;margin-top: 101px;">
      <div class="card col d-flex justify-content-center text-center">
        <!-- <p style="margin-top: 95px;"></p> -->
        <p style="margin-top: 15px;" ></p>
        <img src="../../assets/img/croptest.PNG" style="width: 150px;height: 100px; display: block;margin-left: auto; margin-right: auto;">
       
        <p class="a-o-i" style="padding-top: 30px;">Congratulations </p>
        <p class="a-o-i3" style="margin-top: 18px;color:#222222;">{{LatestCustomerName}}</p> 
        
        
        <!-- <ng-otp-input #ngOtpInput [config]="config" (onInputChange)="onOtpChange($event)"></ng-otp-input><br/> -->
        <p style="margin-top: 10px;"></p>
        <p style="font-size: 16px;color:#5a5656;font-weight: 600;">Welcome to Home Page</p>
        <!-- <a style="font-size: 18px;color:#222222;font-weight: 600;" href="#"><u>Resend OTP?</u></a> -->
        <p style="padding-bottom: 40px;"></p>
        <button type="button" class="btn btn-primary" [disabled]="!verifyButton" (click)="hidedivForLandingpage=true;">Enter</button>
        <p style="padding-bottom: 40px;"></p>
      </div>
      </div>
  
  <div class="container-fluid" #test *ngIf="hidedivForLandingpage" style="padding-left: 0px !important;padding-right: 0px !important;margin-top: 101px;">
    <section class="content">
        <div class="card" style="padding: 0px !important;">
            <!-- <h5 class="card-header" style="padding:0px !important">
                <img src="../assets/img/image@2x.png" style="width: 100%;height: 100px;" />
            </h5> -->
            <div class="card-body" style="padding: 0px !important;">
              <div class="box" id="listItems" >
                <div class="box-body">
                  <ul class="list-group list-group-horizontal" style="list-style: none;overflow-x: scroll;background-color:rgba(0,0,0,.03);padding: 5px;justify-content: center;">
                    <li [ngClass]="{'active': tab==='tab1'}"><img id="img1" src="../assets/img/LoanDetails.png"  (click)="onLoanDetails()" style="margin:5px"></li>
                    <li [ngClass]="{'active': tab==='tab2'}"><img id="img2" src="../assets/img/Documents.png" (click)="onDocDetails()" style="margin:5px"></li>
                    <li [ngClass]="{'active': tab==='tab3'}"><img id="img3" src="../assets/img/Assets.png" (click)="onAssetDetails()" style="margin:5px"></li>
                    <li [ngClass]="{'active': tab==='tab4'}"><img id="img4" src="../assets/img/Events.png" (click)="onEventDetails()" style="margin:5px"></li>
                  </ul>
                </div>
                </div>
                <div class="box" id="LoanDetails" *ngIf='LoanDetails'  style="margin:10px;box-shadow: 0px 3px 6px #00000029;">
                  <div class="box-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: white;border-bottom: 1px solid lightgrey;"><img src="../assets/img/Mlogo.png" style="width: 50%;height: 50px;padding: 10px;"/></div>
                  <!-- <div class="box-header" style="border-bottom: 1px solid #00000029;font-family: open sans-serif;padding-left: 10px;"><b>Loan Details</b></div> -->
                  <div class="box-body">
                    <div class="table-responsive-sm">
                    <table class="table" style="font-family: open sans-serif">
                      <thead>
                      </thead>
                       <tbody>
                        <tr>
                          <td>
                            <img class="noselect" src="{{LatestCustImage}}" width="130px" height="130px;" style="border:1px solid" style="width: 100px;"/>
                         </td>
                        </tr>  
                        <!-- <tr *ngFor="let docs of DocumentData">
                          <td style="white-space: nowrap;">{{docs.Key}}</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{docs.Value}}</td>
                        </tr> -->
                        <tr>
                          <td style="white-space: nowrap;">Loan A/c No</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestLoanNo}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Customer Name</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestCustomerName}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Loan Amount</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestLoanAmount}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Interest Rate</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestInterestRate}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">EMI Starts On</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestEMIStartsOn}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Father Name</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestFatherName}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Mail</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestMail}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Phone</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestPhone}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Customer A/c No</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestCustomerNo}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Membership No</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestMembershipNo}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Period in Days</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestPeriodinDays}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">EMI Amount</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestEMIAmount}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Valuation (10gms)</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestValuation}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Total Weight</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestTotalWeight}}</td>
                        </tr>
                        <tr>
                          <td style="white-space: nowrap;">Net Weight</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">{{LatestNetWeight}}</td>
                        </tr>
                       </tbody>
                    </table>
                    <p style="margin-bottom: 50px;"></p>
                    </div>
                  </div>
                </div>
                <div class="box" id="DocumentsDetails" *ngIf='DocDetails' style="margin-top: 10px;">
                  <!-- <div class="box-header" style="border-bottom: 1px solid #00000029;padding: 8px;font-family: open sans-serif"><b>Documents</b></div> -->
                  <div class="box-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: white;border-bottom: 1px solid lightgrey;"><img src="../assets/img/Mlogo.png" style="width: 50%;height: 50px;padding: 10px;"/></div>
                  <div class="box-body" style="padding-top: 10px;">
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" style="text-align: center;">
                      <div class="carousel-inner">
                        <div class="carousel-item active" *ngIf='bolfUrl' >
                       <iframe  height="397px" [src]='pdfUrl' type="application/pdf"></iframe>
                        </div>
                     
                      </div>
                      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                      </a>
                    </div> 
                  
                  </div>
                </div>
                <div class="box" id="AssetDetails" *ngIf='AssetDetails' style="margin-top: 10px;">
                  <!-- <div class="box-header" style="border-bottom: 1px solid #00000029;padding: 8px;font-family: open sans-serif"><b>Assets</b></div> -->
                  <div class="box-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: white;border-bottom: 1px solid lightgrey;"><img src="../assets/img/Mlogo.png" style="width: 50%;height: 50px;padding: 10px;"/></div>
                  <div class="box-body" style="padding-top: 10px;">
                    <ul class="list-group list-group-horizontal" style="list-style: none;overflow-x:scroll;">
                      <!-- <li *ngFor="let image of images">
                        <p>{{image}}</p>
                        <img src="{{image}}" style="width: 50%;height: 50%;" />
                      </li> -->
                       <li>
                        <p>{{image}}</p>
                        <img src="{{LatestAsset}}" style="width: 50%;height: 50%;" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="box" id="EventDetails" *ngIf='EventDetails' style="margin-top: 10px;">
                  <!-- <div class="box-header" style="border-bottom: 1px solid #00000029;padding: 8px;font-family: open sans-serif"><b>Events</b></div> -->
                  <div class="box-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: white;border-bottom: 1px solid lightgrey;"><img src="../assets/img/Mlogo.png" style="width: 50%;height: 50px;padding: 10px;"/></div>
                   <div class="box-body" style="overflow-y: auto;padding: 20px;">
                   
                   <ng-container *ngFor="let Data of EventDocument">
                    <div class="card" *ngFor="let d of Data" style="padding: 0px;">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-6" style="width: 150px;text-align: left;">{{d.Activity}}</div>
                          <div class="col-sm-6" style="width: 150px;text-align: left;">{{d.Date}}</div>
                          <div class="col-md-6" style="width: 150px;font-size: 12px;text-align: left;padding-top: 3px;">{{d.App}}</div>
                          <div class="col-md-6" style="width: 150px;text-align: left;padding-top: 3px;"><a href="{{d.Verify}}" target="_blank" ><img src="../assets/img/verify.png" style="width: 30px;height: 20px;"/></a></div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <p style="margin-bottom: 50px;"></p>
                     <!-- <div class="table-responsive-sm">
                       <table class="table table-bordered" id="EventTable" *ngFor="let Data of AllItems">
                  
                         <tr *ngFor="let innerData of Data;let i =index"  style="font-family: open sans-serif" >
                          <ng-container *ngIf="i == 0">
                          <th *ngFor="let item of innerData|  keyvalue:originalOrder" >{{item.key}}</th>
                         </ng-container>
                         </tr>
                        <tbody>
                          <tr *ngFor="let innerData of Data">
                            <ng-container *ngFor="let item of innerData |  keyvalue:originalOrder">
                              <td *ngIf="!item.value.startsWith('https://')">{{item.value}}</td>
                              <td *ngIf="item.value.startsWith('https://')"><a href="{{item.value}}" target="_blank" class="button"
                                  style="color: white;">Verify</a></td>
                            </ng-container>
                          </tr>
                        </tbody>
                       </table>
                     </div> -->
                     </div>
                 </div>
                <!-- <div class="box" id="EventDetails" *ngIf='EventDetails' style="margin-top: 10px;">
                 <div class="box-header" style="border-bottom: 1px solid #00000029;padding: 8px;font-family: open sans-serif"><b>Events</b></div>
                  <div class="box-body" style="height: 457px;overflow-y: auto;padding-top: 10px;">
                    <div class="table-responsive-sm">
                      <table class="table table-bordered" id="EventTable">
                        <tr *ngFor="let headerRes of Header"  style="font-family: open sans-serif" >
                         <th *ngFor="let head of headerRes">{{head}}</th>
                        </tr>
                       <tr *ngFor="let event of res;" style="font-family: open sans-serif" >
                          <td *ngFor="let data of event">{{data}}</td>
                       </tr>
                      </table>
                    </div>
                    </div>
                </div> -->
                  <!-- <div class="card-footer" style="padding:0px !important;margin-top: 10px;" ><img src="../assets/images/footer.png" style="width: 100%;height: 63px;"/></div> -->
            </div>
          </div>
    </section>
  </div>

  <div class="container-fluid" style="padding: 0px !important;">
    <div class="card col-md-6" style="padding: 0px !important;">
    <!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
        <div class="d-flex" style="width: 100%;">
            <img
                src="../assets/img/footer@2x.png" style="width: 100%;height: 63px;" />
        </div>
      </nav> -->
      <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
        <div class="d-flex" style="width: 100%;">
            <div class="mr-auto pt-3">
                <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
            </div>
            <div class="pt-3">
             <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
           </div>
            <div class="pt-1 logo-ft ">
              <img src="/assets/img/TRST001.jpeg">
            </div>
        </div>
      </nav>
      <!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
        <div class="d-flex" style="width: 100%;">
            <div class="mr-auto pt-3">
                <p class="ft-text" style="color: white;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
            </div>
            <div class="pt-3">
             <p class="ft-pwr pr-1">Powered by</p> 
           </div>
            <div class="pt-1 logo-ft ">
               <img src="/assets/img/footer-logo.png">
            </div>
        </div>
        
      </nav> -->
    </div>
  </div>