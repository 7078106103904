<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <nav class="navbar navbar-expand-lg navbar-light bg fixed-top" style="padding: 0px !important;">
      <img src="../assets/img/image@2x.png" style="width: 100%;height: 100px;" />
    </nav>
  </div>
</div>
<div class="container-fluid" style="padding: 0px !important;margin-top: 101px;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divtenet">
      <div class="card-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: white;">
        <img src="../assets/img/tenet-logo.png" width="100px" height="50px" /></div>
      <!-- <div class="card-header" style="text-align: center;font-size: 20px;font-weight: bold;">Tenet 
        Diagnostics</div> -->
      <div class="card-body" style="padding: 0px;">

        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif">
            <thead>
            </thead>
            <tbody>
              <tr>
                <td style="white-space: nowrap;padding-top: 10px;">Name</td>
                <td style="padding-top: 10px;">:</td>
                <td style="white-space: normal !important;padding-top: 10px;">{{Name}}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;padding: 0px;padding-left: 10px;">Age</td>
                <td style="padding: 0px;padding-left: 10px;">:</td>
                <td style="padding: 0px;padding-left: 10px;">{{Age}}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">Registered on</td>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
                <td style="white-space: normal !important;padding: 0px;padding-left: 10px;padding-top: 10px;">
                  {{Registeredon}}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">Collected on</td>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
                <td style="white-space: normal !important;padding: 0px;padding-left: 10px;padding-top: 10px;">
                  {{Collectedon}}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">Reported on</td>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
                <td style="white-space: normal !important;padding: 0px;padding-left: 10px;padding-top: 10px;">
                  {{Reportedon}}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">Specimen Type</td>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
                <td
                  style="white-space: normal !important;text-align: justify;word-break: break-word;padding: 0px;padding-left: 10px;padding-top: 10px;padding-right: 10px;">
                  {{SpecimanType}}</td>
              </tr>
              <tr>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">SARS-CoV-2 - RNA DETECTION</td>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
                <td
                  style="white-space: normal !important;word-break: break-word;font-weight: bold;padding: 0px;padding-left: 10px;padding-top: 10px;">
                  {{SARS}}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">N gene</td>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
                <td
                  style="white-space: normal !important;text-align: justify;word-break: break-word;padding: 0px;padding-left: 10px;padding-top: 10px;">
                  {{Ngene}}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">ORF 1ab</td>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
                <td
                  style="white-space: normal !important;text-align: justify;word-break: break-word;padding: 0px;padding-left: 10px;padding-top: 10px;">
                  {{ORF1ab}}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">Method</td>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
                <td
                  style="white-space: normal !important;text-align: justify;word-break: break-word;padding: 0px;padding-left: 10px;padding-top: 10px;">
                  {{Method}}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">TID/SID</td>
                <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
                <td
                  style="white-space: normal !important;text-align: justify;word-break: break-word;padding: 0px;padding-left: 10px;padding-top: 10px;padding-right: 10px;">
                  {{TID}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <p style="font-style: italic;font-size:12px;text-align: center;margin-bottom: 60px;" *ngIf="pclick">Verified on
      TRST01 cloud <a href="{{VerifyLink}}" target="_blank"
        style="color: #1f3978;text-decoration: underline;font-size:12px">Blockchain</a> Platform.<br> <a
        style="color: #ff4d4d;font-style: italic;font-size:12px;text-decoration: underline;"
        (click)="labdetails()">click here</a> for the lab report</p>
    <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divDoc">
      <div class="card-header" style="text-align: center;background-color: #fff;font-weight: bold;font-size: 16px;">
        
        <span style="float: left;padding-top: 10px;"><i class="fa fa-reply" style="font-size: 20px;padding: 3px;" (click)="back()"></i></span>
        <span ><img src="../assets/img/tenet-logo.png" width="100px" height="50px"  style="margin-right: 44px;"/></span>
      </div>
      <div class="card-body" style="text-align: center;">
        <iframe [src]="LabReport" height="397px">
        </iframe>
        <!-- <div class="pdf-container">
          <pdf-viewer [src]="LabReport" [original-size]="false" ></pdf-viewer>
        </div> -->
      </div>

    </div>
  </div>
</div>
<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
      <div class="d-flex" style="width: 100%;">
        <img src="../assets/img/footer@2x.png" style="width: 100%;height: 63px;" />
      </div>
    </nav> -->
    <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
      <div class="d-flex" style="width: 100%;">
          <div class="mr-auto pt-3">
              <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
          </div>
          <div class="pt-3">
           <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
         </div>
          <div class="pt-1 logo-ft ">
            <img src="/assets/img/TRST001.jpeg">
          </div>
      </div>
    </nav>
  </div>
</div>