import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Onboarding',
  templateUrl: './Onboarding.component.html',
  styleUrls: ['./Onboarding.component.css']
})
export class OnboardingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
