<p>
  Onboarding works!
</p>
<section class="setting">    
  <div class="container bg-white"> 
    <div class="row m-3">
      <div class="col">
         <!-- <div class="card align-items-center p-4">            
                 <button><img class="card-img-top rounded-circle" src="/assets/img/user-icon.png" alt="Card image cap">         
                      <div class="card-body">
                      <h5 class="card-title">Organization View</h5>
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>             
                      </div>
                  </button> 
            </div>          -->
      </div>
      <div class="col">
          <div class="card align-items-center p-4">            
              <button><img class="card-img-top rounded-circle" src="/assets/img/template.jpg" alt="Card image cap">         
                   <div class="card-body">
                   <h5 class="card-title">Template</h5>
                   <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>              -->
                   </div>
               </button> 
         </div>
      </div>
      <div class="col">
        <!-- <div class="card align-items-center p-4">            
            <button><img class="card-img-top rounded-circle" src="/assets/img/user-icon.png" alt="Card image cap">         
                 <div class="card-body">
                 <h5 class="card-title">Template</h5>
                 <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>             
                 </div>
             </button> 
       </div> -->
    </div>
     
    </div>
    <!--second-row-->
   
    <!--3rd-row-->
   
  </div>
  </section>
  