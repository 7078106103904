import { Component, OnInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {CsmService} from '../Service/csm.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-CMS',
  templateUrl: './CMS.component.html',
  styleUrls: ['./CMS.component.css']
})
export class CMSComponent implements OnInit {
  firstFormGroup: FormGroup;
  divfirst : boolean =true;
  divsecond : boolean = false;
  divthird :boolean = false;
  divp:boolean = false;
  divSelect:boolean = false;

  multi: any[];
  view: any[] = [400, 200];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  CommodityName:any;
  DateOfChallan:any;
  DcNo:any;
  DepotCode:any;
  DepotName:any;
  DriverNo:any;
  KMSyear:any;
  Lot:any;
  MillerName:any;
  NoOfBags:any;
  PackagingMaterial:any;
  TCNo:any;
  VehicleNo:any;
  dcNumber:any;
  district:any;
  hash:any;
  StockNo:any;
  StockName:any;
  StockQuantity:any;
  Bags:any;
  RecieveDate:any;
  SampleDate:any;
  SampleNo:any;
  vehicle:any = [];
  stockQuantity:any = [];
  mainarr:any =[];
  lotArr:any = [];
  NoofLots:any;
  selectedLotNo:any;
  filteredLotNo:any = [];
  localDC:any;
  findLotNo:any;
  TxId:any;
  TotalBags:any;
  addbags:any;
  blockCount:any;
  Variety:any;
  colorScheme = {
    domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90', '#ff3333','#ffbb33','#ff1aff','#1aff1a']
  };
  constructor(private _csm:CsmService,private router: Router,
    private spinner: NgxSpinnerService,  private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      Name: ['']
    });
  }


  getdetails()
  {
    //this.spinner.show();
    this.spinner.show("spinner-1");
    setTimeout(() => {
      this.spinner.hide("spinner-1");
      this.spinner.show("spinner-2");
      setTimeout(() => {
        this.divSelect = true;
        this.localDC =(<HTMLInputElement>document.getElementById("dcnumber")).value;
        this._csm.getDataHistory((<HTMLInputElement>document.getElementById("dcnumber")).value).subscribe((data:any)=>{
         var check = JSON.parse(data.toString())
         console.log(check.length);
         this.blockCount =check.length;
          if(check.length !=0)
          {
            this.addbags = 0;
            this.lotArr =[];
            this.vehicle =[];
            var obj = {};
            var jsondata = JSON.parse(data.toString());
            console.log(jsondata)
            //console.log(jsondata[0]["TxId"])
            this.TxId =jsondata[0]["TxId"];
            let Mill = jsondata.filter(val => val.Value["status"] == "Mill");
           // console.log(Mill)
            let Godown = jsondata.filter(val => val.Value["status"] == "Godown");
           // console.log(Godown)
            
            for(let i=0;i<Mill.length;i++)
            {
              var lotobj = {};
              if('DcNo' in JSON.parse(Mill[i]["Value"]["jsonValue"]))
              {
                var millerjson = JSON.parse(Mill[i]["Value"]["jsonValue"]);
              //  if(millerjson["Lot"] != "3") //temp
                //{
                  //if(millerjson["Lot"] != "1") //temp
                  //{
                   
                 
                lotobj ={"Text":millerjson["Lot"],"Value":millerjson["Lot"]};
                if(this.lotArr.length == 0)
                {
  
                  this.selectedLotNo = millerjson["Lot"];
                  
                }
                this.lotArr.push(lotobj);
             
            //  }//temp
            //  } //temp
                if(this.selectedLotNo != null)
                {
                  if(millerjson["Lot"] == this.selectedLotNo)
                  {
                    console.log(this.selectedLotNo)
                    this.dcNumber = millerjson["DcNo"];
                    this.hash =millerjson["DcNo"];
                    this.MillerName = millerjson["MillerName"];
                   this.DepotName = millerjson["DepotName"]
                   this.DepotCode = millerjson["DepotCode"]
                   this.CommodityName =millerjson["CommodityName"];
                   this.Variety = millerjson["CommodityCategoryName"];
                   this.DateOfChallan = millerjson["DateOfChallan"];
                   this.Lot = millerjson["Lot"]
                   this.NoOfBags = millerjson["NoOfBags"];
                   this.addbags = this.addbags + parseInt(millerjson["NoOfBags"]);
                   this.TotalBags = millerjson["intTotalQuantityofBags"]
                   this.KMSyear = millerjson["KMSyear"];
                   this.TCNo = millerjson["TCNo"]
                   this.PackagingMaterial = millerjson["PackagingMaterial"];
                   this.VehicleNo = millerjson["VehicleNo"];
                   this.DriverNo = millerjson["DriverNo"]
                   this.district = millerjson["PaddyProcurementDistrict"]
                   obj = {"TC":millerjson["TCNo"],"VNo":millerjson["VehicleNo"],"Name":millerjson["DriverNo"],"Quantity":"0"}
                   this.vehicle.push(obj)
                   this.divp = true;
                   this.divfirst = false;
                   this.divsecond = true;
                  }
             
                }
  
                if(Mill.length-1 ==i)
                {
                  console.log(this.addbags)
                  const unique = [...new Map(this.lotArr.map(item => [item["Value"], item])).values()]
                  this.filteredLotNo = unique;
                  console.log(this.filteredLotNo.length)
                  this.NoofLots = this.filteredLotNo.length;
                  for(let j=0;j<Godown.length;j++)
                  {
                    var stockobj = {};
                      var StockJson = JSON.parse(Godown[j]["Value"]["jsonValue"])
                      console.log(StockJson)
                      console.log(StockJson["strLotNo"])
                      console.log(this.selectedLotNo)
                      if(StockJson["strLotNo"] == this.selectedLotNo)
                      {
                       this.findLotNo =StockJson["strLotNo"];
                var StockJson = JSON.parse(Godown[j]["Value"]["jsonValue"])
                console.log(StockJson)
                var challanDetails = StockJson["challanDetails"];
                console.log(challanDetails)
                for(let c=0;c<challanDetails.length;c++)
                {
                  stockobj = {};
                  stockobj = {"TCNo":challanDetails[c]["ChallanNo"],"Quantity":challanDetails[c]["NetWt"]}
                  this.stockQuantity.push(stockobj);
                }
               
                this.StockName = StockJson["lotStackDetails"][0]["strStackName"]
                this.StockQuantity =StockJson["lotStackDetails"][0]["decQuantity"]
                this.Bags =StockJson["lotStackDetails"][0]["intBag"];
                this.RecieveDate = StockJson["strRecieveDate"]
                console.log(moment(this.RecieveDate, ["h:mm A"]).format("HH:mm"))
                this.SampleDate = StockJson["strSampleDate"]
                this.SampleNo = StockJson["strSampleNo"]
                 //this.divthird = true;
                  var stockData =JSON.parse(Godown[j]["Value"]["jsonValue"]);
                  this.getchart(stockData["lotQualityAnalyses"])
                 
                      }
                      if(Godown.length-1 == j)
                  {
                  console.log(this.vehicle)
                  console.log(this.stockQuantity)
                   this.mainarr = this.vehicle.map(x=>Object.assign(x, this.stockQuantity.find(y=>y.TCNo==x.TC)))
                  this.vehicle = [];
                  this.vehicle = this.mainarr;
                   console.log(this.vehicle)
                  if(this.findLotNo == this.selectedLotNo )
                  {
                    this.divthird =true;
                  }
                  else{
                    this.divthird =false;
                  }
                  }
                    
                  }
                
                }
              }
             
  
              
            }
          }
          //this.spinner.hide();
          this.spinner.hide("spinner-2");
        })
      }, 3000);
    },3000);
   

    
   
  }
  getchart(data:any)
  {
    var multi = [
      {
        "name": "Foreign Matter",
        "series": [
          {
            "name": "1",
            "value": data[0]["decValue"]
          }
        ]
      },
      {
        "name": "Broken",
        "series": [
          {
            "name": "2",
            "value": data[1]["decValue"]
          }
        ]
      },
      {
        "name": "Small Broken",
        "series": [
          {
            "name": "3",
            "value": data[2]["decValue"]
          }
        ]
      },
      {
        "name": "Total Broken",
        "series": [
          {
            "name": "4",
            "value": data[3]["decValue"]
          }
        ]
      },
      {
      "name": "Damaged",
      "series": [
        {
          "name": "5",
          "value": data[4]["decValue"]
        }
      ]
    },
    {
      "name": "Discolored Grain",
      "series": [
        {
          "name": "6",
          "value": data[5]["decValue"]
        }
      ]
    }
    ,
    {
      "name": "Chalky Grain",
      "series": [
        {
          "name": "7",
          "value": data[6]["decValue"]
        }
      ]
    }, 
    {
      "name": "Red Grain",
      "series": [
        {
          "name": "8",
          "value": data[7]["decValue"]
        }
      ]
    }
    ,
    {
      "name": "Admixure",
      "series": [
        {
          "name": "9",
          "value": data[8]["decValue"]
        }
      ]
    }
    ,
  
      {
        "name": "Dehusked Grain",
        "series": [
          {
            "name": "10",
            "value": data[9]["decValue"]
          }
        ]
      },
      {
        "name": "Moisture",
        "series": [
          {
            "name": "11",
            "value": data[10]["decValue"]
          }
        ]
      }
      
     
    ];

    Object.assign(this, { multi });
  }
  percentTickFormatting(val: any) {
    return val.toLocaleString() + '%';
  }
  onSelect(event) {
    console.log(event);
  }
  OnChangeLotNo(event)
  {
    console.log(event)
this.selectedLotNo = event

this._csm.getDataHistory(this.localDC).subscribe((data:any)=>{
  var check = JSON.parse(data.toString())
  this.blockCount =check.length;
  if(check.length !=null)
  {
    this.addbags = 0;
    this.lotArr =[];
    this.vehicle =[];
    var obj = {};
    var jsondata = JSON.parse(data.toString());
    console.log(jsondata)
    let Mill = jsondata.filter(val => val.Value["status"] == "Mill");
    console.log(Mill)
    let Godown = jsondata.filter(val => val.Value["status"] == "Godown");
    console.log(Godown)
    
    for(let i=0;i<Mill.length;i++)
    {
      var lotobj = {};
      if('DcNo' in JSON.parse(Mill[i]["Value"]["jsonValue"]))
      {
        var millerjson = JSON.parse(Mill[i]["Value"]["jsonValue"]);
       
        if(this.selectedLotNo != null)
        {
          if(millerjson["Lot"] == this.selectedLotNo)
          {
            console.log(this.selectedLotNo)
            this.dcNumber = millerjson["DcNo"];
            this.MillerName = millerjson["MillerName"];
           this.DepotName = millerjson["DepotName"]
           this.DepotCode = millerjson["DepotCode"]
           this.CommodityName =millerjson["CommodityName"];
           this.Variety = millerjson["CommodityCategoryName"];
           this.DateOfChallan = millerjson["DateOfChallan"];
           this.Lot = millerjson["Lot"]
           this.NoOfBags = millerjson["NoOfBags"];
           this.addbags = this.addbags + parseInt(millerjson["NoOfBags"]);
           this.KMSyear = millerjson["KMSyear"];
           this.TCNo = millerjson["TCNo"]
           this.PackagingMaterial = millerjson["PackagingMaterial"];
           this.VehicleNo = millerjson["VehicleNo"];
           this.DriverNo = millerjson["DriverNo"]
           this.district = millerjson["PaddyProcurementDistrict"]
           obj = {"TC":millerjson["TCNo"],"VNo":millerjson["VehicleNo"],"Name":millerjson["DriverNo"],"Quantity":"0"}
           this.vehicle.push(obj)
           this.divp = true;
           this.divfirst = false;
           this.divsecond = true;
          }
     
        }

        if(Mill.length-1 ==i)
        {
            console.log(this.addbags)
          for(let j=0;j<Godown.length;j++)
          {

            var stockobj = {};
              var StockJson = JSON.parse(Godown[j]["Value"]["jsonValue"])

              
              console.log(StockJson["strLotNo"])
              console.log(this.selectedLotNo)
             
              if(StockJson["strLotNo"] == this.selectedLotNo)
              {
                this.findLotNo =StockJson["strLotNo"];
        var StockJson = JSON.parse(Godown[j]["Value"]["jsonValue"])
        console.log(StockJson)
        var challanDetails = StockJson["challanDetails"];
        console.log(challanDetails)
        for(let c=0;c<challanDetails.length;c++)
        {
          stockobj = {};
          stockobj = {"TCNo":challanDetails[c]["ChallanNo"],"Quantity":challanDetails[c]["NetWt"]}
          this.stockQuantity.push(stockobj);
        }
       
        this.StockName = StockJson["lotStackDetails"][0]["strStackName"]
        this.StockQuantity =StockJson["lotStackDetails"][0]["decQuantity"]
        this.Bags =StockJson["lotStackDetails"][0]["intBag"];
        this.RecieveDate = StockJson["strRecieveDate"]
        this.SampleDate = StockJson["strSampleDate"]
        this.SampleNo = StockJson["strSampleNo"]
        // this.divthird = true;
          var stockData =JSON.parse(Godown[j]["Value"]["jsonValue"]);
          this.getchart(stockData["lotQualityAnalyses"])
         
              }
              if(Godown.length-1 == j)
          {
          console.log(this.vehicle)
          console.log(this.stockQuantity)
           this.mainarr = this.vehicle.map(x=>Object.assign(x, this.stockQuantity.find(y=>y.TCNo==x.TC)))
          this.vehicle = [];
          this.vehicle = this.mainarr;
           console.log(this.vehicle)
           if(this.findLotNo == this.selectedLotNo )
           {
             this.divthird =true;
           }
           else{
             this.divthird =false;
           }
          }
            
          }
        
        }
      }
     

      
    }
  }
 
})
  }

  Verify()
  {
    this.hash = this.hash+';'+this.TxId+';'+this.blockCount;
    console.log(this.hash)
    this.router.navigate(["verifyhyperledge", this.hash]);
  }
 
}
