import { Component, OnInit } from '@angular/core';
import {TenetService} from '../Service/tenet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tenet-CBP',
  templateUrl: './tenet-CBP.component.html',
  styleUrls: ['./tenet-CBP.component.css']
})
export class TenetCBPComponent implements OnInit {
  p: number = 1;
 divnext:boolean = true;
 divback:boolean = false;
 VerifyLink : any;
 Name:any;
 Age:any;
 TID:any;
 collectedDate:any;
 ReportedDate:any;
 DataItemsList:any;
 divDoc:boolean=false;
 divPersonalDetails :boolean = true;
 divReport:boolean = true;
 pclick:boolean = true;
 LabReport:any;
 constructor( private _service: TenetService,
  private route: ActivatedRoute,private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    let  DataCollection =[];
    let qrcode = this.route.snapshot.params.QRCode;
    if (qrcode != '') {
      this._service.getBlockChainDataDetails(qrcode).subscribe((data) => {
        console.log(data);
        var index = data['posts']['childblockchaintriggers'].length;
        this.VerifyLink = data['posts']['childblockchaintriggers'][index-1].blockchainurl

        for(let i=0;i<data['posts']['childblockchaintriggers'].length;i++)
        {
          let Docarr = data['posts']['childblockchaintriggers'][i].blockchaindata.split('||');
          DataCollection = [];
          for(let k=0;k<Docarr.length;k++)
          {
            
            var obj = {};
            var index = Docarr[k].indexOf(":");
            var Key = Docarr[k].substring(0,index);
            var Value = Docarr[k].substring(index+1,Docarr[k].length);
                if(Key == 'Name')
                {
                  this.Name = Value;
                }
                if(Key == 'Age/Gender')
                {
                  this.Age = Value;
                }
                if(Key == 'TID/SID')
                {
                  this.TID = Value;
                }
                if(Key == 'Collected on')
                {
                  this.collectedDate = Value;
                }
                if(Key == 'Reported on')
                {
                  this.ReportedDate = Value;
                }
                if(Key == 'Hemoglobin')
                {
                   obj = {V1:Key,V2:Value,V3:'gm%',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'PCV/HCT')
                {
                   obj = {V1:Key,V2:Value,V3:'vol%',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Total RBC count')
                {
                   obj = {V1:Key,V2:Value,V3:'mill /cu.mm',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'MCV')
                {
                   obj = {V1:Key,V2:Value,V3:'fL',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'MCH')
                {
                   obj = {V1:Key,V2:Value,V3:'pg',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'MCHC')
                {
                   obj = {V1:Key,V2:Value,V3:'g/dL',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'MCHC')
                {
                   obj = {V1:Key,V2:Value,V3:'g/dL',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'RDW(CV)')
                {
                   obj = {V1:Key,V2:Value,V3:'%',V4:'HIGH',V5:'red',V6:'bold'} 
                   DataCollection.push(obj)
                }
                if(Key == 'MPV')
                {
                   obj = {V1:Key,V2:Value,V3:'fL',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Total WBC Count')
                {
                   obj = {V1:Key,V2:Value,V3:'cells/cumm',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Platelet Count')
                {
                   obj = {V1:Key,V2:Value,V3:'lakhs/cumm',V4:'LOW',V5:'red',V6:'bold'} 
                   DataCollection.push(obj)
                }
                if(Key == "Lab Report")
                {
                    var img = 'https://docs.google.com/viewerng/viewer?url='+Value+'&embedded=true';
                     console.log(img)
                    this.LabReport =this.domSanitizer.bypassSecurityTrustResourceUrl(img)
                } 
                if(Key == 'Neutrophils')
                {
                   obj = {V1:Key,V2:Value,V3:'%',V4:'HIGH',V5:'red',V6:'bold'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Lymphocytes')
                {
                   obj = {V1:Key,V2:Value,V3:'%',V4:'LOW',V5:'red',V6:'bold'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Eosinophils')
                {
                   obj = {V1:Key,V2:Value,V3:'%',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Monocytes')
                {
                   obj = {V1:Key,V2:Value,V3:'%',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Basophils')
                {
                   obj = {V1:Key,V2:Value,V3:'%',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Abs. Neutrophil Count')
                {
                   obj = {V1:Key,V2:Value,V3:'cells/cumm 10^3/uL',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Abs. Lymphocyte Count')
                {
                   obj = {V1:Key,V2:Value,V3:'cells/cumm 10^3/uL',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Abs. Eosinophil Count')
                {
                   obj = {V1:Key,V2:Value,V3:'cells/cumm 10^3/uL',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Abs. Monocyte Count')
                {
                   obj = {V1:Key,V2:Value,V3:'cells/cumm 10^3/uL',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Abs. Basophil Count')
                {
                   obj = {V1:Key,V2:Value,V3:'cells/cumm 10^3/uL',V4:'NORMAL',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Neutrophil - Lymphocyte Ratio(NLR) ')
                {
                   obj = {V1:Key,V2:Value,V3:'',V4:'HIGH',V5:'red',V6:'bold'} 
                   DataCollection.push(obj)
                }
                if(Key == 'RBC')
                {
                   obj = {V1:Key,V2:Value,V3:'',V4:'',V5:'black',V6:'normal'} 
                   DataCollection.push(obj)
                }
                if(Key == 'WBC')
                {
                   obj = {V1:Key,V2:Value,V3:'',V4:'',V5:'black',V6:'bold'} 
                   DataCollection.push(obj)
                }
                if(Key == 'Platelets')
                {
                   obj = {V1:Key,V2:Value,V3:'',V4:'',V5:'black',V6:'bold'} 
                   DataCollection.push(obj)
                }
                if(Docarr.length-1 == k)
                {
                  this.DataItemsList = DataCollection;
                }
          }
        }
      });
    }
  }
  labdetails()
  {
      this.divPersonalDetails = false;
      this.divReport = false;
      this.pclick = false;
      this.divDoc = true;
  }
 back()
 {
  this.divPersonalDetails = true;
  this.divReport = true;
  this.pclick = true;
  this.divDoc = false;
 }
}
