    <!---new-->
    <div style="margin-top: 75px;"></div>
    <section class="setting">    
      <div class="bg-white " style="height: 45px;"> 
    <div class="sec-tab">   
     <ul class="nav" role="tablist" style="margin-bottom: 0px;height: 50px;">
        <li class="nav-item">
          <button id="button" routerLink="/industry" (click)="click1();onChangeRibon()">         
            <img src="../../assets/img/{{iconColor}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Templates</button>
        </li>
        <li class="nav-item">
          <button id="button" routerLink="/OrganisationOverview" (click)="clickonConfiguration();onChangeconfig();" data-toggle="pill" href="#config"  >
             <img src="../../assets/img/{{configicon}}" height="18" width="22"  style="margin-right: 5px;"class="img" > Configurations</button>
        </li>
        <li class="nav-item">
          <button id="button" routerLink="/MasterOverview" (click)="clickMaster();onChangeMaster();" data-toggle="pill" href="#master" >
            <img src="../../assets/img/{{masterIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Masters</button>
        </li>
        <li class="nav-item">
          <button id="button" routerLink="/ApiOverview" (click)="clickApi();onChangeApi();"  data-toggle="pill" href="#api" style="color: #E6800E;">
            <img src="../../assets/img/API icon.png" height="18" width="22"   style="margin-right: 5px;" class="img"> API's</button>
        </li>
        <li class="nav-item">
          <button id="button" routerLink="/BlockchainOverview" (click)="clickBlockChain();onChangeBlockChain();"  data-toggle="pill" href="#blkchain">
            <img src="../../assets/img/{{blockChainIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> Blockchain</button>
        </li>
        <li class="nav-item">
          <button id="button" routerLink="/BillingOverview" (click)="clickBilling();onChangebilling();"  data-toggle="pill" href="#billing">
            <img src="../../assets/img/{{billingIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Billing</button>
        </li>
      </ul>
      <section class="sec-bg" >
        <div style="border-top: 1px solid;background-color: #fff;height: 31px;padding-top:3px ;">
          <div>
           <a routerLink="/Dashboard"  style="font-size: 13px;padding: 10px;padding-left: 20px;color: #000;">Home</a> 
           <span style="margin-left: 1px;margin-right: 1px; font-size: large;">/</span>
           <a routerLink="/ApiOverview"  style="font-size: 13px;padding: 5px;color:#E6800E;">Api</a> 
          </div>
        </div>
      <div class="tab-content">
       
        <div id="api" class="container tab-pane fade active show">
          <div class="d-flex bd-highlight mb-3">
            <div class="p-2 bd-highlight">Api</div>         
          </div>
          <div class="row" style="float:right;margin:15px">
            <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
          </div>
               
        </div>
       
      
      </div>
     
    </section>
    </div>
  </div>
  </section>