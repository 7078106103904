import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IntegralUIGrid } from '@lidorsystems/integralui-web/bin/integralui/components/integralui.grid';
import { IntegralUICommonService } from '@lidorsystems/integralui-web/bin/integralui/services/integralui.common.service';
import { CategoryService } from '../service/category.service';
import { FormService } from '../service/form.service';
import { FormfieldService } from '../service/formfield.service';
import { IndustryService } from '../service/industry.service';
import { TemplateService } from '../service/template.service';
import { UtilitiesService } from '../service/utility.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-stepper-overview',
  templateUrl: './stepper-overview.component.html',
  styleUrls: ['./stepper-overview.component.css'],
})
export class StepperOverviewComponent implements OnInit {
  disableSelect = new FormControl(false);
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  IndustryChange: any;
  IndustryId: any;
  addeditedthirdForm: boolean = false;
  frmdrop: boolean = false;
  addedited: boolean = false;
  CategoryName: any = '';
  IndustryList: any;
  url: any;
  categoryInfo = [];
  DefaultFormData = [];
  APIFormId = [];
  TabValue: string;
  TabForm: Number;
  isUpdate: boolean = false;
  selectedData: any;
  dynamicVal: String;
  dynamicFormId: Number;
  noOfTab: boolean = false;
  FormFieldData = [];
  AllFormFieldData = [];
  selectedTab: boolean = true;
  selectedFormFieldData: any;
  selectedTemplated: Number;
  selectedFormId = [];
  FormatData =[];
  ExampleData =[];
  CondVal: boolean = false;
  isChecked:any=false;
  rowIndex:number;
  rowbgcolor:string;
  HighlightRow : Number; 
  constructor(
    private _formBuilder: FormBuilder,
    private _industry: IndustryService,
    private _utility: UtilitiesService,
    private _form: FormService,
    private _formField: FormfieldService,
    private route: ActivatedRoute,
    private _Template: TemplateService,
    private _Category: CategoryService,
    private commonService?: IntegralUICommonService,
    private toastr?: ToastrService,
    private spinner?: NgxSpinnerService
  ) {
    this.addeditedthirdForm = false;
    this.frmdrop = true;
    this.addedited = false;
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      CategoryName: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      Name: [''],
      Description: [''],
    });
    this.thirdFormGroup = this._formBuilder.group({
      FieldName: [''],
      Description: [''],
      FieldType: [''],
      Form: [''],
      FormId: [''],
      KeyType:[''],
      PrimaryKey:[false],
      MappingKey:[false]
    });
    this.fourthFormGroup = this._formBuilder.group({
      fourthCtrl: ['', Validators.required],
    });
   
    this.IndustryId = this.route.snapshot.paramMap.get('IndustryId');
    console.log(this.IndustryId);
    this.IndustryChange = this.IndustryId;
    this.getIndustryBYId(this.IndustryChange);
    this.getFormId();
    this.getIndustry();
    this.createCategoryForm();
  }

  onIndustryChange(e) {
    console.log(e.value);
    this.getIndustryBYId(e.value);
  }

  onChange($event:Event){
    console.log($event);
    console.log("value changed");

 }
  selectedIndustryForPreview: any = '';
  getIndustryBYId(id) {
    this._industry.getIndustryById(id).subscribe((data) => {
      console.log(data);
      console.log(data[0].Name);
      this.selectedIndustryForPreview = data[0].Name;
    });
  }
  getFormId() {
    this._form.getFormId().subscribe((data) => {
      console.log(data);
    });
  }
  preview()
  {
    for(let i=0;i<this.DefaultFormData.length;i++)
    {
     
      let Counter = 0;
       for(let j=0;j<this.AllFormFieldData.length;j++)
       {
          if(this.DefaultFormData[i].Name == this.AllFormFieldData[j].Form)
          {
           
            this.AllFormFieldData[j].Idd = ++Counter
          }

          if(this.DefaultFormData.length -1 == i)
          {
            if(this.AllFormFieldData.length -1 == j)
            {
              console.log(this.AllFormFieldData)
            }
            
          }
       }
    }
  }
  getIndustry() {
    this._industry.getIndustryDtl().subscribe((data) => {
      console.log(data);
      this.IndustryList = data;
    });
  }

  createCategoryForm() {
    this.firstFormGroup = this._formBuilder.group({
      Id: [''],
      CategoryName: ['', Validators.required],
      IndustryId: [''],
      Description: [''],
      CatIcon: [''],
      CreatedBy: [1],
      UpdatedBy: [1],
    });
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result;
        console.log(this.url);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  CategoryName1:any;
  Description:any;
  getCategoryFormData() {
    console.log(this.firstFormGroup.value);
    this.categoryInfo.push(this.firstFormGroup.value);
    console.log(this.categoryInfo);
    this.CategoryName = this.categoryInfo[0].CategoryName;
    this.CategoryName1=(<HTMLInputElement>document.getElementById('CategoryName')).value 
    this.Description=(<HTMLInputElement>document.getElementById('Description')).value
  }

  onsaveForm() {
    this.HighlightRow = -1; 
    var data = this.secondFormGroup.get('Name').value;

    if (this.DefaultFormData.length == 0) {
      this.TabValue = this.secondFormGroup.get('Name').value;
      
      this.DefaultFormData.push(this.secondFormGroup.value);
      this._form.getFormId().subscribe((data) => {
        if (data.length == 0) {
          this.DefaultFormData[0].FormId = 1;
          this.TabForm = 1;
          this.APIFormId.push(1);
        } else {
          this.DefaultFormData[0].FormId = data[0].FormId + 1;
          this.APIFormId.push(data[0].FormId + 1);
          this.TabForm = data[0].FormId + 1;
        }
      });
    } else {
      var test =
        this.DefaultFormData[this.DefaultFormData.length - 1].FormId + 1;
      var defaultength = this.DefaultFormData.length;
      this.APIFormId.push(test);
      this.DefaultFormData.push(this.secondFormGroup.value);
      this.DefaultFormData[defaultength].FormId = test;
    }
  }
  cancelDefaultForm() {
    this.secondFormGroup.reset();
  }

  OnUpdate() {
    var data = this.selectedData;
    data.Name = this.secondFormGroup.get('Name').value;
    data.Description = this.secondFormGroup.get('Description').value;
    this.isUpdate = false;
    this.secondFormGroup.reset();
    this.addedited = false;
  }
  OnSelectRow(e, data) {
    this.addedited = true;
    this.isUpdate = true;
    this.selectedData = data;
    this.secondFormGroup.get('Name').setValue(data.Name);
    this.secondFormGroup.get('Description').setValue(data.Description);
  }
  OnDelete(e, data) {
    this.DefaultFormData.splice(e, 1);
  }
  onloadTabSelection(data, formId) {
    console.log(data +' ' +formId)
    var centers = this.AllFormFieldData.filter((element) => {
      return element.Form == data;
    });
    this.FormFieldData = centers;
    this.dynamicVal = data;
    this.noOfTab = data;
    this.dynamicFormId = formId;
  }

  onsaveFormField() {
    console.log(this.dynamicVal);
    this.thirdFormGroup.patchValue({
      Form: this.dynamicVal,
    });

    this.thirdFormGroup.patchValue({
      FormId: this.dynamicFormId,
    });
    var Key = this.thirdFormGroup.get('KeyType').value;
    if(Key == "PrimaryKey")
    {
      this.thirdFormGroup.patchValue({
        PrimaryKey: true,
        MappingKey:false
      });
    }
    else if(Key == "MappingKey")
    {
      this.thirdFormGroup.patchValue({
        PrimaryKey: false,
        MappingKey:true
      });
    }
    else{
      this.thirdFormGroup.patchValue({
        PrimaryKey: false,
        MappingKey:false
      });
    }
    console.log(this.thirdFormGroup.value);
    var data = this.thirdFormGroup.get('FieldName').value;
    var data1 = this.thirdFormGroup.get('FieldType').value;
    if (data != '' && data != undefined && data1 != '' && data1 != undefined) {
      this.FormFieldData.push(this.thirdFormGroup.value);
      this.AllFormFieldData.push(this.thirdFormGroup.value);
      console.log(this.FormFieldData);
    } else {
      var x = <HTMLInputElement>document.getElementById('snackbarerror');
      x.innerText = 'Please Provide data to post';
      this._utility.ErrorToast1();
    }
  }
  cancelDefaultFormField() {
    this.thirdFormGroup.reset();
  }
  OnUpdateFormField() {
    var data = this.selectedFormFieldData;
    console.log(data);
    data.FieldName = this.thirdFormGroup.get('FieldName').value;
    data.FieldType = this.thirdFormGroup.get('FieldType').value;
    data.Description = this.thirdFormGroup.get('Description').value;
    this.addeditedthirdForm = false;
    this.thirdFormGroup.reset();
  }
  OnSelectRowFormField(e, data) {
    this.addeditedthirdForm = true;

    this.selectedFormFieldData = data;

    this.thirdFormGroup.get('FieldName').setValue(data.FieldName);
    this.thirdFormGroup.get('FieldType').setValue(data.FieldType);
    this.thirdFormGroup.get('Description').setValue(data.Description);
  }

  OnDeleteformField(e, data) {
    this.FormFieldData.splice(e, 1);
  }

  onSaveCategory() {
    this.spinner.show();
    if (typeof this.url === 'undefined') {
      this.url = '';
    }
    this.firstFormGroup.patchValue({
      CatIcon: this.url,
    });

    if (this.firstFormGroup.valid) {
      console.log(this.firstFormGroup.get('CategoryName').value);
      this._Category
        .getCategorySearch(this.firstFormGroup.get('CategoryName').value)
        .subscribe((data) => {
          if (data.length == 0) {
            this._Category
              .createCategory(this.firstFormGroup.value)
              .subscribe((data) => {
                console.log(data);
                this.createTemplate();
                //this.saveSecondForm();
                //this.saveThirdForm();
                //this.toastr.success('Category Created Sucessfully');
              });
          } else {
          }
        });
    } else {
      const invalid = [];
      const controls = this.firstFormGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
          this.toastr.error('Please Enter ' + invalid[0] + '');
        }
      }
      return invalid;
    }
  }

  createTemplate() {
    var postData = {
      Name: 'Base Template',
      Category:this.firstFormGroup.get('CategoryName').value,
      IsPublish: false,
      CreatedBy: 1,
      UpdatedBy: 1,
      Description:"Base Template"
    };

    this._Template.createTemplate(postData).subscribe((data) => {
      this.selectedTemplated = data['post'].TemplateId;
      console.log( this.selectedTemplated)
      this.saveSecondForm();
    });
  }

  saveSecondForm() {
    this._Template.getTemplateId().subscribe((data) => {
      console.log(this.selectedTemplated )
      console.log(this.DefaultFormData)
      for (var i = 0; i < this.DefaultFormData.length; i++) {
        var res = this.DefaultFormData[i];
        var json_arr = {};
        json_arr['Id'] = null;
        json_arr['Title'] = res.Name;
        json_arr['TemplateId'] = this.selectedTemplated;
        json_arr['Description'] = res.Description;
        json_arr['CreatedBy'] = 1;
        json_arr['UpdatedBy'] = 1;
        json_arr['IsActive'] = true;
        this._form.createForm(json_arr).subscribe((data) => {
          this.selectedFormId.push(data['post'].FormId);
         
        });
        if(this.DefaultFormData.length-1 == i)
        {
          //this.toastr.success( 'Form Data Save Sucessfully');
          this.saveThirdForm();
        }
      }
    });
  }

  saveThirdForm() {
    console.log(this.AllFormFieldData)
    for (var i = 0; i < this.AllFormFieldData.length; i++) {
      var mappingId = false;
      if (i == 0) {
        mappingId = true;
      }
      var res = this.AllFormFieldData[i];
      var json_arr = {};
      json_arr['Id'] = null;
      json_arr['Label'] = res.FieldName;
      json_arr['FieldType'] = res.FieldType;
      json_arr['Description'] = res.Description;
      json_arr['Form'] = res.Form;
      json_arr['FormId'] = res.FormId;
      json_arr['CreatedBy'] = 1;
      json_arr['UpdatedBy'] = 1;
      json_arr['IsActive'] = true;
      json_arr['PrimaryKey'] = res.PrimaryKey;
      json_arr['MappingKey'] = res.MappingKey;
      this._formField.createFormfield(json_arr).subscribe((data) => {
        
      });

      if(this.AllFormFieldData.length-1 == i)
      {
        this.onsteperforth()
        //this.toastr.success('Formfield Data Save Sucessfully');
        this.toastr.success('Template Created Sucessfully');
      }
    }
  }

onsteperforth(){
  
  this._Category.getAPI(this.selectedTemplated,this.APIFormId).subscribe(data =>{
    this.spinner.hide();
    console.log(this.selectedTemplated)
    console.log(this.APIFormId)
    this.FormatData = data["Formats"];
    this.ExampleData = data["Eaxmples"];
    console.log(this.FormatData)
    console.log(this.ExampleData)
  })
}

// ribbon
  //#region robbon
  iconColor= 'Templates icon1.png'
  configicon='setting.png';
  masterIcon='data-icon.svg';
  apiIcon='api.png';
  blockChainIcon='blockchain.png';
  billingIcon='Billing-icon.svg';
 
  click1(){
    this.iconColor='Templates icon.png';
    console.log('color');
  }

  clickonConfiguration(){
    this.configicon='Configuration icon.png';
    console.log('color');
  }
  clickMaster(){
    this.masterIcon='data icon.png';
    console.log('color');
  }
  clickApi(){
    this.apiIcon='API icon.png';
    console.log('color');
  }
  clickBlockChain(){
    this.blockChainIcon='Blockchain icon.png';
    console.log('color');
  }
  clickBilling(){
    this.billingIcon='Billing icon.png';
    console.log('color');
  }


  onChangeRibon(){
    // this.iconColor= 'as.png';
    this.configicon='setting.png';
    this.masterIcon='data-icon.svg';
    this.apiIcon='api.png';
    this.blockChainIcon='blockchain.png';
    this.billingIcon='Billing-icon.svg';
  }

  onChangeconfig(){
      this.iconColor= 'Templates icon1.png';
      // this.configicon='setting.png';
      this.masterIcon='data-icon.svg';
      this.apiIcon='api.png';
      this.blockChainIcon='blockchain.png';
      this.billingIcon='Billing-icon.svg';
  }
  onChangeMaster(){
    this.iconColor= 'Templates icon1.png';
    this.configicon='setting.png';
    // this.masterIcon='data-icon.svg';
    this.apiIcon='api.png';
    this.blockChainIcon='blockchain.png';
    this.billingIcon='Billing-icon.svg';
}
onChangeApi(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  // this.apiIcon='api.png';
  this.blockChainIcon='blockchain.png';
  this.billingIcon='Billing-icon.svg';
}
onChangeBlockChain(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  this.apiIcon='api.png';
  // this.blockChainIcon='blockchain.png';
  this.billingIcon='Billing-icon.svg';
}
onChangebilling(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  this.apiIcon='api.png';
  this.blockChainIcon='blockchain.png';
  // this.billingIcon='Billing-icon.svg';
}
moveUp(index) {
  debugger;
  this.HighlightRow = -1; 
  if (index > 0) {
    const tmp = this.DefaultFormData[index - 1];
    this.DefaultFormData[index - 1] = this.DefaultFormData[index];
    this.DefaultFormData[index] = tmp;
  }
}

moveDown(index) {
  this.HighlightRow = -1; 
  debugger;
      if (index < this.DefaultFormData.length-1) {
        const tmp = this.DefaultFormData[index + 1];
        this.DefaultFormData[index + 1] = this.DefaultFormData[index];
        this.DefaultFormData[index] = tmp;
      }
    }
    moveUp1(index) {
      debugger;
      this.HighlightRow = -1; 
      if (index > 0) {
        const tmp = this.FormFieldData[index - 1];
        this.FormFieldData[index - 1] = this.FormFieldData[index];
        this.FormFieldData[index] = tmp;
      }
    }
    
    moveDown1(index) {
      this.HighlightRow = -1; 
      debugger;
          if (index < this.FormFieldData.length-1) {
            const tmp = this.FormFieldData[index + 1];
            this.FormFieldData[index + 1] = this.FormFieldData[index];
            this.FormFieldData[index] = tmp;
          }
        }
 rowSelect(index)
{
  this.rowIndex = index;
  this.HighlightRow = index;  
}

}
