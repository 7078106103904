import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StandardizedLandingpageService } from '../service/StandardizedLandingpage.service';
import * as moment from 'moment';
import _, { map } from 'underscore';
import { KeyValue } from '@angular/common';
@Component({
  selector: 'app-kapilchits',
  templateUrl: './kapilchits.component.html',
  styleUrls: ['./kapilchits.component.scss']
})
export class KapilchitsComponent implements OnInit {
divPersonalDetails:boolean=true;
divChitDetails:boolean=true;
pclick:boolean=true;
innerEventtable:boolean =false;
eventclick:boolean =false;
innerLedgerTable:boolean=false;
QRCodeVal: string;
ChitDocumentData: any;
PersonalDocumentData: any;
LedgerDocumentData: any;
EventTableData:any;
PersonName:any;
PersonPAN:any;
PersonContactNo:any;
PersonAltContactNo:any;
PersonEmail:any;
PersonAddress:any;
PersonTicket:any;
ChitPSONo:any;
ChitCommdate:any;
ChitGroup:any;
ChitTerminationdate:any;
Chitvalue:any;
ChitMembers:any;
ChitStatus:any;
ChitOngoingMonth:any;
LastInstall:any;
PaidOn:any;
VerifyLink:any;
  constructor( private route: ActivatedRoute,
    private _service: StandardizedLandingpageService)
     { }

  ngOnInit() {
    let URLName = this.route.snapshot.params.Name;
    let qrcode = this.route.snapshot.params.QRCode;
    this.QRCodeVal = qrcode;
    if (this.QRCodeVal != '') {
      this._service.getMobile(qrcode).subscribe((data) => {
        this._service.getOrgById(data['posts'][0].ClientID)
          .subscribe((data1) => {
           
              console.log(data1[0].Icon);

              this._service
                .getBlockChainDataDetails(this.QRCodeVal)
                .subscribe((data) => {
                  console.log(data);
                  let LedgerDocArrData = [];
                  let innerLedgerData =[];
                  var index = data['posts']['childblockchaintriggers'].length;
                  if(index == 0)
                  {
                    this.VerifyLink = data['posts']['blockchainurl'];
                  }
                  else
                  {
                    this.VerifyLink = data['posts']['childblockchaintriggers'][index-1].blockchainurl
                  }
                  for ( let i = 0; i < data['posts']['childblockchaintriggers'].length; i++ )
                  {
                 let   obj ={};
                   let Docarr = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
                   var datearr = data['posts']['childblockchaintriggers'][ i ].blockchaintimestamp.split(' ');
                   if(Docarr[0] == "Form:Personal details")
                   {
                     for (let f = 0; f < Docarr.length; f++) {
                      
                       var index = Docarr[f].indexOf(':');
                       var Key = Docarr[f].substring(0, index);
                       var Value = Docarr[f].substring( index + 1,Docarr[f].length );

                       if(Key == "Name of the member")
                       {
                           this.PersonName = Value;
                          
                       }
                       if(Key == "PAN")
                       {
                           this.PersonPAN = Value;
                          
                       }
                       if(Key == "Contact no")
                       {
                           this.PersonContactNo = Value;
                        
                       }
                       if(Key == "Alternative contact no")
                       {
                           this.PersonAltContactNo = '/ '+Value;
                          
                       }
                       if(Key == "Email")
                       {
                           this.PersonEmail = Value;
                          
                       }
                       if(Key == "Address")
                       {
                        this.PersonAddress = Value;
                       }
                       if(Key == "Ticket no")
                       {
                        this.PersonTicket = Value;
                       }

                   }
                   }
                  if(Docarr[0] == "Form:Ledger details")
                  {
                    var Dataindex = Docarr[1].indexOf(':');
                    var DataValue = Docarr[1].substring(Dataindex + 1, Docarr[1].length);
                  
                    var Transindex = Docarr[5].indexOf(':');
                    var TransValue = Docarr[5].substring(Transindex + 1, Docarr[5].length);
                   
                    var Particularindex = Docarr[4].indexOf(':');
                    var ParticularValue = Docarr[4].substring(Particularindex + 1, Docarr[4].length);
                    
                    var Debitindex = Docarr[6].indexOf(':');
                    var DebitValue = Docarr[6].substring(Debitindex + 1, Docarr[6].length);
                   
                    var Creditindex = Docarr[7].indexOf(':');
                    var CreditValue = Docarr[7].substring(Creditindex + 1, Docarr[7].length);
                   

                    var Transaction = ParticularValue + "/" +TransValue;
                    var amount;
                    var color='';
                    var debit;
                    var credit;
                    var text = '';
                    var text1 = '';
                    console.log(DebitValue)
                    console.log(CreditValue)
                    if(DebitValue == '0' || CreditValue == '0')
                    {
                      debit = '';
                      credit = '';
                    }
                   if(DebitValue != '0'){
                     debit = 'Rs.' +DebitValue;
                      color = 'red';
                      text = 'Dr.'
                    }
                   if(CreditValue != '0'){
                    credit = 'Rs.' +CreditValue;
                     color = 'green';
                     text1 = 'Cr.';
                    }
                    obj ={};
                    obj= {V1:DataValue,V2:Transaction,V3:debit,V4:color,V5:credit,V6:text,V7:text1};
                    LedgerDocArrData.push(obj);
                    innerLedgerData.push(LedgerDocArrData)
                    LedgerDocArrData = [];
                  }
                  if(data['posts']['childblockchaintriggers'].length-1 ==i)
                  {
                    this.LedgerDocumentData = innerLedgerData
                    
                  }
                  
               }
                
               var ParentQRCode = data['posts']['parentqrcode'].uniqueid
               this._service.getBlockChainDataDetails(ParentQRCode).subscribe((Parentdata) => {
               
                for ( let i = 0; i < Parentdata['posts']['childblockchaintriggers'].length; i++ )
                {
                  let Docarr = Parentdata['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
                  if(Docarr[0] == "Form:Chit details")
                {
                  for (let f = 0; f < Docarr.length; f++) {
                   console.log(Docarr[i])
                    var index = Docarr[f].indexOf(':');
                    var Key = Docarr[f].substring(0, index);
                    var Value = Docarr[f].substring( index + 1,Docarr[f].length );
                    if(Key == "PSO No")
                    {
                      this.ChitPSONo = Value
                    }
                    if(Key == "Chit Comm date")
                    {
                      this.ChitCommdate = Value
                    }
                    if(Key == "Group")
                    {
                      this.ChitGroup = Value
                    }
                    if(Key == "Termination date")
                    {
                      this.ChitTerminationdate = Value
                    }
                    if(Key == "Chit value")
                    {
                      this.Chitvalue = Value
                    }
                    if(Key == "No. Of group members")
                    {
                      this.ChitMembers = Value
                    }
                    if(Key == "Chit status")
                    {
                      this.ChitStatus = Value
                    }
                    if(Key == "On going month")
                    {
                      this.ChitOngoingMonth = Value
                    }
                    if(Key == "Last instalment")
                    {
                      this.LastInstall = Value
                    }
                    if(Key == "Paid on")
                    {
                      this.PaidOn = Value
                    }
                }
                }
                }
              
                
               });
                });
            
          });
      });
    }
  }
  EventTable()
  {
    this.divPersonalDetails = false;
    this.divChitDetails =false;
    this.pclick = false;
    this.innerEventtable = true;
    this.eventclick =true;
  }
  back()
  {
    this.divPersonalDetails = true;
    this.divChitDetails =true;
    this.pclick = true;
    this.innerEventtable = false;
    this.eventclick =false;
  }
  innerClicked()
  {
    this.divPersonalDetails = false;
    this.divChitDetails =false;
    this.pclick = false;
    this.innerEventtable = false;
    this.eventclick =false;
    this.innerLedgerTable = true;
  }
  backinner()
  {
    this.divPersonalDetails = true;
    this.divChitDetails =true;
    this.pclick = true;
    this.innerEventtable = false;
    this.eventclick =false;
    this.innerLedgerTable = false;
  }
}
