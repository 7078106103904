import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CsmService {

  constructor(private _http: HttpClient) { }

  getData(Hash)
  {
    return this._http.get('https://cms.trst01.in/api/getTC/'+Hash);
  }

  getDataHistory(Hash)
  {
    return this._http.get('https://cms.trst01.in/api/getTCHistory/'+Hash);
  }

}
