import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolimerasService } from '../service/polimeras.service';
import * as moment from 'moment';
@Component({
  selector: 'app-polimeraas',
  templateUrl: './polimeraas.component.html',
  styleUrls: ['./polimeraas.component.scss']
})
export class PolimeraasComponent implements OnInit {
  divTimeline:boolean=true;
  divFormpicture:boolean=true;
  divHistory:boolean=false;
  DynamicVariety:any;
  DynamicBatchCode:any;
  DynamicFarmer:any;
  VerifyLink:any;
  DynamicFarmPic:any;
  DynamicMap:any;
  DynamicHarvestedDate:any;
  DynamicShippedDate:any;
  DynamicReachedStoreon:any;
  DynamicRipingDate:any;
  imgarr =[];
  ModalOrigin:boolean =false;
  MdalRipingCenter:boolean=false;
  ModalPolimeraas:boolean=false;

  // google maps zoom level
  zoom: number = 15;
  
  // initial center position for the map
  lat: number = 51.673858;
  lng: number = 7.815982;
  constructor( private _service: PolimerasService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    let qrcode = this.route.snapshot.params.QRCode;
    if (qrcode != '') {
      this._service.getBlockChainDataDetails(qrcode).subscribe((data) => {
        console.log(data);
        var index = data['posts']['childblockchaintriggers'].length;
                  if(index == 0)
                  {
                    this.VerifyLink = data['posts']['blockchainurl'];
                  }
                  else
                  {
                    this.VerifyLink = data['posts']['childblockchaintriggers'][index-1].blockchainurl
                  }
        let Docarr = data['posts']['childblockchaintriggers'][index-1].blockchaindata.split('||');
        for(let k=0;k<Docarr.length;k++)
        {
          var index = Docarr[k].indexOf(":");
          var Key = Docarr[k].substring(0,index);
          var Value = Docarr[k].substring(index+1,Docarr[k].length);
          if(Key == 'Variety')
          {
            this.DynamicVariety = Value
            console.log(this.DynamicVariety)
          }
          else if(Key == 'Harvested Date')
          {
            this.DynamicHarvestedDate = Value
            var date =new Date(Value)
            this.DynamicRipingDate = moment(date).add(1,'days').format('DD-MMM-YYYY')
          }
          else if(Key == 'Shipped Date')
          {
            this.DynamicShippedDate = Value
          }
          else if(Key == 'Batch Code')
          {
            this.DynamicBatchCode = Value
          } 
          else if(Key == 'Reached Store on')
          {
            this.DynamicReachedStoreon = Value
            
          }
          else if(Key == 'Farmer/FPO')
          {
            this.DynamicFarmer = Value
          
          } 
        
          else if(Key == 'Farm Picture')
          {
            this.DynamicFarmPic = Value;
            this.imgarr.push(Value)
          }
          else if(Key == "Farm location")
          {
              //this.DynamicMap = Value;
              this.imgarr.push(Value)
              this.DynamicMap = this.imgarr;
              console.log(this.DynamicMap)
          }
        }
      });
    }
  }
  History()
  {
    this.divTimeline = false;
    this.divFormpicture = false;
    this.divHistory =true;
  }
  back()
 {
  this.divTimeline = true;
  this.divFormpicture = true;
  this.divHistory =false;
 }
 OriginPopup()
 {
   this.ModalOrigin = true;
 }
 RipeningPopup()
 {
   this.MdalRipingCenter = true;
 }
 PolimeraasPopup()
 {
   this.ModalPolimeraas =true;
 }
}
