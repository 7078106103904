import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AutocompleteService } from '../service/autocomplete.service';
import { BranchService } from '../service/branch.service';
import { OrganizationService } from '../service/organization.service';
import { UtilitiesService } from '../service/utility.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {
  Role :string;
  Organisationaccess : boolean;
  BranchForm: FormGroup;
  addedited:boolean =false;
  isUpdate:boolean=false;
  isSave:boolean=true;
  title = 'branch';
  mySubscription;
  constructor( private _formbuilder: FormBuilder,private _router: Router,private _autocompleteService:AutocompleteService,
    private _utility:UtilitiesService, private _Branch: BranchService,private _Org:OrganizationService,private location: Location) {
      this.createForm();

      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.mySubscription = this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
    // Trick the Router into believing it's last link wasn't previously loaded
        this._router.navigated = false;
        }
      });
     }

  ngOnInit() {
    this.getautoComplete();
    this.getBranchDtl();
    this.getOrgList();
    console.log(localStorage.getItem('Role'))
    if(this.Role == 'admin')
    {
          this.Organisationaccess= false;
    }
  }

  goBack():void{
    this.location.back();
    }

  ngOnDestroy(){
    console.log('app ngOnDestroy')
    if (this.mySubscription) {
     this.mySubscription.unsubscribe();
    }
  }
  createForm() {
    this.BranchForm = this._formbuilder.group({
      Id: [''],
      OrganisationId : [''],
      Name: ['',Validators.required],
      Code: ['',Validators.required],
      Organisation: [''],
      name: [{value: '', disabled: false}, Validators.required],
      ContactPerson: ['',Validators.required],
      ContactNumber: ['',[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
      Email: ['',Validators.email],
     // IsActive: true,
      CreatedBy: [1],
      UpdatedBy: [1],
      // CreatedDate:[new Date()],
      // UpdatedDate:[new Date()],
    

    });
  }

  get Name() { return this.BranchForm.get('Name'); }
  get Code() { return this.BranchForm.get('Code'); }
  // get Name() { return this.BranchForm.get('Name'); }
  // get Name() { return this.BranchForm.get('Name'); }
  BranchList:any='';
  getBranchDtl(){
    this._Branch.getBranchDetails().subscribe(data=>{
      this.BranchList=data;
      console.log(this.BranchList);
    
    })
  }

  editBranchForm:any;
  selectedOgd:any='';
  getBranchbyid(id:any,data){
    this.selectedOgd= data.Organisation;
    console.log(data);//Organisation
    this.isUpdate=true;
    this.isSave=false;
    this.addedited =true;
    this._Branch.getBranchById(id).subscribe(data=>{
    this.editBranchForm = data[0];
    console.log(data[0]);
   data[0].name='';
   
      this.BranchForm.setValue(data[0]);
      this.BranchForm.patchValue({
        name:
        {value:data[0].OrganisationId, name:data[0].Organisation }
        });
        
     // this.BranchForm.get('Organisation').setValue(data[0].Organisation);
    })


  }

OrgList:any=0;
  getOrgList(){
    this._Org.getOrganizationDetails().subscribe(data => {
      this.OrgList=data;
      console.log(this.OrgList);
    })
  }
  orgName:any='';
  onSave(){
    console.log(this.BranchForm.value);
    console.log(this.BranchForm.get('name').value);
    var autocomplitiondata=this.BranchForm.get('name').value;
    console.log(autocomplitiondata.id)
    console.log(autocomplitiondata.name)
    this.BranchForm.patchValue({
      Organisation: autocomplitiondata.name,
 });
 this.BranchForm.patchValue({
  OrganisationId: autocomplitiondata.id,
});

if (this.BranchForm.valid) {


  this._Branch.createBranch(this.BranchForm.value)
  .subscribe(data=>{
  console.log(data);
  this.addedited=false;
  var x = (<HTMLInputElement>document.getElementById('snackbar'));
  x.innerText = 'Branch Created Sucessfully';
  this._utility.SuccessToast();
  
  setTimeout(() => {
  this.refresh();
  }, 2000);
  
  })
  
  }
  else {
  const invalid = [];
  const controls = this.BranchForm.controls;
  for (const name in controls) {
  if (controls[name].invalid) {
  invalid.push(name);
  var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
  x.innerText = 'Please Enter ' + invalid[0] + '';
  this._utility.ErrorToast1();
  }
  }
  return invalid;
  }
 
//     this._Org.getOrgById(this.BranchForm.get('Organisation').value).subscribe( data =>{
//       console.log(data[0].Name);
//       this.orgName=data[0].Name;
//       this.BranchForm.patchValue({
//         Organisation: data[0].Name,
//    });
//    this.BranchForm.patchValue({
//     OrganisationId: data[0].Id,
// });

// //console.log(this.BranchForm.value)
 
//       this._Branch.createBranch(this.BranchForm.value)
//       .subscribe(data=>{
//         console.log(data);
//         this.addedited=false;
//         var x = (<HTMLInputElement>document.getElementById('snackbar'));
//      x.innerText = 'Branch Created Sucessfully';
//      this._utility.SuccessToast();
  
//      setTimeout(() => {
//        this.refresh();
//      }, 2000);
      
//     })
//   }, (err) => {
//     var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
//     x.innerText = 'An Error Occured' + err.error.toString();
//     this._utility.ErrorToast1();
//   })
// }
// else {
// const invalid = [];
// const controls = this.BranchForm.controls;
// for (const name in controls) {
//   if (controls[name].invalid) {
//     invalid.push(name);
//     var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
//     x.innerText = 'Please Enter ' + invalid[0] + '';
//     this._utility.ErrorToast1();
//   }
// }
// return invalid;
// }

 
  
  }

  onUpdate(e){
    console.log(e);
    if (this.BranchForm.valid) {
   this._Branch.UpdateBranch(e).subscribe(data=>{
  console.log(data);
  var x = (<HTMLInputElement>document.getElementById('snackbar'));
    x.innerText = 'Branch Updated Sucessfully';
    this._utility.SuccessToast();
    //this._router.navigate(["/branch"]);
    setTimeout(() => {
      this.refresh();
    }, 2000);
    //this.refresh();
  
   
   this.isUpdate=false;
   this.isSave=true;
   this.addedited=false;
  //his.BranchForm.reset
  }, (err) => {
    var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
    x.innerText = 'An Error Occured' + err.error.toString();
    this._utility.ErrorToast1();
  })
  }
  else {
  const invalid = [];
  const controls = this.BranchForm.controls;
  for (const name in controls) {
  if (controls[name].invalid) {
    invalid.push(name);
    var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
    x.innerText = 'Please Enter ' + invalid[0] + '';
    this._utility.ErrorToast1();
  }
  }
  return invalid;
  }

  }


  onClearAll(){
    this.BranchForm.reset();
  }

  refresh() {
//debugger;
this.BranchForm.reset();
 //  window.location.reload();
   this._router.navigateByUrl('/branch//miscellaneous/not-found', { skipLocationChange: true }).then(() =>
   this._router.navigate(["/branch"]));

      console.log('refresh');
  }

  validateNumber(event: any) {
    this._utility.validateNumber(event);
  }


  // auto complite data list
  countriesReactive:[];

public placeholder: string = 'Enter the Country Name';
public keyword = 'name';
public historyHeading: string = 'Recently selected';


getautoComplete()
{
this._autocompleteService.getOrganizationDetails().subscribe(data =>{
console.log(data)
this.countriesReactive = data;
})
}
}
