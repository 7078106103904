<app-ribbon (click)="addedited=true"></app-ribbon>
<div style="width: 100%;">
  <section class="indst sec-tab">
    <div class="tab-content" style="width: 100%;">
      <div class="card">
        <!-- <div class="card-header">
  
                  <div class="row">
                      <div class="col-sm-9">
                          <h5 style="margin-top: 7px;margin-bottom: -0px;">Template Master</h5>
                      </div>
                      <div class="col-sm-3">
                          <button class="btn pull-right addnew"
                              (click)="refresh();onClearAll();addedited=false;this.isSave=false;" *ngIf="addedited"
                              title="Back to Template">
                              <i style="color: #E6800E;" class="fa fa-times"></i>
                          </button>
                          <button class="btn pull-right addnew"
                              style="color: #E6800E ;font-size: 16px;font-family: open sans-serif;" *ngIf="!addedited"
                              title="Create  Template" (click)="addedited=true;">
                              <i style="color: #E6800E;" class="fa fa-plus"></i> Create New
                          </button>
                      </div>
                  </div>
              </div> -->
        <div style="min-height:40px;background-color: white;">
          <ul class="nav" role="tablist" style="margin-bottom: 5px;">
            <li (click)="isRibbon=true;addedited=true" class="nav-item">
              <button routerLink="/industry" id="buttonR" (click)="click1();onChangeRibon()" data-toggle="pill" href="#template">         
                <img src="../../assets/img/{{iconColor}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Template</button>
            </li>
            <li (click)="isRibbon=true;addedited=true" class="nav-item">
              <button id="buttonR" (click)="clickonConfiguration();onChangeconfig();" data-toggle="pill" href="#config" >
                 <img src="../../assets/img/{{configicon}}" height="18" width="22"  style="margin-right: 5px;"class="img" > Configuration</button>
            </li>
            <li (click)="isRibbon=true;addedited=true" class="nav-item">
              <button id="buttonR" (click)="clickMaster();onChangeMaster();" data-toggle="pill" href="#master">
                <img src="../../assets/img/{{masterIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Master</button>
            </li>
            <li (click)="isRibbon=true;addedited=true" class="nav-item">
              <button id="buttonR" (click)="clickApi();onChangeApi();"  data-toggle="pill" href="#api">
                <img src="../../assets/img/{{apiIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> API</button>
            </li>
            <li (click)="isRibbon=true;addedited=true" class="nav-item">
              <button id="buttonR" (click)="clickBlockChain();onChangeBlockChain();"  data-toggle="pill" href="#blkchain">
                <img src="../../assets/img/{{blockChainIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> Blockchain</button>
            </li>
            <li (click)="isRibbon=true;addedited=true" class="nav-item">
              <button id="buttonR" (click)="clickBilling();onChangebilling();"  data-toggle="pill" href="#billing">
                <img src="../../assets/img/{{billingIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Billing</button>
            </li>
          </ul>
        </div>
        <div class="card-body" style="background-color: whitesmoke;" *ngIf="addedited">
          <div class="row" style="margin-top: -17px;margin-left: -17px; margin-bottom: 10px;background-color: white">
            <div class="card-body">
              <!-- <a routerLink="/login">Home</a> <span
                style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span> -->
              <a routerLink="/industry">Industry</a> <span
                style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span>
              <a routerLink="/category/id">Category</a><span
                style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span>
              <a routerLink="/template/a">Template</a>
            </div>
          </div>
          <!-- <label>Search :</label>&nbsp;&nbsp;
                  <input type="text"  (keyup)="onKeyUp($event)">  -->
          <!-- <div class="row"> -->
          <input type="text" (keyup)="onKeyUp($event)" name="search" placeholder="Search..">
          <button type="save" style="margin-left: 20px; height: 30px;" class="btn btn-save">See More</button>
          <!-- </div> -->

          <div class="row" style="margin-left: -10px;margin-bottom: 50px;">

            <div *ngFor="let data of IndustryList">
              <div *ngIf="selectedString==''">
                <!--|| selectedString== data.Name-->
                <div class="card card1 text-center">
                  <a routerLink="/template/{{data.Id}}">
                    <div class="card-body" style="margin-left: -5px;margin-top: -5px;">
                      <button class="btn" *ngIf="!addedited" title="Industry"
                        (click)="addedited=true;getIndustrybyid(data.Id);getCategoryByIndustryId(data.Id);">
                        <img src="{{data.Icon}}" hight="80px" width="80px">
                        {{data.Name}}
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div *ngFor="let data of filterdata">
              <div *ngIf="selectedString!=''">
                <div class="card card1 text-center">
                  <div class="card-body" style="margin-left: -5px;margin-top: -5px;">
                    <div class="btn" *ngIf="!addedited" title="Industry"
                      (click)="addedited=true;getIndustrybyid(data.Id);">
                      <img src="{{data.Icon}}" hight="80px" width="80px">
                      <p>{{data.Name}}</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="card card1 text-center">
              <div class="card-body">
                <div class="btn" style="color: #E6800E ;font-size: 16px;font-family: open sans-serif; margin-top: 12%;"
                  *ngIf="!addedited" title="Create  Template" data-toggle="modal" data-target="#exampleModalCenter">
                  <!--(click)="addedited=true;"-->
                  <img src="../../assets/img/Plus.png" hight="50" width="50">
                </div>
              </div>

            </div>
          </div>

        </div>

        <!-- Main in Template -->
        <div class="card-body" style="height: 100%;background-color: white;margin-bottom: -10px;" *ngIf="!addedited">
          <div class="row" style="margin-top: -17px;margin-left: -17px; margin-bottom: 1px;background-color: white">
            <div class="card-body">
              <!-- <a routerLink="/industry">Industry ({{Industry}})</a> <span
                style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span>
              <a routerLink="/category/{{IndId}}/{{CategoryName}}">Category ({{CategoryName}})</a><span
                style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span>
              <a routerLink="/template/{{CategoryName}}/{{IndId}}/{{Industry}}">Template </a> -->
              <a routerLink="/industry">{{Industry}}</a> <span
                style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span>
              <a routerLink="/category/{{IndId}}/{{CategoryName}}">{{CategoryName}}</a><span
                style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span>
              <a routerLink="/template/{{CategoryName}}/{{IndId}}/{{Industry}}">Template </a>
            </div>
          </div>
          <!-- <input type="text" (keyup)="onKeyUp($event)" name="search" placeholder="Search.."> -->
          <!-- <button type="save" style="margin-left: 20px; height: 30px;" class="btn btn-save">See More</button> -->
          <!-- <div class="pull-right addnew" (click)="refresh();onClearAll();addedited=false;this.isSave=false;"
            *ngIf="addedited" title="Back to Industry">
            <i style="color: #E6800E; font-size: x-large;" class="fa fa-home"></i>

          </div> -->
          <!-- <div class="pull-right addnew" style="margin-right: 10px;" (click)="addeditedCategory=false;"
            *ngIf="!addeditedCategory" title="Add to Category">
            <img src="../../assets/img/Plus.png" hight="15" width="15">

          </div> -->
          <!-- <div class="row">
            <div class="com-md-12"> -->
          <!-- <div class="row" style="margin-bottom:10px ;margin-top: 0px;">
         
              <nav *ngFor="let data of LoanCAtegoryList" style="margin-left: 15px;">
            
                      <button id="button" class="btn " (click)="getTemplateByCategory(data.CategoryName)" title="Industry Category">
                          <img src="{{data.Icon}}" hight="80px" width="80px">
                          {{data.CategoryName}}
                      </button>
                
              </nav>
              
              </div> -->
          <!-- </div>
          </div> -->
          <div class="row" style="background-color: white;height: 500px;">
            <!-- <div class="col-md-12"> -->
            <div class="col-md-3" style="margin-left: -17px;">
              <div class="card">
                <!-- <div class="card-header">
                  Featured
                </div> -->
                <div>
                <ul class="list-group list-group-flush" *ngFor="let data of TemplateByCatList">
                  <button id="button" class="list-group-item btn active" aria-current="true" (click)="GetTemplateDataByName(data.TemplateId,data.Name);isDefaultTemplate=true;"
                    data-toggle="modal" data-target="#myLargeModalLabel" style="font-size: 14px;font-weight: 600px;">{{data.Name}}
                  </button>
                </ul>
                </div>
              </div>

            </div>
            <div class="com-md-9 col-md-9">
              <!-- <div class="row" style="margin-bottom:15px ;margin-top: -10px;margin-left: 10px;"> -->
              <!-- <div> -->
              <div class="card" *ngIf="isDefaultTemplate" style="width: 104%;height: 100%;">
                <mat-horizontal-stepper linear #stepper style="border: none;">
                  <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
                    <form [formGroup]="firstFormGroup">
                      <ng-template matStepLabel>Template</ng-template>
                      <div class="row">
                        <div class="col-md-8">
                          <mat-form-field appearance="outline">
                            <mat-label>Template Name</mat-label>
                            <input matInput formControlName="TemplateName" id="tName" placeholder="Last name, First name" required>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <input matInput formControlName="Description" id="Description" placeholder="Description">
                          </mat-form-field>
                        </div>
                      </div>


                      <div>
                        <!-- <button class="btn-save rounded mt-2 ml-2" (click)="SaveTemplate();" mat-button matStepperNext>Save</button> -->
                        <button class="btn-save rounded mt-2 ml-2" mat-button data-toggle="modal" data-target="#exampleModalCopy" *ngIf="isCopy">Save as</button>
                        <button class="btn-save rounded mt-2 ml-2" mat-button matStepperNext>Next</button>
                      </div>
                    </form>
                  </mat-step>
                  <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
                    <form [formGroup]="form1">
                      <ng-template matStepLabel>Form</ng-template>
                      <div class="row">
                        <div class="col-md-5">
                          <mat-form-field appearance="outline">
                            <mat-label>Form Title</mat-label>
                            <input matInput formControlName="Title" placeholder="Form Title" required>
                          </mat-form-field>
                        </div>
                        <div class="col-md-5">
                          <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <input matInput formControlName="Description" placeholder="Description">
                          </mat-form-field>
                        </div>
                        <div class="col-md-2" style="margin-top: 15px;">
                          <button class="btn btn-light rounded justify-content-end  mt-2 mr-3 pull-right"
                            (click)="onsaveForm();cancelForm();" *ngIf="isDefault" mat-button>
                            <i class="fa fa-plus" style="color: #e6800e;" aria-hidden="true"></i></button>
                          <button class="btn btn-primary btn-save rounded mt-2 ml-2" *ngIf="addeditForm"
                            (click)="OnUpdateForm()" mat-button>Update</button>
                        </div>
                      </div>
                      <div class="row ">
                        <div id="table-wrapper">
                          <div id="table-scroll">
                            <table id="customers">
                              <thead class="thead">
                                <tr>
                                  <th>Sl. No</th>
                                  <th>Form Title</th>
                                  <th>Description</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody class="scrollist">
                                <tr *ngFor="let data of FormDataList;let i=index;">

                                  <td>{{i+1}}</td>
                                  <td>{{data.Title}}</td>
                                  <td>{{data.Description}}</td>
                                  <td><button class="btn btn-primary"
                                      (click)="cancelForm();OnSelectFormRow($event,data)" *ngIf="isDefault">
                                      <i class="fa fa-pencil"></i>
                                    </button>
                                    <button class="btn btn-danger" (click)="OnDeleteform(i,data)" *ngIf="isDefault">
                                      <i class="fa fa-remove"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>

                            </table>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
                        <button class="btn-save rounded mt-2 ml-2" (click)="steperSecondStep();onloadTabSelection(TabValue,TabForm);" mat-button
                         matStepperNext>Next</button>
                        <!-- <button class="btn-save rounded mt-2 ml-2" (click)="steperSecondStep();getFormFeildData1(TabValue1,TabForm1);onloadTabSelection(TabValue,TabForm);" mat-button
                          matStepperNext>Next</button> -->
                      </div>
                    </form>
                  </mat-step>
                  <mat-step [stepControl]="thirdFormGroup" [editable]="isEditable">
                    <form [formGroup]="Formfield">
                      <ng-template matStepLabel>Form Field</ng-template>
                      <tabset class="tab" style="max-height: 250px;margin-top: -30px;">
                        <div *ngFor="let data of FormDataList; let i=index">
                          <tab heading="{{data.Title}}" (selectTab)="onloadTabSelection(data.Title,data.FormId);selectedTab=true;" #tabName
                            class="tabs__item" style="max-height: 12px;">
                            <div style="margin-top: -0px;" *ngIf="data.Title==noOfTab">
                            <div class="row" style="margin-top: -5px;">
                              <div class="col-md-3">
                                <mat-form-field appearance="outline">
                                  <mat-label>Field Name</mat-label>
                                  <input matInput formControlName="Label" placeholder="Form Field Label" required>
                                </mat-form-field>
                              </div>
                              <div class="col-md-3">
                                <mat-form-field appearance="outline">
                                  <mat-label>Field Type</mat-label>
                                  <mat-select matInput id="FieldType" formControlName="FieldType" required>
                                    <mat-option value="Text">Text</mat-option>
                                    <mat-option value="Integer">Integer</mat-option>
                                    <mat-option value="Decimal">Decimal</mat-option>
                                    <mat-option value="Date">Date</mat-option>
                                    <mat-option value="DateTime">DateTime</mat-option>
                                  </mat-select>
                                  <!-- <input matInput formControlName="FieldType" placeholder="Field Type" required> -->
                                </mat-form-field>
                              </div>
                              <div class="col-md-4">
                                <mat-form-field appearance="outline">
                                  <mat-label>Description</mat-label>
                                  <input matInput formControlName="Description" placeholder="Description">
                                </mat-form-field>
                              </div>
                              <div class="col-md-2" style="margin-top: 15px;">
                                <button class="btn btn-light rounded justify-content-end  mt-2 mr-3 pull-right"
                                  (click)="onsaveFormField();cancelFormfield();" *ngIf="isDefault" mat-button>
                                  <i class="fa fa-plus" style="color: #e6800e;" aria-hidden="true"></i></button>
                                <button class="btn btn-primary btn-save rounded mt-2 ml-2" *ngIf="addeditFormfield"
                                  (click)="OnUpdateFormfield()" mat-button>Update</button>
                              </div>
                            </div>
                            <div class="row ">
                              <div id="table-wrapper">
                                <div id="table-scroll">
                                  <table id="customers" *ngIf="FormFieldDataList.length !=0">
                                    <thead class="thead">
                                      <tr>
                                        <th>Sl. No</th>
                                        <th>Form Field Label</th>
                                        <th>Field Type</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody class="scrollist" style="color:rgb(53, 51, 51)">
                                      <tr *ngFor="let data of FormFieldDataList;let i=index;">
      
                                        <td>{{i+1}}</td>
                                        <td>{{data.Label}}</td>
                                        <td>{{data.FieldType}}</td>
                                        <td>{{data.Description}}</td>
                                        <td><button class="btn btn-primary"
                                            (click)="cancelForm();OnSelectFormfieldRow($event,data)" *ngIf="isDefault">
                                            <i class="fa fa-pencil"></i>
                                          </button>
                                          <button class="btn btn-danger" (click)="OnDeleteformfield(i,data)" *ngIf="isDefault">
                                            <i class="fa fa-remove"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
      
                                  </table>
                                </div>
                              </div>
                            </div>
                            </div>
                            </tab>
                            </div>
                            </tabset>


                     
                     
                      <div>
                        <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
                        <!-- <button class="btn-save rounded mt-2 ml-2" (click)="saveFormfield();steperthirdStep();"
                          mat-button matStepperNext>Save</button> -->
                        <button class="btn-save rounded mt-2 ml-2" (click)="steperthirdStep();getTemplateData();" mat-button
                          matStepperNext>Next</button>
                      </div>
                    </form>
                  </mat-step>
                  <mat-step [stepControl]="forthFormGroup" [editable]="isEditable">
                    <div id="table-wrapperPreview">
                      <div id="table-scrollPreview">  
                    <form [formGroup]="forthFormGroup">
                      <ng-template matStepLabel>Preview</ng-template>
                      <div class="container  Preview">  
                          
                        <div class="pre-box">
                          <div class="ctgr-1 sec-br pb-4"> 
                            <h3>Template</h3>
                            <div class="row">
                              <!-- <div class="col-sm-12 col-lg-6"> -->
                              <!-- <div class="row"> -->
                              <div class="col-sm-3" style="text-align: right;">
                              Template Name :
                              </div>
                              <div class="col-sm-3 text-left" style="text-align: left;">
                              {{first}}
                              </div>
                              <!-- </div> -->
                              <!-- </div> -->
                              <!-- <div class="col-sm-12 col-lg-6"> -->
                              <!-- <div class="row"> -->
                              <div class="col-sm-3" style="text-align: right;">
                              Description :
                              </div>
                              <div class="col-sm-3 text-left" style="text-align: left;">
                              {{second}}
                              </div>
                              <!-- </div> -->
                              <!-- </div> -->
                              </div>
                           <!--rowend-->
                          
                          <!-- </div>             -->
                        </div> <!--ctgry-end--->   
                        <!--form--start-->
                        <div class="forminfo sec-br">
                          <h3>Form Information</h3>            
                            <div class="row  ml-5 mr-5 table-wrapper-scroll-y my-custom-scrollbar">
                                <div id="table-wrapper">
                                  <div id="table-scroll">
                                    <table id="customers">
                                      <thead class="thead">
                                        <tr>
                                          <!-- <th></th> -->
                                          <th>Form Name</th>
                                          <th>Description</th>
                                          <th>Sl. No</th>
                                        </tr>
                                      </thead>
                                      <tbody class="scrollist">
                                        <tr *ngFor="let data of FormDataList;let i=index;">
                                          <td>{{data.Title}}</td>
                                          <td>{{data.Description}}</td>
                                          <td>{{i+1}}</td>
                                        </tr>
                                      </tbody>
                          
                                    </table>
                                  </div>
                                </div>
                            </div>            
                        </div>  
                        <!--end--> 
                        <!--3rd-form-field---> 
                        <div *ngFor="let dd of FormDataList">
                          <div class="card">
                          <div class="card-header">
                          {{dd.Title}}
                          </div>
                          <div class="card-body">
                          <table id="customers">
                          <thead class="thead">
                          <tr>
                          <th>Form</th>
                          <th>Field Name</th>
                          <th>FieldType</th>
                          <th>Description</th>
                          <th>Sl. No</th>
                          </tr>
                          </thead>
                          <tbody *ngFor="let data of AllFormFieldData;let i=index;">
                          <tr *ngIf="data.Form==dd.Title" style="margin-bottom: 5px;">
                          <td>{{data.Form}}</td>
                          <td>{{data.Label}}</td>
                          <td>{{data.FieldType}}</td>
                          <td>{{data.Description}}</td>
                          <td>{{i+1}}</td>
                          </tr>
                          
                          </tbody>
                          
                          </table>
                          </div>
                          </div>
                          
                          </div>
                        <!-- <div class="formfield sec-br">
                          <h3>Form Field Information</h3>
                          <div class="row ml-5 mb-3 mr-5 table-wrapper-scroll-y my-custom-scrollbar">
                          <table id="customers">
                            <thead class="thead">
                              <tr> 
                                <th>Sr.No</th>   
                                <th>Form Name</th>                                                                            
                                <th>Field Name</th>
                                <th>Field Type</th>
                                <th>Description</th>                 
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let data of AllFormFieldData;let i=index;">
                                <td>{{i+1}}</td>
                                <td>{{data.Form}}</td>
                                <td>{{data.Label}}</td>
                                <td>{{data.FieldType}}</td>
                                <td>{{data.Description}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        </div> -->
                      </div>
                      <!-- </div>
                      </div> -->
                      </div> 
                      
                      <div style="margin-bottom: 25px;">
                        <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
                        <!-- <button *ngIf="isDefault" class="btn-save rounded mt-2 ml-2" (click)="SaveTemplate();saveForm();saveFormfield();"
                        mat-button  >Update</button> -->
                        <button *ngIf="!isPublish" class="btn-save rounded mt-2 ml-2" (click)="Update()"
                          mat-button>Update</button>
                        <button class="btn-save rounded mt-2 ml-2" (click)="steperfourStep();" mat-button
                          matStepperNext>Next</button>
                      </div>
                    </form>
                    </div>
                    </div>
                  </mat-step>
                  <mat-step>
                    <ng-template matStepLabel>API</ng-template>
                    <!-- <p>You are now done.</p> -->
                    <div class="container-fluit">
                      <div class="card">
                        <div class="card-header">API Format</div>
                        <div class="card-body" style="height: 100px;overflow-y: scroll;">
                          <div *ngFor="let data of FormatData;">
                            <ul [innerHTML]="data | prettyprint"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">API Example</div>
                        <div class="card-body" style="height: 100px;overflow-y: scroll;">
                          <div *ngFor="let data of ExampleData;let i=index">
                            <ul [innerHTML]="data | prettyprint"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
                      <button class="btn-save rounded mt-2 ml-2 " data-toggle="modal" data-target="#exampleModal" *ngIf="!isPublish" mat-button>
                        Publish
                      </button>
                      <button routerLink="/template/{{CategoryName}}/{{IndId}}/{{CategoryName}}" class="btn-save rounded mt-2 ml-2" mat-button matStepperNext>Done</button>
                    </div> 
                  </mat-step>
                </mat-horizontal-stepper>
              </div>
              <!-- </div> -->

              <!-- <nav *ngFor="let data of TemplateByCatList" style="margin-left: 19px;margin-right: -10px;">
                <div *ngIf="selectedString==''">
                <div class="card card1 text-center">
                  <div class="card-body" style="margin-left: -5px;margin-top: -5px;">
                    <div class="btn" (click)="onTemplateClick(data.TemplateName)" title="Industry Template">
                 
                      <img src="../../assets/img/templates.png" hight="80px" width="80px">
                      <p #Name>{{data.TemplateName}}</p>
                    </div>
                  </div>

                </div>
              </div>
              </nav>
         
              <nav *ngFor="let data of filterdata" style="margin-left: -25px;margin-right: 15px;">
                <div *ngIf="selectedString!=''">
                <div class="card card1 text-center">
             
                    <div class="card-body" style="margin-left: 0px;margin-top: -5px;">
                        <div class="btn" *ngIf="!addedited" title="Template" (click)="addedited=true;getIndustrybyid(data.Id);">
                         
                            <img src="../../assets/img/templates.png" hight="80px" width="80px">
                            <p>{{data.TemplateName}}</p>
                        </div>
                    </div>
                
                </div>
                </div>
              </nav> -->
              <!-- <nav style="margin-left: 12px;">
              <div class="card card1 tpxext-center">
                <div class="card-body">
                  <div class="btn" style="color: #E6800E ;font-size: 16px;font-family: open sans-serif; margin-top: 12%;"
                    *ngIf="!addedited" title="Create  Template">
                
                    <img style="margin-left: 40px;margin-top: -5px;"  src="../../assets/img/Plus.png" hight="50" width="50">
                  </div>
                </div>
  
              </div>
            </nav> -->

              <!-- </div> -->
            </div>
            <!-- </div> -->
          </div>
        </div>
        <div *ngIf="isRibbon">
          <section class="sec-bg">
            <div class="tab-content">
              <div id="template" class="container tab-pane active"><br>
                <!-- <div class="d-flex bd-highlight mb-3">
                  <div class=" bd-highlight ">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/industry">
                        <img  src="../../assets/img/indsry-icon.png" alt="">
                        <i class="fa fa-industry sec-icon" aria-hidden="true"></i>
                        <div class="card-body">
                          <p class="card-text">Industry</p>
                        </div>
                      </button>             
                    </div>
                    </div>         
                </div> -->
               
              </div>
              <div id="config" class="container tab-pane fade"><br>
                
                <div class="d-flex bd-highlight mb-3">
                  <div class="bd-highlight">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/organisation">
                        <img src="../../assets/img/org-icon.png" alt="">
                        <!-- <i class="fas fa-sitemap sec-icon"></i> -->
                      <div class="card-body">
                        <p class="card-text">Organization</p>
                      </div>
                      </button>              
                    </div>           
                  </div>
                  <div class="bd-highlight">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/branch">
                        <img src="../../assets/img/branch-icon.png" alt="">
                        <!-- <i class="fas fa-code-branch sec-icon"></i> -->
                      <div class="card-body">
                        <p class="card-text">Branch</p>
                      </div>
                      </button>              
                    </div>           
                  </div>
                  <div class="bd-highlight">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/role">
                        <img src="../../assets/img/role-icon.png" alt="">
                        <!-- <i class="fab fa-critical-role sec-icon"></i> -->
                        <div class="card-body">
                          <p class="card-text">Role</p>
                        </div>
                      </button>             
                    </div>           
                  </div>
                  <div class="bd-highlight">
                    <div class="card card1 sec-card-box mr-3">
                      <button class="btn" routerLink="/user">
                        <img src="../../assets/img/user-icon.png" alt="">
                        <!-- <i class="fa fa-user sec-icon" aria-hidden="true"></i> -->
                        <div class="card-body">
                          <p class="card-text">User</p>
                        </div>
                      </button>             
                    </div>           
                  </div>          
                </div>
               
              </div>
              <div id="master" class="container tab-pane fade"><br>
                <div class="d-flex bd-highlight mb-3">
                  <div class="p-2 bd-highlight">Master</div>         
                </div>             
              </div>
              <div id="api" class="container tab-pane fade"><br>
                <div class="d-flex bd-highlight mb-3">
                  <div class="p-2 bd-highlight">Api</div>         
                </div>
                     
              </div>
              <div id="blkchain" class="container tab-pane fade"><br>
                <div class="d-flex bd-highlight mb-3">
                  <div class="p-2 bd-highlight">Blockchain</div>         
                </div>              
              </div>
              <div id="billing" class="container tab-pane fade"><br><div class="d-flex bd-highlight mb-3">
                <div class="p-2 bd-highlight">Billing</div>         
              </div>
                     
              </div>
            </div>

            <div class="row" style="float:right;margin:15px">
              <button  routerLink="/template/{{CategoryName}}/{{IndId}}/{{Industry}}" class="btn-save rounded mt-2 ml-2">Back</button>
            </div>
          </section>
         
         
        </div>


      </div>
    </div>
    <div id="snackbar"></div>
    <div id="snackbarerror"></div>
    <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" style="margin-top: -0px;" id="exampleModalLongTitle"><img
                src="../../assets/img/backarrow.png" data-dismiss="modal" aria-label="Close" height="12" width="14">
              Default Template</h6>
            <div type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span style="color: #E6800E;" (click)="onClearAll();" aria-hidden="true">&times;</span>
            </div>
          </div>
          <div class="modal-body">
            <h1>TEst test</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">API Publish</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to Publish API
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
        <button type="button" class="btn btn-primary" (click)=publish() data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalCopy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Copy Template</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="firstFormGroupCopy">
          <div class="row">
            <div class="col-md-8">
              <mat-form-field appearance="outline">
                <mat-label>Template Name</mat-label>
                <input matInput formControlName="TemplateName" id="tName" placeholder="Last name, First name" required>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput formControlName="Description" id="Description" placeholder="Description">
              </mat-form-field>
            </div>
          </div>
          <div>
            <button class="btn-save rounded mt-2 ml-2" mat-button data-toggle="modal" (click)="Copy();" data-dismiss="modal">Save</button>
            <button class="btn-save rounded mt-2 ml-2" mat-button >Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
  </section>
</div>