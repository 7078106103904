import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { RoleService } from '../service/Role.service';
import { UtilitiesService } from '../service/utility.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  RoleForm: FormGroup;
  addedited:boolean =false;
  isUpdate:boolean=false;
  isSave:boolean=true;
  title = 'branch';
  mySubscription;
  
  constructor( private _formbuilder: FormBuilder,private _router: Router,
    private _utility:UtilitiesService,private _Role:RoleService,private location: Location) {
      this.createForm();

      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.mySubscription = this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
    // Trick the Router into believing it's last link wasn't previously loaded
        this._router.navigated = false;
        }
      });
     }
     ngOnDestroy(){
      console.log('app ngOnDestroy')
      if (this.mySubscription) {
       this.mySubscription.unsubscribe();
      }
    }

    
  goBack(){
    this.location.back();
  }
    createForm() {
      this.RoleForm = this._formbuilder.group({
        Id: [''],
        RoleCode: ['',Validators.required],
        Name: ['',Validators.required],
        // CreatedBy: [1],
        // UpdatedBy: [1],
      });
    }
  
  ngOnInit() {
    this.getRoleDtl();

  }
  RoleList:any;
getRoleDtl(){
  this._Role.getRoleDtl().subscribe(data=>{
    console.log(data);
    this.RoleList=data;
  })
  
}
  getRolebyid(id){
    this.isUpdate=true;
    this.isSave=false;
    this.addedited =true;
    this._Role.getRoleById(id).subscribe(data=>{
      console.log(data);
      this.RoleForm.setValue(data[0]);

    })

  }


  onSave(){
    console.log(this.RoleForm.value);
    if (this.RoleForm.valid) {


      this._Role.createRole(this.RoleForm.value)
      .subscribe(data=>{
      console.log(data);
      this.addedited=false;
      var x = (<HTMLInputElement>document.getElementById('snackbar'));
      x.innerText = 'Role Created Sucessfully';
      this._utility.SuccessToast();
      
      setTimeout(() => {
      this.refresh();
      }, 2000);
      
      })
      
      }
      else {
      const invalid = [];
      const controls = this.RoleForm.controls;
      for (const name in controls) {
      if (controls[name].invalid) {
      invalid.push(name);
      var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
      x.innerText = 'Please Enter ' + invalid[0] + '';
      this._utility.ErrorToast1();
      }
      }
      return invalid;
      }

  }

  onUpdate(e){
    console.log(e);
    if (this.RoleForm.valid) {
      this._Role.UpdateRole(e).subscribe(data=>{
     console.log(data);
     var x = (<HTMLInputElement>document.getElementById('snackbar'));
       x.innerText = 'Role Updated Sucessfully';
       this._utility.SuccessToast();
       //this._router.navigate(["/branch"]);
       setTimeout(() => {
         this.refresh();
       }, 2000);
       //this.refresh();
     
      
      this.isUpdate=false;
      this.isSave=true;
      this.addedited=false;
     //his.BranchForm.reset
     }, (err) => {
       var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
       x.innerText = 'An Error Occured' + err.error.toString();
       this._utility.ErrorToast1();
     })
     }
     else {
     const invalid = [];
     const controls = this.RoleForm.controls;
     for (const name in controls) {
     if (controls[name].invalid) {
       invalid.push(name);
       var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
       x.innerText = 'Please Enter ' + invalid[0] + '';
       this._utility.ErrorToast1();
     }
     }
     return invalid;
     }

  }

  

  onClearAll(){
    this.RoleForm.reset();
  }

  refresh() {
//debugger;
this.RoleForm.reset();
 //  window.location.reload();
   this._router.navigateByUrl('/role//miscellaneous/not-found', { skipLocationChange: true }).then(() =>
   this._router.navigate(["/role"]));

      console.log('refresh');
  }

  validateNumber(event: any) {
    this._utility.validateNumber(event);
  }

}