<section class="Home-page">
  <nav class="navbar navbar-expand-lg navbar-light bg fixed-top">
    <a class="navbar-brand logo" href="https://trst01.com/">
        <img src="/assets/img/logo-latest.png">
    </a>
  </nav>
    </section>

<div class="container">
  <div *ngIf="divMobileNo" style="margin-top: 65px;margin-bottom: 0px;">
    <section>
    <form [formGroup]="firstFormGroup">
      <div class="card col-md-5" style="margin-top: 20px;">
        <img src="../assets/img/obileotp.png" style="width: 30%;height: 20%;margin-top: 30px;" class="center">
        <h2 style="text-align: center;color: #222222;margin-top: 20px;margin-bottom:10px;font-family:'Roboto,bold';font-weight: 600;">Enter Your Mobile Number</h2>
        <h4 style="text-align: center; color:#827F92;margin-bottom: 10px;">we will send you the OTP for authentication</h4>
        <mat-form-field appearance="outline">
          <!-- <mat-label>Mobile No</mat-label> -->
          <input style="padding:0px" (keypress)="validateNumber($event)" matInput placeholder="Mobile No" pattern="[7-9]{1}[0-9]{9}" id="MobileNo" minlength="10" maxlength="10" required
            formControlName="MobileNo">
        </mat-form-field>
        <button type="button" class="btn btn-primary" style="height: 50px;margin-bottom: 70px;background-color: #EA8039;border:none;width: 40%;margin-left: 30%;font-size: large;"
          (click)="Submit()">Generate OTP</button>

      </div>
    </form>
    </section>
  </div>

  <div *ngIf="divOTP" style="margin-top: 65px;margin-bottom: 0px;">
    <section>
      <div  class="card col-md-5 d-flex justify-content-center text-center" style="margin-top: 20px;">
        <img src="../assets/img/obileotp.png" style="width: 30%;height: 20%;margin-top: 30px;" class="center">
        <!-- <p class="a-o-i">OTP Verification</p> -->
        <h2 style="text-align: center;color: #222222;margin-top: 20px;font-family:'Roboto,bold';font-weight: 600;">OTP Verification</h2>
        <h4 style="text-align: center;color:#827F92">Enter the OTP sent to {{maskmobile}}</h4>
        <ng-otp-input #ngOtpInput [config]="config"></ng-otp-input><br/>
        <span style="margin:10px;color:#827F92;margin-top: 10px;">Didn't receive the verification OTP ?</span>
        <span style="margin-bottom:10px"><a (click)="Submit()" style="color:#EA8039"><u>Resend OTP ?</u></a></span>
        <button type="button" class="btn btn-primary" style="height: 50px;margin-bottom: 70px;background-color: #EA8039;border:none;width: 40%;margin-left: 30%;font-size: medium;"
          (click)="SubmitOTP()"> Verify & Continue </button>
      </div>
      </section>
  </div>

  <!-- <div *ngIf="divbeneficiary" style="margin-top: 65px;margin-bottom: 0px;">
    <section>
    <form [formGroup]="secondFormGroup">
      <div class="card col-md-5" style="margin-top: 20px;">
        <img src="../assets/img/benifesiry_icon.png" style="width: 50%;height: 20%;margin-top: 30px;margin-bottom: 10px;" class="center">
        <h2 style="text-align: center;color: #222222;margin-top: 20px;margin-bottom: 10px;">Co-WIN Beneficiary ID</h2>
        <h4 style="text-align: center;color:#827F92;margin-bottom: 10px;">Enter Beneficiary ID sent to {{maskmobile}}</h4>
        <mat-form-field appearance="outline">
          <mat-label>Beneficiary No</mat-label>
          <input matInput placeholder="Beneficiary No" id="BeneficiaryNo" minlength="14" maxlength="14" required
            formControlName="BeneficiaryNo">
        </mat-form-field>
        <button type="button" class="btn btn-primary" style="height: 50px;margin-bottom: 100px;background-color: #EA8039;border:none;width: 40%;margin-left: 30%;font-size: large;"
          (click)="SubmitBeneficiary()">Proceed</button>
      </div>
    </form>
    </section>
  </div> -->
  <div  *ngIf="divbeneficiarylist" style="margin-top: 65px;margin-bottom: 0px;margin-bottom: 40px;">
    <section>
      <div class="row" *ngFor="let data of blistany">
        <div class="col-md-2"></div>
        <div class="card col-md-8" style="margin:10px;box-shadow: 0px 3px 6px #00000029;"  >
          <div class="card-body" style="padding: 0px;">
            <div class="col-md-11">
            <div class="row">
              <!-- <div class="col-md-8"> -->
                <spam id="test" [ngStyle]="{'color': data.V6}">{{data.V1}}</spam><!--style="font-size: 22px;padding-top: 10px;margin-left: 1px;padding-right: 20%;"-->
              <!-- <div class="col-md-2"> -->
                <!-- <div class="row"> -->
               <spam style="padding-top: 10px;margin-left: 0%;margin-right:5px;float:right" *ngIf="data.V5"><a (click)="DetailView(data.V2)"><i class="fa fa-eye " style="font-size: 20px; float:right"></i></a></spam>
               <spam style="padding-top: 10px;float:right" *ngIf="data.V5"> <a  (click)="Certificate(data.V2)"><i class="fa fa-download" style="font-size: 20px;"></i></a></spam>
                <!-- </div> -->
              <!-- </div> -->
              <!-- <div class="col-md-1">
                <p style="padding-top: 10px;" *ngIf="data.V5"> <a  (click)="Certificate(data.V2)"><i class="fa fa-download" style="font-size: 20px;"></i></a></p>
              </div> -->
            <!-- </div> -->
            </div>
          </div>
            <div class="row" *ngIf="data.V5">
              <div class="col-md-4">
                <p>Vaccine Name :<span style="font-weight: bold;"> {{data.V5}}</span></p>
              </div>
              <div class="col-md-4">
                <p>Dose 1 : {{data.V7}}</p>
              </div>
              <div class="col-md-4">
                <p>Dose 2 : {{data.V8}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p>Ref ID : {{data.V2}}</p>
              </div>
              <div class="col-md-4">
                <p>Year of Birth : {{data.V3}}</p>
              </div>
            </div>
           
    
          </div>
          <!-- <div class="card-body" style="padding: 20px;">
             <p style="font-size: 26px;font-weight: bold;">{{data.V1}}</p>
             <p>Ref ID:{{data.V2}}</p>
             <p>Year of Birth:{{data.V3}}</p>
             <p>Status: <span [ngStyle]="{'color': data.V6}">{{data.V4}}</span></p>
             <p>Vaccine Name:{{data.V5}}</p>
             <p>Dose 1:{{data.V7}}</p>
             <p>Dose 2:{{data.V8}}</p>
             <p>Place:{{data.V9}}</p>
             <p><a (click)="Certificate(data.V1)"><i class="fa fa-download"></i></a></p>
          </div> -->
        </div>
        <div class="col-md-2"></div>
      </div>
    </section>
  </div>
  <div *ngIf="divframe" style="margin-top: 70px;">
    <div class="card">
      <div class="card-body" style="text-align: center;">
        <p style="font-size: 15px;text-align: center;padding-top: 10px;"><a  style="color: #ff4d4d;font-style: italic;font-size:15px;text-decoration: underline;" (click)="back()">click here</a> for beneficiaries</p>
           
          <!-- <div style="height: 430px;">
            <div style="position:relative;padding-top:56.25%;height: 100%;">
              <iframe [src]="pdfurl" frameborder="0" allowfullscreen
                style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
            </div>
          </div> -->
          <iframe [src]="pdfurl" height="397px">
          </iframe>
        </div>
    
     
    </div>
  </div>
  <!-- <div  *ngIf="divbeneficiary" style="margin-top: 65px;margin-bottom: 0px;margin-bottom: 40px;">
    <section>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="card col-md-6" style="margin:10px;box-shadow: 0px 3px 6px #00000029;">
          <div class="card-body" style="padding: 0px;">
            <div class="table-responsive-sm">
              <table class="table" style="font-family: open sans-serif">
                <thead>
                </thead>
                <tbody>
                  <tr>
                    <td style="white-space: nowrap;padding-top: 10px;">Beneficiary ID</td>
                    <td style="padding-top: 10px;">:</td>
                    <td style="white-space: normal !important;padding-top: 10px;">{{beneficiaryID}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;padding-top: 10px;">Name</td>
                    <td style="padding-top: 10px;">:</td>
                    <td style="white-space: normal !important;padding-top: 10px;">{{beneficiaryName}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;padding-top: 10px;">Year of Birth</td>
                    <td style="padding-top: 10px;">:</td>
                    <td style="white-space: normal !important;padding-top: 10px;">{{birthday}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;padding-top: 10px;">Gender</td>
                    <td style="padding-top: 10px;">:</td>
                    <td style="white-space: normal !important;padding-top: 10px;">{{bgender}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;padding-top: 10px;">ID Verified</td>
                    <td style="padding-top: 10px;">:</td>
                    <td style="white-space: normal !important;padding-top: 10px;">{{photo_id_type}} {{photo_id_number}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;padding-top: 10px;">Status</td>
                    <td style="padding-top: 10px;">:</td>
                    <td style="white-space: normal !important;padding-top: 10px;">{{vaccination}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
    
          </div>
          <p><a  style="color: #ff4d4d;font-style: italic;font-size:12px;text-decoration: underline;" (click)="back()">click here</a> to beneficiarylist</p>
        </div>
        <div class="col-md-3"></div>
      </div>
    </section>
  </div> -->
</div>
<nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
  <div class="d-flex" style="width: 100%;">
      <div class="mr-auto pt-3">
          <p class="ft-text"> &copy;  2021  TRST01. All Rights Reserved</p>                   
      </div>
      <div class="pt-3">
       <p class="ft-pwr pr-1">Powered by</p> 
     </div>
      <div class="pt-1 logo-ft ">
         <img src="/assets/img/footer-logo.png">
      </div>
  </div>
  
</nav>