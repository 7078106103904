import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FormfieldService {
 //_apiurl: string = 'https://etgarage.in/api';
   _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }

  getFormfieldDetails(getFormid){
    return this._http.get<{posts:any}>(this._apiurl+'/FormFields/GetFormfield/'+getFormid).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id:post._id,
          Form: post.Form,
          FormId: post.FormId,
          Label :  post.Label,
          FieldType :  post.FieldType,
          Description :  post.Description,
          Order : post.Order,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy,
          IsActive : post.IsActive,
          FormFieldId:post.FormFieldId,
          PrimaryKey:post.PrimaryKey,
          MappingKey:post.MappingKey
        };
      })
    }));
  }

getFormfieldById(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Formfields/GetFormfield/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            Id:post._id,
            Form: post.Form,
            FormId: post.FormId,
            Label :  post.Label,
            FieldType :  post.FieldType,
            Description :  post.Description,
            Order : post.Order,
            CreatedBy : post.CreatedBy,
            UpdatedBy : post.UpdatedBy,
            IsActive : post.IsActive,
            
  
          };
        })
      }));

}

getFormfieldByIdData(Name:any,Id:any){
  console.log(this._apiurl+'/FormFields/GetFormfieldData/'+Name+'/'+Id)
  return this._http.get<{posts:any}>(this._apiurl+'/FormFields/GetFormfieldData/'+Name+'/'+Id).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id:post._id,
          Form: post.Form,
          FormId: post.FormId,
          Label :  post.Label,
          FieldType :  post.FieldType,
          Description :  post.Description,
          Order : post.Order,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy,
          IsActive : post.IsActive,
          

        };
      })
    }));

}

  createFormfield(Formfield: any) {
    return this._http.post(this._apiurl+'/Formfields/postFormfield', Formfield);
  }

  UpdateFormfield(Formfield: any) {
    return this._http.put(this._apiurl+'/Formfields/updateFormfield', Formfield);
  }

 
}
