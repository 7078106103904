<div style="margin-top: 75px;"></div>
<section class="setting"> 
  <div class="bg-white " style="height: 45px;"> 
<div class="sec-tab">   
 <ul class="nav" role="tablist" style="margin-bottom: 0px;height: 50px;">
    <li class="nav-item">
      <button id="button" routerLink="/industry" (click)="click1();onChangeRibon()" style="color: #E6800E;">         
        <img src="../../assets/img/Templates icon.png" height="18" width="22"  style="margin-right: 5px;" class="img"> Templates</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/OrganisationOverview" (click)="clickonConfiguration();onChangeconfig();" data-toggle="pill" href="#config" >
         <img src="../../assets/img/{{configicon}}" height="18" width="22"  style="margin-right: 5px;"class="img" > Configurations</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/MasterOverview" (click)="clickMaster();onChangeMaster();" data-toggle="pill" href="#master">
        <img src="../../assets/img/{{masterIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Masters</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/ApiOverview" (click)="clickApi();onChangeApi();"  data-toggle="pill" href="#api">
        <img src="../../assets/img/{{apiIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> API's</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/BlockchainOverview" (click)="clickBlockChain();onChangeBlockChain();"  data-toggle="pill" href="#blkchain">
        <img src="../../assets/img/{{blockChainIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> Blockchain</button>
    </li>
    <li class="nav-item">
      <button id="button" routerLink="/BillingOverview" (click)="clickBilling();onChangebilling();"  data-toggle="pill" href="#billing">
        <img src="../../assets/img/{{billingIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Billing</button>
    </li>
  </ul>
</div>
</div>
</section>
<div style="width: 100%;">
  <section>
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E6800E" type = "line-scale-pulse-out-rapid" [fullScreen] = "true">
    </ngx-spinner>
    <div class="tab-content" style="width: 100%;">
      <div class="card">

       
        <!-- Main in Template -->
        <div class="card-body" style="height: 100%;background-color: white;margin-bottom: -10px;" *ngIf="!addedited">
          <div class="row" style="margin-top: -17px;margin-left: -17px; margin-bottom: 1px;background-color: white">
            <div>
             
              <a routerLink="/industry"  style="font-size: 13px;padding: 10px;">{{Industry}}</a> <span
                style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span>
              <a routerLink="/category/{{IndId}}/{{CategoryName}}"  style="font-size: 13px;">{{CategoryName}}</a><span
                style="margin-left: 5px;margin-right: 5px; font-size: large;">/</span>
              <a routerLink="/template/{{CategoryName}}/{{IndId}}/{{Industry}}"  style="font-size: 13px;">Template </a>
              <!--mat-button-->
            </div>
          </div>
          <div class="row" style="background-color: white;height: 500px;">
            <!-- <div class="col-md-12"> -->
            <div class="col-md-2" style="padding-left: 0px !important;padding-right: 0px !important;">
              <div class="card">
                <!-- <div class="card-header">
                  Featured
                </div> -->
                <div>
                  <ul class="list-group list-group-flush" *ngFor="let data of TemplateByCatList; let i = index">
                    <button id="button2"  aria-current="true" *ngIf="i=='0'"  [ngClass]="something == '0' ? 'active':''" [class.active]="clicked === data"
                      (click)="clicked = (clicked === data ? null :data);GetClick();GetTemplateDataByName(data.TemplateId,data.Name);isDefaultTemplate=true;"
                      style="font-size: 14px;font-weight: 600px;">{{data.Name}}
                    </button>
                    <button id="button2" class="list-group-item btn" aria-current="true"   *ngIf="i!='0'" [class.active]="clicked === data"
                    (click)="clicked = (clicked === data ? null :data);GetClick();GetTemplateDataByName(data.TemplateId,data.Name);isDefaultTemplate=true;"
                    style="font-size: 14px;font-weight: 600px;"><i class="fa fa-star" *ngIf="data.IsPublish"></i>  {{data.Name}}
                  </button>
                  </ul>
                </div>
              </div>

            </div>
            <div class="com-md-10 col-md-10" style="padding-left: 0px !important;padding-right: 0px !important;">
              <div class="card" *ngIf="isDefaultTemplate">
                <mat-horizontal-stepper linear #stepper>
                  <mat-step [stepControl]="firstFormGroup" [completed]="false">
                    <form [formGroup]="firstFormGroup">
                      <ng-template matStepLabel>Template</ng-template>
                      <div class="row">
                        <div class="col-md-8">
                          <mat-form-field appearance="outline">
                            <mat-label>Template Name</mat-label>
                            <input matInput formControlName="TemplateName" id="tName"
                              placeholder="Last name, First name" required>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <input matInput formControlName="Description" id="Description" placeholder="Description">
                          </mat-form-field>
                        </div>
                      </div>
                      <div>
                        <button class="btn-save rounded mt-2 ml-2" mat-button data-toggle="modal"
                          data-target="#exampleModalCopy" *ngIf="isCopy">Save as</button>
                        <button class="btn-save rounded mt-2 ml-2" mat-button matStepperNext>Next</button>
                      </div>
                    </form>
                  </mat-step>
                  <mat-step [stepControl]="secondFormGroup" [completed]="false">
                    <form [formGroup]="secondFormGroup">
                      <ng-template matStepLabel>Form</ng-template>
                      <div class="row">
                        <div class="col-md-5">
                          <mat-form-field appearance="outline">
                            <mat-label>Form Title</mat-label>
                            <input matInput formControlName="Title" placeholder="Form Title">
                          </mat-form-field>
                        </div>
                        <div class="col-md-5">
                          <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <input matInput formControlName="Description" placeholder="Description">
                          </mat-form-field>
                        </div>
                        <div class="col-md-2" style="margin-top: 15px;">
                          <button class="btn btn-light rounded justify-content-end  mt-2 mr-3 pull-right"
                            (click)="onsaveForm();cancelForm();" mat-button>
                            <i class="fa fa-plus" style="color: #e6800e;" aria-hidden="true"></i></button>
                          <button class="btn btn-primary btn-save rounded mt-2 ml-2" *ngIf="addeditForm"
                            (click)="OnUpdateForm()" mat-button>Update</button>
                        </div>
                      </div>
                      <div class="row ">
                        <div id="table-wrapper">
                          <div id="table-scroll">
                            <table id="customers">
                              <thead class="thead">
                                <tr>
                                  <th></th>
                                  <th>Form Title</th>
                                  <th>Description</th>
                                  <th>Sl. No</th>
                                </tr>
                              </thead>
                              <tbody class="scrollist">
                                <tr *ngFor="let data of FormDataList;let i=index;">

                                  <td><button class="btn btn-primary" style="margin-right: 8px;"
                                      (click)="cancelForm();OnSelectFormRow($event,data)">
                                      <i class="fa fa-pencil"></i>
                                    </button>
                                    <button class="btn btn-danger" (click)="OnDeleteform(i,data)">
                                      <i class="fa fa-remove"></i>
                                    </button>
                                  </td>
                                  <td>{{data.Title}}</td>
                                  <td>{{data.Description}}</td>
                                  <td>{{i+1}}</td>

                                </tr>
                              </tbody>

                            </table>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
                        <button class="btn-save rounded mt-2 ml-2" (click)="onloadTabSelection(TabValue,TabForm);"
                          mat-button matStepperNext>Next</button>
                      </div>
                    </form>
                  </mat-step>
                  <mat-step [stepControl]="thirdFormGroup" [completed]="false">
                    <form [formGroup]="thirdFormGroup">
                      <ng-template matStepLabel>Form Field</ng-template>
                      <tabset class="tab" style="max-height: 250px;margin-top: -30px;">
                        <div *ngFor="let data of FormDataList; let i=index">
                          <tab heading="{{data.Title}}"
                            (selectTab)="onloadTabSelection(data.Title,data.FormId);selectedTab=true;" #tabName
                            class="tabs__item" style="max-height: 12px;">
                            <div style="margin-top: -0px;" *ngIf="data.Title==noOfTab">
                              <div class="row" style="margin-top: -5px;">
                                <div class="col-md-3">
                                  <mat-form-field appearance="outline">
                                    <mat-label>Field Name</mat-label>
                                    <input matInput formControlName="Label" id="Name" placeholder="Name" style="color: black;">
                                  </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                  <mat-form-field appearance="outline">
                                    <mat-label>Field Type</mat-label>
                                    <mat-select matInput id="FieldType" formControlName="FieldType">
                                      <mat-option value="Text">Text</mat-option>
                      <mat-option value="Integer">Integer</mat-option>
                      <mat-option value="Decimal">Decimal</mat-option>
                      <mat-option value="Date">Date</mat-option>
                      <mat-option value="DateTime">DateTime</mat-option>
                      <mat-option value="LatLong">LatLong</mat-option>
                      <mat-option value="Mobile">MobileNo</mat-option>
                      <mat-option value="LatLong">LatLong</mat-option>
                      <mat-option value="Image">Image</mat-option>
                      <mat-option value="pdf">PDF</mat-option>
                      <mat-option value="txt">Txt</mat-option>
                      <mat-option value="doc">MS Word</mat-option>
                      <mat-option value="xls">MS Excel</mat-option>
                                    </mat-select>
                                    <!-- <input matInput formControlName="FieldType" placeholder="Field Type" required> -->
                                  </mat-form-field>
                                </div>
                                <div class="col-md-2">
                                  <mat-form-field appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <input matInput formControlName="Description" placeholder="Description" style="color: black;">
                                  </mat-form-field>
                                </div>
                                <div class="col-md-2">
                                  <mat-form-field appearance="outline">
                                    <mat-label>Key Type</mat-label>
                                    <mat-select [disabled]="disableSelect.value" id="KeyType" formControlName="KeyType">
                                      <mat-option value="PrimaryKey">Primary Key</mat-option>
                                      <mat-option value="MappingKey">Mapping Key</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="col-md-2" style="margin-top: 15px;">
                                  <button class="btn btn-light rounded justify-content-end  mt-2 mr-3 pull-right"
                                    (click)="onsaveFormField();cancelFormfield();" mat-button>
                                    <i class="fa fa-plus" style="color: #e6800e;" aria-hidden="true"></i></button>
                                  <button class="btn btn-primary btn-save rounded mt-2 ml-2" *ngIf="addeditFormfield"
                                    (click)="OnUpdateFormfield()" mat-button>Update</button>
                                </div>
                              </div>
                              <div class="row ">
                                <div id="table-wrapper">
                                  <div id="table-scroll">
                                    <table id="customers" *ngIf="FormFieldDataList.length !=0">
                                      <thead class="thead">
                                        <tr>
                                          <th></th>
                                          <th>Form Field Label</th>
                                          <th>Field Type</th>
                                          <th>Description</th>
                                          <th>Sl. No</th>

                                        </tr>
                                      </thead>
                                      <tbody class="scrollist" style="color:rgb(53, 51, 51)">
                                        <tr *ngFor="let data of FormFieldDataList;let i=index;">

                                          <td><button class="btn btn-primary" style="margin-right: 8px;"
                                              (click)="cancelForm();OnSelectFormfieldRow($event,data)">
                                              <i class="fa fa-pencil"></i>
                                            </button>
                                            <button class="btn btn-danger" (click)="OnDeleteformfield(i,data)">
                                              <i class="fa fa-remove"></i>
                                            </button>
                                          </td>
                                          <td>{{data.Label}}</td>
                                          <td>{{data.FieldType}}</td>
                                          <td>{{data.Description}}</td>
                                          <td>{{i+1}}</td>

                                        </tr>
                                      </tbody>

                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </tab>
                        </div>
                      </tabset>
                      <div>
                        <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
                        <button class="btn-save rounded mt-2 ml-2" (click)="getTemplateData();" mat-button
                          matStepperNext>Next</button>
                      </div>
                    </form>
                  </mat-step>
                  <mat-step [stepControl]="forthFormGroup" [completed]="false">
                    <div id="table-wrapperPreview">
                      <div id="table-scrollPreview">
                        <form [formGroup]="forthFormGroup">
                          <ng-template matStepLabel>Preview</ng-template>
                          <div class="container  Preview">

                            <div class="pre-box">
                              <div class="ctgr-1 sec-br pb-4">
                                <h3>Template</h3>
                                <div class="row">
                                  <!-- <div class="col-sm-12 col-lg-6"> -->
                                  <!-- <div class="row"> -->
                                  <div class="col-sm-3" style="text-align: right;">
                                    Template Name :
                                  </div>
                                  <div class="col-sm-3 text-left" style="text-align: left;">
                                    {{first}}
                                  </div>
                                  <!-- </div> -->
                                  <!-- </div> -->
                                  <!-- <div class="col-sm-12 col-lg-6"> -->
                                  <!-- <div class="row"> -->
                                  <div class="col-sm-3" style="text-align: right;">
                                    Description :
                                  </div>
                                  <div class="col-sm-3 text-left" style="text-align: left;">
                                    {{second}}
                                  </div>
                                  <!-- </div> -->
                                  <!-- </div> -->
                                </div>
                                <!--rowend-->

                                <!-- </div>             -->
                              </div>
                              <!-- <div class="forminfo sec-br">
                          <h3>Form Information</h3>            
                            <div class="row  ml-5 mr-5 table-wrapper-scroll-y my-custom-scrollbar">
                                <div id="table-wrapper">
                                  <div id="table-scroll">
                                    <table id="customers">
                                      <thead class="thead">
                                        <tr>
                                         
                                          <th>Form Name</th>
                                          <th>Description</th>
                                          <th>Sl. No</th>
                                        </tr>
                                      </thead>
                                      <tbody class="scrollist">
                                        <tr *ngFor="let data of FormDataList;let i=index;">
                                          <td>{{data.Title}}</td>
                                          <td>{{data.Description}}</td>
                                          <td>{{i+1}}</td>
                                        </tr>
                                      </tbody>
                          
                                    </table>
                                  </div>
                                </div>
                            </div>            
                        </div>   -->

                              <div *ngFor="let dd of FormDataList">
                                <div class="card">
                                  <div class="card-header">
                                    {{dd.Title}}
                                  </div>
                                  <div class="card-body">
                                    <table id="customers">
                                      <thead class="thead">
                                        <tr>
                                          <!-- <th>Form</th> -->
                                          <th>Field Name</th>
                                          <th>FieldType</th>
                                          <th>Description</th>
                                          <th>Sl. No</th>
                                        </tr>
                                      </thead>
                                      <tbody *ngFor="let data of AllFormFieldData;let i=index;">
                                        <tr *ngIf="data.Form==dd.Title" style="margin-bottom: 5px;">
                                          <!-- <td>{{data.Form}}</td> -->
                                          <td>{{data.Label}}</td>
                                          <td>{{data.FieldType}}</td>
                                          <td>{{data.Description}}</td>
                                          <td>{{data.Idd}}</td>
                                          <!-- <td>{{i+1}}</td> -->
                                        </tr>

                                      </tbody>

                                    </table>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <!-- </div>
                      </div> -->
                          </div>

                          <div style="margin-bottom: 25px;">
                            <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
                            <!-- <button *ngIf="UpdateStatus" class="btn-save rounded mt-2 ml-2" (click)="Update()"
                            mat-button>Update</button> -->
                            <!-- <button *ngIf="!isPublish" class="btn-save rounded mt-2 ml-2" (click)="Update()"
                              mat-button>Update</button> -->
                              <button class="btn-save rounded mt-2 ml-2" (click)="Update()"
                              mat-button matStepperNext>Next</button>
                            <!-- <button class="btn-save rounded mt-2 ml-2" (click)="steperfourStep();" mat-button matStepperNext>Next</button> -->
                          </div>
                        </form>
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [completed]="false">
                    <ng-template matStepLabel>API</ng-template>
                    <!-- <p>You are now done.</p> -->
                    <div class="container-fluit">
                      <div class="card">
                        <div class="card-header">API Format</div>
                        <div class="card-body" style="height: 100px;overflow-y: scroll;">
                          <div *ngFor="let data of FormatData;">
                            <ul [innerHTML]="data | prettyprint"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">API Example</div>
                        <div class="card-body" style="height: 100px;overflow-y: scroll;">
                          <div *ngFor="let data of ExampleData;let i=index">
                            <ul [innerHTML]="data | prettyprint"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button class="btn-save rounded mt-2 ml-2" mat-button matStepperPrevious>Back</button>
                      <button class="btn-save rounded mt-2 ml-2 " data-toggle="modal" data-target="#exampleModal"
                      *ngIf="!PublishStatus" mat-button>
                        Publish
                      </button>
                      <!-- <button class="btn-save rounded mt-2 ml-2 " data-toggle="modal" data-target="#exampleModal"
                        *ngIf="!isPublish" mat-button>
                        Publish
                      </button> -->
                      <button (click)="done()"
                        class="btn-save rounded mt-2 ml-2" mat-button matStepperNext>Done</button>
                    </div>
                  </mat-step>
                </mat-horizontal-stepper>
              </div>

            </div>
            <!-- </div> -->
          </div>
        </div>
<!-- ribbion -->
<div *ngIf="isRibbon">
  <section class="sec-bg">
  <div class="tab-content">
  <div id="template" class="container tab-pane active"><br>
  <!-- <div class="d-flex bd-highlight mb-3">
  <div class=" bd-highlight ">
  <div class="card card1 sec-card-box mr-3">
  <button class="btn" routerLink="/industry">
  <img src="../../assets/img/indsry-icon.png" alt="">
  <i class="fa fa-industry sec-icon" aria-hidden="true"></i>
  <div class="card-body">
  <p class="card-text">Industry</p>
  </div>
  </button>
  </div>
  </div>
  </div> -->
  
  </div>
  <div id="config" class="container tab-pane fade"><br>
  
  <div class="d-flex bd-highlight mb-3">
  <div class="bd-highlight">
  <div class="card card1 sec-card-box mr-3">
  <button class="btn" routerLink="/organisation">
  <img src="../../assets/img/org-icon.png" alt="">
  <!-- <i class="fas fa-sitemap sec-icon"></i> -->
  <div class="card-body">
  <p class="card-text">Organization</p>
  </div>
  </button>
  </div>
  </div>
  <div class="bd-highlight">
  <div class="card card1 sec-card-box mr-3">
  <button class="btn" routerLink="/branch">
  <img src="../../assets/img/branch-icon.png" alt="">
  <!-- <i class="fas fa-code-branch sec-icon"></i> -->
  <div class="card-body">
  <p class="card-text">Branch</p>
  </div>
  </button>
  </div>
  </div>
  <div class="bd-highlight">
  <div class="card card1 sec-card-box mr-3">
  <button class="btn" routerLink="/role">
  <img src="../../assets/img/role-icon.png" alt="">
  <!-- <i class="fab fa-critical-role sec-icon"></i> -->
  <div class="card-body">
  <p class="card-text">Role</p>
  </div>
  </button>
  </div>
  </div>
  <div class="bd-highlight">
  <div class="card card1 sec-card-box mr-3">
  <button class="btn" routerLink="/user">
  <img src="../../assets/img/user-icon.png" alt="">
  <!-- <i class="fa fa-user sec-icon" aria-hidden="true"></i> -->
  <div class="card-body">
  <p class="card-text">User</p>
  </div>
  </button>
  </div>
  </div>
  </div>
  
  </div>
  <div id="master" class="container tab-pane fade"><br>
  <div class="d-flex bd-highlight mb-3">
  <div class="p-2 bd-highlight">Master</div>
  </div>
  </div>
  <div id="api" class="container tab-pane fade"><br>
  <div class="d-flex bd-highlight mb-3">
  <div class="p-2 bd-highlight">Api</div>
  </div>
  
  </div>
  <div id="blkchain" class="container tab-pane fade"><br>
  <div class="d-flex bd-highlight mb-3">
  <div class="p-2 bd-highlight">Blockchain</div>
  </div>
  </div>
  <div id="billing" class="container tab-pane fade"><br><div class="d-flex bd-highlight mb-3">
  <div class="p-2 bd-highlight">Billing</div>
  </div>
  
  </div>
  </div>
  
  <div class="row" style="float:right;margin:15px">
  <button routerLink="/template/{{CategoryName}}/{{IndId}}/{{Industry}}" class="btn-save rounded mt-2 ml-2">Back</button>
  </div>
  </section>
  
  </div>
      </div>
    </div>
    <div id="snackbar"></div>
    <div id="snackbarerror"></div>
    <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" style="margin-top: -0px;" id="exampleModalLongTitle"><img
                src="../../assets/img/backarrow.png" data-dismiss="modal" aria-label="Close" height="12" width="14">
              Default Template</h6>
            <div type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span style="color: #E6800E;" (click)="onClearAll();" aria-hidden="true">&times;</span>
            </div>
          </div>
          <div class="modal-body">
            <h1>TEst test</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">API Publish</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="OrganisationFormGroup">
              <label>Organisation</label>
              <select class="form-select form-control" (change)='onOrganisationChange($event)'  formControlName="OrganisationId">
                <option *ngFor="let data of orgList"  value="{{data.OrganisationId}}" >{{data.Name}}</option>
              </select>
              
          </form>
          </div>
          <!-- <div class="modal-body">
            Are you sure you want to Publish API
          </div> -->
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
            <button type="button" class="btn btn-primary" (click)=publish() data-dismiss="modal" [disabled]="modalButtn">Yes</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModalCopy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Copy Template</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="firstFormGroupCopy">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Template Name</mat-label>
                    <input matInput formControlName="TemplateName" id="tName" placeholder="Last name, First name"
                      required>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="Description" id="Description" placeholder="Description">
                  </mat-form-field>
                </div>
              </div>
              <div>
                <button class="btn-save rounded mt-2 ml-2" mat-button data-toggle="modal" (click)="InitialCopy();"
                  data-dismiss="modal">Save</button>
                <button class="btn-save rounded mt-2 ml-2" mat-button>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>