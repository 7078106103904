import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protractor } from 'protractor/built/ptor';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CowinService {
// _apiurl: string = 'https://etgarage.in/api';
_apiurl: string = apiurl;
constructor(private _http:HttpClient,private _router:Router) { }


CoWINMobileSentOTP(MobileNo: any) {
 
  //return this._http.post('http://localhost:4000/cowin/generateOTP', MobileNo);
  return this._http.post(this._apiurl+'/cowin/generateOTP', MobileNo);
}

CoWINConfirmOTP(MobileNo: any) {

  //return this._http.post('http://localhost:4000/cowin/confirmOTP', MobileNo);
  return this._http.post(this._apiurl+'/cowin/confirmOTP', MobileNo);
}

CoWINBeneficiaryList(TokenNo)
{

  //return this._http.get('http://localhost:4000/cowin/beneficiaries/'+TokenNo);
  return this._http.get(this._apiurl+'/cowin/beneficiaries/'+TokenNo);
}

//  CoWINGetDoc(TokenNo,BeneficiaryNo)
//  {
 
//   return this._http.get('http://localhost:4000/cowin/certificate/'+TokenNo+'/'+BeneficiaryNo)
//   //return this._http.get(this._apiurl+'/cowin/certificate/'+TokenNo+'/'+BeneficiaryNo)
//  }

 CoWINGetDoc(TokenNo,BeneficiaryNo)
 {
  const headers = {
    responseType: 'blob' as 'arraybuffer',
    headers: new HttpHeaders({
      'Authorization': `Bearer ${TokenNo}`
    })
  };
  return this._http.get('https://cdn-api.co-vin.in/api/v2/registration/certificate/download?beneficiary_reference_id='+BeneficiaryNo+'', headers)
 }

 UploadPDF(base64)
{
  return this._http.post(this._apiurl+'/PDFExtract/uploadPDFfile', base64);
}

}
