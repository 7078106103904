import { Component, OnInit } from '@angular/core';
import { sha256 } from 'js-sha256';
import { ActivatedRoute, Router } from '@angular/router';
import converter from 'hex2dec';

@Component({
  selector: 'app-verifyhyperledge',
  templateUrl: './verifyhyperledge.component.html',
  styleUrls: ['./verifyhyperledge.component.css']
})
export class VerifyhyperledgeComponent implements OnInit {
  TransactionID:any;
  TransactionHash:any;
  Transblock:any;
  constructor(private route: ActivatedRoute,private router: Router) { }

  ngOnInit() {
    let sp =this.route.snapshot.params.DCNumber.split(';');
    let DCNumber = sp[0];
    //let DCNumber = this.route.snapshot.params.DCNumber["DCNumber"];
    //this.TransactionID = "00000123"+DCNumber.substr(DCNumber.length - 5);
    //this.TransactionID = this.route.snapshot.paramMap.get("TxId");
    this.TransactionID = sp[1];
    this.TransactionHash = sha256(DCNumber);
    var dc = sp[0].substr(sp[0].length - 6);
    var mult = dc*sp[2];
    // console.log(dc)
    // console.log(sp[2])
    // console.log(mult)
    // console.log(converter.decToHex(mult.toString()))
    this.Transblock = converter.decToHex(mult.toString());
  //   var result           = '';
  //   var characters       = '0123456789';
  //   var charactersLength = characters.length;
  //   for ( var i = 0; i < 4; i++ ) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));

  //     if(4-1 ==i)
  //     {
  //         this.Transblock = result;
  //     }
  // }
  }
}
