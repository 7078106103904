<div class="container-fluid" style="padding-right: 0px !important;padding-left: 0px !important;">
  <div *ngIf="open">
     <img src="../assets/img/open.png" style="height: 100%;width: 100%;">
  </div>
  <section class="content" *ngIf="second">
    <div class="card">
        <h5 class="card-header" style="padding:0px !important">
            <img src="../assets/img/PHeader.png" style="width: 100%;height: 100px;" />
        </h5>
        <div class="card-body" style="padding: 0px !important;padding-top: 1px;">
          <div class="card-title" *ngIf="DynamicTitle" style="font-style: italic;padding-left: 24px;">Our handpicked mangoes are safe to eat </div>
             <div class="card" style="margin-bottom: 20px; box-shadow: 5px 5px 5px 5px #F8F8F8" (click)="History()" *ngIf="CardHistory" >
              <div class="table-responsive-sm">
                <table class="table" style="font-family: open sans-serif" style="margin-bottom: 0px !important;">
                  <thead>
                  </thead>
                   <tbody>
                    <tr>
                      <td style="width: 80px;"><img src="../assets/img/History.png" style="width: 70px;height: 70px;"></td>
                      <td style="padding-top: 29px;text-align: left"><p style="font-family: Roboto;font-weight: bold;font-size: 14px;">Know Your Mango</p></td>
                    </tr>
                   </tbody>
                </table>
                </div>
             </div>
             <div class="card" style="margin-bottom: 20px; box-shadow: 5px 5px 5px 5px #F8F8F8" (click)="Nutrition()" *ngIf="CardNutrition">
              <div class="table-responsive-sm">
                <table class="table" style="font-family: open sans-serif" style="margin-bottom: 0px !important;">
                  <thead>
                  </thead>
                   <tbody>
                    <tr>
                      <td style="width: 80px;"><img src="../assets/img/Nutrition.png" style="width: 70px;height: 70px;" ></td>
                      <td style="padding-top: 29px;text-align: left"><p style="font-family: Roboto;font-weight: bold;font-size: 14px;">Nutrition Facts</p></td>
                    </tr>
                   </tbody>
                </table>
                </div>
             </div>
             <div class="card" style="margin-bottom: 20px; box-shadow: 5px 5px 5px 5px #F8F8F8" (click)="Traceability()" *ngIf="CardTraceability">
              <div class="table-responsive-sm">
                <table class="table" style="font-family: open sans-serif" style="margin-bottom: 0px !important;">
                  <thead>
                  </thead>
                   <tbody>
                    <tr>
                      <td style="width: 80px;"><img src="../assets/img/Traceability.png" style="width: 70px;height: 70px;"></td>
                      <td style="padding-top: 29px;text-align: left"><p style="font-family: Roboto;font-weight: bold;font-size: 15px;">Trace Your Mango</p></td>
                    </tr>
                   </tbody>
                </table>
                </div>
             </div>
            <div class="card" *ngIf="CardPictures">
              <div class="card-header"><span style="font-weight: bold;font-size: 15px;">Origin of Mango</span></div>
              <div class="card-body">
                <ul class="list-group list-group-horizontal" style="list-style: none;overflow-x:scroll;">
                  <li *ngFor="let img of DynamicMap" style="margin:5px" >
                    <img src="{{img}}" style="width:150px;height:120px;" (click)="popup(img)"/>
                  </li>
                </ul>
              </div>
              <!-- <div class="table-responsive-sm">
                <table class="table" style="font-family: open sans-serif">
                  <thead>
                  </thead>
                   <tbody>
                    <tr>
                      <td style="width: 80px;height: 70px;"><img src="../assets/img/PI1.png"></td>
                      <td style="width: 80px;height: 70px;"><img src="../assets/img/PI2.png"></td>
                    </tr>
                   </tbody>
                </table>
                </div> -->
            </div>

            <div class="card" *ngIf="InnerCardHistory">
             
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" href="#" data-toggle="tab" href="#home1">Know Your Mango</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#menu2">Social Impact</a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="home1" class="tab-pane fade in active show">
               <div class="card">
                  <div class="card-header"
              style="text-align: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;"><img src="../assets/img/back.png" style="width: 19px;height: 16px;" (click)="back()"/>  Know Your Mango
            </div>
                <div class="card-body">
                  <div class="table-responsive-sm" >
                    <table class="table" style="font-family: open sans-serif" >
                      <thead>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Variety</td>
                          <td>:</td>
                          <td>{{DynamicVariety}}</td>
                        </tr>
                       
                        <tr>
                          <td>Origin of the Mango</td>
                          <td>:</td>
                          <td style="white-space: normal !important;text-align: justify;">Ulavapadu, Prakasam, AP</td>
                        </tr>
                        <tr>
                          <td>Harvested Date</td>
                          <td>:</td>
                          <td>{{DynamicHarvestedDate}}</td>
                        </tr>
                        <tr>
                          <td>Days Ripened for</td>
                          <td>:</td>
                          <td>{{DynamicDaysripenedfor}}</td>
                        </tr>
                        <tr>
                          <td>Method of Ripening</td>
                          <td>:</td>
                          <td style="white-space: normal !important;"> Govt Prescribed Natural ripening</td>
                        </tr>
                        <tr>
                          <td>Shipped Date</td>
                          <td>:</td>
                          <td>{{DynamicShippedDate}}</td>
                        </tr>
                        <tr>
                          <td>Reached Store on</td>
                          <td>:</td>
                          <td>{{DynamicReachedStoreon}}</td>
                        </tr>
                        <tr>
                          <td>Store Sale Date</td>
                          <td>:</td>
                          <td>{{DynamicStoreSaleDate}}</td>
                        </tr>
                        <tr>
                          <td>License No</td>
                          <td>:</td>
                          <td style="white-space: normal !important;word-break: break-all;">U51909TG2020PTC138380</td>
                        </tr>
                        <tr>
                          <td>Total Quality Checks</td>
                          <td>:</td>
                          <td>22</td>
                        </tr>
                        <tr>
                          <td>Batch Code</td>
                          <td>:</td>
                          <td>{{DynamicBatchCode}}</td>
                        </tr>
                        <tr>
                          <td>Best before</td>
                          <td>:</td>
                          <td>{{DynamicBestbefore}}</td>
                        </tr>
                        <tr>
                          <td>Farmer/FPO</td>
                          <td>:</td>
                          <td>{{DynamicFarmer}}</td>
                        </tr>
                        <tr>
                          <td>Breed known for</td>
                          <td>:</td>
                          <!-- <td style="white-space: normal !important;text-align: justify;">Big Size, Rich Aroma, and the
                            Unique Taste make this variety of Ulavapadu Banginapalli a sought out after mango in the summer season.
                            Ulavapadu Banginapalli variety is the most famous and best-seller among all mango varieties in
                            Andhra Pradesh and Telangana. The pulp is fibreless, firm, and yellow with a sweet taste.
                          </td> -->
                          <td style="white-space: normal !important;text-align: justify;">
                          <p>Big Size, Rich Aroma and the Unique Taste make this variety of Ulavapadu Banginapalli a sought after mango in the summer season, along with the presence of essential nutritional element.</p>
                          <p>Ulavapadu Banginapalli variety of mango is the most famous, sought-after and best-seller among all varieties present in the region of Andhra Pradesh and Telangana. The pulp is fibreless, firm and yellow in colour with a sweet rich taste. </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
               </div>
              </div>
              <div id="menu2" class="tab-pane fade">
                <div class="card">
                  <div class="card-header"
                    style="text-align: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;"><img src="../assets/img/back.png" style="width: 19px;height: 16px;" (click)="back()"/>  Social Impact
                  </div>
                  <div class="card-body" style="padding-top: 10px;text-align: center;">
                    <p style="font-size: 15px;text-align: justify">
                      There are around 1100 varieties of mango grown all around the world, with the majority of the fruit coming from India. Depending upon the region and the time in which they come, mangoes are available in various colours and sizes. Ulavapadu Banginapalli variety is the most famous and best-seller among all mango varieties in Andhra Pradesh and Telangana. The pulp is fibreless, firm, and yellow with a sweet taste. Most of Ulavapadu Banginapalli varieties of mangoes remain busy to match the demand. The factors on which a Ulavapadu mango can be identified, is through colour, shape, aroma and taste. The mangoes are naturally ripened mangoes, and have a evenly graded green to yellow colour. Generally, it is available from Mid-April to June and each weigh around the 150-300 gms. Mango production in ‘mango village’ of Ulavapadu, Prakasam district, is spread over 18000 acres. Besides farmers from the nearby areas of Nuzvid, Krishna district also take up Baginapalli mango production. The farmers in the areas are progressive, and have adopted zero-based natural farming techniques to meet the increasing demand for organically-grown fruit. 
                      </p>
                      <p style="font-size: 15px;text-align: justify">
                        Every choice we make at Polimeraas brings you the best variety of mangoes at the height of freshness, flavour & nutrition. Establishing traceability technology (on origin of Mango, location of the plantation, harvested date, ripening methods followed, quality checks and transportation to the retail outlet) for mangoes will not only help consumers to get value for their money, but also curb product falsification and mislabelling. The complete journey of a fruit from the farm to the retail shelf is made available to the consumers. This is especially relevant for all, as authenticity and source of origin are important parameters in ensuring the right value for producers and consumers. The true beneficiaries will be borne by the farmers, associated with Polimeraas, which would ultimately effect on a 10%+ improvement in their income.
                      </p>
                      <p style="font-size: 15px;text-align: justify">
                        The believe in transparent systems and processes have made us reach to a growing number of consumers, that helps us to build trust, and facilitate better market for farmers in supplying their produce to consumers. The quality and freshness are not compromised in any way, and Polimeraas is bound to bring back the real taste of naturally ripened mangoes. 
                      </p>
                  </div>
                </div>
              </div>
            </div>
             
            </div>

            <div class="card" *ngIf="InnerCardNutrition">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link active" href="#" data-toggle="tab" href="#home">Nutrition Facts</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#menu1">Benefits</a>
                </li>
              </ul>
              <div class="tab-content">
                <div id="home" class="tab-pane fade in active show">
                  <div class="card">
                    <div class="card-header"
                      style="text-align: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;"><img src="../assets/img/back.png" style="width: 19px;height: 16px;" (click)="back()"/>  Nutrition Facts
                    </div>
                    <div class="card-body">
                      <div class="table-responsive-sm">
                        <table class="table" style="font-family: open sans-serif" style="overflow-x: scroll;">
                          <thead>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="text-align: left;width: 20px;">Calories</td>
                              <td style="width: 10px;">:</td>
                              <td >107</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Protein</td>
                              <td>:</td>
                              <td>1.4g</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Carbs</td>
                              <td>:</td>
                              <td>19gms</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Fat</td>
                              <td>:</td>
                              <td>0g</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Dietary Fiber</td>
                              <td>:</td>
                              <td>3gms</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Vitamin C</td>
                              <td>:</td>
                              <td>76%</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Copper</td>
                              <td>:</td>
                              <td>0.137mg</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Folate</td>
                              <td>:</td>
                              <td>53mcg</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Vitamin A</td>
                              <td>:</td>
                              <td>25%</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Vitamin E</td>
                              <td>:</td>
                              <td>1.11mg</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Vitamin K</td>
                              <td>:</td>
                              <td>5.2mcg</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Niacin</td>
                              <td>:</td>
                              <td>0.828mg</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Potassium</td>
                              <td>:</td>
                              <td>257mg</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Riboflavin</td>
                              <td>:</td>
                              <td>0.047mg</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Manganese</td>
                              <td>:</td>
                              <td>0.078mg</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Thiamine</td>
                              <td>:</td>
                              <td>0.035mg</td>
                            </tr>
                            <tr>
                              <td style="text-align: left;">Magnesium</td>
                              <td>:</td>
                              <td>12mg</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="menu1" class="tab-pane fade">
                  <div class="card">
                    <div class="card-header"
                      style="text-align: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;"><img src="../assets/img/back.png" style="width: 19px;height: 16px;" (click)="back()"/>  Benefits
                    </div>
                    <div class="card-body" style="padding-top: 10px;text-align: center;">
                      <div class="table-responsive-sm">
                        <table class="table" style="font-family: open sans-serif">
                          <thead>
                          </thead>
                           <tbody>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;">Powerful Antioxidants helps boost immunity system</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;"> Abundance of Vitamin A,E and C</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;">Abundance of Fiber and Potassium, to support proper functioning of heart</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;">Higher Fiber presence helps in proper digestion</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;">Contains Antioxidants, which helps and protect the eye</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;"> Presence of Vitamin C and Vitamin A, are beneficial for healthy skincare routine</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;">Contains Carotenoid which prevents colon and skin cancer</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;">Among the most important dietary changes for reducing risk of high blood pressure</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;">Excellent sources of Potassium, Calcium and Iron</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;"> Contains Phytochemicals to reduce the risk of obesity</p></td>
                            </tr>
                            <tr>
                              <td style="width: 16px;height: 16px;"><img src="../assets/img/tick1.png"></td>
                              <td style="text-align: left;"><p style="font-family: Roboto;font-size: 12px;white-space: normal;">Anti Inflammatory properties to promote good health</p></td>
                            </tr>
                           </tbody>
                        </table>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card" *ngIf="InnerCardPictures">
              <div class="card-header" >
              
                <div style="float: left;padding-top: 10px;background-color: #f7f6f3;font-weight: bold;font-size: 16px;"><img src="../assets/img/back.png" style="width: 19px;height: 16px;" (click)="back()"/>  Trace your Mango </div>
                <!-- <div style="float: right;"><a href="{{VerifyLink}}" target="_blank" class="button"
                                  style="color: white;">Verify</a></div> -->
            </div>
              <div class="card-body">
               
                <!-- <ul class="timeline">
                  <li style="background: #d4d9df;display: inline-block; position: absolute; left: 50px;width: 2px;height: 30px;">
                  </li>
                  <li>
                    <a target="_blank" href="#" style="color:black;">Verify</a>
                    <a href="{{VerifyLink}}" target="_blank"><i class="fa fa-check-circle" style="padding-right: 20px;font-size: 24px;color: #40bf40;"></i>
                    </a>
                    <a target="_blank" href="#" style="color:black;">Harvest Date</a>
                    <a href="#" class="float-right" style="color:#ff9840">{{DynamicHarvestedDate}}</a>
                  </li>
                  <li style="background: #d4d9df;display: inline-block; position: absolute; left: 50px;width: 2px;height: 30px;">
                  </li>
                  <li>
                    <a target="_blank" href="#" style="color:black;">Verify</a>
                    <a href="{{VerifyLink}}" target="_blank"><i class="fa fa-check-circle" style="padding-right: 20px;font-size: 24px;color: #40bf40;"></i></a>
                    <a href="#" style="color:black;">Ripening Center</a>
                    <a href="#" class="float-right" style="color:#ff9840">{{DynamicShippedDate}}</a>
                  </li>
                  <li>
                    <a target="_blank" href="#" style="color:black;">Verify</a>
                    <a href="{{VerifyLink}}" target="_blank"><i class="fa fa-check-circle" style="padding-right: 20px;font-size: 24px;color: #40bf40;"></i></a>
                    <a href="#" style="color:black;">Polimeraas Store</a>
                    <a href="#" class="float-right" style="color:#ff9840">{{DynamicReachedStoreon}}</a>
                    <p style="font-family: Roboto;font-style: italic;font-size:11px;padding-left: 36px;">13, Himayat Nagar Rd, Gandhi Kuteer Colony, Narayanguda, Hyderabad, Telangana 500029</p>
                  </li>
                </ul> -->
                <div class="table-responsive-sm">
                  <table class="table" style="font-family: open sans-serif;padding: 0px !important;">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="font-style: italic;color: #40bf40;padding-right: 0px !important;padding-left: 0px !important;font-size: 14px;">Verify </td>
                        <td>
                          <a href="{{VerifyLink}}" target="_blank"><i class="fa fa-check-circle"
                            style="font-size: 24px;color: #40bf40;"></i>
                        </a>
                        </td>
                        <td>
                          <a  style="color:black;">Harvest Date</a>
                        </td>
                        <td>
                          <a  class="float-right" style="color:#ff9840">{{DynamicHarvestedDate}}</a>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding-top: 17px;"><p style="font-family: Roboto;font-weight: bold;font-size: 14px;text-align: right;color: #EA7E3D;display: none;">28/04/2021</p></td>
                        <td  style="padding-left: 17px;padding-top: 0px;padding-bottom: 0px;"><img src="../assets/img/Arrow.png" style="height: 30px;"></td>
                        <td style="padding-top: 17px;"><p style="font-family: Roboto;font-weight: bold;font-size: 14px;display: none;">Harvest Date</p></td>
                      </tr>
                      <tr>
                        <td style="font-style: italic;color: #40bf40;padding-right: 0px !important;padding-left: 0px !important;font-size: 14px;">Verify </td>
                        <td> <a href="{{VerifyLink}}" target="_blank"><i class="fa fa-check-circle" style="font-size: 24px;color: #40bf40;"></i></a></td>
                        <td> <a  style="color:black;">Ripening Center</a></td>
                        <td> <a  class="float-right" style="color:#ff9840">{{DynamicShippedDate}}</a></td>
                      </tr>
                      <tr>
                        <td style="padding-top: 17px;"><p style="font-family: Roboto;font-weight: bold;font-size: 14px;text-align: right;color: #EA7E3D;display: none;">28/04/2021</p></td>
                        <td  style="padding-left: 17px;padding-top: 0px;padding-bottom: 0px;"><img src="../assets/img/Arrow.png" style="height: 30px;"></td>
                        <td style="padding-top: 17px;"><p style="font-family: Roboto;font-weight: bold;font-size: 14px;display: none;">Harvest Date</p></td>
                      </tr>
                      <tr>
                        <td style="font-style: italic;color: #40bf40;padding-right: 0px !important;padding-left: 0px !important;font-size: 14px;">Verify </td>
                        <td><a href="{{VerifyLink}}" target="_blank"><i class="fa fa-check-circle" style="font-size: 24px;color: #40bf40;"></i></a></td>
                        <td><a  style="color:black;">Polimeraas Store</a><br><p style="font-family: Roboto;font-style: italic;font-size:11px;white-space: normal;">13, Himayat Nagar Rd, Gandhi Kuteer Colony, Narayanguda, Hyderabad, Telangana 500029</p></td>
                        <td> <a  class="float-right" style="color:#ff9840">{{DynamicReachedStoreon}}</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer" style="padding:0px !important;margin-top: 10px;" ><img src="../assets/img/trstfooter.png" style="width: 100%;height: 63px;"/></div>
        </div>
        <div id="myModal" class="modal" style="display: block !important;" *ngIf="ImageZoom">
          <span class="close" style="float: right;text-align: end;" (click)="ImageZoom=false">&times;</span>
          <img class="modal-content" src="{{ImageZooomOne}}">
        </div>
       
    </div>
    
  </section>
  
</div>
<!-- <div style="margin-top: 63px;"></div>
<div class="container-fluid" style="background-color: #F8F8F8;">
  <div class="card" style="background-color: #F8F8F8;">
    <div class="card-header" style="text-align: center;background-color: #FFE699;font-weight: bold;font-size: 20px;">Our
      hand picked Mango's are safe to eat</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="card box box-success">
            <div class="card-header"
              style="text-align: center;background-color: #f7f6f3;font-weight: bold;font-size: 16px;">Variety Details
            </div>
            <div class="card-body">
              <div class="table-responsive-sm" style="overflow-x: scroll;">
                <table class="table" style="font-family: open sans-serif" style="overflow-x: scroll;">
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Variety</td>
                      <td>:</td>
                      <td>{{DynamicVariety}}</td>
                    </tr>
                    <tr>
                      <td>Breed known for</td>
                      <td>:</td>
                      <td>Big Size, Rich Aroma, and the
                        Unique Taste make Ulavapadu Banginapalli a sought out after mango in the summer season.
                        Ulavapadu Banginapalli variety is the most famous and best-seller among all mango varieties in
                        Andhra Pradesh and Telangana. The pulp is fibreless, firm, and yellow with a sweet taste.</td>
                    </tr>
                    <tr>
                      <td>Origin of the Mango</td>
                      <td>:</td>
                      <td>Ulavapadu, Prakasam, AP</td>
                    </tr>
                    <tr>
                      <td>Harvested Date</td>
                      <td>:</td>
                      <td>{{DynamicHarvestedDate}}</td>
                    </tr>
                    <tr>
                      <td>Days ripened for</td>
                      <td>:</td>
                      <td>{{DynamicDaysripenedfor}}</td>
                    </tr>
                    <tr>
                      <td>Method of ripening</td>
                      <td>:</td>
                      <td>Govt Prescribed Natural ripening</td>
                    </tr>
                    <tr>
                      <td>Shipped Date</td>
                      <td>:</td>
                      <td>{{DynamicShippedDate}}</td>
                    </tr>
                    <tr>
                      <td>Reached Store on</td>
                      <td>:</td>
                      <td>{{DynamicReachedStoreon}}</td>
                    </tr>
                    <tr>
                      <td>Store Sale Date</td>
                      <td>:</td>
                      <td>{{DynamicStoreSaleDate}}</td>
                    </tr>
                    <tr>
                      <td>License No</td>
                      <td>:</td>
                      <td>U51909TG2020PTC138380</td>
                    </tr>
                    <tr>
                      <td>Total quality Check</td>
                      <td>:</td>
                      <td>22 quality checks</td>
                    </tr>
                    <tr>
                      <td>Batch Code</td>
                      <td>:</td>
                      <td>{{DynamicBatchCode}}</td>
                    </tr>
                    <tr>
                      <td>Best before</td>
                      <td>:</td>
                      <td>{{DynamicBestbefore}}</td>
                    </tr>
                    <tr>
                      <td>Farmer/FPO</td>
                      <td>:</td>
                      <td>{{DynamicFarmer}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card box box-success">
            <div class="card-header"
              style="text-align: center;background-color: #f7f6f3;font-weight: bold;font-size: 16px;">Farmer Details
            </div>
            <div class="card-body" style="padding-top: 10px;text-align: center;">
              <pinch-zoom><img src="{{DynamicFarmPic}}" style="width: 50%;height: 50%;" /></pinch-zoom>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px;"></div>
  </div>
  <div class="card " style="background-color: #F8F8F8;">
    <div class="card-header" style="text-align: center;background-color: #FFE699;font-weight: bold;font-size: 20px;">
      Nutrition Facts for 165gms of sliced Mango ( One Cup)</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-5">
          <div class="card box box-success">
            <div class="card-header"
              style="text-align: center;background-color: #f7f6f3;font-weight: bold;font-size: 16px;">Nutrition Details
            </div>
            <div class="card-body">
              <div class="table-responsive-sm">
                <table class="table" style="font-family: open sans-serif" style="overflow-x: scroll;">
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Calories</td>
                      <td>:</td>
                      <td>107</td>
                    </tr>
                    <tr>
                      <td>Protein</td>
                      <td>:</td>
                      <td>1.4g</td>
                    </tr>
                    <tr>
                      <td>Carbs</td>
                      <td>:</td>
                      <td>19gms</td>
                    </tr>
                    <tr>
                      <td>Fat</td>
                      <td>:</td>
                      <td>0g</td>
                    </tr>
                    <tr>
                      <td>Dietary Fiber</td>
                      <td>:</td>
                      <td>3gms</td>
                    </tr>
                    <tr>
                      <td>Vitamin C</td>
                      <td>:</td>
                      <td>76%</td>
                    </tr>
                    <tr>
                      <td>Copper</td>
                      <td>:</td>
                      <td>0.137mg</td>
                    </tr>
                    <tr>
                      <td>Folate</td>
                      <td>:</td>
                      <td>53mcg</td>
                    </tr>
                    <tr>
                      <td>Vitamin A</td>
                      <td>:</td>
                      <td>25%</td>
                    </tr>
                    <tr>
                      <td>Vitamin E</td>
                      <td>:</td>
                      <td>1.11mg</td>
                    </tr>
                    <tr>
                      <td>Vitamin K</td>
                      <td>:</td>
                      <td>5.2mcg</td>
                    </tr>
                    <tr>
                      <td>Niacin</td>
                      <td>:</td>
                      <td>0.828mg</td>
                    </tr>
                    <tr>
                      <td>Potassium</td>
                      <td>:</td>
                      <td>257mg</td>
                    </tr>
                    <tr>
                      <td>Riboflavin</td>
                      <td>:</td>
                      <td>0.047mg</td>
                    </tr>
                    <tr>
                      <td>Manganese</td>
                      <td>:</td>
                      <td>0.078mg</td>
                    </tr>
                    <tr>
                      <td>Thiamine</td>
                      <td>:</td>
                      <td>0.035mg</td>
                    </tr>
                    <tr>
                      <td>Magnecium</td>
                      <td>:</td>
                      <td>12mg</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="card box box-success">
            <div class="card-header"
              style="text-align: center;background-color: #f7f6f3;font-weight: bold;font-size: 16px;">Social Impact on Mango
            </div>
            <div class="card-body" style="padding-top: 10px;text-align: center;">
              <p style="font-size: 15px;">There are around 1100 varieties of mango grown all around the world, with the majority of the fruit coming from India. Depending upon the region and the time in which they come, mangoes are available in various colours and sizes. The production of the most popular Ratnagiri Alphonso mangoes was due to the grafting of different species of mangos. Most of Ratnagiri varieties of mangoes remain busy to match the demand. The factors on which a Ratnagiri mango can be identified, is through colour, shape, aroma and taste. The mangoes are naturally ripened mangoes have a more evenly graded green to yellow colour. Generally, it is available from Mid-April to June and each weigh around the 150-300 gms. There are nearly 15000 farmers engaged in mango production in Ratnagiri districts. Besides around 3000 from the nearby areas of Kolhapur, Sangli and Satara also take up mango production.
                Every choice we make at Polimeraas brings you the best variety of mangoes at the height of freshness, flavour & nutrition. Establishing traceability technology (on origin of Mango, location of the plantation, harvested date, ripening methods followed, quality checks and transportation to the retail outlet) for mangoes will not only help consumers to get value for their money, but also curb product falsification and mislabelling. The complete journey of a fruit from the farm to the retail shelf is made available to the consumers. This is especially relevant for all, as authenticity and source of origin are very important in ensuring the right value for producers and consumers. The true beneficiaries will be borne by the farmers, associated with Polimeraas, which would ultimately effect on a 10%+ improvement in their income.
                The believe in transparent systems and processes have made us reach to a growing number of consumers, that helps us to build trust, and facilitate better market for farmers in supplying their produce to consumers. The quality and freshness are not compromised in any way, and Polimeraas is bound to bring back the real taste of naturally ripened mangoes. 
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px;"></div>
  </div>
  <div class="card" style="background-color: #F8F8F8;">
    <div class="card-header" style="text-align: center;background-color: #FFE699;font-weight: bold;font-size: 20px;">
      Traceability Map</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body" style="overflow-x: scroll;">
              <img src="https://etgdocstorage.s3.ap-south-1.amazonaws.com/Organisation/Icons/1619527419607.jpeg" />
            </div>
          </div>
        </div>

      </div>
    </div>
    <div style="margin-top: 60px;"></div>
  </div>
</div> -->