<div class=".content" style="vertical-align: middle;padding-top: 200px;padding-left: 20px;padding-right: 20px;"
  *ngIf="hidediv">
  <div class="card col d-flex justify-content-center text-center">
    <p class="a-o-i">Enter Verification Code</p>
    <ng-otp-input #ngOtpInput [config]="config" (onInputChange)="onOtpChange($event)"></ng-otp-input><br />
    <button type="button" class="btn btn-primary" [disabled]="!verifyButton" (click)="VerifyOTP($event)">Verify</button>
  </div>
</div>
<nav class="navbar navbar-expand-md" *ngIf="hidedivForLandingpage" style="background-color: #1F3978;">
  <div class="container-fluid">
    <a href="#default" class="logo"><img [src]="Dynamicicon" style="width: 50px;height: 50px;"></a>
    <div>
      <div class="navbar-nav ml-auto">
        <span id="Blockchain">Unified Blockchain Cloud Platform </span>
      </div>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <div class="card">
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let TabData of AllForm let m=index">
        <a href="#home{{m}}" *ngIf="m ==0" class="nav-link active" data-toggle="tab" style="text-decoration: none;">{{TabData.Key}}</a>
        <a href="#home{{m}}" *ngIf="m !=0" class="nav-link" data-toggle="tab" style="text-decoration: none;">{{TabData.Key}}</a>
      </li>
    </ul>
    <div class="tab-content">
      <ng-container *ngFor="let TabData of AllForm let m=index">
        <div class="tab-pane fade show active" id="home{{m}}" *ngIf="m ==0" style="overflow-x: scroll;">

          <div class="table-responsive-sm" style="overflow-y: scroll;">

            <table class="table table-bordered" id="EventTable" style="overflow-x: scroll;">
              <ng-container *ngFor="let Data of AllItems let i=index">
                <ng-container *ngIf="Data.Form == TabData.Key">
                  <tr style="font-family: open sans-serif;text-decoration: none;">
                    <ng-container *ngIf="i == TabData.Value">
                      <ng-container *ngFor="let item of Data|  keyvalue:originalOrder">
                        <th *ngIf="'Form' !=item.key">{{item.key}}</th>
                      </ng-container>
                    </ng-container>
                  </tr>
                  <tbody>
                    <tr>
                      <ng-container *ngFor="let item of Data |  keyvalue:originalOrder">
                        <ng-container *ngIf="Data.Form !=item.value">
                          <td *ngIf="!item.value.startsWith('https://')">{{item.value}}</td>
                          <td *ngIf="item.value.startsWith('https://')"><a href="{{item.value}}" target="_blank"
                              class="button" style="color: white;text-decoration: none;">Verify</a></td>
                        </ng-container>
                      </ng-container>
                    </tr>
                  </tbody>
                </ng-container>
              </ng-container>
            </table>
          </div>

        </div>
        <div class="tab-pane fade show" id="home{{m}}" *ngIf="m !=0" style="overflow-x: scroll;">

          <div class="table-responsive-sm" style="overflow-y: scroll;">
            <table class="table table-bordered" id="EventTable" style="overflow-x: scroll;">
              <ng-container *ngFor="let Data of AllItems let i=index">
                <ng-container *ngIf="Data.Form == TabData.Key">
                  <tr style="font-family: open sans-serif">
                    <ng-container *ngIf="i == TabData.Value">
                      <ng-container *ngFor="let item of Data|  keyvalue:originalOrder">
                        <th *ngIf="'Form' !=item.key">{{item.key}}</th>
                      </ng-container>
                    </ng-container>
                  </tr>
                  <tbody>
                    <tr>
                      <ng-container *ngFor="let item of Data |  keyvalue:originalOrder">
                        <ng-container *ngIf="Data.Form !=item.value">
                          <td *ngIf="!item.value.startsWith('https://')">{{item.value}}</td>
                          <td *ngIf="item.value.startsWith('https://')"><a href="{{item.value}}" target="_blank"
                              class="button" style="color: white;text-decoration: none;">Verify</a></td>
                        </ng-container>
                      </ng-container>
                    </tr>
                  </tbody>
                </ng-container>
              </ng-container>
            </table>
          </div>

        </div>
      </ng-container>
    </div>
  
  </div>
</div>