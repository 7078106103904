<div style="margin-top: 75px;"></div>

<p>tabtest works!</p>
<!-- <mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
           <span>First 1</span>        
          </ng-template>
          <div class="d-flex">
            <h2>Test page</h2>                 
          </div>   
       </mat-tab>
    <mat-tab label="Second">Content 2</mat-tab>
    <mat-tab label="Third">Content 3</mat-tab>
  </mat-tab-group> -->
<!-- <mat-tab-group dynamicHeight>
    <mat-tab label="Short tab">
      <div class="example-small-box mat-elevation-z4">
        Small content
      </div>
    </mat-tab>
    <mat-tab label="Long tab">
      <div class="example-large-box mat-elevation-z4">
        Large content
      </div>
    </mat-tab>
  </mat-tab-group> -->
<!-- <select> -->
<div style="margin-left: 30px;">
    <form [formGroup]="form">
        <input type="checkbox" formControlName="published"> Published
        <!-- <div *ngIf="form.controls.published.value"> -->

        <h2>Credentials</h2>
        <button (click)="addCreds()">Add</button>

        <div formArrayName="credentials" *ngFor="let creds of form.controls.credentials?.value; let i = index">
            <ng-container [formGroupName]="i">
                <input placeholder="Username" formControlName="username">
                <input placeholder="Password" formControlName="password">
            </ng-container>
        </div>

        <!-- </div> -->
        <button class="btn btn-primary" (click)="onSave()">Save</button>
    </form>
</div>
<!-- </select> -->

<div class="card">
    <div class="card-body">
        <div class="box">
            <tabset class="tab">
                <tab heading="Test Test" style="max-width: 750px;" class="tabs__item"
                    style="border: #d8d6d6;border-style: solid;border-width: thin; width:100%">
                    <h2>Welcome to new Tab 1111</h2>

                </tab>
                <tab heading="Test 22" style="max-width: 750px;" class="tabs__item"
                    style="border: #d8d6d6;border-style: solid;border-width: thin; width:100%">
                    <h2>Welcome to new Tab 111</h2>

                </tab>
            </tabset>
        </div>
    </div>
</div>


 <hr>
<section>
    <div class="container-fluit">
        <div class="container">
            <button type="button" style="width: 100%;" class="form-control" class="btn btn-success"
                (click)="addFieldValue()">Add Form Fields</button>
            <div>
                <div class="box-body table-responsive">
                    <table id="datatable2" class="table table-striped table-bordered " style="width:100%">
                        <thead class="tableheader" Style="background-color: blueviolet;">
                            <tr>
                                <th></th>
                                <th style="min-width: 200px">Field Name</th>
                                <th style="min-width: 200px">Field Type</th>
                                <th style="min-width: 200px">Description</th>
                                <th style="min-width: 200px">Order</th>

                        </thead>
                        <tbody>
                            <tr *ngFor="let cv  of formfields; let i=index">
                                <td> <button class="btn btn-danger btn-sm" (click)="deleteFieldValue(i,cv)" style="margin-top: 17px;"><i
                                            class="fa fa-remove"></i></button>
                                    <button class="btn btn-primary btn-sm" (click)="selectedItem(i,cv)" style="margin-top: 17px;"><i
                                            class="fa fa-pencil"></i></button>
                                </td>
                              
                                    
                                <td>
                                    <input type="text" maxlength="50" id="FieldName{{i}}" value="{{cv.FieldName}}"
                                        style="margin-top: 17px;" class="form-control" autocomplete="off" />
                                </td>
                                <td>
                                    <input type="text" maxlength="50" id="FieldType{{i}}" value="{{cv.FieldType}}"
                                        style="margin-top: 17px;" class="form-control" autocomplete="off" />
                                </td>
                                <td>
                                    <input type="text" maxlength="50" id="Description{{i}}" value="{{cv.Description}}"
                                        style="margin-top: 17px;" class="form-control" autocomplete="off" />
                                </td>
                                <td>
                                    <input type="text" maxlength="50" id="Order{{i}}" value="{{cv.Order}}"
                                        style="margin-top: 17px;" class="form-control" autocomplete="off" />
                                </td>


                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <button type="button" style="width: 100%;" class="form-control" class="btn btn-success"
            (click)="submitformfielddetails()">Save Field</button>
        </div>
    </div>
</section> -->
<hr>
<section>
    <div class="container">
        <app-inlinetabletest></app-inlinetabletest>
    </div>
</section>