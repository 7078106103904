<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <div class="card-header" style="padding:0px !important;">
      <img src="../assets/img/PHeader.png" style="width: 100%;height: 100px;" />
  </div>
  <div class="card-body" style="padding: 0px !important;">
    <div id="divchitDetails" *ngIf="cardChitDetails" (click)="OnClickChitDetails()" style="padding-right: 15px !important;padding-left: 15px !important;margin-top: 15px;">
      <div class="card col-md-12" style="margin-bottom: 20px; box-shadow: 5px 5px 5px 5px #F8F8F8">
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif" style="margin-bottom: 0px !important;">
            <thead>
            </thead>
            <tbody>
              <tr>
                <td style="width: 80px;"><img src="../assets/img/ChitDetails.png" style="width: 60px;height: 60px;"></td>
                <td style="padding-top: 29px;text-align: left">
                  <p style="font-family: Roboto;font-weight: bold;font-size: 14px;">Chit Details</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="divpersonalDetails" *ngIf="cardPersonalDetails" (click)="OnClickPersonalDetails()" style="padding-right: 15px !important;padding-left: 15px !important;">
      <div class="card col-md-12" style="margin-bottom: 20px; box-shadow: 5px 5px 5px 5px #F8F8F8">
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif" style="margin-bottom: 0px !important;">
            <thead>
            </thead>
            <tbody>
              <tr>
                <td style="width: 80px;"><img src="../assets/img/PersonalDetails.png" style="width: 60px;height: 60px;"></td>
                <td style="padding-top: 29px;text-align: left">
                  <p style="font-family: Roboto;font-weight: bold;font-size: 14px;">Personal Details</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="divledgerDetails" *ngIf="cardLedgerDetails" (click)="OnClickLedgerDetails()" style="padding-right: 15px !important;padding-left: 15px !important;">
      <div class="card col-md-12" style="margin-bottom: 20px; box-shadow: 5px 5px 5px 5px #F8F8F8">
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif" style="margin-bottom: 0px !important;">
            <thead>
            </thead>
            <tbody>
              <tr>
                <td style="width: 80px;"><img src="../assets/img/Ledger.png" style="width: 60px;height: 60px;"></td>
                <td style="padding-top: 29px;text-align: left">
                  <p style="font-family: Roboto;font-weight: bold;font-size: 14px;">Ledger Details</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="diveventTable" *ngIf="cardEventTable" (click)="OnClickEventTable()" style="padding-right: 15px !important;padding-left: 15px !important;">
      <div class="card col-md-12" style="margin-bottom: 20px; box-shadow: 5px 5px 5px 5px #F8F8F8">
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif" style="margin-bottom: 0px !important;">
            <thead>
            </thead>
            <tbody>
              <tr>
                <td style="width: 80px;"><img src="../assets/img/EventTable.png" style="width: 60px;height: 60px;"></td>
                <td style="padding-top: 29px;text-align: left">
                  <p style="font-family: Roboto;font-weight: bold;font-size: 14px;">Event Table</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="divInnerchitDetails" *ngIf="innercardChitDetails">
      <div style="float: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;height: 40px;width: 100%;padding: 10px;">
        <img src="../assets/img/back.png" style="width: 19px;height: 16px;" (click)="back()" /> Chit Details
      </div>
     <div class="card card col-md-12"  >
        <div class="card-body" style="padding:0px !important;padding-top: 10px !important;" >
          <ng-container *ngFor="let chitdocs of ChitDocumentData let i=index">
          <div class="card" *ngIf="i==0" style="padding:0px !important;border: 1px solid #fffe54;border-radius: 10px;"> 
            <div class="card-body" style="padding:0px !important;padding-top: 10px !important;">
              <table class="table" style="font-family: open sans-serif">
                <thead>
                </thead>
                 <tbody>
                  <tr *ngFor="let docs of chitdocs">
                    <td>{{docs.Key}}</td>
                    <td>:</td>
                    <td *ngIf="docs.Key != 'Verify'">{{docs.Value}}</td>
                    <td *ngIf="docs.Key == 'Verify'"><a href="{{docs.Value}}" target="_blank"
                      class="button" style="color: white;text-decoration: none;">Verify</a></td>
                  </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </ng-container>
        </div>
      </div>
     
    </div>
    <div id="divInnerpersonalDetails" *ngIf="innercardPersonalDetails">
      <div style="float: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;height: 40px;width: 100%;padding: 10px;">
        <img src="../assets/img/back.png" style="width: 19px;height: 16px;" (click)="back()" /> Personal Details
      </div>
      <div class="card card col-md-12"  >
        <div class="card-body" style="padding:0px !important;padding-top: 10px !important;" >
          <div class="card" *ngFor="let chitdocs of PersonalDocumentData let i=index" style="padding:0px !important;border: 1px solid #fffe54;border-radius: 10px;"> 
            <div class="card-body" style="padding:0px !important;padding-top: 10px !important;">
              <table class="table" style="font-family: open sans-serif">
                <thead>
                </thead>
                 <tbody>
                  <tr *ngFor="let docs of chitdocs ">
                    <td>{{docs.Key}}</td>
                    <td>:</td>
                    <td *ngIf="docs.Key != 'Verify'">{{docs.Value}}</td>
                    <td *ngIf="docs.Key == 'Verify'"><a href="{{docs.Value}}" target="_blank"
                      class="button" style="color: white;text-decoration: none;">Verify</a></td>
                  </tr>
                  </tbody>
              </table>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div id="divInnerledgerDetails" *ngIf="innercardLedgerDetails">
      <div style="float: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;height: 40px;width: 100%;padding: 10px;">
        <img src="../assets/img/back.png" style="width: 19px;height: 16px;" (click)="back()" /> Ledger Details
      </div>
      <div class="card card col-md-12"  >
        <div class="card-body" style="padding:0px !important;padding-top: 10px !important;" >
          <div class="card"  style="padding:0px !important;"> 
            <div class="card-body" style="padding:0px !important;padding-top: 10px !important;">
              <table class="table" style="font-family: open sans-serif">
                <thead>
                </thead>
                 <tbody>
                   <ng-container *ngFor="let chitdocs of LedgerDocumentData let i=index">
                  <tr style=" border-bottom: 1px solid !important; " *ngFor="let docs of chitdocs ">
                    <!-- <td style="padding: 0px;padding-left: 5px;width: 5px;"><i class="fa fa-circle" style="font-size:10px" [ngStyle]="{'color': docs.V4}"></i></td> -->
                     <td style="padding: 0px;padding-left:10px;padding-top: 10px;"><span style="font-weight: bold;">{{docs.V1}}</span><p>{{docs.V2}}</p></td>
                     <td style="font-weight: bold;" [ngStyle]="{'color': docs.V4}"><span>{{docs.V3}}</span> </td>
                     <td style="font-weight: bold;" [ngStyle]="{'color': docs.V4}"><span>{{docs.V5}}</span> </td>
                   </tr>
                  </ng-container>
                   <!-- <tr style=" border-bottom: 1px solid !important; ">
                    <td style="padding: 0px;padding-left: 5px;width: 5px;"><i class="fa fa-circle" style="font-size:10px" [ngStyle]="{'color': 'green'}"></i></td>
                     <td style="padding: 0px;padding-left:10px;padding-top: 10px;"><span style="font-weight: bold;">12-June-2021</span><p>113070900295 june amount</p></td>
                     <td style="font-weight: bold;"><span>Rs.2000</span> </td>
                   </tr>
                   <tr style=" border-bottom: 1px solid !important; ">
                    <td style="padding: 0px;padding-left: 5px;width: 5px;"><i class="fa fa-circle" style="font-size:10px" [ngStyle]="{'color': 'green'}"></i></td>
                    <td style="padding: 0px;padding-left:10px;padding-top: 10px;"><span style="font-weight: bold;">12-July-2021</span><p>113070900296 july amount</p></td>
                    <td style="font-weight: bold;"><span>Rs.2000</span> </td>
                  </tr>
                  <tr style=" border-bottom: 1px solid !important; ">
                    <td style="padding: 0px;padding-left: 5px;width: 5px;"><i class="fa fa-circle" style="font-size:10px" [ngStyle]="{'color': 'red'}"></i></td>
                    <td style="padding: 0px;padding-left:10px;padding-top: 10px;"><span style="font-weight: bold;">12-Aug-2021</span><p>113070900297 august amount</p></td>
                    <td style="font-weight: bold;"><span>Rs.2000</span> </td>
                  </tr> -->
                  <!-- <tr *ngFor="let docs of chitdocs ">
                    <td>{{docs.Key}}</td>
                    <td>:</td>
                    <td *ngIf="docs.Key != 'Verify'">{{docs.Value}}</td>
                    <td *ngIf="docs.Key == 'Verify'"><a href="{{docs.Value}}" target="_blank"
                      class="button" style="color: white;text-decoration: none;">Verify</a></td>
                  </tr> -->
                  </tbody>
              </table>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div id="divInnerEventTable" *ngIf="innercardEventTable">
      <div class="card card col-md-12" >
        <div class="card-header" style="text-align: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;margin-top: 10px;">
          <img src="../assets/img/back.png" style="width: 19px;height: 16px;" (click)="back()" /> Event Table
        </div>
        <div class="card card col-md-12"  >
          <div class="card-body" style="padding:0px !important;padding-top: 10px !important;" >
            <div class="card" *ngFor="let chitdocs of EventTableData let i=index" style="padding:0px !important;border: 1px solid #fffe54;border-radius: 10px;"> 
              <div class="card-body" style="padding:0px !important;padding-top: 10px !important;">
                <table class="table" style="font-family: open sans-serif">
                  <thead>
                  </thead>
                   <tbody>
                    <tr *ngFor="let docs of chitdocs ">
                      <td style="white-space: nowrap;width: 100px;">{{docs.Key}}</td>
                      <td style="width: 20px;">:</td>
                      <td *ngIf="docs.Key != 'Verify'">{{docs.Value}}</td>
                      <td *ngIf="docs.Key == 'Verify'"><a href="{{docs.Value}}" target="_blank"
                        class="button" style="color: white;text-decoration: none;">Verify</a></td>
                    </tr>
                    </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer" style="padding:0px !important;margin-top: 10px;" ><img src="../assets/img/trstfooter.png" style="width: 100%;height: 63px;"/></div>
  </div>
 
</div>
