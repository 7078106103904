import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-Header',
  templateUrl: './Header.component.html',
  styleUrls: ['./Header.component.css']
})
export class HeaderComponent implements OnInit {
  user: string; // decorate the property with @Input()
  currentdate :string;
  private updateSubscription: Subscription;
  constructor() { }

 
  ngOnInit() {
    this.updateSubscription = interval(1000).subscribe(
      (val) => { localStorage.setItem("CurrentDateTime",moment().format("dddd, MMMM Do, YYYY, HH:mm:ss "));
      this.currentdate =localStorage.getItem("CurrentDateTime");
    
    })
  //   setInterval(function(){
  //     localStorage.setItem("CurrentDateTime",moment().format("dddd, MMMM Do, YYYY, HH:mm:ss "));
  // }, 3000);
    
  }

}
