<app-ribbon (click)="addedited=true"></app-ribbon>
<div class="card sec-tab">
</div>
<div class="card-body" style="background-color: whitesmoke;" [hidden]="addedited" >
  <!-- <ng-container> -->
    <div  class="row" style="margin: 20px;">
      <div class="col-md-6">
        <div class="card" style="background-color: #F8F8F8;">
        <div id="chartdiv" style="width: 100%; height: 350px"></div>
      </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="background-color:#F8F8F8">
        <div id="chartdiv1" style="width: 100%; height: 350px"></div>
      </div>
      </div>
    </div>
    <div  class="row"  style="margin: 20px;">
      <div class="col-md-6">
        <div class="card" style="background-color: #F8F8F8;">
        <div id="chartdiv2" style="width: 100%; height: 350px"></div>
      </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="background-color: #F8F8F8;">
        <div id="chartdiv3" style="width: 100%; height: 350px"></div>
      </div>
      </div>
    </div>
  <!-- </ng-container> -->


</div>

 <!-- ribbion -->
 <div *ngIf="isRibbon" class="container-fluid">
  <section class="sec-bg">
    <div class="tab-content">
      <div id="template" class="container tab-pane active"><br>
        <!-- <div class="d-flex bd-highlight mb-3">
          <div class=" bd-highlight ">
            <div class="card card1 sec-card-box mr-3">
              <button class="btn" routerLink="/industry">
                <img  src="../../assets/img/indsry-icon.png" alt="">
                <i class="fa fa-industry sec-icon" aria-hidden="true"></i>
                <div class="card-body">
                  <p class="card-text">Industry</p>
                </div>
              </button>             
            </div>
            </div>         
        </div> -->
       
      </div>
      <div id="config" class="container tab-pane fade"><br>
        
        <div class="d-flex bd-highlight mb-3">
          <div class="bd-highlight">
            <div class="card card1 sec-card-box mr-3">
              <button class="btn" routerLink="/organisation">
                <img src="../../assets/img/org-icon.png" alt="">
                <!-- <i class="fas fa-sitemap sec-icon"></i> -->
              <div class="card-body">
                <p class="card-text">Organization</p>
              </div>
              </button>              
            </div>           
          </div>
          <div class="bd-highlight">
            <div class="card card1 sec-card-box mr-3">
              <button class="btn" routerLink="/branch">
                <img src="../../assets/img/branch-icon.png" alt="">
                <!-- <i class="fas fa-code-branch sec-icon"></i> -->
              <div class="card-body">
                <p class="card-text">Branch</p>
              </div>
              </button>              
            </div>           
          </div>
          <div class="bd-highlight">
            <div class="card card1 sec-card-box mr-3">
              <button class="btn" routerLink="/role">
                <img src="../../assets/img/role-icon.png" alt="">
                <!-- <i class="fab fa-critical-role sec-icon"></i> -->
                <div class="card-body">
                  <p class="card-text">Role</p>
                </div>
              </button>             
            </div>           
          </div>
          <div class="bd-highlight">
            <div class="card card1 sec-card-box mr-3">
              <button class="btn" routerLink="/user">
                <img src="../../assets/img/user-icon.png" alt="">
                <!-- <i class="fa fa-user sec-icon" aria-hidden="true"></i> -->
                <div class="card-body">
                  <p class="card-text">User</p>
                </div>
              </button>             
            </div>           
          </div>          
        </div>
       
      </div>
      <div id="master" class="container tab-pane fade"><br>
        <div class="d-flex bd-highlight mb-3">
          <div class="p-2 bd-highlight">Master</div>         
        </div>             
      </div>
      <div id="api" class="container tab-pane fade"><br>
        <div class="d-flex bd-highlight mb-3">
          <div class="p-2 bd-highlight">Api</div>         
        </div>
             
      </div>
      <div id="blkchain" class="container tab-pane fade"><br>
        <div class="d-flex bd-highlight mb-3">
          <div class="p-2 bd-highlight">Blockchain</div>         
        </div>              
      </div>
      <div id="billing" class="container tab-pane fade"><br><div class="d-flex bd-highlight mb-3">
        <div class="p-2 bd-highlight">Billing</div>         
      </div>
             
      </div>
    </div>

    <div class="row" style="float:right;margin:15px">
      <button  routerLink="/Dashboard" (click)="reload();" class="btn-save rounded mt-2 ml-2">Back</button>
    </div>
  </section>