import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {LogModel} from '../Models/Login.model';
import { map } from 'rxjs/operators';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
 private posts:LogModel[] = [];
  //_apiurl: string = 'https://etgarage.in/api';
  _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }

  validateuser(Username,Password){
    return this._http.get<{posts:any}>(this._apiurl+'/User/GetUser/'+Username+'/'+Password+'').pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
         UserName :post.UserName,
         RoleId :post.RoleId,
         Id :post._id
        };
      })
    }));
  }


}

/*export class Login {
  Username: string;
  Password: string;
}*/
export class LoginResponse {
  Id: number;
  UserName: string;
  Token: string;
  TokenExpire: string;
  Role:number;

}
