import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiurl } from '../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class StandardizedLandingpageService {

 _apiurl: string = apiurl;
 constructor(private _http: HttpClient) { }

 getMobile(QRCode)
 {
   //return this._http.get(this._apiurl+'/Loan/GetData/'+QRCode);
   return this._http.get(this._apiurl+'/ClientReference/GetData/'+QRCode);
 }

 sendOTP(mobilenumber)
 {
   return this._http.get(this._apiurl+'/OTP/SendOTP/'+mobilenumber);
 }

 VerifyOTP(mobilenumber,OTP)
 {
   return this._http.get(this._apiurl +'/OTP/VerifyOTP/' + mobilenumber + '/'+OTP);
 }
 getBlockChainDataDetails(QRCode) {

   return this._http.get(this._apiurl+'/Document/getDocument/' + QRCode);

 }
 getOrgById(Id:any){
  return this._http.get<{posts:any}>(this._apiurl+'/Organisation/GetOrganisation/'+Id).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
            Id:post._id,
            Name: post.OrganisationName,
            Code: post.OrganisationCode,
            Address: post.Address,
            Building: post.Building,
            Location: post.Location,
            PIN: post.PIN,
            City: post.City,
            State: post.State,
            Industry: post.Industry,
            Category: post.Category,
            ContactPerson:post.ContactPerson,
            PrimaryContact: post.PrimaryContact,
            ContactNumber: post.PrimaryContact,
            Email: post.Email,
            GST: post.GST,
            HeadOfficeCode: post.HeadOfficeCode,
            CreatedBy:post.CreatedBy,
            UpdatedBy: post.UpdatedBy,
            URLName:post.URLName,
            Desc:post.Desc,
            Icon:post.Icon
        };
      })
    }));

}
}
