import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protractor } from 'protractor/built/ptor';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }

  base64ToPDF(base64:any)
  {
    return this._http.post(this._apiurl+'/Document/base64toPDF',base64);
  }

}
