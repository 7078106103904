import { Component, OnInit } from '@angular/core';
import { AutocompleteService } from '../service/autocomplete.service';
import { TemplateService } from '../service/template.service';

@Component({
  selector: 'app-tem',
  templateUrl: './tem.component.html',
  styleUrls: ['./tem.component.css']
})
export class TemComponent implements OnInit {

  constructor(private _autocompleteService:AutocompleteService,
    private _Template:TemplateService
    ) { }

  ngOnInit() {
    this.getSearchBoxData();
   // this.getTemplateByTemplateName('');
  }
  SearchReactive:[];
  public placeholder: string = 'Search Template';
public keyword = 'name';
public historyHeading: string = 'Recently selected';
  getSearchBoxData(){
    this._autocompleteService.getTemplateSearchDetails().subscribe(
      data =>{
        console.log(data);
        this.SearchReactive=data;
      }
    )
  }

text:any='';
selectedString='';
  onKeyUp(x) { // appending the updated value to the variable 
    console.log(x.target.value);
    this.text += x.target.value + ' | '; 
  this.selectedString=(x.target.value).toString();
    this.getTemplateByTemplateName(this.selectedString);
  } 

  filterdata:any=0;
  getTemplateByTemplateName(tempName){
    this._Template.getTemplateByTemplateName(tempName)
    .subscribe(data=>{
     // console.log(data);
      this.filterdata=data;
      console.log(this.filterdata)
    })
  }
}
