<div class="portal-form" style="margin-top:70px;margin-bottom:70px;margin-left: -0px;margin-right: -0px;">
    <section> 
  <div class="container  tab-content"> 
        <div class="card">
          <div class="card-header h4-style "> 
           
            <div class="row">
              <div class="col-sm-10">
                <h3 >Branch</h3>
              </div>
               <div class="col-sm-2">
                <button class="btn pull-right addnew" (click)="refresh();onClearAll();addedited=false;this.isSave=false;" *ngIf="addedited" title="Back to Branch">
                <i style="color: #E6800E;" class="fa fa-times"></i>
              </button>
              <button class="btn pull-right addnew" style="color: #E6800E ;font-size: 16px;font-family: open sans-serif;" *ngIf="!addedited" title="Create New Branch" (click)="addedited=true;">
                <i style="color: #E6800E;" class="fa fa-plus"></i> Create New
              </button>
              </div>
              </div>
          </div>
          <div class="card-body" *ngIf="!addedited" style="min-height: auto;" style="height:482px">
            <div class="box">
              <div style="overflow-x: auto;margin-top: 0px;">
                <table id="customers"  style="width:100%;white-space: nowrap;overflow: hidden;font-family: open sans-serif; color:#777777;font-size: 16px;">
                  <thead class="thead">
                    <tr>
                      <th></th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Organisation</th>
                      <th>Contact Person</th>
                      <th>Contact Number</th>
                      <th>Email</th>
                
                   
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of BranchList; let i=index;">
                      <td>
                        <i  class="fa fa-edit" (click)="getBranchbyid(data.Id,data)">
                        </i>
                      </td>
                      <td>{{i+1}}</td>
                      <td>{{data.Name}}</td>
                      <td>{{data.Code}}</td>
                      <td>{{data.Organisation}}</td>
                      <td>{{data.ContactPerson}}</td>
                      <td>{{data.ContactNumber}}</td>
                      <td>{{data.Email}}</td>
                  
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-body" *ngIf="addedited" style="height: 482px;">
         <form [formGroup]="BranchForm" autocomplete="off" >
          <!-- <div class="row">
            <div class="col-sm-12">
                <div class="card-header h4-style"> <h3>Branch View</h3></div>
                 </div>
            </div> -->
            <div class="row">
              <div class="col-sm-6">                
                <div class="card-body">
                    <h5 class="card-title">Branch Details</h5>                                    
                    <div class="form-group card-text pb-1 row">        
                        <label for="usr"  class="col-sm-12 col-lg-4  col-form-label text-right">
                          Branch Name<sup style="color: #e5800f; font-size: 14px;">*</sup>:</label>
                        <div class="col-sm-12 col-lg-8 border p-1">
                          <!-- <div *ngIf="Name.invalid && (Name.dirty || Name.touched)" class="alert alert-danger">
                      
                            <div *ngIf="Name.errors.required">
                              Branch  Name is required
                            </div>
                          </div> -->
                          <input type="text" class="form-control" id="Name" formControlName="Name" placeholder="Enter Branch Name" autocomplete="false" required >
                            <!-- <div class="validator"
                          *ngIf="BranchForm.controls['Name'].touched && !BranchForm.controls['Name'].valid">
                          <span style= "color:red" *ngIf="BranchForm.controls['Name'].hasError('required') ">
                              Please Enter Branch Name 
                          </span>
                      </div> -->
                     
                        </div>
                       
                      </div>                                    
                </div>   
                            
              </div>
              <div class="col-sm-6">
                   <div class="card-body"> 
          <h5 class="card-title">&nbsp;</h5>
           <div class="form-group pb-1 card-text row">        
                    <label for="usr"  class="col-sm-12 col-lg-4 col-form-label text-right">
                      Branch Code<sup style="color: #e5800f;  font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <!-- <div *ngIf="Code.invalid && (Code.dirty || Code.touched)" class="alert alert-danger">
                      
                        <div *ngIf="Code.errors.required">
                          Code is required
                        </div>
                      </div> -->
                    <input type="text" class="form-control" id="usr" formControlName="Code" placeholder="Enter Branch Code" autocomplete="false">
                    <!-- <div class="validator"
                    *ngIf="BranchForm.controls['Code'].touched && !BranchForm.controls['Code'].valid">
                    <span style= "color:red" *ngIf="BranchForm.controls['Code'].hasError('required') ">
                        Please Enter Branch Code 
                    </span>
                </div> -->
                  </div> 
     
                  </div> 
                  </div>
               </div>
            </div>
      <!--2nd-row-->
      <div class="row">
        <div class="col-sm-6">
          <!-- <div class="card"> -->
            <div class="card-body">                                                
                  <div class="form-group pb-1 card-text row">
                    <label for="usr"  class="col-sm-12 col-lg-4 col-form-label text-right">
                        Organization :</label>
                    <!-- <div class="col-sm-12 col-lg-8 border p-1"> -->
                    <!-- <input type="text" class="form-control" id="usr" formControlName="Organisation" placeholder="Enter Organization"> -->
                    <!-- <select class="form-control pl-2" id="OrganisationId" formControlName="OrganisationId" name="Select">
                      <option disabled>Select Organisation</option>
                      <option *ngFor="let olt of OrgList" [ngValue]="olt.Id">
                        {{ olt.Name }}
                      </option>
                  </select> -->
                  <div class="ng-autocomplete col-sm-12 col-lg-8 border p-1">
                    <ng-autocomplete #ngAutoCompleteReactive
                                     [data]="countriesReactive"
                                     formControlName="name"
                                     [initialValue]=""
                                     [placeholder]="placeholder"
                                     [searchKeyword]="keyword"
                                     historyIdentifier="countriesReactiveList"
                                     [historyHeading]="historyHeading"
                                     historyListMaxNumber="3"
                                     notFoundText="Not found"
                                     [itemTemplate]="itemTemplateReactive"
                                     [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
          
                    <ng-template #itemTemplateReactive let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>
          
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                  <!-- </div> -->
                  </div>                             
            </div>
          <!-- </div> -->
        </div>
        <div class="col-sm-6">
             <div class="card-body"> 
                                 
            </div>
         </div>
      </div>
      <!--2nd-col--->
      <div class="row">
        <div class="col-sm-6">
         
        </div>
        <div class="col-sm-6">
             <div class="card-body">                           
                                                    
            </div>
         </div>
      </div>
      <!--3rd-row-->
      
      <!---4th-row-->
      <div class="row">
        <div class="col-sm-6">
          <!-- <div class="card"> -->
            <div class="card-body">
                <h5 class="card-title">Contact Details</h5>                                
                <div class="form-group pb-1 card-text row">        
                    <label for="usr"  class="col-sm-12 col-lg-4 text-right col-form-label">
                        Contact Person<sup style="color: #e5800f;  font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                    <input type="text" class="form-control" id="usr" formControlName="ContactPerson" placeholder="Enter Contact Person" name="phone" autocomplete="false" required>  
                    </div> 
                  </div> 
                               
            </div>
          <!-- </div> -->
        </div>
        <div class="col-sm-6">
             <div class="card-body"> 
                <h5>&nbsp;</h5>                           
                <div class="form-group pb-1 card-text row">
                    <label for="usr"  class="col-sm-12 col-lg-4 text-right col-form-label">
                        Contact Number<sup style="color: #e5800f;  font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <input type="text" class="form-control" (keypress)="validateNumber($event);" id="ContactNumber" minlength="10" maxlength="10" formControlName="ContactNumber" placeholder="Enter Contact Number" name="phone" autocomplete="false" required> 
                      </div> 
                  </div>                                     
            </div>
         </div>
      </div>
      <!--2nd-col--->
      <div class="row">
        <div class="col-sm-6">
          <!-- <div class="card"> -->
            <div class="card-body">
                                           
                <div class="form-group pb-1 card-text row">
                    <label for="email"  class="col-sm-12 col-lg-4 text-right col-form-label">Email :</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                    <input type="email" class="form-control" id="email" pattern="^[^ ]+@[^ ]+\.[a-z]{2,6}$" formControlName="Email" placeholder="Enter Email" autocomplete="false" name="email">
                    
                  </div> 
                  </div> 
                            
            </div>
          <!-- </div> -->
        </div>
        <div class="col-sm-6">
             <div class="card-body"> 
                                        
                                           
            </div>
         </div>
      </div>
      <!--5th-row-->
     
      
      
      <!--form-end-->
      <div class="d-flex justify-content-end mt-4 mb-4 card-body">
        <button type="save" (click)="onClearAll()"  class="btn btn-clear mr-3">Clear All</button>
        <button type="save" (click)="onSave()" *ngIf=" isSave" class="btn btn-save">Save</button>
        <button type="save" (click)="onUpdate(BranchForm.value);"  *ngIf="isUpdate" class="btn btn-save">Update</button>
   </div>
   
     
         </form>
          </div>
     </div>  
     <div class="row pull-right" style="float:right;margin:15px">
      <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
      </div>
  </div>
  <div id="snackbar"></div>
  <div id="snackbarerror"></div>
    </section>  
   </div>