import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protractor } from 'protractor/built/ptor';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {
 // _apiurl: string = 'https://etgarage.in/api';
 _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }
  getCategoryDtl(){
    return this._http.get<{posts:any}>(this._apiurl+'/Category/getCategory').pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          CategoryName :post.CategoryName,
          IndustryId : post.IndustryId,
          Description : post.Description,
          CatIcon : post.CatIcon,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy
        };
      })
    }));
  }
  getCategorySearch(CategoryName:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Category/getCategorySearch/'+CategoryName).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            Id : post._id,
            CategoryName :post.CategoryName,
            IndustryId : post.IndustryId,
            Description : post.Description,
            CatIcon : post.CatIcon,
            CreatedBy : post.CreatedBy,
            UpdatedBy : post.UpdatedBy
          };
        })
      }));
  
  }
  getCategoryById(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Category/getCategoryId/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            Id : post._id,
            CategoryName :post.CategoryName,
            IndustryId : post.IndustryId,
            Description : post.Description,
            CatIcon : post.CatIcon,
            CreatedBy : post.CreatedBy,
            UpdatedBy : post.UpdatedBy
          };
        })
      }));

}

getCategoryByIndustryId(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Category/getCategoryByIndustryId/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            Id : post._id,
            CategoryName :post.CategoryName,
            IndustryId : post.IndustryId,
            Description : post.Description,
            CatIcon : post.CatIcon,
            CreatedBy : post.CreatedBy,
            UpdatedBy : post.UpdatedBy
          };
        })
      }));

}

checkDuplicateCategory(Name)
{
  return this._http.get(this._apiurl+'/Category/CheckDuplicateCategoryByName/'+Name);
}

getAPI(templateid,formids)
{
  return this._http.get(this._apiurl+'/FormFields/createDynamicFormFields/' + templateid + '/'+formids);
}


  createCategory(Category: any) {
    return this._http.post(this._apiurl+'/Category/createCategory', Category);
  }

  UpdateCategory(Category: any) {
    return this._http.put(this._apiurl+'/Category/updateCategory', Category);
  }

  CoWINMobileSentOTP(MobileNo: any) {
    const options = {
      headers: new HttpHeaders({
        'x-api-key': `f8wSpl1u3n4q1syRTD5ky7SrZhC5TwuD88o8SNss`
      })
    };
    return this._http.post('https://cdn-api.co-vin.in/api/v2/auth/generateOTP', MobileNo,options);
  }

  CoWINConfirmOTP(MobileNo: any) {
    const options = {
      headers: new HttpHeaders({
        'x-api-key': `f8wSpl1u3n4q1syRTD5ky7SrZhC5TwuD88o8SNss`
      })
    };
    return this._http.post('https://cdn-api.co-vin.in/api/v2/auth/confirmOTP', MobileNo,options);
  }

 CoWINBeneficiaryList(TokenNo)
 {
  const options = {
    headers: new HttpHeaders({
      'Authorization': `Bearer ${TokenNo}`
    })
  };
  return this._http.get('https://cdn-api.co-vin.in/api/v2/appointment/beneficiaries', options)
 }

//  CoWINGetDoc(TokenNo,BeneficiaryNo)
//  {
//   const headers = {
//     responseType: 'blob' as 'arraybuffer',
//     headers: new HttpHeaders({
//       'Authorization': `Bearer ${TokenNo}`
//     })
//   };
//   return this._http.get('https://cdn-api.co-vin.in/api/v2/registration/certificate/public/download?beneficiary_reference_id='+BeneficiaryNo+'', headers)
//  }

//  UploadPDF(base64)
// {
//   //return this._http.post(this._apiurl+'/PDFExtract/GetPDFExtractData' ,base64);
//   return this._http.post('http://localhost:4000/PDFExtract/uploadPDFfile', base64);
// }
// ExtractPDFData()
// {
//   return this._http.get('http://localhost:4000/PDFExtract/getPDFextractdata');
// }

}