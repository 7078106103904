import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CowinService } from '../service/cowin.service';
import { sha256 } from 'js-sha256';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from '../service/utility.service';
import { ElementRef } from '@angular/core';
@Component({
  selector: 'app-CoWIN',
  templateUrl: './CoWIN.component.html',
  styleUrls: ['./CoWIN.component.scss'],
})
export class CoWINComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  divMobileNo: boolean = true;
  divOTP: boolean = false;
  divbeneficiary: boolean = false;
  divbeneficiarylist:boolean = false;
  divframe:boolean = false;
  pdfurl:any;
  maskmobile:any;
  ispdfShow:boolean=false;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '',
  };
  //@ViewChild('myInput') myInputVariable: ElementRef;
  buttonhide:boolean = true;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  constructor(
     private hostElement: ElementRef,
    private _formBuilder: FormBuilder,
    private _http: HttpClient,
    private _cowin: CowinService,
    private toastr: ToastrService,
    private domSanitizer: DomSanitizer,
    private _utilities:UtilitiesService
  ) {
    this.ispdfShow=false;
  }
  bdata : any;
//   bdata={
//     "beneficiaries": [
//         {
//             "beneficiary_reference_id": "35211189458820",
//             "name": "Vaddimgunta Padmavathi",
//             "birth_year": "1970",
//             "gender": "Female",
//             "mobile_number": "7372",
//             "photo_id_type": "PAN Card",
//             "photo_id_number": "XXXXXX742P",
//             "comorbidity_ind": "N",
//             "vaccination_status": "Vaccinated",
//             "vaccine": "COVAXIN",
//             "dose1_date": "",
//             "dose2_date": "15-06-2021",
//             "appointments": [
//                 {
//                     "appointment_id": "025cb07c-7e5f-4200-a561-fad410032eb6",
//                     "center_id": 601874,
//                     "name": "Narayana Hospital - CVC",
//                     "state_name": "Andhra Pradesh",
//                     "district_name": "Sri Potti Sriramulu Nellore",
//                     "block_name": "Nellore",
//                     "from": "09:00",
//                     "to": "16:00",
//                     "dose": 2,
//                     "session_id": "d618b659-c816-440c-89c0-80b58d77aeb4",
//                     "date": "15-06-2021",
//                     "slot": "09:00AM-11:00AM"
//                 },
//                 {
//                     "appointment_id": "840bb929-74a3-43bd-8206-a8ed981584a5",
//                     "center_id": 601874,
//                     "name": "Narayana Hospital - CVC",
//                     "state_name": "Andhra Pradesh",
//                     "district_name": "Sri Potti Sriramulu Nellore",
//                     "block_name": "Nellore",
//                     "from": "09:00",
//                     "to": "18:00",
//                     "dose": 1,
//                     "session_id": "de0e4236-57ec-4e90-830f-0c9913076902",
//                     "date": "17-05-2021",
//                     "slot": "09:00AM-11:00AM"
//                 }
//             ]
//         },
//         {
//             "beneficiary_reference_id": "59898619414130",
//             "name": "Venkata Praveen Kumar Vaddimgunta",
//             "birth_year": "1993",
//             "gender": "Male",
//             "mobile_number": "7372",
//             "photo_id_type": "PAN Card",
//             "photo_id_number": "XXXXXX582Q",
//             "comorbidity_ind": "N",
//             "vaccination_status": "Partially Vaccinated",
//             "vaccine": "COVAXIN",
//             "dose1_date": "15-06-2021",
//             "dose2_date": "",
//             "appointments": [
//                 {
//                     "appointment_id": "bbc66617-dbb7-4d14-bb63-5f05695f1415",
//                     "center_id": 601874,
//                     "name": "Narayana Hospital - CVC",
//                     "state_name": "Andhra Pradesh",
//                     "district_name": "Sri Potti Sriramulu Nellore",
//                     "block_name": "Nellore",
//                     "from": "09:00",
//                     "to": "16:00",
//                     "dose": 1,
//                     "session_id": "f1957d86-bd18-40ca-ae2c-cb3b884d4f95",
//                     "date": "15-06-2021",
//                     "slot": "09:00AM-11:00AM"
//                 }
//             ]
//         }
//     ]
// }
iframe:any
noofBdata=[];
noofBenefisary=[];
  ngOnInit() {
    this.iframe = this.hostElement.nativeElement.querySelector('iframe');
    //this.getPdfData();
    this.firstFormGroup = this._formBuilder.group({
      MobileNo: ['', Validators.required],
      txnId: [''],
    });

    this.secondFormGroup = this._formBuilder.group({
      BeneficiaryNo: ['', Validators.required],
      token:['']
    });
  }
onPDFShow(){
  this.ispdfShow=true;
}

  Submit() {
    var trailingCharsIntactCount = 4;
    this.maskmobile = new Array(this.firstFormGroup.get('MobileNo').value.length - trailingCharsIntactCount + 1).join('X') + this.firstFormGroup.get('MobileNo').value.slice( -trailingCharsIntactCount);
    var data = {
      mobile: this.firstFormGroup.get('MobileNo').value,
    };

    this.divMobileNo = false;
    this.divOTP = true;
    this.divbeneficiary =false;
    this.divframe =false

    this._cowin.CoWINMobileSentOTP(data).subscribe((data) => {
      console.log(data)
      this.divMobileNo = false;
      this.divOTP = true;
      this.divbeneficiary =false;
      this.divframe =false
      console.log(data['txnId'])
      this.firstFormGroup.patchValue({
        txnId: data['txnId'],
      });
    });
  }

  SubmitOTP() {
    let otpvalue =
      this.ngOtpInputRef.otpForm.value.ctrl_0 +
      this.ngOtpInputRef.otpForm.value.ctrl_1 +
      this.ngOtpInputRef.otpForm.value.ctrl_2 +
      this.ngOtpInputRef.otpForm.value.ctrl_3 +
      this.ngOtpInputRef.otpForm.value.ctrl_4 +
      this.ngOtpInputRef.otpForm.value.ctrl_5;
    console.log(otpvalue);
    var hash = sha256(otpvalue);

    var data = {
      otp: hash,
      txnId: this.firstFormGroup.get('txnId').value,
    };

  

      this._cowin.CoWINConfirmOTP(data).subscribe((data) => {
      console.log(data);
      this.divMobileNo = false;
      this.divOTP = false;
      this.divbeneficiarylist = true;
      this.divframe =false
      console.log(data['token'])
      this.secondFormGroup.patchValue({
        token: data['token'],
      });

      this._cowin.CoWINBeneficiaryList(this.secondFormGroup.get('token').value).subscribe((result:any) =>
        {
              console.log(result)
              
              this.bdata = result
if(this.bdata.beneficiaries.length != 0)
{
  this.blist = [];
  for(let b=0;b<this.bdata.beneficiaries.length;b++){
  
    this.buttonhide = false;
   let beneficiaryId =  this.bdata.beneficiaries[b].beneficiary_reference_id;
   let Name =  this.bdata.beneficiaries[b].name;
   let Staus =  this.bdata.beneficiaries[b].vaccination_status;
   let birth =  this.bdata.beneficiaries[b].birth_year;
   let color = '';
   let dose1 = '';
   let dose2 = '';
   let place = '';
    if(Staus === 'Vaccinated')
    {
     color = '#41ad49';
    }
    else if(Staus === 'Partially Vaccinated') {
     color = '#ff9840';
    }
    else if(Staus === 'Not Vaccinated') {
     color = '#e20000';
    }
    for(let i=0;i<this.bdata.beneficiaries[b].appointments.length;i++)
    {
     if(this.bdata.beneficiaries[b].appointments[i].dose == 2) 
     {
           dose2 = this.bdata.beneficiaries[b].appointments[i].date;
     }
     else
     {
          dose1 = this.bdata.beneficiaries[b].appointments[i].date;
          place = this.bdata.beneficiaries[b].appointments[i].name + ' '+this.bdata.beneficiaries[b].appointments[i].district_name;
     }
         
     if(this.bdata.beneficiaries[b].appointments.length - 1 == i)
     {
       let obj = {V1:Name,V2:beneficiaryId,V3:birth,V4:Staus,V5:this.bdata.beneficiaries[b].vaccine,V6:color,
         V7:dose1,V8:dose2,V9:place};
       this.blist.push(obj);
       if(this.bdata.beneficiaries.length - 1 == b)
       {
        
        this.blistany = this.blist;
        console.log(this.blistany)
       }
     }
    }
    if(this.bdata.beneficiaries[b].appointments.length == 0)
    {
     if(Staus === 'Vaccinated')
     {
      color = '#41ad49';
     }
     else if(Staus === 'Partially Vaccinated') {
      color = '#ff9840';
     }
     else if(Staus === 'Not Vaccinated') {
      color = '#e20000';
     }
      this.buttonhide = false;
     let obj = {V1:Name,V2:beneficiaryId,V3:birth,V4:Staus,V5:this.bdata.beneficiaries[b].vaccine,V6:color,
       V7:this.bdata.beneficiaries[b].dose1_date,V8:this.bdata.beneficiaries[b].dose2_date,V9:''};
     this.blist.push(obj);
     this.blistany = this.blist;
    }
  }
}
else{
    this.toastr.error("this mobile number is not registered for vaccination.");
     setTimeout(()=>{                           
      window.location.href = 'https://selfregistration.cowin.gov.in/';
  }, 1000);
}
        
        });
    });




    // this.divMobileNo = false;
    // this.divOTP = false;
    // this.divframe =false
    // this.divbeneficiarylist = true;
    // this.divMobileNo = false;
    //  this.divOTP = false;
    //  this.divbeneficiary =false;
    //  this.divframe =false
  //  this.blist = [];
  //  for(let b=0;b<this.bdata.beneficiaries.length;b++){
  //    debugger;
  //   let beneficiaryId =  this.bdata.beneficiaries[b].beneficiary_reference_id;
  //   let Name =  this.bdata.beneficiaries[b].name;
  //   let Staus =  this.bdata.beneficiaries[b].vaccination_status;
  //   let birth =  this.bdata.beneficiaries[b].birth_year;
  //   let color = '';
  //   let dose1 = '';
  //   let dose2 = '';
  //   let place = '';
  //    if(Staus === 'Vaccinated')
  //    {
  //     color = '#41ad49';
  //    }
  //    else if(Staus === 'Partially Vaccinated') {
  //     color = '#ff9840';
  //    }
  //    else if(Staus === 'Not Vaccinated') {
  //     color = '#e20000';
  //    }
  //    for(let i=0;i<this.bdata.beneficiaries[b].appointments.length;i++)
  //    {

  //     if(this.bdata.beneficiaries[b].appointments[i].dose == 2) 
  //     {
  //           dose2 = this.bdata.beneficiaries[b].appointments[i].date;
  //     }
  //     else
  //     {
  //          dose1 = this.bdata.beneficiaries[b].appointments[i].date;
  //          place = this.bdata.beneficiaries[b].appointments[i].name + ' '+this.bdata.beneficiaries[b].appointments[i].district_name;
  //     }
          
  //     if(this.bdata.beneficiaries[b].appointments.length - 1 == i)
  //     {
  //       let obj = {V1:Name,V2:beneficiaryId,V3:birth,V4:Staus,V5:this.bdata.beneficiaries[b].vaccine,V6:color,
  //         V7:dose1,V8:dose2,V9:place};
  //       this.blist.push(obj);
  //       if(this.bdata.beneficiaries.length - 1 == b)
  //       {
  //        this.blistany = this.blist;
  //       }
  //     }
  //    }
     
    
  //  }
    // this._cowin.CoWINConfirmOTP(data).subscribe((data) => {
    //   console.log(data);
    //   this.divMobileNo = false;
    //   this.divOTP = false;
    //   this.divbeneficiary =true;
    //   this.divframe =false
    //   console.log(data['token'])
    //   this.secondFormGroup.patchValue({
    //     token: data['token'],
    //   });
    // });
  }
 
BeneficiaryName:any;
age:any;
gender:any;
IDVerified:any;
UniqueHealthID:any;
BeneficiaryReferenceID:any;
VaccineName:any;
DateofDose:any;
Nextduedate:any;
Vaccinatedby:any;
Vaccinationat:any;
blist = [];
blistany :any;
SubmitBeneficiary()
{
  
    // this._cowin.CoWINBeneficiaryList(this.secondFormGroup.get('token').value).subscribe(result =>
    // {
    //       console.log(result)
    // });
}

blobToBase64(file)
{
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise(resolve => {
    reader.onloadend = () => {
      this.UploadPDF(reader.result)
      resolve(reader.result);

    };
  });
}

beneficiaryID:any;
beneficiaryName:any;
birthday:any;
bgender:any;
photo_id_type:any;
photo_id_number:any;
vaccination:any;
DetailView(Value)
{

  console.log(Value)
  this._cowin.CoWINGetDoc(this.secondFormGroup.get('token').value,Value).subscribe((data:any) => {
  console.log(data)
  this.divbeneficiarylist = false;
  this.divMobileNo = false;
  this.divOTP = false;
  
       var file = new Blob([data], {type: 'application/pdf'});
        console.log(file)
        var fileURL = URL.createObjectURL(file);
        console.log(fileURL)
        this.blobToBase64(file)
        //this.pdfurl = this.domSanitizer.bypassSecurityTrustResourceUrl(fileURL)
        // this.iframe.src = this.pdfurl;
  });
  // this.divbeneficiary = true;
  // this.divbeneficiarylist = false;
  // this.divMobileNo = false;
  // this.divOTP = false;
  // console.log(Value)
  // for(let b=0;b<this.bdata.beneficiaries.length;b++){

  //    if(this.bdata.beneficiaries[b].beneficiary_reference_id === Value)
  //    {
  //           this.beneficiaryID = this.bdata.beneficiaries[b].beneficiary_reference_id;
  //           this.beneficiaryName = this.bdata.beneficiaries[b].name;
  //           this.birthday = this.bdata.beneficiaries[b].birth_year; 
  //           this.bgender = this.bdata.beneficiaries[b].gender;
  //           this.photo_id_type = this.bdata.beneficiaries[b].photo_id_type;
  //           this.photo_id_number = this.bdata.beneficiaries[b].photo_id_number;
  //           this.vaccination = this.bdata.beneficiaries[b].vaccine;
  //    }
  // }
}
Certificate(Value)
{
  console.log(Value)
  this._cowin.CoWINGetDoc(this.secondFormGroup.get('token').value,Value).subscribe((data:any) => {
         var file = new Blob([data], {type: 'application/pdf'});
          console.log(file)
          let url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'cowincertificate';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    });

}
back()
{
  this.divbeneficiary = false;
  this.divbeneficiarylist = true;
  this.divMobileNo = false;
  this.divOTP = false;
  this.divframe =false;
}
UploadPDF(base64)
{

  var json_arr = {};
  json_arr['base64'] = base64;
  this._cowin.UploadPDF(json_arr).subscribe((data:any) =>{
      console.log(data)
      this.divframe =true;
      var img = 'https://docs.google.com/viewerng/viewer?url='+data+'&embedded=true'
      this.pdfurl = this.domSanitizer.bypassSecurityTrustResourceUrl(img)
  })
}


// ExtractPDFData()
// {
//   return this._http.get('http://localhost:4000/PDFExtract/getPDFextractdata');
// }
//===========================
// BeneficiaryName:any='ASDFG ASDFG SDFG'
// age:any=25;
// gender:any='Male'
// IDVerified:any='Aadhaar # XXXXXXXX1872'
// UniqueHealthID:any=''
// BeneficiaryReferenceID:any='19375725110100'

// VaccineName:any="Test Test"
// DateofDose:any="27 May 2021 (Batch no. 37F21047A)"
// Nextduedate :any='Between 24 Jun 2021 and 08 Jul 2021'
// Vaccinatedby : any='Sandhya Rani'
// Vaccinationat :any='Narayana Hospital - CVC, Sri Potti'

validateNumber(e){
  this._utilities.validateMobileNumber(e);
}
}
