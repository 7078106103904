import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AutocompleteService } from '../service/autocomplete.service';
import { CategoryService } from '../service/category.service';
import { FormService } from '../service/form.service';
import { FormfieldService } from '../service/formfield.service';
import { IndustryService } from '../service/industry.service';
import { TemplateService } from '../service/template.service';
import { UtilitiesService } from '../service/utility.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css'],
})
export class TemplateComponent implements OnInit {
  isRibbon:boolean=false;
  templateForm: FormGroup;
  addedit: boolean = false;
  // templateForm: FormGroup;
  addedited: boolean = false;
  isUpdate: boolean = false;
  isSave: boolean = true;
  isDefault:boolean = false;
  isPublish:boolean = false;
  title = 'branch';
  mySubscription;
  IndustryId: any;
  SelectedCategoryName: any;
  firstFormGroup: FormGroup;
  firstFormGroupCopy: FormGroup;
  isCopy:boolean= true;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  forthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  Formfield: FormGroup;
  form1: FormGroup;
  isEditable = true;
  isDefaultTemplate: boolean = false;
  constructor(
    private _formbuilder: FormBuilder,
    private _router: Router,
    private _autocompleteService: AutocompleteService,
    private _industry: IndustryService,
    private route: ActivatedRoute,
    private _Category: CategoryService,
    private _formBuilder: FormBuilder,
    private _utility: UtilitiesService,
    private _Template: TemplateService,
    private _form: FormService,
    private _formfield: FormfieldService,
    private toastr: ToastrService
  ) {
    this.createForm();
    this.isRibbon=false;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this._router.navigated = false;
      }
    });
    this.isDefaultTemplate = true;
  }
  ngOnDestroy() {
    console.log('app ngOnDestroy');
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  createForm() {
    this.templateForm = this._formbuilder.group({
      Id: [''],
      TemplateName: ['', Validators.required],
      NumberOfTemplate: ['', Validators.required],
      Category: ['', Validators.required],
      Payload: ['', Validators.required],
      CreatedBy: [1],
      UpdatedBy: [1],
    });
  }
  selectedTempId: Number;
  GetTemplateDataByName(TempId,Name) {
    console.log(TempId);
    console.log(this.isCopy)
    this.selectedTempId = TempId;
this._Template.getTemplateById(TempId).subscribe(data=>{
    console.log(data[0].IsPublish)
    this.isPublish =data[0].IsPublish;
});
   this.getFormData(TempId,Name);
    this.getTempDetails(TempId);
  }
  CategoryName: any;
  IndId: any;
  Industry: any;
  ngOnInit() {
    this.getRoleDtl();
    //this.getFormData();
    //this.getFormFeildData();
    this.CategoryName = this.route.snapshot.paramMap.get('CategoryName');
    this.IndustryId = this.route.snapshot.paramMap.get('IndustryId');
    this.SelectedCategoryName = this.route.snapshot.paramMap.get(
      'SelectedCategoryName'
    );

    console.log(this.CategoryName);
    this.IndId = this.route.snapshot.paramMap.get('Id');
    // console.log('/template/'+this.CategoryName+'/'+this.IndId+'/'+this.CategoryName+'')
    console.log(this.IndId);
    this.Industry = this.route.snapshot.paramMap.get('Industry');
    console.log(this.Industry);
    //var categ=this.CategoryName.toString();
    this.getCategoryByIndustryId(this.IndId);
    this.getTemplateByCategory(this.CategoryName);
    //
    this.firstFormGroup = this._formBuilder.group({
      TemplateName: ['', Validators.required],
      Description: [''],
    });
    this.firstFormGroupCopy = this._formBuilder.group({
      TemplateName: ['', Validators.required],
      Description: [''],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
    this.form1 = this._formBuilder.group({
      Title: ['', Validators.required],
      Description: [''],
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });
    this.Formfield = this._formBuilder.group({
      Label: ['', Validators.required],
      FieldType: ['', Validators.required],
      Description: [''],
      Form: [''],
      FormId: [''],
    });

    this.forthFormGroup = this._formBuilder.group({
      forthCtrl: ['', Validators.required],
    });
    this.fifthFormGroup = this._formBuilder.group({
      fifthCtrl: ['', Validators.required],
    });
  }
  publish() {
    console.log(this.selectedTemplated);
    var json_arr = {};
    json_arr['IsPublish'] = true;
    json_arr['TemplateId'] = this.selectedTemplated;
    this._Template.UpdateTemplate(json_arr).subscribe((data) => {
      console.log(data);
    });
  }
  //#region
  SelectedTemplate: any;
  onTemplateClick(e) {
    console.log(e);
    this.SelectedTemplate = e;
  }
  //get Category by Industry Id
  selectedIndustryId: any;
  LoanCAtegoryList: any;
  getCategoryByIndustryId(id) {
    console.log(id);
    this.selectedIndustryId = id;
    this._Category.getCategoryByIndustryId(id).subscribe((data) => {
      console.log(data);
      this.LoanCAtegoryList = data;
    });
  }

  TemplateList: any;
  getRoleDtl() {
    this._Template.getTemplateDtl().subscribe((data) => {
      console.log(data);
      this.TemplateList = data;
      // this._utility.dataTables("template");
    });
  }
  TemlateName:string;
  getTempDetails(id) {
    this._Template.getTemplateById(id).subscribe((data) => {
      console.log(data);
      console.log(data[0].Name);
      this.firstFormGroup.patchValue({
        TemplateName: data[0].Name,
      });
      if (data[0].Name != 'Default') {
       this.isDefault = true;
       this.isCopy = false;
      } else {
        this.isDefault = false;
        this.isCopy = true;
      }
    });
  }

  getTemplatebyid(id) {
    console.log(id);
    this.isUpdate = true;
    this.isSave = false;
    this.addedited = true;
    this._Template.getTemplateById(id).subscribe((data) => {
      console.log(data);
      this.templateForm.setValue(data[0]);
    });
  }

  onSave() {
    console.log(this.templateForm.value);
    if (this.templateForm.valid) {
      this._Template
        .createTemplate(this.templateForm.value)
        .subscribe((data) => {
          console.log(data);
          this.addedited = false;
          var x = <HTMLInputElement>document.getElementById('snackbar');
          x.innerText = 'Template Created Sucessfully';
          this._utility.SuccessToast();

          setTimeout(() => {
            this.refresh();
          }, 2000);
        });
    } else {
      const invalid = [];
      const controls = this.templateForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
          var x = <HTMLInputElement>document.getElementById('snackbarerror');
          x.innerText = 'Please Enter ' + invalid[0] + '';
          this._utility.ErrorToast1();
        }
      }
      return invalid;
    }
  }

  onUpdate(e) {
    console.log(e);
    if (this.templateForm.valid) {
      this._Template.UpdateTemplate(e).subscribe(
        (data) => {
          console.log(data);
          var x = <HTMLInputElement>document.getElementById('snackbar');
          x.innerText = 'Template Updated Sucessfully';
          this._utility.SuccessToast();
          //this._router.navigate(["/branch"]);
          setTimeout(() => {
            this.refresh();
          }, 2000);
          //this.refresh();

          this.isUpdate = false;
          this.isSave = true;
          this.addedited = false;
          //his.BranchForm.reset
        },
        (err) => {
          var x = <HTMLInputElement>document.getElementById('snackbarerror');
          x.innerText = 'An Error Occured' + err.error.toString();
          this._utility.ErrorToast1();
        }
      );
    } else {
      const invalid = [];
      const controls = this.templateForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
          var x = <HTMLInputElement>document.getElementById('snackbarerror');
          x.innerText = 'Please Enter ' + invalid[0] + '';
          this._utility.ErrorToast1();
        }
      }
      return invalid;
    }
  }

  onClearAll() {
    this.templateForm.reset();
  }

  refresh() {
    //debugger;
    this.templateForm.reset();
    //  window.location.reload();
    this._router
      .navigateByUrl('/template//miscellaneous/not-found', {
        skipLocationChange: true,
      })
      .then(() => this._router.navigate(['/template']));

    console.log('refresh');
  }

  validateNumber(event: any) {
    this._utility.validateNumber(event);
  }

  //
  SearchReactive: [];
  public placeholder: string = 'Search Template';
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';
  getSearchBoxData() {
    this._autocompleteService.getTemplateSearchDetails().subscribe((data) => {
      console.log(data);
      this.SearchReactive = data;
    });
  }

  // text:any='';
  // selectedString='';
  //   onKeyUp(x) { // appending the updated value to the variable
  //     console.log(x.target.value);
  //     this.text += x.target.value + ' | ';
  //   this.selectedString=(x.target.value).toString();
  //     this.getTemplateByTemplateName(this.selectedString);
  //   }

  //   filterdata:any=0;
  //   getTemplateByTemplateName(tempName){
  //     this._Template.getTemplateByTemplateName(tempName)
  //     .subscribe(data=>{
  //      // console.log(data);
  //       this.filterdata=data;
  //       console.log(this.filterdata)
  //     })
  //   }

  text: any = '';
  selectedString = '';
  onKeyUp(x) {
    // appending the updated value to the variable
    console.log(x.target.value);
    this.text += x.target.value + ' | ';
    this.selectedString = x.target.value.toString();
    console.log(this.selectedString);
    this.getTemplateSearch(this.selectedString);
  }
  filterdata: any;
  getTemplateSearch(name) {
    this._Template.getTemplateSearch(name).subscribe((data) => {
      console.log(data);
      this.filterdata = data;
    });
  }

  TemplateByCatList: any;
  getTemplateByCategory(Category) {
    this._Template.getTemplateDtlByName(Category).subscribe((data) => {
      console.log(data);
      this.TemplateByCatList = data;
      this.GetTemplateDataByName(data[0].TemplateId,data[0].Name);
    });
  }

  //  //get Category by Industry Id
  //  selectedIndustryId:any;
  //  LoanCAtegoryList:any;
  //  getCategoryByIndustryId(id){
  //    console.log(id);
  //    this.selectedIndustryId=id;
  //    this._category.getCategoryByIndustryId(id)
  //    .subscribe(data => {
  //      console.log(data);
  //      this.LoanCAtegoryList=data;
  //    })

  //  }

  //#endregion
  //#region net
  steperSecondStep() {
    this.secondFormGroup.patchValue({
      secondCtrl: 1,
    });
  }
  steperthirdStep() {
    this.thirdFormGroup.patchValue({
      thirdCtrl: 1,
    });
  }
  FormatData = [];
  ExampleData = [];
  steperfourStep() {

    this.FormatData = [];
    this.ExampleData = [];
    this.forthFormGroup.patchValue({
      forthCtrl: 1,
    });
    console.log(this.APIFormId);
    console.log(this.selectedTemplated);
    this._Category
      .getAPI(this.selectedTemplated, this.APIFormId)
      .subscribe((data) => {
        console.log(this.selectedTemplated);
        console.log(this.APIFormId);
        this.FormatData = data['Formats'];
        this.ExampleData = data['Eaxmples'];
        console.log(this.FormatData)
        console.log(this.ExampleData)
      });
  }
  //#endregion

  //#region getDefault Template Data

  Copy()
  {
      console.log(this.CategoryName)
      console.log(this.selectedTempId)
      console.log(this.firstFormGroupCopy.get('TemplateName').value)
      console.log(this.firstFormGroupCopy.get('Description').value)
      console.log(this.FormDataList)
      console.log(this.AllFormFieldData)
      var postData = {
        Name: this.firstFormGroupCopy.get('TemplateName').value,
        Category: this.CategoryName,
        TemplateId: 1,
        CreatedBy: 1,
        UpdatedBy: 1,
      };
      console.log(postData);
      this._Template.createTemplate(postData).subscribe((data) => {
        
        if(data['post'].TemplateId > 1)
        {
          this._Template.getTemplateId().subscribe((data) => {
           
            this.selectedTemplated = data[0].TemplateId;

            for (var i = 0; i < this.FormDataList.length; i++) {
              console.log(this.FormDataList[i]);
              var res = this.FormDataList[i];
              var json_arr = {};
              var json_arr = {};
              json_arr['Id'] = null;
              json_arr['Title'] = res.Title;
              json_arr['TemplateId'] = this.selectedTemplated;
              json_arr['Description'] = res.Description;
              json_arr['CreatedBy'] = 1;
              json_arr['UpdatedBy'] = 1;
              json_arr['IsActive'] = true;
              console.log(json_arr);
              this._form.createForm(json_arr).subscribe((data) => {
                console.log(data);
              });

             if(this.FormDataList.length-1 == i)
             {
              for (var i = 0; i < this.AllFormFieldData.length; i++) {
                var mappingId =false;
                if(i == 0)
                {
                  mappingId = true;
                }  
                console.log(this.AllFormFieldData[i]);
                var res = this.AllFormFieldData[i];
                var json_arr = {};
                json_arr['Id'] = null;
                json_arr['Label'] = res.Label;
                json_arr['FieldType'] = res.FieldType;
                json_arr['Description'] = res.Description;
                json_arr['Form'] = res.Form;
                json_arr['FormId'] = res.FormId;
                json_arr['CreatedBy'] = 1;
                json_arr['UpdatedBy'] = 1;
                json_arr['IsActive'] = true;
                json_arr["MappingField"] = mappingId;
                console.log(json_arr);
                this._formfield.createFormfield(json_arr).subscribe((data) => {
                  console.log(data);
                });
                if(this.AllFormFieldData.length-1 == i)
                {
                  this._router.navigate(['/template', this.CategoryName,this.IndId,this.CategoryName]);
                }
              }
             }
            }
          });
        }
      });
  }
  Update()
  {
    console.log(this.selectedTempId);
    console.log(this.firstFormGroup.get('TemplateName').value);
    console.log(this.firstFormGroup.get('Description').value);
    console.log(this.FormDataList);
    console.log(this.AllFormFieldData);
    var postData = {
      Name: this.firstFormGroup.get('TemplateName').value,
      TemplateId: this.selectedTempId,
    };
    console.log(postData);
    this._Template.UpdateTemplate1(postData).subscribe((data) => {
      for (var i = 0; i < this.FormDataList.length; i++) {
        console.log(this.FormDataList[i]);
        var res = this.FormDataList[i];
        var json_arr = {};
        var json_arr = {};
        json_arr['Title'] = res.Title;
        json_arr['Description'] = res.Description;
        json_arr['FormId'] = res.FormId;
        console.log(json_arr);
        this._form.UpdateForm(json_arr).subscribe((data) => {
          console.log(data);
        });
        if (this.FormDataList.length - 1 == i) {
          for (var i = 0; i < this.AllFormFieldData.length; i++) {
            var res = this.AllFormFieldData[i];
            var json_arr = {};
            json_arr['Id'] = null;
            json_arr['Label'] = res.Label;
            json_arr['FieldType'] = res.FieldType;
            json_arr['Description'] = res.Description;
            json_arr['Form'] = res.Form;
            json_arr['FormId'] = res.FormId;
            json_arr['CreatedBy'] = 1;
            json_arr['UpdatedBy'] = 1;
            json_arr['IsActive'] = true;
            json_arr['FormFieldId'] = res.FormFieldId;
            console.log(json_arr);
            this._formfield.UpdateFormfield(json_arr).subscribe((data) => {
              console.log(data);
            });
            if(this.AllFormFieldData.length-1 ==i)
            {
              this.toastr.success("Records updated successfully!!!");
            }
          }
        }
      }
    });
  }
  FormDataList = [];
  getFormid = [];
  TabValue: string;
  TabForm: Number;
  TabValue1=[];
  TabForm1 =[];
  getFormData(TempId,Name) {
    this.TabValue1=[];
    this.TabForm1 =[];
    this.getFormid =[];
    this.APIFormId = [];
    this.selectedTemplated = TempId;
    this._form.getFormDetails(TempId).subscribe((data) => {
      console.log(data);
      //for (let i = 0; i < data.length; i++) {

        // this.getFormid.push(data[i].FormId);
        // if (data.length - 1 == i) {
        //   this.getFormFeildData(this.getFormid);
        // }

        // this._form.getFormId().subscribe((data1) => {
        //   console.log(data1);
        //   data[i].FormId = data1[0].FormId + (i + 1);
        //   if (i == 0) {
        //     this.TabValue = data[i].Title;
        //     this.TabForm = data1[0].FormId + (i + 1);
        //   }
        // });
     // }
      this.FormDataList = data;
      console.log(this.FormDataList)
      this._form.getFormId().subscribe((data) => {
        for(let i=0;i<this.FormDataList.length;i++)
        {
          this.APIFormId.push(this.FormDataList[i].FormId)
          console.log(this.APIFormId)
          if(Name == "Default")
          {
            if(i == 0)
            {
              this.TabForm=data[0].FormId+(i+1);
              this.TabValue= this.FormDataList[i].Title;
            }
            this.TabValue1.push(this.FormDataList[i].Title)
            this.TabForm1.push(this.FormDataList[i].FormId)
            console.log(this.TabValue1)
            console.log(this.TabForm1)
            this.getFormid.push(this.FormDataList[i].FormId);
            this.FormDataList[i].FormId = data[0].FormId+(i+1);
            if(this.FormDataList.length-1 == i)
            {
              console.log(this.getFormid)
              this.getFormFeildData(this.getFormid,Name);
            }
            console.log(this.FormDataList);
          }
          else{
            if(i == 0)
            {
              this.TabForm=this.FormDataList[i].FormId;
              this.TabValue= this.FormDataList[i].Title;
            }
            this.TabValue1.push(this.FormDataList[i].Title)
            this.TabForm1.push(this.FormDataList[i].FormId)
            console.log(this.TabValue1)
            console.log(this.TabForm1)
            this.getFormid.push(this.FormDataList[i].FormId);
            this.FormDataList[i].FormId = this.FormDataList[i].FormId;
            if(this.FormDataList.length-1 == i)
            {
              this.getFormFeildData(this.getFormid,Name);
            }
            console.log(this.FormDataList);
          }
          
          }
         
      });
      
    });
  }
  APIFormId = [];
  onsaveForm() {
    this.APIFormId = [];
    this.TabValue1 = [];
    this.TabForm1 =[];
    console.log(this.form1.value);
    var data = this.form1.get('Title').value;
    this.FormDataList.push(this.form1.value);
  
    this._form.getFormId().subscribe((data) => {
      for(let i=0;i<this.FormDataList.length;i++)
      {
        if(i== 0)
        {
          this.TabForm = data[0].FormId+(i+1);
          this.TabValue =this.FormDataList[i].Title;
        }
        this.TabValue1.push(this.FormDataList[i].Title)
        this.TabForm1.push(data[0].FormId+(i-1))
         console.log(this.TabValue1)
          console.log(this.TabForm1)
        this.FormDataList[i].FormId = data[0].FormId+(i+1);
        this.APIFormId.push(data[0].FormId+(i+1));
      }
      console.log(this.FormDataList);
      console.log(this.APIFormId);
    });
    // if (this.FormDataList.length != 0) {
    //   this.FormDataList.push(this.form1.value);
    //   console.log(this.FormDataList);
    //   this._form.getFormId().subscribe((data) => {
    //     console.log(data);
    //     if (data.length == 0) {
    //       this.FormDataList[0].FormId = 1;
    //       this.APIFormId.push(1);
    //     } else {
    //       this.FormDataList[0].FormId = data[0].FormId + 1;
    //       this.APIFormId.push(data[0].FormId + 1);

    //       var test = data[0].FormId + 1;
    //       var defaultength = this.FormDataList.length;
    //       this.APIFormId.push(test);
    //       console.log(this.APIFormId);
    //       this.FormDataList.push(this.form1.value);
    //       this.FormDataList[defaultength].FormId = test;
    //     }
    //   });
    // } else {
    //   this.TabValue = this.form1.get('Title').value;
    //   this.TabForm = 1;
    //   this.FormDataList.push(this.form1.value);
    //   this._form.getFormId().subscribe((data) => {
    //     console.log(data);
    //     if (data.length == 0) {
    //       this.FormDataList[0].FormId = 1;
    //       this.APIFormId.push(1);
    //     } else {
    //       this.FormDataList[0].FormId = data[0].FormId + 1;
    //       this.APIFormId.push(data[0].FormId + 1);
    //     }
    //   });
    // }
    //  else {
    //   var test=this.FormDataList[this.FormDataList.length-1].FormId+1;
    //    console.log(test);
    //    console.log(this.FormDataList.length);
    //    var defaultength=this.FormDataList.length;
    //    this.APIFormId.push(test);
    //     this.FormDataList.push(this.secondFormGroup.value);
    //     this.FormDataList[defaultength].FormId=test;
    //    }

    // console.log(data);
    // if(data=!'' && data !=undefined && this.form1.valid){
    //   this.FormDataList.push(this.form1.value);
    //   console.log(this.FormDataList);
    // }else{
    //   var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
    //   x.innerText = 'Please Provide data to post';
    //   this._utility.ErrorToast1();
    // }
  }
  cancelForm() {
    this.form1.reset();
  }
  addeditForm: boolean = false;
  selectedData: any;
  OnSelectFormRow(e, data) {
    this.addeditForm = true;
    this.isUpdate = true;
    console.log(e);
    this.selectedData = data;
    console.log(data);
    //console.log(e.target.value);
    // (<HTMLInputElement>document.getElementById('Name')).value=data[0].Name;
    // (<HTMLInputElement>document.getElementById('Description')).value=data[0].Description;

    this.form1.get('Title').setValue(data.Title);
    this.form1.get('Description').setValue(data.Description);
  }
  OnUpdateForm() {
    var data = this.selectedData;
    console.log(data);
    data.Title = this.form1.get('Title').value;
    data.Description = this.form1.get('Description').value;
    this.isUpdate = false;
    this.form1.reset();
    this.addeditForm = false;
  }
  OnDeleteform(e, data) {
    console.log(data);
    console.log(this.FormDataList);
    // this.FormFieldData.splice(e,1);
    this.FormDataList.splice(e, 1);
    console.log(this.FormDataList);
  }

  // final Save Form
  saveForm() {
    this._Template.getTemplateId().subscribe((data) => {
      console.log(data);
      console.log('Template:' + data.length);
      if (data.length == 0) {
        this.selectedTemplated = 1;
      } else {
        console.log('Test:' + data[0].TemplateId);
        this.selectedTemplated = data[0].TemplateId + 1;
      }
      console.log(this.selectedTemplated);
      for (var i = 0; i < this.FormDataList.length; i++) {
        console.log(this.FormDataList[i]);
        var res = this.FormDataList[i];
        var json_arr = {};
        var json_arr = {};
        json_arr['Id'] = null;
        json_arr['Title'] = res.Title;
        json_arr['TemplateId'] = this.selectedTemplated;
        json_arr['Description'] = res.Description;
        json_arr['CreatedBy'] = 1;
        json_arr['UpdatedBy'] = 1;
        json_arr['IsActive'] = true;
        console.log(json_arr);
        this._form.createForm(json_arr).subscribe((data) => {
          console.log(data);

          var x = <HTMLInputElement>document.getElementById('snackbar');
          x.innerText = 'Form Data Save Sucessfully';
          this._utility.SuccessToast();
        });
      }
    });
  }

  // testing
  getFormFieldByTemplateId() {}

  //
  first: any;
  second: any;
  getTemplateData() {
    this.first = this.firstFormGroup.get('TemplateName').value;
    this.second = this.firstFormGroup.get('Description').value;
  }

  // first=(<HTMLInputElement>document.getElementById('tName'))

  // second=(<HTMLInputElement>document.getElementById('tSescription'))
  //this.firstFormGroup.get('TemplateName').value;

  //

  FormFieldDataList = [];
  getFormFeildData(getFormid,Name) {
   console.log(getFormid)
    this._formfield.getFormfieldDetails(getFormid).subscribe((data) => {
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        if(Name =="Default")
        {
          this._form.getFormId().subscribe((data1) => {
            console.log(data1);
            data[i].FormId = data1[0].FormId + data[i].FormId;
          });
        }
        this.AllFormFieldData = data;
        console.log(this.AllFormFieldData);
      }
    });
  }

  getFormFeildData1(V,F) {
    console.log(V)
    console.log(F)
    this._formfield.getFormfieldByIdData(V,F).subscribe((data) => {
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        this._form.getFormId().subscribe((data1) => {
          console.log(data1);
          data[i].FormId = data1[0].FormId + data[i].FormId;
        });
        this.AllFormFieldData = data;
        console.log(this.AllFormFieldData);
      }
    });
  }

  dynamicVal: String;
  // FormFieldData=[];
  AllFormFieldData = [];
  onsaveFormField() {
    console.log(this.dynamicVal);
    this.Formfield.patchValue({
      Form: this.dynamicVal,
    });

    this.Formfield.patchValue({
      FormId: this.dynamicFormId,
    });
    console.log(this.AllFormFieldData);
    console.log(this.Formfield.value);
    var data = this.Formfield.get('Label').value;
    var data1 = this.Formfield.get('FieldType').value;
    if (data != '' && data != undefined && data1 != '' && data1 != undefined) {
      this.FormFieldDataList.push(this.Formfield.value);
      this.AllFormFieldData.push(this.Formfield.value);
      console.log(this.AllFormFieldData);
    } else {
      var x = <HTMLInputElement>document.getElementById('snackbarerror');
      x.innerText = 'Please Provide data to post';
      this._utility.ErrorToast1();
    }

    // this.thiredFormGroup.patchValue({
    // secondCtrl: true,
    // });
  }
  // onsaveFormfield(){
  //   console.log(this.Formfield.value);
  //   var data=this.Formfield.get('Label').value;
  //   var data1=this.Formfield.get('FieldType').value;
  //   // if(data=''){
  //   //   var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
  //   //   x.innerText = 'Please Provide data to post';
  //   //   this._utility.ErrorToast1();
  //   // }
  //   console.log(data1);
  //   if(data=!'' && data !=undefined && this.Formfield.valid){
  //     this.FormFieldDataList.push(this.Formfield.value);
  //     console.log(this.FormFieldDataList);
  //   }else{
  //     var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
  //     x.innerText = 'Please Provide data to post';
  //     this._utility.ErrorToast1();
  //   }

  // }
  // tab selection

  noOfTab: boolean = false;
  dynamicFormId: Number;
  dupArr = [];
  onloadTabSelection(data, formId) {
    console.log(formId+ ' '+data);
    
    console.log(this.AllFormFieldData);
    // this.APIFormId.push(formId);
    // this.APIFormId = this.APIFormId.filter(function (item, index, inputArray) {
    //   return inputArray.indexOf(item) == index;
    // });
    // console.log(this.APIFormId);

    // debugger;
    // console.log(this.FormFieldDataList)
    // var centers = this.AllFormFieldData.filter(element => {
    //   return element.Form == data;
    //   })
    var centers = this.AllFormFieldData.filter((element) => {
      return element.FormId == formId;
    });
    for (let i = 0; i < centers.length; i++) {
      centers[i].FormId = formId;
      centers[i].Form = data;
      if (centers.length - 1 == i) {
        this.FormFieldDataList = centers;
        console.log(this.FormFieldDataList);
      }
    }
    console.log(data);
    this.dynamicVal = data;
    this.noOfTab = data;
    this.dynamicFormId = formId;
    // for(var i=0;i<=this.DefaultFormData.length;i++){
    // this.noOfTab=true;
    // //this.DefaultFormData[i].Name;
    // //this.noOfTab=(<HTMLInputElement>document.getElementById('tabName'));
    // console.log(this.noOfTab);

    // }
  }
  cancelFormfield() {
    this.Formfield.reset();
  }
  addeditFormfield: boolean = false;
  selectedDataformfield: any;
  OnSelectFormfieldRow(e, data) {
    this.addeditFormfield = true;
    this.isUpdate = true;
    console.log(e);
    this.selectedDataformfield = data;
    console.log(data);
    //console.log(e.target.value);
    // (<HTMLInputElement>document.getElementById('Name')).value=data[0].Name;
    // (<HTMLInputElement>document.getElementById('Description')).value=data[0].Description;

    this.Formfield.get('Label').setValue(data.Label);
    this.Formfield.get('FieldType').setValue(data.FieldType);
    this.Formfield.get('Description').setValue(data.Description);
  }
  OnUpdateFormfield() {
    var data = this.selectedDataformfield;
    console.log(data);
    data.Label = this.Formfield.get('Label').value;
    data.FieldType = this.Formfield.get('FieldType').value;
    data.Description = this.Formfield.get('Description').value;
    this.isUpdate = false;
    this.Formfield.reset();
    this.addeditFormfield = false;
  }
  OnDeleteformfield(e, data) {
    console.log(data);
    console.log(this.FormFieldDataList);
    // this.FormFieldData.splice(e,1);
    this.FormFieldDataList.splice(e, 1);
    console.log(this.FormFieldDataList);
  }

  // final Save Formfield
  saveFormfield() {
    for (var i = 0; i < this.AllFormFieldData.length; i++) {
      console.log(this.AllFormFieldData[i]);
      var res = this.AllFormFieldData[i];
      var json_arr = {};
      json_arr['Id'] = null;
      json_arr['Label'] = res.Label;
      json_arr['FieldType'] = res.FieldType;
      json_arr['Description'] = res.Description;
      json_arr['Form'] = res.Form;
      json_arr['FormId'] = res.FormId;
      json_arr['CreatedBy'] = 1;
      json_arr['UpdatedBy'] = 1;
      json_arr['IsActive'] = true;

      console.log(json_arr);
      this._formfield.createFormfield(json_arr).subscribe((data) => {
        console.log(data);

        var x = <HTMLInputElement>document.getElementById('snackbar');
        x.innerText = 'Formfield Data Save Sucessfully';
        this._utility.SuccessToast();
      });
    }
  }
  selectedTemplated: Number;
  SaveTemplate() {
    console.log(this.firstFormGroup.value);
    var postData = {
      Name: this.firstFormGroup.get('TemplateName').value,
      Category: this.CategoryName,
      TemplateId: 1,
      CreatedBy: 1,
      UpdatedBy: 1,
    };
    console.log(postData);
    this._Template.createTemplate(postData).subscribe((data) => {
      console.log(data);
      this.selectedTemplated = data['post'].TemplateId;
    });
  }

  //#endregion

  // ribbon
  //#region robbon
  iconColor= 'Templates icon1.png'
  configicon='setting.png';
  masterIcon='data-icon.svg';
  apiIcon='api.png';
  blockChainIcon='blockchain.png';
  billingIcon='Billing-icon.svg';
 
  click1(){
    this.iconColor='Templates icon.png';
    console.log('color');
  }

  clickonConfiguration(){
    this.configicon='Configuration icon.png';
    console.log('color');
  }
  clickMaster(){
    this.masterIcon='data icon.png';
    console.log('color');
  }
  clickApi(){
    this.apiIcon='API icon.png';
    console.log('color');
  }
  clickBlockChain(){
    this.blockChainIcon='Blockchain icon.png';
    console.log('color');
  }
  clickBilling(){
    this.billingIcon='Billing icon.png';
    console.log('color');
  }


  onChangeRibon(){
    // this.iconColor= 'as.png';
    this.configicon='setting.png';
    this.masterIcon='data-icon.svg';
    this.apiIcon='api.png';
    this.blockChainIcon='blockchain.png';
    this.billingIcon='Billing-icon.svg';
  }

  onChangeconfig(){
      this.iconColor= 'Templates icon1.png';
      // this.configicon='setting.png';
      this.masterIcon='data-icon.svg';
      this.apiIcon='api.png';
      this.blockChainIcon='blockchain.png';
      this.billingIcon='Billing-icon.svg';
  }
  onChangeMaster(){
    this.iconColor= 'Templates icon1.png';
    this.configicon='setting.png';
    // this.masterIcon='data-icon.svg';
    this.apiIcon='api.png';
    this.blockChainIcon='blockchain.png';
    this.billingIcon='Billing-icon.svg';
}
onChangeApi(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  // this.apiIcon='api.png';
  this.blockChainIcon='blockchain.png';
  this.billingIcon='Billing-icon.svg';
}
onChangeBlockChain(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  this.apiIcon='api.png';
  // this.blockChainIcon='blockchain.png';
  this.billingIcon='Billing-icon.svg';
}
onChangebilling(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  this.apiIcon='api.png';
  this.blockChainIcon='blockchain.png';
  // this.billingIcon='Billing-icon.svg';
}

  //#endregion ribbon
}
