import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentsService } from '../service/documnets.service';
import * as moment from 'moment';
import _, { map } from 'underscore';
import { KeyValue } from '@angular/common';
import { concatAll } from 'rxjs/operators';
import { constants } from 'node:buffer';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-mahesh-bank',
  templateUrl: './mahesh-bank.component.html',
  styleUrls: ['./mahesh-bank.component.css'],
})
export class MaheshBankComponent implements OnInit {
  //For Verification OTP
  LoanNo:any;
  hidediv: boolean;
  hidedivForLandingpage: boolean;
  hidefornextverification:boolean;
  MobileNo: number;
  verifyButton: boolean;
  QRCodeVal: string;
  //mobile='999999999';
  mobile:string;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  //For Landingpage
  frmdrop: boolean;
  loanDtl: boolean;
  docDtl: boolean;
  assetDtl: boolean;
  approvalsDtl: boolean;
  eventDtl: boolean;
  CustomerName: string;
  FatherName: string;
  EMIStartsOn: Date;
  Email: string;
  GoldLoanAccountNo: number;
  LoanAccountNo: number;
  EMIAmount: number;
  GrossWeight: string;
  Interest: string;
  LoanAmount: number;
  MembershipNo: number;
  NetWeight: number;
  PeriodInDays: number;
  TokenNo: number;
  TotalWeight: string;
  pdfUrl: any;
  pdfAadhar: any;
  pdfPan: any;
  pdfValutor: any;
  approvedBy: string;
  status: string;
  UpdatedDate: string;
  ValuationOfGoldPer10Gms: string;
  images = [];
  pdfs = [];
  checkpdf = [];
  Events: any;
  val: string;
  obj = {};
  CustImage: string;

  LoanDetails: Boolean;
  DocDetails: Boolean;
  AssetDetails: Boolean;
  ApporvedDetails: Boolean;
  EventDetails: Boolean;
  tab: any = 'tab1';
  tab1: any;
  tab2: any;
  tab3: any;
  tab4: any;
  bolAadhar: boolean;
  bolPan: boolean;
  bolValutor: boolean;
  bolfUrl: boolean;
  blockChainResult = [];
  res: any;
  DocumentData: any;
  HeaderResult = [];
  Header: any;
  AllItems: any;
  LatestLoanNo :any;
  LatestCustomerName :any;
  LatestLoanAmount:any;
  LatestInterestRate:any;
  LatestEMIStartsOn:any;
  LatestFatherName:any;
  LatestMail:any;
  LatestPhone:any;
  LatestCustomerNo:any;
  LatestMembershipNo:any;
  LatestPeriodinDays:any;
  LatestEMIAmount:any;
  LatestValuation:any;
  LatestTotalWeight:any;
  LatestNetWeight:any;
  LatestLoanClosure:any;
  LatestLoanReceipt:any;
  LatestAsset:any;
  LatestCustImage:any;
  LatestLoanApplication:any;
  LatestLoanApproval:any;
  LatestLoanSanctioned:any;
  LatestRef:any;
  LatestMonthlyEMI:any;
  LatestPaidDate:any;
  LatestFormName:any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '',
  };
  EventDocument: any;
  assetImage:any;
  constructor(
    private _service: DocumentsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) {
    this.frmdrop = true;
    this.loanDtl = true;
  }
  ngOnInit(): void {
    this.hidediv = true;
    this.hidedivForLandingpage = false;
    this.hidefornextverification = false;
    this.verifyButton = false;
    var res = window.location.search.split('=');
    let qrcode = this.route.snapshot.params.QRCode;

    //this.QRCodeVal = res[1];
    this.QRCodeVal = qrcode;
    if (this.QRCodeVal != '') {
      console.log(qrcode);
      this._service.getMobile(qrcode).subscribe(data => {
       this.MobileNo =  data["posts"][0].Mobile;
        this.mobile = data["posts"][0].Mobile;
        console.log(data)
        // this._service.sendOTP(data["posts"][0].Mobile).subscribe(data =>{
        //   this.showSuccess(this.MobileNo);
        // });
      });
      //this._service.getMobile(res[1]).subscribe(data => {
      //     this._service.getMobile(qrcode).subscribe(data => {
      //     this.MobileNo =  data["posts"][0].Mobile;
      //     this._service.sendOTP(data["posts"][0].Mobile).subscribe(data =>{
      //      console.log(data)
      //      this.showSuccess(this.MobileNo)
      //     })
      // });
    }
  }
  resendOTP(){
    let qrcode = this.route.snapshot.params.QRCode;
    this.QRCodeVal = qrcode;
    if (this.QRCodeVal != '') {
      console.log(qrcode);
      this._service.getMobile(qrcode).subscribe(data => {
       this.MobileNo =  data["posts"][0].Mobile;
        this.mobile = data["posts"][0].Mobile;
        console.log(data)
        this._service.sendOTP(data["posts"][0].Mobile).subscribe(data =>{
          this.showSuccess(this.MobileNo);
        });
      });
    }
  }
  
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }
  showSuccess(MobileNo) {
    this.toastr.success('OTP is sent to this ' + MobileNo + ' mobile number');
  }
  showVerify(message) {
    this.toastr.success(message);
  }
  onOtpChange(OTP) {
    if (OTP === '') {
      this.verifyButton = false;
    } else {
      this.verifyButton = true;
    }
  }
LatestVerifyOTP(OTP)
{

  this.hidediv = false;
  this.hidefornextverification=true;
  this.LoanDetails = true;
  
console.log(OTP)
     this._service.getBlockChainDataDetails(this.QRCodeVal).subscribe((data) => {
this.LoanDetails = true;
this.bolfUrl = true;
this.bolAadhar = false;
this.bolPan = false;
this.bolValutor = false;
let AllData = [];
let innerEventData = [];
console.log(data['posts'])
for (let i = 0;i < data['posts']['childblockchaintriggers'].length;i++) {
let EventData = [];
this.images = [];
let obj = {};
let DocArrData = [];
let FilterData = [];
this.LatestFormName = "";
let Docarr = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
var datearr = data['posts']['childblockchaintriggers'][ i].blockchaintimestamp.split(' ');

for(let k=0;k<Docarr.length;k++)
{
  obj = {};
  var index = Docarr[k].indexOf(":");
  var Key = Docarr[k].substring(0,index);
  var Value = Docarr[k].substring(index+1,Docarr[k].length);
  if(Key == "Loan A/c No")
  {
      this.LatestLoanNo = Value;
      console.log(this.LatestLoanNo)
  }
  if(Key == "Loan Application")
  {
    this.LatestLoanApplication = Value;
  }
  if(Key == "Loan Approval")
  {
     this.LatestLoanApproval = Value;
  }
  if(Key == "Loan Sanctioned")
  {
    this.LatestLoanSanctioned = Value;
  }
  if(Key == "Customer Name")
  {
      this.LatestCustomerName = Value;
  }
  if(Key == "Loan Amount")
  {
        this.LatestLoanAmount = Value;
  }
  if(Key == "Interest Rate")
  {
      this.LatestInterestRate = Value;
  }
 if(Key == "EMI Starts On")
 {
    this.LatestEMIStartsOn = Value;
 }
 if(Key == "Father Name")
 {
      this.LatestFatherName = Value;

 }
if(Key == "Mail")
{
      this.LatestMail = Value;
}
if(Key == "Phone")
{
      this.LatestPhone = Value;
}
if(Key == "Customer A/c No")
{
      this.LatestCustomerNo = Value;
}
if(Key == "Membership No")
{
      this.LatestMembershipNo = Value;
}
if(Key == "Period in Days")
{
    this.LatestPeriodinDays = Value;
}
if(Key == "EMI Amount")
{
      this.LatestEMIAmount = Value;
}
if(Key =="Valuation (10gms)")
{
    this.LatestValuation = Value;
}
if(Key == "Total Weight")
{
      this.LatestTotalWeight = Value;
}
if(Key == "Net Weight")
{
    this.LatestNetWeight = Value;
}
if(Key == "Loan Closure")
{
      this.LatestLoanClosure = Value;
}
if(Key == "Loan Receipt")
{
      this.LatestLoanReceipt = Value;
      var img = 'https://docs.google.com/viewerng/viewer?url='+Value+'&embedded=true';
      this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(img);
}
if(Key == "Asset")
{
    this.LatestAsset = Value;
}
if(Key == "Cust Image")
{
    this.LatestCustImage = Value;
}

if(Key == "Cust Image")
{
    this.LatestCustImage = Value;
}
if(Key == "Ref")
{
    this.LatestRef = Value;  
}
if(Key == "EMI")
{
    this.LatestMonthlyEMI = Value;  
}
if(Key == "Paid Date")
{
    this.LatestPaidDate = Value;  
}

if(Key == "Ref")
{
    this.LatestRef = Value;
}
if(Key == "EMI")
{
    this.LatestMonthlyEMI = Value;
}
if(Key == "Paid Date")
{
    this.LatestPaidDate = Value;

    

}
if(Value == "EMI Details")
{
      this.LatestFormName = Value;
}
}


if(this.LatestFormName == "EMI Details")
{
  obj ={}
EventData = [];
obj = {Activity:'EMI Paid',Date:this.LatestPaidDate,App:'Ref:'+this.LatestRef,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
EventData.push(obj);
innerEventData.push(EventData);
}



if(data['posts']['childblockchaintriggers'].length - 1 == i)
{
  obj ={}
  EventData = [];
  obj = {Activity:'Loan Sanctioned',Date:this.LatestLoanSanctioned,App:'A/c No:'+this.LatestLoanNo,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
  EventData.push(obj);
  //innerEventData.push(EventData);
  innerEventData.unshift(EventData)
  obj ={}
  EventData = [];
  obj = {Activity:'Loan Approval',Date:this.LatestLoanApproval,App:'A/c No:'+this.LatestLoanNo,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
  EventData.push(obj);
  //innerEventData.push(EventData);
  innerEventData.unshift(EventData)
  obj ={}
  EventData = [];
  obj = {Activity:'Loan Application',Date:this.LatestLoanApplication,App:'A/c No:'+this.LatestLoanNo,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
  EventData.push(obj);
  //innerEventData.push(EventData);
  innerEventData.unshift(EventData)
  this.EventDocument = innerEventData.reverse();
  console.log(this.EventDocument)
}

}
     })


}
  VerifyOTP(OTP) {

    
    this.hidediv = false;
    this.hidefornextverification=true;
    this.LoanDetails = true;
    

       this._service.getBlockChainDataDetails(this.QRCodeVal).subscribe((data) => {
this.LoanDetails = true;
this.bolfUrl = true;
console.log(data['posts']);
// this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
//   data['posts']['fileurl']
// );
this.bolfUrl = true;
this.bolAadhar = false;
this.bolPan = false;
this.bolValutor = false;
let AllData = [];
let innerEventData = [];
for (
let i = 0;
i < data['posts']['childblockchaintriggers'].length;
i++
) {
let EventData = [];
this.images = [];
let obj = {};
let DocArrData = [];
let FilterData = [];
let Docarr = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
// var bytes  = CryptoJS.AES.decrypt(data['posts']['childblockchaintriggers'][ i ].blockchaindata, 'trst01');
// console.log(bytes)
// var originalText = bytes.toString(CryptoJS.enc.Utf8);
// let Docarr = originalText.split('||');
var datearr = data['posts']['childblockchaintriggers'][ i].blockchaintimestamp.split(' ');



  if(Docarr[0] == 'Form:Loan Details')
  {
    Docarr.pop();
    Docarr.pop();
    Docarr.pop();
    if(DocArrData.length == 0)
    {
      for(let k=0;k<Docarr.length;k++)
      {
         obj = {};
         var index = Docarr[k].indexOf(":");
         var Key = Docarr[k].substring(0,index);
         var Value = Docarr[k].substring(index+1,Docarr[k].length);
         if(Value != "Loan Details")
         {
          obj ={"Key":Key,"Separator":':',"Value":Value}
          DocArrData.push(obj)
          if(Key == "Customer Name")
          {
              this.CustomerName = Value
          }
          if(Docarr.length-1 ==k)
          {
            this.DocumentData =DocArrData
            
          }
          if(Key == "Loan A/c No")
          {
            this.LoanNo = Value
          }
          if(Docarr.length -1 == k)
          {
            if(i == 0)
            {
             
                obj ={}
                EventData = [];
               obj = {Activity:'Loan Closure',Date:'20 Sep 2021',App:'Ref: MC009587638',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
               EventData.push(obj);
               innerEventData.push(EventData);
               obj ={}
               EventData = [];
               obj = {Activity:'EMI Paid',Date:'19 Jul 2021',App:'Ref: MCB0075656372',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
               EventData.push(obj);
               innerEventData.push(EventData);
               obj ={}
               EventData = [];
               obj = {Activity:'EMI Paid',Date:'19 Jun 2021',App:'Ref: MCB006888979',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
               EventData.push(obj);
               innerEventData.push(EventData);
               obj ={}
               EventData = [];
               obj = {Activity:'Loan Sanctioned',Date:'18 May 2021',App:'A/c No:'+this.LoanNo,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
               EventData.push(obj);
               innerEventData.push(EventData);
               obj ={}
               EventData = [];
               obj = {Activity:'Loan Approval',Date:'29 Jan 2021',App:'A/c No:'+this.LoanNo,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
               EventData.push(obj);
               innerEventData.push(EventData);
               obj ={}
               EventData = [];
               obj = {Activity:'Loan Application',Date:'29 Jan 2021',App:'A/c No:'+this.LoanNo,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
               EventData.push(obj);
               innerEventData.push(EventData);
            }
          }
         }
       
        
      }
      // if(i == 0)
      // {
       
      //     obj ={}
      //     EventData = [];
      //    obj = {Activity:'Loan Closure',Date:'20 Sep 2021',App:'Ref: MC009587638',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
      //    EventData.push(obj);
      //    innerEventData.push(EventData);
      //    obj ={}
      //    EventData = [];
      //    obj = {Activity:'EMI Paid',Date:'19 Jul 2021',App:'Ref: MCB0075656372',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
      //    EventData.push(obj);
      //    innerEventData.push(EventData);
      //    obj ={}
      //    EventData = [];
      //    obj = {Activity:'EMI Paid',Date:'19 Jun 2021',App:'Ref: MCB006888979',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
      //    EventData.push(obj);
      //    innerEventData.push(EventData);
      //    obj ={}
      //    EventData = [];
      //    obj = {Activity:'Loan Sanctioned',Date:'18 May 2021',App:'A/c No:203',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
      //    EventData.push(obj);
      //    innerEventData.push(EventData);
      //    obj ={}
      //    EventData = [];
      //    obj = {Activity:'Loan Approval',Date:'29 Jan 2021',App:'A/c No:203',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
      //    EventData.push(obj);
      //    innerEventData.push(EventData);
      //    obj ={}
      //    EventData = [];
      //    obj = {Activity:'Loan Application',Date:'29 Jan 2021',App:'A/c No:203',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
      //    EventData.push(obj);
      //    innerEventData.push(EventData);
      // }
    }
  else{
    for(let k=0;k<Docarr.length;k++)
    {
      if(Value != "Loan Details")
      {
        if(DocArrData[k-1].Key == Key)
        {
              DocArrData[k-1].Value =Value;
        }
        if(Docarr.length-1 ==k)
        {
          console.log(DocArrData)
          this.DocumentData =DocArrData
        
        }
      }
    }
   
  }
    
  }
  let Docarrpic = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
var removeValFromIndex = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];    

for (let i = removeValFromIndex.length -1; i >= 0; i--)
Docarrpic.splice(removeValFromIndex[i],1);

for(let m=0;m<Docarrpic.length;m++)
{
if(Docarrpic[0] == 'Form:Loan Details')
{

var index = Docarrpic[m].indexOf(":");
var Key = Docarrpic[m].substring(0,index);
var Value = Docarrpic[m].substring(index+1,Docarrpic[m].length);

if(Key == 'Loan Receipt')
{
var img = 'https://docs.google.com/viewerng/viewer?url='+Value+'&embedded=true';
this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(img);
if(i == 0)
{
// obj ={}
// EventData = [];
// var dateformat = datearr[1].split('/');
// var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
// var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
// obj = {Activity:'Receipt Added to Loan',Date:dateUTC1,App:' Approved',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
// EventData.push(obj);
// innerEventData.push(EventData);
}
}
if(Key == 'Asset')
{

this.assetImage = Value;
if(i == 0)
{
this.images.push(Value)

//  obj ={}
//  EventData = [];
//  var dateformat = datearr[1].split('/');
//  var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
//  var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
//  obj = {Activity:'Asset Images Added to Loan',Date:dateUTC1,App:' Approved',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
//  EventData.push(obj);
//  innerEventData.push(EventData);
}
}
if(Key == 'Cust Image')
{
this.CustImage = Value;
console.log(this.CustImage)
}

}
 
}

if(Docarr[0] == 'Form:EMI Details')
{
for(let s=0;s<Docarr.length;s++)
{
console.log(Docarr)
var index = Docarr[s].indexOf(':');
var Key = Docarr[s].substring(0, index);
var Value = Docarr[s].substring(index + 1, Docarr[s].length);
if(Key == 'EMI Amount')
{
// obj ={}
// EventData = [];
// var dateformat = datearr[1].split('/');
// var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
// var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
// obj = {Activity:'EMI Amount :'+ Value,Date:dateUTC1,App:Docarr[3],Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
// EventData.push(obj);
// innerEventData.push(EventData);
}

}
}

if(data['posts']['childblockchaintriggers'].length-1 ==i)
{
this.EventDocument = innerEventData;
console.log(this.EventDocument)
}     
// for (let k = 0; k < Docarr.length; k++) {
//   obj = {};
//   var index = Docarr[k].indexOf(':');
//   var Key = Docarr[k].substring(0, index);
//   var Value = Docarr[k].substring(index + 1, Docarr[k].length);

//     obj[Key] = Value;
//     DocArrData.push(obj);
//     if (Docarr.length - 1 == k) {
//       var dateformat = datearr[1].split('/');
//       var dateUTC = new Date(
//         dateformat[2] +
//           '-' +
//           dateformat[1] +
//           '-' +
//           dateformat[0] +
//           ' ' +
//           datearr[0] +
//           ' UTC'
//       );
//       var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
//       var objDate = {};
//       objDate['Updated On'] = dateUTC1;
//       DocArrData.push(objDate);
//       var objVerify = {};
//       objVerify['Verify'] =
//         data['posts']['childblockchaintriggers'][i].blockchainurl;
//       DocArrData.push(objVerify);
//       AllData.push(
//         DocArrData.reduce(function (result, current) {
//           return Object.assign(result, current);
//         }, {})
//       );

//       if (data['posts']['childblockchaintriggers'].length - 1 == i) {
//         var val = _.keys(
//           _.countBy(AllData, function (data) {
//             return data.Form;
//           })
//         );
//         for (let m = 0; m < val.length; m++) {
    
//           FilterData.push(
//             AllData.filter((obj) => obj.Form == val[m])
//           );
//           if (val.length - 1 == m) {
//             this.AllItems = FilterData;
//             console.log(this.AllItems);
//           }
//         }
//       }
//     }

// }
}
});

    //  let otpvalue = this.ngOtpInputRef.otpForm.value.ctrl_0 + this.ngOtpInputRef.otpForm.value.ctrl_1 + this.ngOtpInputRef.otpForm.value.ctrl_2 + this.ngOtpInputRef.otpForm.value.ctrl_3;
    
    //   this._service.VerifyOTP(this.MobileNo, otpvalue).subscribe(data => {

    //             if (data["posts"].message == "OTP verified success") {

    //               this.hidediv = false;
    //               this.hidefornextverification=true;
    //               this.LoanDetails = true;
                  
    //                 this.showVerify(data["posts"].message)
    //                 console.log(data["posts"].message)

    //                  this._service.getBlockChainDataDetails(this.QRCodeVal).subscribe((data) => {
    //   this.LoanDetails = true;
    //   this.bolfUrl = true;
    //   console.log(data['posts']);
    //   // this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
    //   //   data['posts']['fileurl']
    //   // );
    //   this.bolfUrl = true;
    //   this.bolAadhar = false;
    //   this.bolPan = false;
    //   this.bolValutor = false;
    //   let AllData = [];
    //   let innerEventData = [];
    //   for (
    //     let i = 0;
    //     i < data['posts']['childblockchaintriggers'].length;
    //     i++
    //   ) {
    //    let EventData = [];
    //     this.images = [];
    //     let obj = {};
    //     let DocArrData = [];
    //     let FilterData = [];
    //     //let Docarr = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
    //     var bytes  = CryptoJS.AES.decrypt(data['posts']['childblockchaintriggers'][ i ].blockchaindata, 'trst01');
    //     console.log(bytes)
    //     var originalText = bytes.toString(CryptoJS.enc.Utf8);
    //     let Docarr = originalText.split('||');
    //     var datearr = data['posts']['childblockchaintriggers'][ i].blockchaintimestamp.split(' ');
        
      

    //             if(Docarr[0] == 'Form:Loan Details')
    //             {
    //               Docarr.pop();
    //               Docarr.pop();
    //               Docarr.pop();
    //               if(DocArrData.length == 0)
    //               {
    //                 for(let k=0;k<Docarr.length;k++)
    //                 {
    //                   obj = {};
    //                    var index = Docarr[k].indexOf(":");
    //                    var Key = Docarr[k].substring(0,index);
    //                    var Value = Docarr[k].substring(index+1,Docarr[k].length);
    //                    if(Value != "Loan Details")
    //                    {
    //                     obj ={"Key":Key,"Separator":':',"Value":Value}
    //                     DocArrData.push(obj)
    //                     if(Key == "Customer Name")
    //                     {
    //                         this.CustomerName = Value
    //                     }
    //                     if(Docarr.length-1 ==k)
    //                     {
    //                       this.DocumentData =DocArrData
                          
    //                     }
    //                     if(Key == "Loan A/c No")
    //                     {
    //                       this.LoanNo = Value
    //                     }
    //                     if(Docarr.length -1 == k)
    //                     {
    //                       if(i == 0)
    //                       {
                           
    //                           obj ={}
    //                           EventData = [];
    //                          obj = {Activity:'Loan Closure',Date:'20 Sep 2021',App:'Ref: MC009587638',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                          EventData.push(obj);
    //                          innerEventData.push(EventData);
    //                          obj ={}
    //                          EventData = [];
    //                          obj = {Activity:'EMI Paid',Date:'19 Jul 2021',App:'Ref: MCB0075656372',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                          EventData.push(obj);
    //                          innerEventData.push(EventData);
    //                          obj ={}
    //                          EventData = [];
    //                          obj = {Activity:'EMI Paid',Date:'19 Jun 2021',App:'Ref: MCB006888979',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                          EventData.push(obj);
    //                          innerEventData.push(EventData);
    //                          obj ={}
    //                          EventData = [];
    //                          obj = {Activity:'Loan Sanctioned',Date:'18 May 2021',App:'A/c No:'+this.LoanNo,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                          EventData.push(obj);
    //                          innerEventData.push(EventData);
    //                          obj ={}
    //                          EventData = [];
    //                          obj = {Activity:'Loan Approval',Date:'29 Jan 2021',App:'A/c No:'+this.LoanNo,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                          EventData.push(obj);
    //                          innerEventData.push(EventData);
    //                          obj ={}
    //                          EventData = [];
    //                          obj = {Activity:'Loan Application',Date:'29 Jan 2021',App:'A/c No:'+this.LoanNo,Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                          EventData.push(obj);
    //                          innerEventData.push(EventData);
    //                       }
    //                     }
    //                    }
                     
                      
    //                 }
    //                 // if(i == 0)
    //                 // {
                     
    //                 //     obj ={}
    //                 //     EventData = [];
    //                 //    obj = {Activity:'Loan Closure',Date:'20 Sep 2021',App:'Ref: MC009587638',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                 //    EventData.push(obj);
    //                 //    innerEventData.push(EventData);
    //                 //    obj ={}
    //                 //    EventData = [];
    //                 //    obj = {Activity:'EMI Paid',Date:'19 Jul 2021',App:'Ref: MCB0075656372',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                 //    EventData.push(obj);
    //                 //    innerEventData.push(EventData);
    //                 //    obj ={}
    //                 //    EventData = [];
    //                 //    obj = {Activity:'EMI Paid',Date:'19 Jun 2021',App:'Ref: MCB006888979',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                 //    EventData.push(obj);
    //                 //    innerEventData.push(EventData);
    //                 //    obj ={}
    //                 //    EventData = [];
    //                 //    obj = {Activity:'Loan Sanctioned',Date:'18 May 2021',App:'A/c No:203',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                 //    EventData.push(obj);
    //                 //    innerEventData.push(EventData);
    //                 //    obj ={}
    //                 //    EventData = [];
    //                 //    obj = {Activity:'Loan Approval',Date:'29 Jan 2021',App:'A/c No:203',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                 //    EventData.push(obj);
    //                 //    innerEventData.push(EventData);
    //                 //    obj ={}
    //                 //    EventData = [];
    //                 //    obj = {Activity:'Loan Application',Date:'29 Jan 2021',App:'A/c No:203',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //                 //    EventData.push(obj);
    //                 //    innerEventData.push(EventData);
    //                 // }
    //               }
    //             else{
    //               for(let k=0;k<Docarr.length;k++)
    //               {
    //                 if(Value != "Loan Details")
    //                 {
    //                   if(DocArrData[k-1].Key == Key)
    //                   {
    //                         DocArrData[k-1].Value =Value;
    //                   }
    //                   if(Docarr.length-1 ==k)
    //                   {
    //                     console.log(DocArrData)
    //                     this.DocumentData =DocArrData
                      
    //                   }
    //                 }
    //               }
                 
    //             }
                  
    //             }
    //             let Docarrpic = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
    //     var removeValFromIndex = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];    

    //     for (let i = removeValFromIndex.length -1; i >= 0; i--)
    //       Docarrpic.splice(removeValFromIndex[i],1);

    // for(let m=0;m<Docarrpic.length;m++)
    // {
    //   if(Docarrpic[0] == 'Form:Loan Details')
    //   {
      
    //     var index = Docarrpic[m].indexOf(":");
    //     var Key = Docarrpic[m].substring(0,index);
    //     var Value = Docarrpic[m].substring(index+1,Docarrpic[m].length);
     
    //     if(Key == 'Loan Receipt')
    //     {
    //       var img = 'https://docs.google.com/viewerng/viewer?url='+Value+'&embedded=true';
    //        this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(img);
    //        if(i == 0)
    //        {
    //           // obj ={}
    //           // EventData = [];
    //           // var dateformat = datearr[1].split('/');
    //           // var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
    //           // var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
    //           // obj = {Activity:'Receipt Added to Loan',Date:dateUTC1,App:' Approved',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //           // EventData.push(obj);
    //           // innerEventData.push(EventData);
    //        }
    //     }
    //    if(Key == 'Asset')
    //     {
          
    //       this.assetImage = Value;
    //       if(i == 0)
    //       {
    //         this.images.push(Value)
         
    //         //  obj ={}
    //         //  EventData = [];
    //         //  var dateformat = datearr[1].split('/');
    //         //  var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
    //         //  var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
    //         //  obj = {Activity:'Asset Images Added to Loan',Date:dateUTC1,App:' Approved',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //         //  EventData.push(obj);
    //         //  innerEventData.push(EventData);
    //       }
    //     }
    //     if(Key == 'Cust Image')
    //     {
    //       this.CustImage = Value;
    //       console.log(this.CustImage)
    //     }
       
    //   }
               
    // }

    // if(Docarr[0] == 'Form:EMI Details')
    // {
    //   for(let s=0;s<Docarr.length;s++)
    //   {
    //     console.log(Docarr)
    //       var index = Docarr[s].indexOf(':');
    //       var Key = Docarr[s].substring(0, index);
    //       var Value = Docarr[s].substring(index + 1, Docarr[s].length);
    //        if(Key == 'EMI Amount')
    //        {
    //         // obj ={}
    //         // EventData = [];
    //         // var dateformat = datearr[1].split('/');
    //         // var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
    //         // var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
    //         // obj = {Activity:'EMI Amount :'+ Value,Date:dateUTC1,App:Docarr[3],Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
    //         // EventData.push(obj);
    //         // innerEventData.push(EventData);
    //        }
              
    //   }
    // }
       
    // if(data['posts']['childblockchaintriggers'].length-1 ==i)
    // {
    //     this.EventDocument = innerEventData;
    //     console.log(this.EventDocument)
    // }     
    //     // for (let k = 0; k < Docarr.length; k++) {
    //     //   obj = {};
    //     //   var index = Docarr[k].indexOf(':');
    //     //   var Key = Docarr[k].substring(0, index);
    //     //   var Value = Docarr[k].substring(index + 1, Docarr[k].length);

    //     //     obj[Key] = Value;
    //     //     DocArrData.push(obj);
    //     //     if (Docarr.length - 1 == k) {
    //     //       var dateformat = datearr[1].split('/');
    //     //       var dateUTC = new Date(
    //     //         dateformat[2] +
    //     //           '-' +
    //     //           dateformat[1] +
    //     //           '-' +
    //     //           dateformat[0] +
    //     //           ' ' +
    //     //           datearr[0] +
    //     //           ' UTC'
    //     //       );
    //     //       var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
    //     //       var objDate = {};
    //     //       objDate['Updated On'] = dateUTC1;
    //     //       DocArrData.push(objDate);
    //     //       var objVerify = {};
    //     //       objVerify['Verify'] =
    //     //         data['posts']['childblockchaintriggers'][i].blockchainurl;
    //     //       DocArrData.push(objVerify);
    //     //       AllData.push(
    //     //         DocArrData.reduce(function (result, current) {
    //     //           return Object.assign(result, current);
    //     //         }, {})
    //     //       );

    //     //       if (data['posts']['childblockchaintriggers'].length - 1 == i) {
    //     //         var val = _.keys(
    //     //           _.countBy(AllData, function (data) {
    //     //             return data.Form;
    //     //           })
    //     //         );
    //     //         for (let m = 0; m < val.length; m++) {
                  
    //     //           FilterData.push(
    //     //             AllData.filter((obj) => obj.Form == val[m])
    //     //           );
    //     //           if (val.length - 1 == m) {
    //     //             this.AllItems = FilterData;
    //     //             console.log(this.AllItems);
    //     //           }
    //     //         }
    //     //       }
    //     //     }
          
    //     // }
    //   }
    // });
    //             }
    //             else
    //             {
    //               this.showVerify(data["posts"].message);
    //             }
    //             });

   
  }
  hidedivForLandingpage1:boolean=false;
  //test
  VerifyOTP1(OTP) {
    this.hidediv = false;
    this.hidedivForLandingpage1 = true;
    this.hidefornextverification=true;
    this.LoanDetails = true;

    this._service.getBlockChainDataDetails(this.QRCodeVal).subscribe((data) => {
      this.LoanDetails = true;
      this.bolfUrl = true;
      console.log(data['posts']);
      // this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      //   data['posts']['fileurl']
      // );
      this.bolfUrl = true;
      this.bolAadhar = false;
      this.bolPan = false;
      this.bolValutor = false;
      let AllData = [];
      let innerEventData = [];
      for (
        let i = 0;
        i < data['posts']['childblockchaintriggers'].length;
        i++
      ) {
       let EventData = [];
        this.images = [];
        let obj = {};
        let DocArrData = [];
        let FilterData = [];
        let Docarr = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
        var datearr = data['posts']['childblockchaintriggers'][ i].blockchaintimestamp.split(' ');
        
      

                if(Docarr[0] == 'Form:Loan Details')
                {
                  Docarr.pop();
                  Docarr.pop();
                  Docarr.pop();
                  if(DocArrData.length == 0)
                  {
                    for(let k=0;k<Docarr.length;k++)
                    {
                      obj = {};
                       var index = Docarr[k].indexOf(":");
                       var Key = Docarr[k].substring(0,index);
                       var Value = Docarr[k].substring(index+1,Docarr[k].length);
                       if(Value != "Loan Details")
                       {
                        obj ={"Key":Key,"Separator":':',"Value":Value}
                        DocArrData.push(obj)
                        if(Key == "Customer Name")
                        {
                            this.CustomerName = Value
                        }
                        if(Docarr.length-1 ==k)
                        {
                          this.DocumentData =DocArrData
                          
                        }
                       }
                     
                      
                    }
                    if(i == 0)
                    {
                      //  obj ={}
                      //  var dateformat = datearr[1].split('/');
                      //  var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                      //  var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
                      //  obj = {Activity:'Loan has been Created',Date:dateUTC1,App:' Approved',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
                      //  EventData.push(obj);
                      //  innerEventData.push(EventData);

                        obj ={}
                        EventData = [];
                       obj = {Activity:'Loan Closure',Date:'20 Sep 2021',App:'Ref: MC009587638',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
                       EventData.push(obj);
                       innerEventData.push(EventData);
                       obj ={}
                       EventData = [];
                       obj = {Activity:'EMI Paid',Date:'19 Jul 2021',App:'Ref: MCB0075656372',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
                       EventData.push(obj);
                       innerEventData.push(EventData);
                       obj ={}
                       EventData = [];
                       obj = {Activity:'EMI Paid',Date:'19 Jun 2021',App:'Ref: MCB006888979',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
                       EventData.push(obj);
                       innerEventData.push(EventData);
                       obj ={}
                       EventData = [];
                       obj = {Activity:'Loan Sanctioned',Date:'18 May 2021',App:'A/c No:203',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
                       EventData.push(obj);
                       innerEventData.push(EventData);
                    }
                  }
                else{
                  for(let k=0;k<Docarr.length;k++)
                  {
                    if(Value != "Loan Details")
                    {
                      if(DocArrData[k-1].Key == Key)
                      {
                            DocArrData[k-1].Value =Value;
                      }
                      if(Docarr.length-1 ==k)
                      {
                        console.log(DocArrData)
                        this.DocumentData =DocArrData
                      
                      }
                    }
                  }
                 
                }
                  
                }
                let Docarrpic = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
        var removeValFromIndex = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];    

        for (let i = removeValFromIndex.length -1; i >= 0; i--)
          Docarrpic.splice(removeValFromIndex[i],1);

    for(let m=0;m<Docarrpic.length;m++)
    {
      if(Docarrpic[0] == 'Form:Loan Details')
      {
      
        var index = Docarrpic[m].indexOf(":");
        var Key = Docarrpic[m].substring(0,index);
        var Value = Docarrpic[m].substring(index+1,Docarrpic[m].length);
     
        if(Key == 'Loan Receipt')
        {
          var img = 'https://docs.google.com/viewerng/viewer?url='+Value+'&embedded=true';
           this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(img);
           if(i == 0)
           {
              // obj ={}
              // EventData = [];
              // var dateformat = datearr[1].split('/');
              // var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
              // var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
              // obj = {Activity:'Receipt Added to Loan',Date:dateUTC1,App:' Approved',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
              // EventData.push(obj);
              // innerEventData.push(EventData);
           }
        }
       if(Key == 'Asset')
        {
          
          this.assetImage = Value;
          if(i == 0)
          {
            this.images.push(Value)
         
            //  obj ={}
            //  EventData = [];
            //  var dateformat = datearr[1].split('/');
            //  var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
            //  var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
            //  obj = {Activity:'Asset Images Added to Loan',Date:dateUTC1,App:' Approved',Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
            //  EventData.push(obj);
            //  innerEventData.push(EventData);
          }
        }
        if(Key == 'Cust Image')
        {
          this.CustImage = Value;
        }
       
      }
               
    }

    if(Docarr[0] == 'Form:EMI Details')
    {
      for(let s=0;s<Docarr.length;s++)
      {
        console.log(Docarr)
          var index = Docarr[s].indexOf(':');
          var Key = Docarr[s].substring(0, index);
          var Value = Docarr[s].substring(index + 1, Docarr[s].length);
           if(Key == 'EMI Amount')
           {
            // obj ={}
            // EventData = [];
            // var dateformat = datearr[1].split('/');
            // var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
            // var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
            // obj = {Activity:'EMI Amount :'+ Value,Date:dateUTC1,App:Docarr[3],Verify:data['posts']['childblockchaintriggers'][i].blockchainurl}
            // EventData.push(obj);
            // innerEventData.push(EventData);
           }
              
      }
    }
       
    if(data['posts']['childblockchaintriggers'].length-1 ==i)
    {
        this.EventDocument = innerEventData;
        console.log(this.EventDocument)
    }     
        // for (let k = 0; k < Docarr.length; k++) {
        //   obj = {};
        //   var index = Docarr[k].indexOf(':');
        //   var Key = Docarr[k].substring(0, index);
        //   var Value = Docarr[k].substring(index + 1, Docarr[k].length);

        //     obj[Key] = Value;
        //     DocArrData.push(obj);
        //     if (Docarr.length - 1 == k) {
        //       var dateformat = datearr[1].split('/');
        //       var dateUTC = new Date(
        //         dateformat[2] +
        //           '-' +
        //           dateformat[1] +
        //           '-' +
        //           dateformat[0] +
        //           ' ' +
        //           datearr[0] +
        //           ' UTC'
        //       );
        //       var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
        //       var objDate = {};
        //       objDate['Updated On'] = dateUTC1;
        //       DocArrData.push(objDate);
        //       var objVerify = {};
        //       objVerify['Verify'] =
        //         data['posts']['childblockchaintriggers'][i].blockchainurl;
        //       DocArrData.push(objVerify);
        //       AllData.push(
        //         DocArrData.reduce(function (result, current) {
        //           return Object.assign(result, current);
        //         }, {})
        //       );

        //       if (data['posts']['childblockchaintriggers'].length - 1 == i) {
        //         var val = _.keys(
        //           _.countBy(AllData, function (data) {
        //             return data.Form;
        //           })
        //         );
        //         for (let m = 0; m < val.length; m++) {
                  
        //           FilterData.push(
        //             AllData.filter((obj) => obj.Form == val[m])
        //           );
        //           if (val.length - 1 == m) {
        //             this.AllItems = FilterData;
        //             console.log(this.AllItems);
        //           }
        //         }
        //       }
        //     }
          
        // }
      }
    });
  }

  //end test

  //WithOut OTP
  // VerifyOTP(OTP) {

  //     this.hidediv = false;
  //     this.hidedivForLandingpage = true;
  //     this.LoanDetails = true;

  //     this._service.getBlockChainDataDetails(this.QRCodeVal).subscribe(data => {
  //         this.LoanDetails = true;
  //         this.bolfUrl=true;
  //         console.log(data["posts"])
  //         console.log(data["posts"]["fileurl"])
  //         this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(data["posts"]["fileurl"]);
  //         this.bolfUrl=true;
  //         this.bolAadhar=false;
  //         this.bolPan = false;
  //         this.bolValutor = false;
  //         for (let i = 0; i < data["posts"]["childblockchaintriggers"].length; i++) {
  //             let obj = {};
  //             let DocArrData =[];
  //             if (i == 0) {
  //                let Docarr = data["posts"]["childblockchaintriggers"][i].blockchaindata.split('||');
  //                for(let k=0;k<Docarr.length;k++)
  //                {
  //                  obj = {};
  //                   var index = Docarr[k].indexOf(":");
  //                   var Key = Docarr[k].substring(0,index);
  //                   var Value = Docarr[k].substring(index+1,Docarr[k].length);
  //                   obj ={"Key":Key,"Separator":':',"Value":Value}
  //                   DocArrData.push(obj)
  //                }
  //                 console.log(DocArrData)
  //                 this.DocumentData =DocArrData
  //             }
  //             else {
  //                 let obj ={};
  //                 var datearr = data["posts"]["childblockchaintriggers"][i].blockchaintimestamp.split(' ');
  //                 var dateformat = datearr[1].split('/');
  //                 var dateUTC = new Date(dateformat[2] + '-' + dateformat[1] + "-" + dateformat[0] + " " + datearr[0] + " UTC");
  //                 console.log(dateUTC)
  //                 var dateUTC1 =moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
  //                  let arr = data["posts"]["childblockchaintriggers"][i].blockchaindata.split('||')

  //                  if(i ==  (data["posts"]["childblockchaintriggers"].length -1))
  //                  {
  //                      debugger;
  //                     let Headerarr =[];
  //                     console.log(i)
  //                     for(let j=0;j<arr.length;j++)
  //                     {
  //                         debugger;
  //                        var index = arr[j].indexOf(":");
  //                        Headerarr[j] = arr[j].substring(0,index);

  //                     }
  //                     Headerarr.push("Updated On")
  //                     Headerarr.push("Verify")
  //                     this.HeaderResult.push(Headerarr)
  //                  }

  //                  for(let j=0;j<arr.length;j++)
  //                  {
  //                     var index = arr[j].indexOf(":");
  //                     arr[j] = arr[j].substring(index+1,arr[j].length);

  //                  }
  //                //  console.log(Headerarr)
  //                  arr.push(dateUTC1)
  //                  arr.push(data["posts"]["childblockchaintriggers"][i].blockchainurl)

  //                  this.blockChainResult.push(arr)

  //             }
  //         }
  //         this.res = this.blockChainResult;
  //         this.Header = this.HeaderResult;
  //         console.log(this.Header)
  //     })

  //     }

  //With OTP
  // VerifyOTP(OTP) {
  //    // this.MobileNo = 9160143927;
  //     //let otpvalue =this.ngOtpInputRef.otpForm.value.ctrl_0+this.ngOtpInputRef.otpForm.value.ctrl_1+this.ngOtpInputRef.otpForm.value.ctrl_2+this.ngOtpInputRef.otpForm.value.ctrl_3+this.ngOtpInputRef.otpForm.value.ctrl_4+this.ngOtpInputRef.otpForm.value.ctrl_5;
  //     let otpvalue = this.ngOtpInputRef.otpForm.value.ctrl_0 + this.ngOtpInputRef.otpForm.value.ctrl_1 + this.ngOtpInputRef.otpForm.value.ctrl_2 + this.ngOtpInputRef.otpForm.value.ctrl_3;
  //     console.log(this.QRCodeVal)
  //     this._service.VerifyOTP(this.MobileNo, otpvalue).subscribe(data => {

  //         console.log(data["posts"].message)

  //         if (data["posts"].message == "OTP verified success") {
  //             this.showVerify(data["posts"].message)
  //             this.hidediv = false;
  //             this.hidedivForLandingpage = true;
  //             this.LoanDetails = true;

  //             this._service.getBlockChainDataDetails(this.QRCodeVal).subscribe(data => {
  //                 this.LoanDetails = true;
  //                 this.bolfUrl=true;
  //                 console.log(data["posts"])
  //                 console.log(data["posts"]["fileurl"])
  //                 this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(data["posts"]["fileurl"]);
  //                 this.bolfUrl=true;
  //                 this.bolAadhar=false;
  //                 this.bolPan = false;
  //                 this.bolValutor = false;
  //                 for (let i = 0; i < data["posts"]["childblockchaintriggers"].length; i++) {
  //                     let obj = {};
  //                     let DocArrData =[];
  //                     if (i == 0) {
  //                        let Docarr = data["posts"]["childblockchaintriggers"][i].blockchaindata.split('||');
  //                        for(let k=0;k<Docarr.length;k++)
  //                        {
  //                          obj = {};
  //                           var index = Docarr[k].indexOf(":");
  //                           var Key = Docarr[k].substring(0,index);
  //                           var Value = Docarr[k].substring(index+1,Docarr[k].length);
  //                           obj ={"Key":Key,"Separator":':',"Value":Value}
  //                           DocArrData.push(obj)
  //                        }
  //                         console.log(DocArrData)
  //                         this.DocumentData =DocArrData
  //                     }
  //                     else {
  //                         let obj ={};
  //                         var datearr = data["posts"]["childblockchaintriggers"][i].blockchaintimestamp.split(' ');
  //                         var dateformat = datearr[1].split('/');
  //                         var dateUTC = new Date(dateformat[2] + '-' + dateformat[1] + "-" + dateformat[0] + " " + datearr[0] + " UTC");
  //                          let arr = data["posts"]["childblockchaintriggers"][i].blockchaindata.split('||')

  //                          if(i ==  (data["posts"]["childblockchaintriggers"].length -1))
  //                          {
  //                              debugger;
  //                             let Headerarr =[];
  //                             console.log(i)
  //                             for(let j=0;j<arr.length;j++)
  //                             {
  //                                 debugger;
  //                                var index = arr[j].indexOf(":");
  //                                Headerarr[j] = arr[j].substring(0,index);

  //                             }
  //                             Headerarr.push("Updated On")
  //                             Headerarr.push("Verify")
  //                             this.HeaderResult.push(Headerarr)
  //                          }

  //                          for(let j=0;j<arr.length;j++)
  //                          {
  //                             var index = arr[j].indexOf(":");
  //                             arr[j] = arr[j].substring(index+1,arr[j].length);

  //                          }
  //                        //  console.log(Headerarr)
  //                          arr.push(dateUTC)
  //                          arr.push(data["posts"]["childblockchaintriggers"][i].blockchainurl)

  //                          this.blockChainResult.push(arr)

  //                     }
  //                 }
  //                 this.res = this.blockChainResult;
  //                 this.Header = this.HeaderResult;
  //                 console.log(this.Header)
  //             })
  //         }
  //         else {
  //             this.showVerify(data["posts"].message)
  //         }

  //     })

  // }

  clickLoanDtl() {
    this.loanDtl = true;
    this.docDtl = false;
    this.assetDtl = false;
    this.approvalsDtl = false;
    this.eventDtl = false;
  }

  clickdocDtlDtl() {
    this.loanDtl = false;
    this.docDtl = true;
    this.assetDtl = false;
    this.approvalsDtl = false;
    this.eventDtl = false;
  }
  clickassetDtlDtl() {
    this.loanDtl = false;
    this.docDtl = false;
    this.assetDtl = true;
    this.approvalsDtl = false;
    this.eventDtl = false;
  }
  clickapprovalsDtlDtl() {
    this.loanDtl = false;
    this.docDtl = false;
    this.assetDtl = false;
    this.approvalsDtl = true;
    this.eventDtl = false;
  }
  clickeventDtlDtl() {
    this.loanDtl = false;
    this.docDtl = false;
    this.assetDtl = false;
    this.approvalsDtl = false;
    this.eventDtl = true;
  }

  refresh1() {
    this.loanDtl = false;
    this.docDtl = false;
    this.assetDtl = false;
    this.approvalsDtl = false;
    this.eventDtl = false;
  }
  onLoanDetails() {
    this.tab = 'tab1';
    this.LoanDetails = true;
    this.DocDetails = false;
    this.AssetDetails = false;
    this.ApporvedDetails = false;
    this.EventDetails = false;
  }
  onDocDetails() {
    this.tab = 'tab2';
    this.LoanDetails = false;
    this.DocDetails = true;
    this.AssetDetails = false;
    this.ApporvedDetails = false;
    this.EventDetails = false;
  }
  onAssetDetails() {
    this.tab = 'tab3';
    this.LoanDetails = false;
    this.DocDetails = false;
    this.AssetDetails = true;
    this.ApporvedDetails = false;
    this.EventDetails = false;
  }
  onEventDetails() {
    this.tab = 'tab4';
    this.LoanDetails = false;
    this.DocDetails = false;
    this.AssetDetails = false;
    this.ApporvedDetails = false;
    this.EventDetails = true;
  }
  onApprovedtDetails() {
    this.LoanDetails = false;
    this.DocDetails = false;
    this.AssetDetails = false;
    this.ApporvedDetails = true;
    this.EventDetails = true;
  }
}
