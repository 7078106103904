import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Configuration',
  templateUrl: './Configuration.component.html',
  styleUrls: ['./Configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
