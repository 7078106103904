<div class="portal-form" style="margin-top:70px;margin-bottom:70px;margin-left: -0px;margin-right: -0px;">
  <section>
    <div class="container  tab-content">
      <div class="card">
        <div class="card-header h4-style ">

          <div class="row">
            <div class="col-sm-10">
              <h3>User</h3>
            </div>
            <div class="col-sm-2">
              <button class="btn pull-right addnew" (click)="refresh();onClearAll();addedited=false;this.isSave=false;"
                *ngIf="addedited" title="Back to User">
                <i style="color: #E6800E;" class="fa fa-times"></i>
              </button>
              <button class="btn pull-right addnew"
                style="color: #E6800E ;font-size: 16px;font-family: open sans-serif;" *ngIf="!addedited"
                title="Create User" (click)="addedited=true;">
                <i style="color: #E6800E;" class="fa fa-plus"></i> Create New
              </button>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="!addedited" style="min-height: auto;" style="height:482px">
          <div class="box">
            <div style="overflow-x: auto;margin-top: 0px;">
              <table id="customers"
                style="width:100%;white-space: nowrap;overflow: hidden;font-family: open sans-serif; color:#777777;font-size: 16px;">
                <thead class="thead">
                  <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Organisation</th>
                    <th>Branch</th>
                    <th>User Name</th>
                    <th>Password</th>
                    <th>Role</th>
                    <th>Email</th>
                    <th>Contact Number</th>


                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of UserListList; let i=index;">
                    <td>
                      <i class="fa fa-edit" (click)="getUserbyid(data.Id)">
                      </i>
                    </td>
                    <td>{{i+1}}</td>
                    <td>{{data.OrganisationName}}</td>
                    <td>{{data.BranchName}}</td>
                    <td>{{data.UserName}}</td>
                    <td>{{data.Password}}</td>
                    <td>{{data.RoleName}}</td>
                    <td>{{data.Email}}</td>
                    <td>{{data.ContactNumber}}</td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="addedited" style="height: 482px;">
          <form [formGroup]="UserForm" autocomplete="off">
            <div class="row" style="margin-top: 10px;">
              <div class="col-sm-6">
                <div class="card-body">
                
                  <div class="form-group card-text pb-1 row">
                    <label for="usr" class="col-sm-12 col-lg-4  col-form-label text-right">
                      Organisation<sup class="star-style" style="font-size: 14px;">*</sup>:</label>
                    <!-- <div class="col-sm-12 col-lg-8 border p-1"> -->
                      <!-- <input type="text" class="form-control" formControlName="OrganisationId" id="OrganisationId" placeholder="Enter Organisation"> -->
                      <!-- <select class="form-control pl-2" id="OrganisationId" formControlName="OrganisationId" name="Select">
                        <option disabled>Select Organisation</option>
                        <option *ngFor="let olt of OrgList" [ngValue]="olt.Id">
                          {{ olt.Name }}
                        </option>
                    </select> -->
                    <div class="ng-autocomplete col-sm-12 col-lg-8 border p-1">
                      <ng-autocomplete #ngAutoCompleteReactive
                                       [data]="countriesReactive"
                                       formControlName="name"
                                       [initialValue]=""
                                       [placeholder]="placeholder"
                                       [searchKeyword]="keyword"
                                       historyIdentifier="countriesReactiveList"
                                       [historyHeading]="historyHeading"
                                       historyListMaxNumber="3"
                                       notFoundText="Not found"
                                       [itemTemplate]="itemTemplateReactive"
                                       [notFoundTemplate]="notFoundTemplate">
                      </ng-autocomplete>
            
                      <ng-template #itemTemplateReactive let-item>
                        <a [innerHTML]="item.name"></a>
                      </ng-template>
            
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  <!-- </div> -->
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card-body">
                
                  <div class="form-group pb-1 card-text row">
                    <label for="usr" class="col-sm-12 col-lg-4 col-form-label text-right">
                      Branch<sup class="star-style" style="font-size: 14px;">*</sup>:</label>
                    <!-- <div class="col-sm-12 col-lg-8 border p-1"> -->
                      <!-- <input type="text" class="form-control"  formControlName="BranchId" id="BranchId" placeholder="Enter Branch"> -->
                      <!-- <select class="form-control pl-2" id="BranchId" formControlName="BranchId" name="Select">
                        <option disabled>Select Organisation</option>
                        <option *ngFor="let olt of BranchList" [ngValue]="olt.Id">
                          {{ olt.Name }}
                        </option>
                    </select> -->
                    <div class="ng-autocomplete col-sm-12 col-lg-8 border p-1">
                      <ng-autocomplete #ngAutoCompleteReactive
                                       [data]="countriesReactive1"
                                       formControlName="name1"
                                       [initialValue]=""
                                       [placeholder]="placeholder1"
                                       [searchKeyword]="keyword1"
                                       historyIdentifier="countriesReactiveList1"
                                       [historyHeading]="historyHeading1"
                                       historyListMaxNumber="3"
                                       notFoundText="Not found"
                                       [itemTemplate]="itemTemplateReactive1"
                                       [notFoundTemplate]="notFoundTemplate1">
                      </ng-autocomplete>
            
                      <ng-template #itemTemplateReactive1 let-item>
                        <a [innerHTML]="item.name"></a>
                      </ng-template>
            
                      <ng-template #notFoundTemplate1 let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="card-body">
                
                  <div class="form-group card-text pb-1 row">
                    <label for="usr" class="col-sm-12 col-lg-4  col-form-label text-right">
                      User Name<sup class="star-style" style="font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <input type="text" class="form-control" formControlName="UserName" id="UserName" placeholder="Enter User Name">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card-body">
             
                  <div class="form-group pb-1 card-text row">
                    <label for="usr" class="col-sm-12 col-lg-4 col-form-label text-right">
                      Password<sup class="star-style" style="font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <input type="password" class="form-control"  formControlName="Password" id="usr" placeholder="Enter Password">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--2nd-row-->
            <div class="row">
              <div class="col-sm-6">
                <!-- <div class="card"> -->
                <div class="card-body">
                  <div class="form-group pb-1 card-text row">
                    <label for="usr" class="col-sm-12 col-lg-4 col-form-label text-right">
                      First Name<sup class="star-style" style="font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <input type="text" class="form-control" autocomplete="false"  formControlName="FirstName" id="FirstName" placeholder="Enter First Name">
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
              <div class="col-sm-6">
                <div class="card-body">

                  <div class="form-group pb-1 card-text row">
                    <label for="usr" class="col-sm-12 col-lg-4 col-form-label text-right">
                      Last Name<sup class="star-style" style="font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <input type="text" class="form-control" autocomplete="false" formControlName="LastName" id="LastName" placeholder="Enter Last Name">
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!--2nd-col--->
            <div class="row">
              <div class="col-sm-6">
                <div class="card-body">

                  <div class="form-group pb-1 card-text row">
                    <label for="usr" class="col-sm-12 col-lg-4 text-right col-form-label">
                      Role<sup class="star-style" style="font-size: 14px;">*</sup>:</label>
                    <!-- <div class="col-sm-12 col-lg-8 border p-1"> -->
                      <!-- <input type="number" class="form-control"  formControlName="RoleId" id="usr" placeholder="Enter Role" name="phone" required> -->
                      <!-- <select class="form-control pl-2" id="RoleId" formControlName="RoleId" name="Select">
                        <option disabled>Select Role</option>
                        <option *ngFor="let olt of RoleList" [ngValue]="olt.Id">
                          {{ olt.Name }}
                        </option>
                    </select> -->
                    <div  class="ng-autocomplete col-sm-12 col-lg-8 border p-1 border">
                      <ng-autocomplete #ngAutoCompleteReactive
                                       [data]="countriesReactive2"
                                       formControlName="name2"
                                       [initialValue]=""
                                       [placeholder]="placeholder2"
                                       [searchKeyword]="keyword2"
                                       historyIdentifier="countriesReactiveList2"
                                       [historyHeading]="historyHeading2"
                                       historyListMaxNumber="3"
                                       notFoundText="Not found"
                                       [itemTemplate]="itemTemplateReactive2"
                                       [notFoundTemplate]="notFoundTemplate2">
                      </ng-autocomplete>
            
                      <ng-template #itemTemplateReactive2 let-item>
                        <a [innerHTML]="item.name"></a>
                      </ng-template>
            
                      <ng-template #notFoundTemplate2 let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                    <!-- </div> -->
                  </div>

                </div>

              </div>
              <!-- </div> -->

              <div class="col-sm-6">
                <div class="card-body">
                  <!--  <h5 class="card-title">Contact Details</h5>   -->
                  <div class="form-group pb-1 card-text row">
                    <label for="usr" class="col-sm-12 col-lg-4 text-right col-form-label">
                      Mobile Number<sup class="star-style" style="font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <input type="text" class="form-control"  autocomplete="false" (keypress)="validateNumber($event);" minlength="10" maxlength="10"  formControlName="ContactNumber" id="ContactNumber" placeholder="Enter Mobile Number" name="phone"
                        required>
                    </div>
                  </div>

                </div>

              </div>
            </div>




            <!--3rd-row-->

            <!---4th-row-->
            <div class="row">
              <div class="col-sm-6">
                <!-- <div class="card"> -->
                <div class="card-body">
                  <!--  <h5 class="card-title">&nbsp;</h5>  -->
                  <div class="form-group pb-1 card-text row">

                    <label for="email" class="col-sm-12 col-lg-4 text-right col-form-label">Email &nbsp; :</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <input type="email" class="form-control" autocomplete="false" pattern="^[^ ]+@[^ ]+\.[a-z]{2,6}$"  formControlName="Email" id="email" placeholder="Enter Email" name="email">
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
              <div class="col-sm-6">

              </div>
            </div>



            <!--form-end-->
            <div class="d-flex justify-content-end mt-4 mb-4 card-body">
              <button type="save" (click)="onClearAll()" class="btn btn-clear mr-3">Clear All</button>
              <button type="save" (click)="onSave()" *ngIf=" isSave" class="btn btn-save">Save</button>
              <button type="save" (click)="onUpdate(UserForm.value);" *ngIf="isUpdate"
                class="btn btn-save">Update</button>
            </div>


          </form>
        </div>
      </div>
      <div class="row pull-right" style="float:right;margin:15px">
        <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
      </div>
    </div>
    <div id="snackbar"></div>
    <div id="snackbarerror"></div>
  </section>
</div>