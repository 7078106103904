<div class="portal-form" style="margin-top:70px;margin-bottom:70px;margin-left: -0px;margin-right: -0px;">
  <section>
    <div class="container  tab-content">
      <div class="card">
        <div class="card-header h4-style ">

          <div class="row">
            <div class="col-sm-10">
              <h3>Role</h3>
            </div>
            <div class="col-sm-2">
              <button class="btn pull-right addnew" (click)="refresh();onClearAll();addedited=false;this.isSave=false;"
                *ngIf="addedited" title="Back to Role">
                <i style="color: #E6800E;" class="fa fa-times"></i>
              </button>
              <button class="btn pull-right addnew"
                style="color: #E6800E ;font-size: 16px;font-family: open sans-serif;" *ngIf="!addedited"
                title="Create  Role" (click)="addedited=true;">
                <i style="color: #E6800E;" class="fa fa-plus"></i> Create New
              </button>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="!addedited" style="min-height: auto;" style="height:482px">
          <div class="box">
            <div style="overflow-x: auto;margin-top: 0px;">
              <table id="customers"
                style="width:100%;white-space: nowrap;overflow: hidden;font-family: open sans-serif; color:#777777;font-size: 16px;">
                <thead class="thead">
                  <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Role Code</th>
                    <th>Role Name</th>
                    <!-- <th>Created Date</th>
                    <th>Updated Date</th> -->
                   


                  </tr>
                </thead>
                <tbody><!--<i class="fad fa-pencil"></i>-->
                  <tr *ngFor="let data of RoleList; let i=index;">
                    <td style="align-self: center;">
                      <i class="fa fa-edit" style="color:blue" (click)="getRolebyid(data.Id,data)">
                      </i>
                      <!-- <span class="glyphicon glyphicon-edit" style="color:blue" (click)="getRolebyid(data.Id,data)"></span> -->
                      <!-- <span class="glyphicon glyphicon-pencil"></span> -->
                    </td>
                    <td>{{i+1}}</td>
                    <td>{{data.RoleCode}}</td>
                    <td>{{data.Name}}</td>
                    <!-- <th></th>
                    <th></th> -->
                    

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="addedited" style="height: 482px;">
          <form [formGroup]="RoleForm" autocomplete="off">
            <!-- <div class="row">
          <div class="col-sm-12">
              <div class="card-header h4-style"> <h3>Branch View</h3></div>
               </div>
          </div> -->
            <div class="row" style="margin-right: 120px;">
              <div class="col-sm-12">
                <div class="card-body">

                  <div class="form-group card-text pb-1 row">
                    <label for="usr" class="col-sm-12 col-lg-4  col-form-label text-right">
                      Role Code<sup style="color: #e5800f; font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <!-- <div *ngIf="Name.invalid && (Name.dirty || Name.touched)" class="alert alert-danger">
                    
                          <div *ngIf="Name.errors.required">
                            Branch  Name is required
                          </div>
                        </div> -->
                      <input type="text" class="form-control" id="RoleCode" formControlName="RoleCode"
                        placeholder="Enter Role Code " autocomplete="false" required>
                      <!-- <div class="validator"
                        *ngIf="RoleForm.controls['RoleCode'].touched && !RoleForm.controls['RoleCode'].valid">
                        <span style= "color:red" *ngIf="RoleForm.controls['RoleCode'].hasError('required') ">
                            Please Enter Role Code
                        </span>
                    </div> -->

                    </div>

                  </div>
                </div>

              </div>
              <div class="col-sm-12">
                <div class="card-body">
                  <div class="form-group pb-1 card-text row">
                    <label for="usr" class="col-sm-12 col-lg-4 col-form-label text-right">
                      Role Name <sup style="color: #e5800f;  font-size: 14px;">*</sup>:</label>
                    <div class="col-sm-12 col-lg-8 border p-1">
                      <!-- <div *ngIf="Code.invalid && (Code.dirty || Code.touched)" class="alert alert-danger">
                    
                      <div *ngIf="Code.errors.required">
                        Code is required
                      </div>
                    </div> -->
                      <input type="text" class="form-control" id="Name" formControlName="Name"
                        placeholder="Enter Role Name" autocomplete="false" required>
                      <!-- <div class="validator"
                  *ngIf="RoleForm.controls['RoleName'].touched && !RoleForm.controls['RoleName'].valid">
                  <span style= "color:red" *ngIf="RoleForm.controls['RoleName'].hasError('required') ">
                      Please Enter Role Name
                  </span>
              </div> -->
                    </div>

                  </div>
                </div>
              </div>

              <!--form-end-->
              <div class="d-flex justify-content-end mt-4 mb-4 card-body">
                <button type="save" (click)="onClearAll()" class="btn btn-clear mr-3">Clear All</button>
                <button type="save" (click)="onSave()" *ngIf=" isSave" class="btn btn-save">Save</button>
                <button type="save" (click)="onUpdate(RoleForm.value);" *ngIf="isUpdate"
                  class="btn btn-save">Update</button>
              </div>
            </div>



          </form>
        </div>
      </div>
      <div class="row pull-right" style="float:right;margin:15px">
        <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
      </div>
    </div>
    <div id="snackbar"></div>
    <div id="snackbarerror"></div>
  </section>
</div>