<!-- <ngx-spinner
bdColor="#f2f2f2"
size="large"
color="#ff751a"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: #000"><img src="../assets/img/Bspinner.png"></p>
</ngx-spinner> -->
<ngx-spinner
bdColor="#f2f2f2"
size="medium"
color="#ff751a" name="spinner-1"
type="ball-atom"
>
<p style="font-size: 20px; color: #000">Please Wait...</p>
</ngx-spinner>
<ngx-spinner 
bdColor="#f2f2f2" name="spinner-2" size="small">
<p style="font-size: 20px; color: white"><img src="../assets/img/Bspinner.png" style="height: 100%;width: 100%;"/></p>
</ngx-spinner>
<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <nav class="col-md-6 navbar navbar-expand-lg navbar-light bg fixed-top" style="padding: 0px !important;margin: 0 auto;float: none;">
      <img src="../assets/img/image@2x.png" style="width: 100%;height: 100px;" />
    </nav>
  </div>
</div>
<div class="container-fluid" style="padding: 0px !important;margin-top: 100px;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divfirst">
      <div class="card-header" style="text-align: center;font-size: 15px;font-weight: bold;">
        Public Distribution System </div>
      <div class="card-body" style="padding: 1px !important;height: 560px;">
        <div id="demo" class="carousel slide" data-ride="carousel">

          <!-- Indicators -->
          <ul class="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" class="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
          </ul>
          
          <!-- The slideshow -->
          <div class="carousel-inner" style="text-align: center;">
            <div class="carousel-item active">
              <img src="../../assets/img/1.png" style="height: 330px;width: 290px;" >
            </div>
            <div class="carousel-item">
              <img src="../../assets/img/2.png" style="height: 330px;width: 290px;">
            </div>
            <div class="carousel-item">
              <img src="../../assets/img/3.png"  style="height: 330px;width: 290px;">
            </div>
          </div>
          
          <!-- Left and right controls -->
          <a class="carousel-control-prev" href="#demo" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </a>
          <a class="carousel-control-next" href="#demo" data-slide="next">
            <span class="carousel-control-next-icon"></span>
          </a>
        </div>
        
       
        <div class="table-responsive-sm">
          <div class="text-center">
            <mat-form-field appearance="outline">
                <mat-label>DC Number</mat-label>
              <input matInput id="dcnumber">
            </mat-form-field>
          </div>
            <div class="text-center">
              <i class="fa fa-arrow-circle-right" style="color: #FF7143;font-size: 50px;" (click)="getdetails()"></i>
              <!-- <button type="button" class="btn btn-primary btn-lg" (click)="getdetails()" style="background-color: #1e3978;">Get Details</button> -->
            </div>
            <!-- <p style="font-style: italic;font-size:12px;text-align: center;margin-top: 10px;">
              Use Camera to scan QR
            </p> -->
        </div>

      </div>
    </div>
    <div class="table-responsive-sm" *ngIf="divSelect">
      <div style="text-align: center;">
        <mat-form-field appearance="outline">
            <mat-label>Lot Number</mat-label>
            <mat-select name="LotNo"  placeholder="Lot No" [(ngModel)]="selectedLotNo" (selectionChange)="OnChangeLotNo($event.value)">
              <mat-option *ngFor="let obj of filteredLotNo" [value]="obj.Value">{{obj.Text}}</mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="form-group"> 
        <div class="row">
          <div class="col-2"></div>
          <div class="col-2">
            <label style="padding-top: 5px;text-align: center;">Lot No</label>
          </div>
          <div class="col-6">
            <select placeholder="Lot No" [(ngModel)]="selectedLotNo" (change)="OnChangeLotNo($event.value)" class="form-control selectpicker">
              <option *ngFor="let obj of filteredLotNo" [value]="obj.Value">{{obj.Text}}</option>
            </select>
          </div>
          <div class="col-2"></div>
        </div>
    </div> -->

    <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divsecond">
      <!-- <div class="card-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: #312f30;">
        <img src="../assets/img/CSM.jpg" width="100px" height="50px" /></div> -->
      <div class="card-body" style="padding: 0px;">
        <div class="card-header" style="text-align: center;font-size: 15px;font-weight: bold;">
          PDS - Hyperledger Blockchain Verification </div>
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif;padding: 0px;" >
            <thead>
            </thead>
            <tbody>
              <tr>
              <td style="padding-top: 10px;width:150px">DC No</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{dcNumber}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Miller</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{MillerName}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Depot</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{DepotName}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Depot Code</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{DepotCode}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Commodity</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{CommodityName}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Variety</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{Variety}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Paddy Proc. Dist.</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{district}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">DC Date</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{DateOfChallan | date: 'dd-MM-yyyy'}}</td>
            </tr>
            <!-- <tr>
              <td style="padding-top: 10px;width:150px">Lot</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{Lot}}</td>
            </tr> -->
            <tr>
              <td style="padding-top: 10px;width:150px">No. of Lot</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{NoofLots}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">No. of Bags</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{addbags}}</td>
              <!-- <td style="padding-top: 10px;">{{NoOfBags}}</td> -->
            </tr>
               <!-- <tr>
              <td style="padding-top: 10px;width:150px">Total No of Bags</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{TotalBags}}</td>
            </tr> -->
            <tr>
              <td style="padding-top: 10px;width:150px">KMS</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{KMSyear}}</td>
            </tr>
            <!-- <tr>
              <td style="padding-top: 10px;width:150px">TC No</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{TCNo}}</td>
            </tr> -->
            <tr>
              <td style="padding-top: 10px;width:150px">Packing Material</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{PackagingMaterial}}</td>
            </tr>
            <!-- <tr>
              <td style="padding-top: 10px;width:150px">Vehicle No</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{VehicleNo}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Driver</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{DriverNo}}</td>
            </tr> -->
            </tbody>
            </table>
            </div>
            <div class="table-responsive-sm" style="padding: 10px;">
              <table class="table" id="table1" style="font-family: open sans-serif;padding: 2px;border: 1px solid #D3D3D3; " >
                <tr >
                  <th id="table1" style="border: 1px solid #D3D3D3;">TC</th>
                  <th id="table1" style="border: 1px solid #D3D3D3;">Quantity(Qt.)</th>
                  <th id="table1" style="border: 1px solid #D3D3D3;">Vehicle No</th>
                  <th id="table1" style="border: 1px solid #D3D3D3;">Driver</th>
                </tr>
               
                  <tr *ngFor = "let obj of vehicle">
                      <td id="table1" style="border: 1px solid #D3D3D3;">{{obj.TC}}</td>
                      <td id="table1" style="border: 1px solid #D3D3D3;">{{obj.Quantity}}</td>
                      <td id="table1" style="border: 1px solid #D3D3D3;">{{obj.VNo}}</td>
                      <td id="table1" style="border: 1px solid #D3D3D3;">{{obj.Name}}</td>
                  </tr>
               
              </table>
              </div>
      </div>
    </div>
    <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divthird">
      <div class="card-body" style="padding: 0px;">
        <div class="card-header" style="text-align: center;font-size: 15px;font-weight: bold;">
          Stack Details </div>
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif;padding: 0px;" >
            <thead>
            </thead>
            <tbody>
              <tr>
              <td style="padding-top: 10px;width:150px">Sample No</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{SampleNo}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Sample Date</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{SampleDate | date: 'dd-MM-yyyy'}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Recieve Date</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{RecieveDate | date: 'dd-MM-yyyy'}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Stack Name</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{StockName}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Stack Quantity (Qt.)</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{StockQuantity}} </td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">Bags (Nos.)</td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{Bags}}</td>
            </tr>
           
            </tbody>
            </table>
            </div>

      </div>
    </div>
    <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divthird">
      <div class="card-header" style="text-align: center;font-size: 15px;font-weight: bold;">
        Quality Analysis</div>
      <div class="card-body" style="padding: 0px;">
        <ngx-charts-bar-horizontal-stacked
  [view]="view"
  [scheme]="colorScheme"
  [results]="multi"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [showDataLabel]=true
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [dataLabelFormatting]="percentTickFormatting"
  (select)="onSelect($event)">
</ngx-charts-bar-horizontal-stacked>
      </div>
    </div>
    <p style="font-style: italic;font-size:14px;text-align: center;margin-bottom: 60px;" *ngIf="divp">This information is
      <a (click)="Verify()" target="_blank"
        style="color: #ff751a;text-decoration: underline;font-size:12px">verified</a> on Hyperledger.</p>
  </div>
</div>
<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <nav class="col-md-6 navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom" style="margin: 0 auto;float: none;">
      <div class="d-flex" style="width: 100%;">
          <div class="mr-auto pt-3">
              <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
          </div>
          <div class="pt-3">
           <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
         </div>
          <div class="pt-1 logo-ft ">
              <img src="/assets/img/TRST001.jpeg">
          </div>
      </div>
    </nav>
    <!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom" style="position: absolute !important;">
      <div class="" style="width: 100%;">
          <div class="pt-3">
            <p class="ft-pwr pr-1" style="font-size: 10px;text-align: center;">Powered by TRST01 Blockchain Platform</p>
         </div>
      </div>
    </nav> -->
  </div>
</div>