import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolimerasService } from '../service/polimeras.service';
import { MouseEvent } from '@agm/core';
import { castNumber } from '@amcharts/amcharts4/core';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
@Component({
  selector: 'app-polimeras',
  templateUrl: './polimeras.component.html',
  styleUrls: ['./polimeras.component.css'],
})
export class PolimerasComponent implements OnInit {
  ImageZoom:boolean = false;
  ImageZooomOne:any;
  DynamicTitle:boolean =true;
  CardHistory:boolean =true;
  CardNutrition:boolean =true;
  CardTraceability:boolean =true;
  CardPictures:boolean =true;
  InnerCardHistory:boolean =false;
  InnerCardNutrition:boolean =false;
  InnerCardPictures:boolean =false;
  DynamicVariety:any;
  DynamicHarvestedDate:any;
  DynamicDaysripenedfor:any;
  DynamicShippedDate:any;
  DynamicReachedStoreon:any;
  DynamicStoreSaleDate:any;
  DynamicBatchCode:any;
  DynamicBestbefore:any;
  DynamicFarmer:any;
  DynamicGPS:any;
  DynamicFarmPic:any;
  DynamicMap:any;
  VerifyLink:any;
  open:boolean=true;
  second:boolean = false;
  imgarr =[];
  constructor(
    private _service: PolimerasService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    setTimeout(() => {
      this.open = false;
      this.second = true;
     }, 3000);
  }
popup(img)
{
  console.log(img)
  this.ImageZoom = true;
  this.ImageZooomOne =img;
}
  ngOnInit() {

    
    let qrcode = this.route.snapshot.params.QRCode;
    if (qrcode != '') {
      this._service.getBlockChainDataDetails(qrcode).subscribe((data) => {
        console.log(data);
        var index = data['posts']['childblockchaintriggers'].length;
        console.log(index)
      
         this.VerifyLink = data['posts']['childblockchaintriggers'][index-1].blockchainurl
         console.log(this.VerifyLink)
        let Docarr = data['posts']['childblockchaintriggers'][index-1].blockchaindata.split('||');
        for(let k=0;k<Docarr.length;k++)
        {
          var index = Docarr[k].indexOf(":");
          var Key = Docarr[k].substring(0,index);
          var Value = Docarr[k].substring(index+1,Docarr[k].length);
          if(Key == 'Variety')
          {
            this.DynamicVariety = Value
            console.log(this.DynamicVariety)
          }
          else if(Key == 'Harvested Date')
          {
            this.DynamicHarvestedDate = Value
          }
          else if(Key == 'Days ripened for')
          {
            this.DynamicDaysripenedfor = Value
          }
          else if(Key == 'Shipped Date')
          {
            this.DynamicShippedDate = Value
          }
          else if(Key == 'Reached Store on')
          {
            this.DynamicReachedStoreon = Value
          }
          else if(Key == 'Store Sale Date')
          {
            this.DynamicStoreSaleDate = Value
          } 
          else if(Key == 'Batch Code')
          {
            this.DynamicBatchCode = Value
          } 
          else if(Key == 'Best before')
          {
            this.DynamicBestbefore = Value
          } 
          else if(Key == 'Farmer/FPO')
          {
            this.DynamicFarmer = Value
          } 
          else if(Key == 'GPS')
          {
            this.DynamicGPS =Value.replace(/[{}]/g, "")
          }
          else if(Key == 'Farm Picture')
          {
            this.DynamicFarmPic = Value;
            this.imgarr.push(Value)
          }
          else if(Key == "Farm location")
          {
              //this.DynamicMap = Value;
              this.imgarr.push(Value)
              this.DynamicMap = this.imgarr;
              console.log(this.DynamicMap)
          }
        }
      });
    }
  }

  History()
  {
    this.DynamicTitle = false;
    this.CardHistory =false;
    this.CardNutrition =false;
    this.CardTraceability =false;
    this.CardPictures =false;
    this.InnerCardHistory = true;
    this.InnerCardNutrition =false
  }
  back()
  {
    this.DynamicTitle = true;
    this.CardHistory =true;
    this.CardNutrition =true;
    this.CardTraceability =true;
    this.CardPictures =true;
    this.InnerCardHistory = false;
    this.InnerCardNutrition =false;
    this.InnerCardPictures =false;
  }
  Nutrition()
  {
    this.DynamicTitle = false;
    this.CardHistory =false;
    this.CardNutrition =false;
    this.CardTraceability =false;
    this.CardPictures =false;
    this.InnerCardHistory = false;
    this.InnerCardNutrition =true;
    this.InnerCardPictures =false;
  }
  Traceability()
  {
    this.DynamicTitle = false;
    this.CardHistory =false;
    this.CardNutrition =false;
    this.CardTraceability =false;
    this.CardPictures =false;
    this.InnerCardHistory = false;
    this.InnerCardNutrition =false;
    this.InnerCardPictures =true;
  }
}


