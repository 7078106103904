import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FormService {
   //_apiurl: string = 'https://etgarage.in/api';
   _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }

  getFormDetails(TempId){
    return this._http.get<{posts:any}>(this._apiurl+'/Forms/GetForm/'+TempId).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id:post._id,
          Title: post.Title,
          Description :post.Description,
         // Order : post.Order,
        //  TemplateId :  post.TemplateId,
          //CreatedBy : post.CreatedBy,
         // UpdatedBy : post.UpdatedBy,
         // IsActive : post.IsActive,
          FormId : post.FormId
        };
      })
    }));
  }

  getFormId(){
    return this._http.get<{posts:any}>(this._apiurl+'/Forms/LatestFormId').pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            FormId:post.FormId,
          };
        })
      }));

}


  
getFormById(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Forms/GetForm/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            Id:post._id,
            Title: post.Title,
            Description :post.Description,
            Order : post.Order,
            TemplateId :  post.TemplateId,
            CreatedBy : post.CreatedBy,
            UpdatedBy : post.UpdatedBy,
            IsActive : post.IsActive,
            FormId : post.FormId
            
  
          };
        })
      }));

}

  createForm(Form: any) {
    return this._http.post(this._apiurl+'/Forms/postForm', Form);
  }

  UpdateForm(Form: any) {
    return this._http.put(this._apiurl+'/Forms/updateForm', Form);
  }

}
