<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <nav class="navbar navbar-expand-lg navbar-light bg fixed-top" style="padding: 0px !important;">
      <img src="../assets/img/image@2x.png" style="width: 100%;height: 100px;" />
    </nav>
  </div>
</div>
<div class="container-fluid" style="padding: 0px !important;margin-top: 101px;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divPersonalDetails">
    <div class="card-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: white;">
      <img src="../assets/img/tenet-logo.png" width="100px" height="50px" /></div>
    <div class="card-body" style="padding: 0px;">
      <div class="table-responsive-sm">
        <table class="table" style="font-family: open sans-serif">
          <thead>
          </thead>
          <tbody>
            <tr>
              <td style="white-space: nowrap;padding-top: 10px;">Name</td>
              <td style="padding-top: 10px;">:</td>
              <td style="white-space: normal !important;padding-top: 10px;">{{Name}}</td>
            </tr>
            <tr>
              <td style="white-space: nowrap;padding: 0px;padding-left: 10px;">Age/Gender</td>
              <td style="padding: 0px;padding-left: 10px;">:</td>
              <td style="padding: 0px;padding-left: 10px;">{{Age}}</td>
            </tr>
            <tr>
              <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">TID/SID</td>
              <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
              <td
                style="white-space: normal !important;text-align: justify;word-break: break-word;padding: 0px;padding-left: 10px;padding-top: 10px;padding-right: 10px;">
                {{TID}}</td>
            </tr>
            <tr>
              <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">Collected on</td>
              <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
              <td style="white-space: normal !important;padding: 0px;padding-left: 10px;padding-top: 10px;">
                {{collectedDate}}</td>
            </tr>
            <tr>
              <td style="white-space: nowrap;padding: 0px;padding-left: 10px;padding-top: 10px;">Reported on</td>
              <td style="padding: 0px;padding-left: 10px;padding-top: 10px;">:</td>
              <td style="white-space: normal !important;padding: 0px;padding-left: 10px;padding-top: 10px;">
                {{ReportedDate}}</td>
            </tr>
           
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divReport">
    <div class="card-header" style="text-align: center;font-size: 15px;font-weight: bold;">DEPARTMENT OF HEMATOLOGY <br>
      Complete Blood Picture(CBP), EDTA Whole Blood</div>
      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif">
            <thead>
            </thead>
            <tbody>
              <tr style="border-bottom:1px solid lightgrey" *ngFor="let item of DataItemsList | paginate: { itemsPerPage: 11, currentPage: p }">
                <td style="padding-top: 10px;width: 160px;">{{item.V1}}</td>
                <td style="white-space: normal !important;padding-top: 10px;" [ngStyle]="{'font-weight': item.V6}" > {{item.V2}} <span style="font-size: 10px;font-weight: lighter;">{{item.V3}} </span></td>
                 <td style="padding-top: 10px;"><span style="font-size: 10px;" [ngStyle]="{'color': item.V5}">{{item.V4}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination" style="float:right;">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
  </div>
  <!-- <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divnext">
    <div class="card-header" style="text-align: center;font-size: 15px;font-weight: bold;">DEPARTMENT OF HEMATOLOGY <br>
      Complete Blood Picture(CBP), EDTA Whole Blood</div>
      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif">
            <thead>
            </thead>
            <tbody>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">Hemoglobin</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 13.6 <span style="font-size: 10px;">gm% </span></td>
                 <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">PCV/HCT</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 41.4 <span style="font-size: 10px;"> vol%</span></td> 
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">Total RBC count</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 4.63 <span style="font-size: 10px;"> mill /cu.mm</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">MCV</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 89.4 <span style="font-size: 10px;"> fL</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">MCH</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 29.4 <span style="font-size: 10px;"> pg</span>

                </td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">MCHC</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 32.8 <span style="font-size: 10px;"> g/dL</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">RDW(CV)</td>
                <td style="white-space: normal !important;padding-top: 10px;font-weight: bold;">  10 <span style="font-size: 10px;font-weight: lighter;"> %</span></td>
                <td style="padding-top: 10px;"><span style="color: red;font-size: 10px;">HIGH</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">MPV</td>
                <td style="white-space: normal !important;padding-top: 10px;">  9.8 <span style="font-size: 10px;"> fL</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">Total WBC Count</td>
                <td style="white-space: normal !important;padding-top: 10px;">  15900 <span style="font-size: 10px;"> cells/cumm</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">Platelet Count</td>
                <td style="white-space: normal !important;padding-top: 10px;font-weight: bold;">  1.38 <span style="font-size: 10px;font-weight: lighter;"> lakhs/cumm</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;color: red;">LOW</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="float: right;padding: 10px;">
          <button type="button" class="btn btn-warning" style="background: #ff944d;" (click)="next()">Proceed >></button>
        </div>
      </div>
  </div>
  <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divback">
    <div class="card-header" style="text-align: center;font-size: 15px;font-weight: bold;">DEPARTMENT OF HEMATOLOGY <br>
      Complete Blood Picture(CBP), EDTA Whole Blood</div>
      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif">
            <thead>
            </thead>
            <tbody>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">Neutrophils</td>
                <td style="white-space: normal !important;padding-top: 10px;font-weight: bold;"> 95 <span style="font-size: 10px;font-weight: lighter;">% </span></td>
                 <td style="padding-top: 10px;"><span style="font-size: 10px;color: red;">HIGH</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">Lymphocytes</td>
                <td style="white-space: normal !important;padding-top: 10px;font-weight: bold;"> 02 <span style="font-size: 10px;font-weight: lighter;"> %</span></td> 
                <td style="padding-top: 10px;"><span style="font-size: 10px;color: red;">LOW</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">Eosinophils</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 01 <span style="font-size: 10px;"> %</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">Monocytes</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 02 <span style="font-size: 10px;"> %</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="white-space: nowrap;padding-top: 10px;">Basophils</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 00 <span style="font-size: 10px;"> %</span>

                </td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="padding-top: 10px;width: 160px;">Abs. Neutrophil Count</td>
                <td style="white-space: normal !important;padding-top: 10px;"> 15105 <span style="font-size: 10px;"> cells/cumm 10^3/uL</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="padding-top: 10px;width: 160px;">Abs. Lymphocytes Count</td>
                <td style="white-space: normal !important;padding-top: 10px;">  318 <span style="font-size: 10px;"> cells/cumm 10^3/uL</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="padding-top: 10px;width: 160px;">Abs. Eosinophils Count</td>
                <td style="white-space: normal !important;padding-top: 10px;">  159 <span style="font-size: 10px;"> cells/cumm 10^3/uL</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="padding-top: 10px;width: 160px;">Abs. Monocyte Count</td>
                <td style="white-space: normal !important;padding-top: 10px;">  318 <span style="font-size: 10px;"> cells/cumm 10^3/uL</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="padding-top: 10px;width: 160px;">Abs. Basophils Count</td>
                <td style="white-space: normal !important;padding-top: 10px;">  0 <span style="font-size: 10px;"> cells/cumm 10^3/uL</span></td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;">NORMAL</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="padding-top: 10px;">Neutrophil - Lymphocyte Ratio(NLR)</td>
                <td style="white-space: normal !important;padding-top: 10px;">  48 </td>
                <td style="padding-top: 10px;"><span style="font-size: 10px;color: red;">HIGH</span></td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="padding-top: 10px;">RBC</td>
                <td style="white-space: normal !important;padding-top: 10px;" colspan="2">  Normocytic Normochromic </td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="padding-top: 10px;">WBC</td>
                <td style="white-space: normal !important;padding-top: 10px;font-weight: bold;" colspan="2">  Relative neutrophilia </td>
              </tr>
              <tr style="border-bottom:1px solid lightgrey">
                <td style="padding-top: 10px;">Platelets</td>
                <td style="white-space: normal !important;padding-top: 10px;font-weight: bold;" colspan="2">  Mild Thrombocytopenia </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="float: left;padding: 10px;">
          <button type="button" class="btn btn-warning" style="background: #ff944d;" (click)="back()"><< Back</button>
        </div>
      </div>
  </div> -->
  <p style="font-style: italic;font-size:12px;text-align: center;margin-bottom: 70px;" *ngIf="pclick" >Verified on
    TRST01 cloud <a href="{{VerifyLink}}" target="_blank"
      style="color: #1f3978;text-decoration: underline;font-size:12px">Blockchain</a> Platform.<br> <a
      style="color: #ff4d4d;font-style: italic;font-size:12px;text-decoration: underline;"
      (click)="labdetails()" >click here</a> for the complete lab report</p>
      <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divDoc">
        <!-- <div class="card-header" style="text-align: left;background-color: #fff;font-weight: bold;font-size: 16px;">
          <i class="fa fa-reply" style="font-size: 20px;padding: 3px;" (click)="back()"></i>
        </div> -->
        <div class="card-header" style="text-align: center;background-color: #fff;font-weight: bold;font-size: 16px;">
          <span style="float: left;padding-top: 10px;"><i class="fa fa-reply" style="font-size: 20px;padding: 3px;" (click)="back()"></i></span>
          <span ><img src="../assets/img/tenet-logo.png" width="100px" height="50px"  style="margin-right: 44px;"/></span>
        </div>
        <div class="card-body" style="text-align: center;">
          <iframe [src]="LabReport" height="397px">
          </iframe>
          <!-- <iframe src="https://docs.google.com/viewerng/viewer?url=https://etgdocstorage.s3.ap-south-1.amazonaws.com/Tenet/PDF/ResultEntryReport_QR.pdf&embedded=true" height="397px">
          </iframe> -->
        </div>
  
      </div>
  </div>
</div>
<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom" style="height: 63px;">
      <div class="d-flex" style="width: 100%;" >
        <img src="../assets/img/footer@2x.png" style="width: 100%;height: 63px;" />
      </div>
    </nav> -->
    <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
      <div class="d-flex" style="width: 100%;">
          <div class="mr-auto pt-3">
              <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
          </div>
          <div class="pt-3">
           <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
         </div>
          <div class="pt-1 logo-ft ">
            <img src="/assets/img/TRST001.jpeg">
          </div>
      </div>
    </nav>
    <!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
      <div class="d-flex" style="width: 100%;">
          <div class="mr-auto pt-3">
              <p class="ft-text" style="color: white;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
          </div>
          <div class="pt-3">
           <p class="ft-pwr pr-1">Powered by</p> 
         </div>
          <div class="pt-1 logo-ft ">
             <img src="/assets/img/footer-logo.png">
          </div>
      </div>
      
    </nav> -->

  </div>
</div>