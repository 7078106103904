import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DonatekartService } from '../service/Donatekart.service';
import * as moment from 'moment';
import _, { map } from 'underscore';
import { KeyValue } from '@angular/common';
import { VerifyqldbService } from '../Service/verifyqldb.service';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-Donatekart',
  templateUrl: './Donatekart.component.html',
  styleUrls: ['./Donatekart.component.css']
})
export class DonatekartComponent implements OnInit {
  QRCodeVal: string;
  date:any;
  Phone:any;
  Meals:any;
  Amount:any;
  Currency:any;
  Name:any;
  Email:any;
  Address:any;
  CampaignName:any;
  Description:any;
  VerifyLink:any;
  DonorImg:any;
  img =[];
  DonorId:any;
  MealsDelivered:number;
  MD:number;
  mainImg =[];
  DivDonorDetails:boolean = true;
  DivImages:boolean = true;
  DivBeneficiaryDetails = false;
  BeneficiaryDetails = [];
  divp:boolean =true;
  constructor( 
    private route: ActivatedRoute,
    private _service: DonatekartService
    )
     { }

  ngOnInit() {
    let qrcode = this.route.snapshot.params.QRCode;
    this.QRCodeVal = qrcode;
    if (this.QRCodeVal != '') {
      this._service.getBlockChainDataDetails(this.QRCodeVal)
      .subscribe((data) => {
        console.log(data)
        
        var index = data['posts']['childblockchaintriggers'].length;
        this.VerifyLink = data['posts']['blockchainurl'];
        if(index == 0)
        {
          this.VerifyLink = data['posts']['blockchainurl'];
          console.log(this.VerifyLink)
        }
        else
        {
          //this.VerifyLink = data['posts']['childblockchaintriggers'][index-1].blockchainurl;
          //console.log(this.VerifyLink)
        }
        for ( let i = 0; i < data['posts']['childblockchaintriggers'].length; i++ )
        {
         let   obj ={};
        //  var bytes  = CryptoJS.AES.decrypt(data['posts']['childblockchaintriggers'][ i ].blockchaindata, 'trst01');
        //  console.log(bytes)
        //  var originalText = bytes.toString(CryptoJS.enc.Utf8);
        //  let Docarr = originalText.split('||');
         let Docarr = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
         if(Docarr[0] == "Form:Donation Form")
          {
                     for (let f = 0; f < Docarr.length; f++) {
                      
                       var index = Docarr[f].indexOf(':');
                       var Key = Docarr[f].substring(0, index);
                       var Value = Docarr[f].substring( index + 1,Docarr[f].length );
                       if(Key == "Timestamp")
                       {
                           this.date = Value;
                          
                       }
                       if(Key == "Phone") 
                       {
                           this.Phone = Value;
                          
                       }
                       if(Key == "Meals") 
                       {
                           this.Meals = Value;
                          
                       }
                       if(Key == "Amount") 
                       {
                           this.Amount = 'Rs.'+Value;
                          
                       }
                       if(Key == "Currency") 
                       {
                           this.Currency = Value;
                          
                       }
                       if(Key == "Name") 
                       {
                           this.Name = Value;
                          
                       }
                       if(Key == "Email") 
                       {
                           this.Email = Value;
                          
                       }
                       if(Key == "Address") 
                       {
                           this.Address = Value;
                          
                       }
                       if(Key == "Campaign Name") 
                       {
                           this.CampaignName = Value;
                          
                       }
                       if(Key == "Description") 
                       {
                           this.Description = Value;
                          
                       }
                       if(Key == "Donor Image")
                       {
                          this.DonorImg = Value;
                       }
                       if(Key == "Donor Id")
                       {
                            this.DonorId = Value;
                       }

                      
                     }

          }
         if(data['posts']['childblockchaintriggers'].length - 1 == i)
         {
          //console.log(this.DonorId)
          this._service.getDataById(this.DonorId).subscribe((data:any)=>{
                console.log(data)
                this.img = [];
                for(let i=0;i<data.length;i++)
                {
                   var Bobj = {V1:data[i].BeneficiaryName,V2:data[i].MobileNo,V3:data[i].IdentityType,V4:data[i].IdentityNo,V5:data[i].Date};
                   if(data[i].BeneficiaryName != null)
                   {
                    this.BeneficiaryDetails.push(Bobj)
                   }
                  
                  if(this.MD === undefined)
                  {
                    this.MD =0;
                  }
                  
                   this.img.push(data[i].Images)
                   //if(i != 0)
                  // {
                    if((i+1) % 3 == 0)
                    {
                       console.log(i)
                       this.mainImg.push(this.img);
                       console.log(this.mainImg)
                       this.img = [];
                    }
                   //}
                   this.MD += Number(data[i].MealsDelivered);
                   
                  if(data.length-1 ==i)
                  {
                  //  console.log(this.MD)
                    this.MealsDelivered = this.MD;
                    this.mainImg.push(this.img);
                    console.log(this.BeneficiaryDetails)
                  }
                }
          })
         }
          
        }
      });
    }
  }
  showBeneficiaryDetails()
  {
      this.DivDonorDetails = false;
      this.DivImages = false;
      this.divp = false;
      this.DivBeneficiaryDetails =true;

  }
  back()
  {
      this.DivDonorDetails = true;
      this.DivImages = true;
      this.divp = true;
      this.DivBeneficiaryDetails =false;
  }
}
