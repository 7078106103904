import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protractor } from 'protractor/built/ptor';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  //_apiurl: string = 'https://etgarage.in/api';
  _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }
  getTemplateDtl(){
    return this._http.get<{posts:any}>(this._apiurl+'/Template/GetTemplate').pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          Name: post.Name,
          Category: post.Category,
          payload : post.payload,
          CreatedBy :  post.CreatedBy,
          UpdatedBy :  post.UpdatedBy

        };
      })
    }));
  }

  getTemplateDtlByName(Category){
    return this._http.get<{posts:any}>(this._apiurl+'/Template/getTemplateByCategory/'+Category).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          Name: post.Name,
          Category: post.Category,
          payload : post.payload,
          CreatedBy :  post.CreatedBy,
          UpdatedBy :  post.UpdatedBy,
          TemplateId :post.TemplateId,
          IsPublish :post.IsPublish
        };
      })
    }));
  }


  getTemplateId(){
    return this._http.get<{posts:any}>(this._apiurl+'/Template/LatestTempId').pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            TemplateId:post.TemplateId,
          };
        })
      }));
}

  getTemplateById(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Template/GetTemplate/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            Id : post._id,
            Name: post.Name,
            Category: post.Category,
            payload : post.payload,
            CreatedBy :  post.CreatedBy,
            UpdatedBy :  post.UpdatedBy,
            IsPublish :post.IsPublish,
            Description :post.Description
          };
        })
      }));

}

getTemplateByTemplateName(TemplateName:any){
  return this._http.get<{posts:any}>(this._apiurl+'/Template/GetTemplateByTemplateName/'+TemplateName).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          Name: post.Name,
          Category: post.Category,
          payload : post.payload,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy

        };
      })
    }));

}
getTemplateSearch(TemplateName:any){
  return this._http.get<{posts:any}>(this._apiurl+'/Template/getTemplateSearch/'+TemplateName).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          Name: post.Name,
          Category: post.Category,
          payload : post.payload,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy
        };
      })
    }));

}

getTemplateByCategory(Category:any){
  return this._http.get<{posts:any}>(this._apiurl+'/Template/getTemplateByCategory/'+Category).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          Name: post.Name,
          Category: post.Category,
          payload : post.payload,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy

        };
      })
    }));

}

  createTemplate(Template: any) {
    return this._http.post(this._apiurl+'/Template/postTemplate', Template);
  }

  UpdateTemplate(Template: any) {
    console.log(Template)
    return this._http.put(this._apiurl+'/Template/updateTemplate', Template);
  }

  UpdateTemplate1(Template: any) {
    return this._http.put(this._apiurl+'/Template/updateTemplate1', Template);
  }
}
