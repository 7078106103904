import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protractor } from 'protractor/built/ptor';
import { apiurl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  //_apiurl: string = 'https://etgarage.in/api';
  _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }
  getIndustryDtl(){
    return this._http.get<{posts:any}>(this._apiurl+'/Industry/getIndustry').pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          Code:post.Code,
          Name :post.Name,
          Description : post.Description,
          Icon : post.Icon,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy
        };
      })
    }));
  }

  getIndustryById(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Industry/getIndustryId/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            Id : post._id,
            Code:post.Code,
            Name :post.Name,
            Description : post.Description,
            Icon : post.Icon,
            CreatedBy : post.CreatedBy,
            UpdatedBy : post.UpdatedBy
          };
        })
      }));

}

getIndustrySearch(Name:any){
  return this._http.get<{posts:any}>(this._apiurl+'/Industry/getIndustrySearch/'+Name).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          Code:post.Code,
          Name :post.Name,
          Description : post.Description,
          Icon : post.Icon,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy
        };
      })
    }));

}
getIndustrySearch1(Name:any){
  return this._http.get<{posts:any}>(this._apiurl+'/Industry/getJoinData/'+Name).pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          Code:post.Code,
          Name :post.Name,
          Description : post.Description,
          Icon : post.Icon,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy
        };
      })
    }));

}

checkDuplicateIndustries(Name)
{
  return this._http.get(this._apiurl+'/Industry/CheckDuplicateIndustryByName/'+Name);
}

  createIndustry(Industry: any) {
    return this._http.post(this._apiurl+'/Industry/createIndustry', Industry);
  }

  UpdateIndustry(Industry: any) {
    return this._http.put(this._apiurl+'/Industry/updateIndustry', Industry);
  }
}
