import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './Settings/Settings.component';
import { ConfigurationComponent } from './Configuration/Configuration.component';
import { OnboardingComponent } from './Onboarding/Onboarding.component';
import { HeaderComponent } from './Header/Header.component';
import { FotterComponent } from './Fotter/Fotter.component';
import { ProfileComponent } from './Profile/Profile.component';
import { TemComponent } from './tem/tem.component';
import { LoginComponent } from './login/login.component';
import { BranchComponent } from './branch/branch.component';
import { OrganisationComponent } from './organisation/organisation.component';
import { RoleComponent } from './role/role.component';
import { UserComponent } from './user/user.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 import { ToastrModule } from 'ngx-toastr';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { IndustryComponent } from './industry/industry.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TemplatesearchComponent } from './templatesearch/templatesearch.component';
import { StepertestComponent } from './stepertest/stepertest.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {MatRadioModule} from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { TemplateComponent } from './template/template.component';
import { RouterModule } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { CommonModule } from '@angular/common';
import { InlinetabletestComponent } from './inlinetabletest/inlinetabletest.component';
import { IntegralUIModule } from '../../node_modules/@lidorsystems/integralui-web/bin/integralui/integralui.module';
import { TabtestComponent } from './tabtest/tabtest.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormfieldtestComponent } from './formfieldtest/formfieldtest.component';
import { MaheshBankComponent } from './mahesh-bank/mahesh-bank.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { PrettyPrintPipe } from './pipe/pretty-print.pipe';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { StepperOverviewComponent } from './stepper-overview/stepper-overview.component';
//import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import {DemoMaterialModule} from './material-module';
import { TemplateOverviewComponent } from './template-overview/template-overview.component';
import { RibbonComponent } from './ribbon/ribbon.component';
import { OrganisationOverviewComponent } from './Organisation-overview/Organisation-overview.component';
import { MasterOverviewComponent } from './master-overview/master-overview.component';
import { ApiOverviewComponent } from './api-overview/api-overview.component';
import { BlockchainOverviewComponent } from './blockchain-overview/blockchain-overview.component';
import { BillingOverviewComponent } from './billing-overview/billing-overview.component';
import { StandardizedLandingpageComponent } from './standardized-landingpage/standardized-landingpage.component';
import { PolimerasComponent } from './polimeras/polimeras.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { CoWINComponent } from './CoWIN/CoWIN.component';
import { ChitfundComponent } from './chitfund/chitfund.component';
import { PolimeraasComponent } from './polimeraas/polimeraas.component';
import { KapilchitsComponent } from './kapilchits/kapilchits.component';
import { AgmCoreModule } from '@agm/core';
import { TenetComponent } from './tenet/tenet.component';
import { TenetCBPComponent } from './tenet-CBP/tenet-CBP.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DonatekartComponent } from './Donatekart/Donatekart.component';
import { VerifyqldbComponent } from './verifyqldb/verifyqldb.component';
import {TypesafetyPipe} from './pipe/typesafety.pipe';
import { UlinePipe } from './pipe/uline-pipe.service';
import { CMSComponent } from './CMS/CMS.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { VerifyhyperledgeComponent } from './verifyhyperledge/verifyhyperledge.component';
import { PanchratanComponent } from './panchratan/panchratan.component';
import { ChartsModule } from 'ng2-charts';
@NgModule({
  declarations: [																																												
    AppComponent,
      HomeComponent,
      SettingsComponent,
      ConfigurationComponent,
      OnboardingComponent,
      HeaderComponent,
      FotterComponent,
      ProfileComponent,
      TemComponent,
      LoginComponent,
      BranchComponent,
      OrganisationComponent,
      RoleComponent,
      UserComponent,
      IndustryComponent,
      TemplatesearchComponent,
      StepertestComponent,
      TemplateComponent,
      CategoryComponent,
      InlinetabletestComponent,
      TabtestComponent,
      FormfieldtestComponent,
      MaheshBankComponent,
      PrettyPrintPipe,
      DashboardComponent,
      StepperOverviewComponent,
      TemplateOverviewComponent,
      RibbonComponent,
      OrganisationOverviewComponent,
      MasterOverviewComponent,
      ApiOverviewComponent,
      BlockchainOverviewComponent,
      BillingOverviewComponent,
      StandardizedLandingpageComponent,
      PolimerasComponent,
      CoWINComponent,
      ChitfundComponent,
      PolimeraasComponent,
      KapilchitsComponent,
      TenetComponent,
      TenetCBPComponent,
      DonatekartComponent,
      VerifyqldbComponent,
      TypesafetyPipe,
      UlinePipe,
      CMSComponent,
      VerifyhyperledgeComponent,
      PanchratanComponent
   ],
  imports: [
    //TabsModule,
    MatTabsModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatStepperModule,
    FormsModule,
   MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSliderModule,
    CdkStepperModule,
    TabsModule.forRoot(),
    //NgxSpinnerModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    AutocompleteLibModule,
     ToastrModule.forRoot(),
     MatStepperModule,
    MatTabsModule,
     MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatAutocompleteModule,
    NgOtpInputModule,
    //
    IntegralUIModule,
    CommonModule,
    NgxSpinnerModule,
    DemoMaterialModule,
    PinchZoomModule ,
    NgxPaginationModule,
    PdfViewerModule,
    ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD69whHQbOZ1I-HpIWgTEDVcsfWanKysjc',
      libraries: ['places']
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
 
  providers: [ { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },],
  bootstrap: [AppComponent],
 

})
export class AppModule { }
