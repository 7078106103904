import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { protractor } from 'protractor/built/ptor';
import { apiurl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
 //_apiurl: string = 'https://etgarage.in/api';
 _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }
  getRoleDtl(){
    return this._http.get<{posts:any}>(this._apiurl+'/Role/GetRoleDtl').pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id : post._id,
          RoleCode:post.RoleCode,
          Name :post.Name

        };
      })
    }));
  }

  getRoleById(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Role/GetRole/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
            Id : post._id,
            RoleCode:post.RoleCode,
            Name :post.Name
            
  
          };
        })
      }));

}


getRole(RoleId){
  return this._http.get<{posts:any}>(this._apiurl+'/Role/GetRole/'+RoleId+'').pipe(map((postData)=>{
    return postData.posts.map(post =>{
      return{
        Id : post._id,
        RoleCode:post.RoleCode,
        Name :post.Name

      };
    })
  }));
}


  createRole(Role: any) {
    return this._http.post(this._apiurl+'/Role/postRole', Role);
  }

  UpdateRole(Role: any) {
    return this._http.put(this._apiurl+'/Role/updateRole', Role);
  }
}
