import { Component, OnInit } from '@angular/core';
import {VerifyqldbService} from '../Service/verifyqldb.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-verifyqldb',
  templateUrl: './verifyqldb.component.html',
  styleUrls: ['./verifyqldb.component.css']
})
export class VerifyqldbComponent implements OnInit {
  displayMore:boolean=false;
  displayMore2:boolean=false;
  QRCodeVal: string;
  Hash:any;
  Trans:any;
  Block:any;
  Date:any;
  currentdate:any;
  constructor(private _qldb:VerifyqldbService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    let qrcode = this.route.snapshot.params.QRCode;
    this.QRCodeVal = qrcode;
    if (this.QRCodeVal != '') {
      this._qldb.getVerificationDetails(qrcode).subscribe(data=>{
          console.log(data)
          this.Hash = data['hash'];
          this.Trans = data['strandId'];
          this.Date = data['txTime'];
          this.Block = data['sequenceNo'];
      })
    }
  }



}
