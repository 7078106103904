<section style="background-color: #EAF7F1;">
<div class="container-fluid col-md-6" style="padding: 0px !important;margin-top: 15px;background-color: #EAF7F1;">
  <div class="card" style="padding-left: 20px !important;padding-right: 20px !important;background-color: #EAF7F1;border: 0px solid !important;" >
    <img src="../../assets/img/panchratan/headerwithouttext.png" style="border-radius: 8px;">
    <img  class="top-logo" src="../../assets/img/panchratan/PancharatanLogo.jpg" style="width: 80px;height: 70px;">
    <div class="top-left" style="font-size: 14dp;">Save The Soil</div>
    <div class="top-right" style="font-size: 14dp;">Save The Future</div>
  </div>
  <div class="card" style="padding-left: 20px !important;padding-right: 20px !important;background-color: #EAF7F1;border: 0px solid !important;margin-top: 15px !important;" >
    <img src="../../assets/img/panchratan/header2withouttext.png" style="height:100px;border-radius: 8px;">
    <div style="font-size: 11px;font-family: MSemiMedium;top:20px;position: absolute;left:70px;right: 40px;;color: #31AE7A;">
      Our Cows Get The First-Rate Lush, Nutritious</div>
      <div style="font-size: 11px;font-family: MSemiMedium;top:32px;position: absolute;left:120px;right: 40px;color: #31AE7A;">
        Organic Grasses Every Day</div>
        <div style="font-size: 11px;font-family: MSemiMedium;top:45px;position: absolute;left:100px;right: 40px;color: #31AE7A;">
          This Leads To Excellent Wholesome</div>
          <div style="font-size: 11px;font-family: MSemiMedium;top:58px;position: absolute;left:150px;right: 40px;color: #31AE7A;">
            Milk Every Day</div>
  </div>
  <div class="card" style="background-color: #FFFFFF;border: 0px solid !important;margin-left: 20px;margin-right: 20px;margin-top: 15px !important;" >
    <div class="card-header" style="padding-top: 20px;text-align: center;font-size: 15px;font-weight: bold;background-color: #FFF !important;">
      <p style="float: left;color: #31AE7A;font-weight: bold; font-family: MSemiBold;font-size: 13px;">Crop : {{CropName}}</p>
      <p style="float: right;color: #31AE7A;font-weight: bold; font-family: MSemiBold;font-size: 13px;">Category : {{VarietyName}}</p>
     </div> 
    <!-- <div style="padding-top: 5px;">
       <p style="float: left;color: #9cb770;font-weight: bold; font-family: MSemiBold;font-size: 13px;">Crop : {{CropName}}</p>
       <p style="float: right;color: #9cb770;font-weight: bold; font-family: MSemiBold;font-size: 13px;">Category : {{VarietyName}}</p>
    </div> -->
    <section class="timeline-area">
      <div  class="tt div0 div">
         <span><img src="../assets/img/farm.svg"  /></span><br/>
          <span style="font-size: 16px;background-color:#9cb770 ;padding-left: 15px;padding-right: 15px;border-radius: 5px;color: white;font-family: MSemiBold;font-size: 12px;">Farm</span><br>
          <span style="color:#757575;font-family: MSemiBold;font-size: 12px;">{{FTransplantationDate}}</span><br>
        </div>
      <div  class="tt div1 div">
         
          <span><img src="../assets/img/harvest.svg"  /></span><br>
          <span style="font-size: 16px;background-color:#9cb770 ;padding-left: 15px;padding-right: 15px;border-radius: 5px;color: white;font-family: MSemiBold;font-size: 12px;">Growth Monitoring</span><br>
          <span style="color:#757575;font-family: MSemiBold;font-size: 12px;">{{FGrowthMonitoringDate}}</span>
          
      </div>
      <div  class="tt div2 div">
          
          <span><img src="../assets/img/Product_delivery.svg"/></span><br>
          <span style="font-size: 16px;background-color:#9cb770 ;padding-left: 15px;padding-right: 15px;border-radius: 5px;color: white;font-family: MSemiBold;font-size: 12px;">Harvest</span><br>
          <span style="color:#757575;font-family: MSemiBold;font-size: 12px;">{{FGrowthMonitoringHarvestDate}}</span>
                      <span></span>
      </div>
      <div  class="tt div3 div">
          
          <span><img src="../assets/img/store.svg" /></span><br>
          <span style="font-size: 16px;background-color:#9cb770 ;padding-left: 15px;padding-right: 15px;border-radius: 5px;color: white;font-family: MSemiBold;font-size: 12px;">Packing</span><br>
          <span style="color:#757575;font-family: MSemiBold;font-size: 12px;">{{FPackageDate}} </span>
                      <span></span>
      </div>
  </section>
  
  </div>
  <div class="card" style="padding-left: 10px !important;padding-right: 10px !important;background-color: #FFF;border: 0px solid !important;margin-left: 20px;margin-right: 20px;margin-top: 15px !important;" >
    <h3 *ngIf="chartReady" class="res" style="font-weight: bold;">Growth Map</h3>
    <div style="display: block;" *ngIf="chartReady">
      <canvas baseChart width="300" height="200"
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins">
      </canvas>
    </div>
    
  </div>
  <!-- <div class="card" style="height: 200px;padding-left: 20px !important;padding-right: 20px !important;background-color: #FFFFFF;border: 0px solid !important;margin-left: 20px;margin-right: 20px;margin-top: 15px !important;" >
    <img src="../../assets/img/panchratan/flow.png">
    <div style="top:85px;left: 40px;position: absolute;">
      <button class="button" style="border-radius: 10px;">Farm</button>
      <div style="padding-left: 15px;color: #777777;font-family: MSemiRegular;font-size: 13px;">Days</div>
      <div style="padding-left: 7px;color: #777777;font-family: MSemiRegular;font-size: 13px;">Location</div>
    </div>
    <div style="top:85px;left: 110px;position: absolute;">  
      <button class="button" style="border-radius: 10px;">Harvest</button>
      <div style="padding-left: 15px;color: #777777;font-family: MSemiRegular;font-size: 13px;">Days</div>
      <div style="padding-left: 7px;color: #777777;font-family: MSemiRegular;font-size: 13px;">Location</div>
    </div>
    <div style="top:85px;left: 193px;position: absolute;">
      
      <button class="button" style="border-radius: 10px;">Packing</button>
      <div style="padding-left: 15px;color: #777777;font-family: MSemiRegular;font-size: 13px;">Days</div>
      <div style="padding-left: 7px;color: #777777;font-family: MSemiRegular;font-size: 13px;">Location</div>
    </div>
    <div style="top:85px;left: 280px;position: absolute;">
      
      <button class="button" style="border-radius: 10px;">Store</button>
      <div style="padding-left: 15px;color: #777777;font-family: MSemiRegular;font-size: 13px;">Days</div>
      <div style="padding-left: 7px;color: #777777;font-family: MSemiRegular;font-size: 13px;">Location</div>
    </div>
  </div> -->
  
  <div class="card" style="height:150px;margin-left: 20px;margin-right: 20px;background-color: #FFF;border: 0px solid !important;border-radius: 8px;margin-top: 15px !important;" >
    
    <div class="card-body" style="border-radius: 8px;">
      <div style="position: absolute;top:15px">
        <img [src]="UserImage" style="width: 130px;height: 120px;border-radius: 50%;border: 1px solid #31AE7A;" *ngIf="BoolUserImage">
      </div>
      <div style="position: absolute;top:45px;left: 153px;">
        <label style="color: #31AE7A;font-size: 13px;margin-bottom:0px !important;padding-right: 5px;" class="res">It Is My Responsibility To Take Care Of Farm</label>
      </div>
      <!-- <div style="position: absolute;top:30px;left: 105px;">
        <label style="color: #31AE7A;font-size: 13px;" class="res">To Take Care Of Farm</label>
      </div> -->
      <div style="float: right;margin-top: 40px;">
        <label style="color: #31AE7A;font-weight: bold;margin-top: 50px;font-size: 18px;" id="username">{{User}}</label>
      </div>
      <!-- <div class="row">
        <div class="col-4">
          <img src="https://etgdocstorage.s3.ap-south-1.amazonaws.com/Organisation/Icons/1638454511893.jpeg" style="width: 80px;height: 80px;border-radius: 30%;">
        </div>
        <div class="col-6" style="padding: 0px;">
            <label style="color: #31AE7A;font-size: 12dp;margin-bottom:0px !important" class="res">It Is My Responsibility</label>
            <label style="color: #31AE7A;font-size: 12dp;" class="res">To Take Care Of Farm</label>
        </div>
        <div class="col-2" style="padding: 0px;">
          <label style="color: #31AE7A;font-style: italic;font-weight: bold;margin-top: 50px;font-size: 18px;" id="username">{{User}}</label>
      </div>
      </div> -->
    </div>
    <div>

    </div>
  </div>
  <div class="card" style="height:250px;margin-left: 20px;margin-right: 20px;background-color: #FFF;border: 0px solid !important;border-radius: 8px;margin-top: 15px !important;" >
    <agm-map 
    [latitude]="17.2415401"
    [longitude]="78.2094423"
    [zoom]="12"
    [disableDefaultUI]="false"
    [zoomControl]="false">
  
    <agm-marker 
    [latitude]="17.2415401"
    [longitude]="78.2094423"
    label="panchratan farm">
  </agm-marker>
  </agm-map>
  </div>

  <!-- <div class="card" style="padding-left: 20px !important;padding-right: 20px !important;background-color: #EAF7F1;border: 0px solid !important;margin-top: 15px !important;" >
    <img src="../../assets/img/panchratan/PlantsGrowing.gif">
  </div> -->
  <div class="card" style="padding-left: 20px !important;padding-right: 20px !important;padding-bottom: 10px !important;background-color: #FFFFFF;border: 0px solid !important;margin-bottom: 20px;margin-left: 20px;margin-right: 20px;" >
    <!-- <a href="https://www.youtube.com/watch?v=7ow5WfAoTCc" target="_blank"><img src="../../assets/img/panchratan/youtube.png" style="width: 50px;height: 35px;margin-top: 10px;" class="center"></a> -->
    <iframe class="center" width="80%" height="200px" src="https://www.youtube.com/embed/7ow5WfAoTCc" style="margin-top: 10px;">
    </iframe>
    <p style="text-align: center;color: #777777;font-family: MSemiRegular;font-size: 11px;">To know more about Panchratan watch our video</p>
  </div>
  <p style="font-style: italic;font-size:12px;text-align: center;margin-bottom: 20px;">Verified on
    TRST01 cloud <a href="{{VerifyLink}}" target="_blank"
      style="color: #1f3978;text-decoration: underline;font-size:12px">Blockchain</a> Platform.<br></p>
</div>
<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
     <nav class="col-md-6 navbar navbar-expand-sm ft-bg navbar-dark" style="background-color: #fff;margin: 0 auto;float: none;">
      <div class="" style="width: 100%;">
          <div>
            <!-- <p class="ft-pwr pr-1" style="font-size: 10px;text-align: center;color: #000;">Powered by <img src="../../assets/img/panchratan/TRST01.png" style="height: 30px;width: 50px;"></p> -->
            <img src="../../assets/img/panchratan/PoweredbyTRST01.svg" style="height: 50px;width: 150px;" class="center">
         </div>
      </div>
    </nav>
  </div>
</div>
</section>