import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tabtest',
  templateUrl: './tabtest.component.html',
  styleUrls: ['./tabtest.component.css']
})
export class TabtestComponent implements OnInit {
noOfTab=[
  {"Name":"User"},
  {"Name":"Role"},
  {"Name":"Menu"},
  {"Name":"Farmer"},
  {"Name":"Employee"},
]
form: FormGroup;
fieldkeyvalues:any;
constructor(private fb: FormBuilder) {
  this.form = this.fb.group({
    published: true,
    credentials: this.fb.array([]),
  });
  this.formfields = [];
  this.fieldkeyvalues = [];
}
creds:any;
addCreds() {
   this.creds = this.form.controls.credentials as FormArray;
  this.creds.push(this.fb.group({
    username: '',
    password: '',
  }));
}

  ngOnInit(): void {
  }

  onSave(){
    console.log(this.creds.value);
  }

  // tab test
  FormupList: any[];
  formfields: any
  newAttribute: any = {};
  hdrid: any;

  addedit:boolean=false;
  count:any;
  afield:any;
  addFieldValue() {
      this.count++;
      this.addedit = true;
    // this.afield= (<HTMLInputElement>document.getElementById("NumOfRecords")).value;
     console.log(this.afield)
  //    if(this.formfields !="" || this.formfields !=undefined || this.formfields !=null){
  //     var afield= (<HTMLInputElement>document.getElementById("NumOfRecords")).value;
  //    console.log(afield)
      //    for(var i=0; i<=afield; i++){
         // if(this.afield < table){
      this.formfields.push(this.newAttribute)
      var tables = document.getElementsByTagName('tr');
      var table = tables[tables.length];
    
          this.newAttribute = {};
     // }
   
      //this.formfields=afield;
  //        }
  //    }
  // else{
  //        alert("Cant we store field more than no of recors");
  //    }
  }


  CloseAllEditable() {
    for (let item of this.formfields) {
        if (item.isEditable) {
            item.isEditable = false;
        }
    }
}
deleteFieldValue(index, data) {
    if (data.CreatedDate == undefined)
        this.formfields.splice(index, 1);
}

addFieldValues() {
   
    this.addedit = true;
    this.fieldkeyvalues.push(this.newAttribute)
    var tables = document.getElementsByTagName('tr');
    var table = tables[tables.length];
    this.newAttribute = {};
}
selectedItem(index, data){
  console.log(index)
  console.log(data);
  // console.log(e.target.value);
  // var selectvalue=e.target.value;
  // this.formfields.push(selectvalue);
  // console.log(selectvalue);
  // console.log( this.formfields);
}
editItem(index,data){
  this.formfields.get(index,data);
  console.log(this.formfields);
}
Data=[];
submitformfielddetails() {

  for (var i = 0; i < this.formfields.length; i++) {
      if (this.formfields[i].Id == undefined || this.formfields[i].Id == null || this.formfields[i].Id == '') {

          var intialPostData = {
              Id: null,
              FormId: this.hdrid,
              FieldName: (<HTMLInputElement>document.getElementById("FieldName" + i.toString())).value,
              FieldType: (<HTMLInputElement>document.getElementById("FieldType" + i.toString())).value,
              Description: (<HTMLInputElement>document.getElementById("Description" + i.toString())).value,
              Order: (<HTMLInputElement>document.getElementById("Order" + i.toString())).value,
          
          }
          console.log(intialPostData)
      this.Data.push(intialPostData);
      console.log(this.Data);

      }
      else {
          var updatePostData = {
              Id: this.formfields[i].Id,
              FormId: this.formfields[i].FormId,
              FieldName: (<HTMLInputElement>document.getElementById("FieldName" + i.toString())).value,
              FieldType: (<HTMLInputElement>document.getElementById("FieldType" + i.toString())).value,
              Description: (<HTMLInputElement>document.getElementById("Description" + i.toString())).value,
              Order: (<HTMLInputElement>document.getElementById("Order" + i.toString())).value,
            
          }
          console.log(updatePostData)
         
      }

  }
}

}
