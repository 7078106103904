<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
<nav class="navbar navbar-expand-lg navbar-light bg fixed-top" style="padding: 0px !important;">
  <img src="../assets/img/image@2x.png" style="width: 100%;height: 100px;" />
</nav>
</div>
</div>
<div class="container-fluid" style="padding: 0px !important;margin-top: 101px;">
  <div class="card col-md-6" style="padding: 0px !important;">
    
    <div class="card-body" style="padding: 0px !important;">
      <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;">
        <div class="card-header" style="text-align: center;font-size: 20px;font-weight: bold;background-color: white;padding: 0px;">
          <img src="../assets/img/dk1.PNG" />
        </div>
        <div class="card-body" style="padding-top: 3px;padding-bottom: 3px;" *ngIf="DivDonorDetails">
    
          <p style="text-align: center;padding-top: 5px;font-size: 20px;color: #ff7400;">{{CampaignName}}</p>
         
          <div class="table-responsive-sm">
            
            <div class="table-responsive-sm">
              <table class="table" style="font-family: open sans-serif;padding: 0px;" >
                <thead>
                </thead>
                <tbody>
                  <tr>
                  <td style="white-space: nowrap;">Name</td>
                  <td>:</td>
                  <td>{{Name}}</td>
                </tr>
                <tr>
                  <td style="white-space: nowrap;">Date</td>
                  <td>:</td>
                  <!-- <td>{{date | date: 'dd-MM-yyyy'}}</td> -->
                  <td>{{date | ulinePipe:'DD-MM-YYYY'}}</td>
                </tr>
                  <!-- <tr>
                    <td style="white-space: nowrap;">Phone</td>
                    <td>:</td>
                    <td>{{Phone}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;">Email</td>
                    <td>:</td>
                    <td>{{Email}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;">Address</td>
                    <td>:</td>
                    <td>{{Address}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;">Amount</td>
                    <td>:</td>
                    <td>{{Amount}}</td>
                  </tr> -->
                  <tr>
                    <td style="white-space: nowrap;width: 135px;">Meals Contributed</td>
                    <td>:</td>
                    <td>{{Meals}}</td>
                  </tr>
                  <tr>
                    <td style="white-space: nowrap;width: 135px;">Meals Delivered</td>
                    <td>:</td>
                    <td>{{MealsDelivered}}</td>
                  </tr>
                  <!-- <tr>
                    <td style="white-space: nowrap;">Currency</td>
                    <td>:</td>
                    <td>{{Currency}}</td>
                  </tr> -->
                 
                </tbody>
              </table>
          </div>
        </div>
        </div>
        <div class="card-body" style="padding-top: 3px;padding-bottom: 3px;" *ngIf="DivImages">
          <div class="table-responsive-sm">
            <div class="table-responsive-sm">
              <table class="table" style="font-family: open sans-serif;padding: 0px;" >
                <thead>
                </thead>
                <tbody>
                  <!-- <tr *ngFor="let data of img">
                  <td style="text-align: center;"><img [src]=" 'data:image/png;base64,'+data| safe: 'resourceUrl'" style="height: 80px;width: 80px;"></td>
                 </tr> -->
                 <tr *ngFor="let img of mainImg">
                  <td *ngFor="let data of img" style="text-align: center;"><img [src]=" 'data:image/png;base64,'+data| safe: 'resourceUrl'" style="height: 80px;width: 80px;"></td>
                 </tr>
                </tbody>
              </table>
          </div>
        </div>
        </div>
        <div class="card-body" style="padding-top: 3px;padding-bottom: 3px;" *ngIf="DivBeneficiaryDetails">
          <div class="card-header"
          style="text-align: left;background-color: #fff;font-weight: bold;font-size: 16px;">
          <i class="fa fa-reply" style="font-size: 20px;padding: 3px;"  (click)="back()"></i> <span style="padding-left: 40px;">Beneficiary Details</span>
      </div>
           <div class="card" style="border: 1px solid #FF7C1F;"  *ngFor="let data of BeneficiaryDetails">
             <div class="card-body" style="text-align: center;padding: 2px;">
              <div class="row">
                <div class="col-sm-6" style="width: 150px;text-align: left;padding-left: 20px;">
                  <p style="margin: 0px !important;font-size: 17px;color: #FF7C1F;">{{data.V1}}</p>
                  <p style="margin: 0px !important;">{{data.V2}}</p>
                </div>
                <div class="col-sm-6" style="width: 150px;text-align: left;padding-top: 25px;">{{data.V5 | ulinePipe:'DD-MM-YYYY'}}</div>
                <div class="col-md-6" style="width: 150px;text-align: left;padding-top: 3px;padding-left: 20px;">{{data.V3}}</div>
                <div class="col-md-6" style="width: 150px;text-align: left;padding-top: 3px;">{{data.V4}}</div>
                </div>
                <!-- <p style="margin: 0px !important;color: #FF7C1F;font-size: 16px;">{{data.V1}}</p>
                <p style="margin: 0px !important;">{{data.V2}}</p>
                <p style="margin: 0px !important;">{{data.V3}}</p>
                <p style="margin: 0px !important;">{{data.V4}}</p> -->
             </div>
           </div>
           <!-- <div class="card" style="border: 1px solid #FF7C1F;">
            <div class="card-body" style="text-align: center;padding: 2px;">
               <p style="margin: 0px !important;color: #FF7C1F;font-size: 16px;">praveen</p>
               <p style="margin: 0px !important;">9160143927</p>
               <p style="margin: 0px !important;">Aadhar card</p>
               <p style="margin: 0px !important;">111111</p>
            </div>
          </div> -->
        </div>
      <p *ngIf="divp" style="font-style: italic;font-size:12px;text-align: center;margin-bottom: 60px;">Verified on TRST01 cloud <a href="{{VerifyLink}}" target="_blank" style="color: #1f3978;font-size: 12px;">Blockchain</a> Platform.<br> 
        <a
        style="color: #EA8039;font-style: italic;font-size:12px;" href="https://www.donatekart.com/" target="_blank">click here</a> to donate more Smiles & <a
        style="color: #EA8039;font-style: italic;font-size:12px;" (click)="showBeneficiaryDetails()">click here</a> beneficiary details</p>
</div>
<div class="container-fluid" style="padding: 0px !important;">
  <div class="card col-md-6" style="padding: 0px !important;">
    <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
      <div class="d-flex" style="width: 100%;">
          <div class="mr-auto pt-3">
              <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
          </div>
          <div class="pt-3">
            <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
          </div>
           <div class="pt-1 logo-ft ">
              <img src="/assets/img/TRST001.jpeg">
           </div>
      </div>
    </nav>
  </div>
</div>