import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PolimerasService {

 //_apiurl: string = 'https://etgarage.in/api';
 _apiurl: string = apiurl;
 constructor(private _http: HttpClient) { }

 getBlockChainDataDetails(QRCode) {

   return this._http.get(this._apiurl+'/Document/getDocument/' + QRCode);

 }

}
