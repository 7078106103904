    <!---new-->
    <div style="margin-top: 75px;"></div>
    <section class="setting">    
      <div class="bg-white " style="height: 45px;"> 
    <div class="sec-tab">   
     <ul class="nav" role="tablist" style="margin-bottom: 0px;height: 50px;">
        <li class="nav-item">
          <button id="button" routerLink="/industry" (click)="click1();onChangeRibon()">         
            <img src="../../assets/img/{{iconColor}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Templates</button>
        </li>
        <li class="nav-item">
          <button id="button" routerLink="/OrganisationOverview" (click)="clickonConfiguration();onChangeconfig();" data-toggle="pill" href="#config" >
             <img src="../../assets/img/{{configicon}}" height="18" width="22"  style="margin-right: 5px;"class="img" > Configurations</button>
        </li>
        <li class="nav-item">
          <button id="button" routerLink="/MasterOverview" (click)="clickMaster();onChangeMaster();" data-toggle="pill" href="#master">
            <img src="../../assets/img/{{masterIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Masters</button>
        </li>
        <li class="nav-item">
          <button id="button"  routerLink="/ApiOverview" (click)="clickApi();onChangeApi();"  data-toggle="pill" href="#api">
            <img src="../../assets/img/{{apiIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> API's</button>
        </li>
        <li class="nav-item">
          <button id="button" routerLink="/BlockchainOverview" (click)="clickBlockChain();onChangeBlockChain();"  data-toggle="pill" href="#blkchain">
            <img src="../../assets/img/{{blockChainIcon}}" height="18" width="22"   style="margin-right: 5px;" class="img"> Blockchain</button>
        </li>
        <li class="nav-item">
          <button id="button" routerLink="/BillingOverview" (click)="clickBilling();onChangebilling();"  data-toggle="pill" href="#billing">
            <img src="../../assets/img/{{billingIcon}}" height="18" width="22"  style="margin-right: 5px;" class="img"> Billing</button>
        </li>
      </ul>
      <!-- <ul class="nav nav-pills pb-3" role="tablist">
        <li class="nav-item">
        <a class="nav-link active green" data-toggle="pill" href="#template">
        <i class="fas fa-file-invoice"></i>
        Template</a>
        </li>
        <li class="nav-item">
        <a class="nav-link green" data-toggle="pill" href="#config" >
        <i class="fas fa-cogs"></i>
        Configuration</a>
        </li>
        <li class="nav-item">
        <a class="nav-link" data-toggle="pill" href="#master">
        <i class="fas fa-database"></i>
        Master</a>
        </li>
        <li class="nav-item">
        <a class="nav-link" data-toggle="pill" href="#api">
        <i class="fa fa-desktop" aria-hidden="true"></i>
        API</a>
        </li>
        <li class="nav-item">
        <a class="nav-link" data-toggle="pill" href="#blkchain">
        <i class="fab fa-hive"></i>
        Blockchain</a>
        </li>
        <li class="nav-item">
        <a class="nav-link" data-toggle="pill" href="#billing">
        <i class="fas fa-receipt"></i>
        Billing</a>
        </li>
        </ul> -->
      <!-- Tab panes -->
      <section class="sec-bg" *ngIf="isRibbon">
      <div class="tab-content">
        <div id="template" class="container tab-pane active">
          <!-- <div class="d-flex bd-highlight mb-3">
            <div class=" bd-highlight ">
              <div class="card sec-card-box mr-3">
                <button routerLink="/industry">
                  <img  src="../../assets/img/indsry-icon.png" alt="">
                
                  <div class="card-body">
                    <p class="card-text">Industry</p>
                  </div>
                </button>             
              </div>
              </div>         
          </div> -->
         
        </div>
        <div id="config" class="container tab-pane fade">
          <!-- <div class="container"> -->
            <div class="d-flex bd-highlight mb-3">
              <div class="row">
                <div class="bd-highlight">
                  <div class="card sec-card-box mr-3">
                    <button (click)="isRibbon=true;"  routerLink="/organisation">
                      <img src="../../assets/img/org-icon.png" alt="">
                      <!-- <i class="fas fa-sitemap sec-icon"></i> -->
                    <div class="card-body">
                      <p class="card-text">Organization</p>
                    </div>
                    </button>              
                  </div>           
                </div>
                <div class="bd-highlight">
                  <div class="card sec-card-box mr-3">
                    <button routerLink="/branch">
                      <img src="../../assets/img/branch-icon.png" alt="">
                      <!-- <i class="fas fa-code-branch sec-icon"></i> -->
                    <div class="card-body">
                      <p class="card-text">Branch</p>
                    </div>
                    </button>              
                  </div>           
                </div>
                <div class="bd-highlight">
                  <div class="card sec-card-box mr-3">
                    <button routerLink="/role">
                      <img src="../../assets/img/role-icon.png" alt="">
                      <!-- <i class="fab fa-critical-role sec-icon"></i> -->
                      <div class="card-body">
                        <p class="card-text">Role</p>
                      </div>
                    </button>             
                  </div>           
                </div>
                <div class="bd-highlight">
                  <div class="card sec-card-box mr-3">
                    <button routerLink="/user">
                      <img src="../../assets/img/user-icon.png" alt="">
                      <!-- <i class="fa fa-user sec-icon" aria-hidden="true"></i> -->
                      <div class="card-body">
                        <p class="card-text">User</p>
                      </div>
                    </button>             
                  </div>           
                </div> 
              </div>
             
                   
            </div>
            <div class="row" style="float:right;margin:15px">
              <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
            </div>  
          <!-- </div> -->
         
        
        </div>
        <div id="master" class="container tab-pane fade">
          <div class="d-flex bd-highlight mb-3">
            <div class="p-2 bd-highlight">Master</div>         
          </div>  
          <div class="row" style="float:right;margin:15px">
            <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
          </div>           
        </div>
        <div id="api" class="container tab-pane fade">
          <div class="d-flex bd-highlight mb-3">
            <div class="p-2 bd-highlight">Api</div>         
          </div>
          <div class="row" style="float:right;margin:15px">
            <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
          </div>
               
        </div>
        <div id="blkchain" class="container tab-pane fade">
          <div class="d-flex bd-highlight mb-3">
            <div class="p-2 bd-highlight">Blockchain</div>         
          </div>   
          <div class="row" style="float:right;margin:15px">
            <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
          </div>           
        </div>
        <div id="billing" class="container tab-pane fade">
          <div class="d-flex bd-highlight mb-3">
          <div class="p-2 bd-highlight">Billing</div>         
        </div>

        <div class="row" style="float:right;margin:15px">
          <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
        </div>  
        </div>
      
      </div>
     
    </section>
    </div>
  </div>
  </section>