import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { IntegralUIGrid } from '@lidorsystems/integralui-web/bin/integralui/components/integralui.grid';
import { IntegralUICommonService } from '@lidorsystems/integralui-web/bin/integralui/services/integralui.common.service';
import 'integralui-web/components/integralui.treeview.js';
@Component({
  selector: 'app-inlinetabletest',
  templateUrl: './inlinetabletest.component.html',
  styleUrls: ['./inlinetabletest.component.css']
})
export class InlinetabletestComponent implements OnInit {
    dynamicForm: FormGroup;
    submitted = false;
    numberOfTickets=[
        {
            "formName":"FramerForm",
            "description":"FarmerForm Desc"
         },
         {
            "formName":"UserForm",
            "description":"UserForm Desc"
         },
         {
            "formName":"TestForm",
            "description":"TestForm Desc"
         },
    ];
    constructor(private formBuilder: FormBuilder) { }
  // convenience getters for easy access to form fields
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.tickets as FormArray; }
    ngOnInit() {
        this.dynamicForm = this.formBuilder.group({
            numberOfTickets: ['', Validators.required],
            tickets: new FormArray([]),
            
        });

        if (this.t.length < this.numberOfTickets.length) {
            for (let i = this.t.length; i < this.numberOfTickets.length; i++) {
                this.t.push(this.formBuilder.group({
                    name: ['', Validators.required],
                    email: ['', [Validators.required, Validators.email]]
                }));
            }
        } else {
            for (let i = this.t.length; i >= this.numberOfTickets.length; i--) {
                this.t.removeAt(i);
            }
        }
    }

  

    onChangeTickets(e) {
        const numberOfTickets = e.target.value || 0;
        if (this.t.length < numberOfTickets) {
            for (let i = this.t.length; i < numberOfTickets; i++) {
                this.t.push(this.formBuilder.group({
                    name: ['', Validators.required],
                    email: ['', [Validators.required, Validators.email]]
                }));
            }
        } else {
            for (let i = this.t.length; i >= numberOfTickets; i--) {
                this.t.removeAt(i);
            }
        }
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.dynamicForm.invalid) {
            return;
        }

        // display form values on success
        alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.dynamicForm.value, null, 4));
    }

    onReset() {
        // reset whole form back to initial state
        this.submitted = false;
        this.dynamicForm.reset();
        this.t.clear();
    }

    onClear() {
        // clear errors and reset ticket fields
        this.submitted = false;
        this.t.reset();
    }


    onSave(){
        console.log(this.dynamicForm.value);
    }
}
