
<section class="Home-page">
  <nav class="navbar navbar-expand-lg navbar-light bg fixed-top">
    <a class="navbar-brand pt-1 logo-ft">
        <!-- <img src="/assets/img/logo-latest.png">  &nbsp;&nbsp;<span style="font-size: 20px;color: white;">Hyperledger explore</span>  -->
        <img src="/assets/img/TRST001.jpeg">  &nbsp;&nbsp;<span style="font-size: 20px;color: white;">Hyperledger explore</span> 
    </a>
  </nav>
    </section>
    <div class="card" style="margin-top:80px;box-shadow: 0px 3px 6px #00000029;" >
      <div class="card-body" style="padding: 0px;">
        <div class="card-header" style="text-align: center;font-size: 15px;font-weight: bold;">
          Transaction Details </div>
        <div class="table-responsive-sm">
          <table class="table" style="font-family: open sans-serif;padding: 0px;" >
            <thead>
            </thead>
            <tbody>
              <tr>
              <td style="padding-top: 10px;width: 150px;">
                 <label style="width: 150px;"><i class="fa fa-question-circle" ></i> Transaction ID</label>
                </td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{TransactionID}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width: 150px;">
                <label style="width: 150px;"><i class="fa fa-question-circle" ></i> Transaction Hash</label>
              </td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">{{TransactionHash}}</td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">
                <label><i class="fa fa-question-circle"></i> Status</label>
              </td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;">
                <label style="color: green;background-color: rgb(220, 247, 220);" class="btn"><i class="fa fa-check-circle" style="margin-right: 5px;"></i>Success</label>
              </td>
            </tr>
            <tr>
              <td style="padding-top: 10px;width:150px">
                <label><i class="fa fa-question-circle" ></i> Block:</label>
              </td>
              <td style="padding-top: 10px;width:20px">:</td>
              <td style="padding-top: 10px;"><label>
                {{Transblock}} </label></td>
            </tr>
                       </tbody>
            </table>
            </div>
         
      </div>
    </div>
    <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom" style="margin: 0 auto;float: none;">
      <div class="d-flex" style="width: 100%;">
          <div class="mr-auto pt-3">
              <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
          </div>
          <div class="pt-3">
           <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
         </div>
          <div class="pt-1 logo-ft ">
              <img src="/assets/img/TRST001.jpeg">
          </div>
      </div>
    </nav>

<!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
  <div class="d-flex" style="width: 100%;">
      <div class="mr-auto pt-3">
          <p class="ft-text"> &copy;  2021  TRST01. All Rights Reserved</p>                   
      </div>
  </div>
  
</nav> -->