import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { apiurl } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {
   //_apiurl: string = 'https://etgarage.in/api';
   _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }

  getUserDetails(){
    return this._http.get<{posts:any}>(this._apiurl+'/User/getUserList').pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
          Id:post._id,
          OrganisationId : post.OrganisationId,
          OrganisationName : post.OrganisationName,
          BranchId : post.BranchId,
          BranchName : post.BranchName,
          UserName :post.UserName,
          Password : post.Password,
          FirstName : post.FirstName,
          MiddleName : post.MiddleName,
          LastName : post.LastName,
          Email : post.Email,
          ContactNumber : post.ContactNumber,
          RoleId : post.RoleId,
          RoleName : post.RoleName,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy
         
       

        };
      })
    }));
  }

getUserById(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/User/getUserList/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
          Id : post._id,
          OrganisationId : post.OrganisationId,
          OrganisationName : post.OrganisationName,
          BranchId : post.BranchId,
          BranchName : post.BranchName,
          UserName :post.UserName,
          Password : post.Password,
          FirstName : post.FirstName,
          MiddleName : post.MiddleName,
          LastName : post.LastName,
          Email : post.Email,
          ContactNumber : post.ContactNumber,
          RoleId : post.RoleId,
          RoleName : post.RoleName,
          CreatedBy : post.CreatedBy,
          UpdatedBy : post.UpdatedBy
            
  
          };
        })
      }));

}

  createUser(User: any) {
    return this._http.post(this._apiurl+'/User/postUser', User);
  }

  UpdateUser(User: any) {
    return this._http.put(this._apiurl+'/User/updateUser', User);
  }

 
}
