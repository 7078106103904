<p>
  Profile works!
</p>
<section class="bg-white m-5 p-4">
  <div class="d-flex">
      <!-- <div class="p-2 h3" style="color: #e5800f;">User View</div> -->
         </div>    
<!---formstart-->
 <section class="profile">       
          <div class="container">
              <div class="row profile">
                  <div class="col-md-3">
                      <div class="profile-sidebar">
                          <!-- SIDEBAR USERPIC -->
                          <div class="profile-userpic text-center">
                              <img src="/assets/img/user-1.png" class="img-responsive" alt="">
                          </div>
                          <!-- END SIDEBAR USERPIC -->
                          <!-- SIDEBAR USER TITLE -->
                          <div class="profile-usertitle">
                              <div class="profile-usertitle-name">
                                  xxxxxxxxxx
                              </div>
                              <div class="profile-usertitle-job">
                                  Developer
                              </div>
                          </div>       
                      </div>
                  </div>
                  <div class="col-md-9">
                      <div class="profile-content">
                          <form>                               
                                  <div class="form-group border-bottom row">
                                      <label for="input" class="col-sm-3 col-form-label">User Name:</label>
                                      <div class="col-sm-9">
                                        <input type="text" class="form-control border-0" id="input" placeholder="Enter User Name">
                                      </div>
                                    </div>
                                    <div class="form-group border-bottom row">
                                      <label for="input" class="col-sm-3 col-form-label">Password:</label>
                                      <div class="col-sm-9">
                                        <input type="text" class="form-control border-0" id="input" placeholder="Enter Password">
                                      </div>
                                    </div>
                                    <div class="form-group border-bottom row">
                                      <label for="input" class="col-sm-3 col-form-label">First Name:</label>
                                      <div class="col-sm-9">
                                        <input type="text" class="form-control border-0" id="inpu" placeholder="Enter First Name">
                                      </div>
                                    </div>
                                    <div class="form-group border-bottom row">
                                      <label for="input" class="col-sm-3 col-form-label">Last Name:</label>
                                      <div class="col-sm-9">
                                        <input type="text" class="form-control border-0" id="input" placeholder="Enter Last Name">
                                      </div>
                                    </div>
                                    <div class="form-group border-bottom row">
                                      <label for="input" class="col-sm-3 col-form-label">Role:</label>
                                      <div class="col-sm-9">
                                        <input type="number" class="form-control border-0" id="number" placeholder="Enter Role">
                                      </div>
                                    </div>
                                    <div class="form-group border-bottom row">
                                      <label for="input" class="col-sm-3 col-form-label"> Mobile Number:</label>
                                      <div class="col-sm-9">
                                        <input type="number" class="form-control border-0" id="number" placeholder="Enter  Mobile Number">
                                      </div>
                                    </div>
                                    <div class="form-group border-bottom row">
                                      <label for="input" class="col-sm-3 col-form-label">Email:</label>
                                      <div class="col-sm-9">
                                        <input type="email" class="form-control border-0" id="email" placeholder="Enter Email">
                                      </div>
                                    </div>              
                                    <div class="d-flex justify-content-end mt-4 mb-4 card-body">
                                      <button type="save" class="btn btn-clear mr-3">Clear All</button>
                                    <button type="save" class="btn btn-save">Save</button>
                                 </div>
                            </form>
                      </div>
                  </div>
              </div>
          </div>       
 </section><!--inside-section-->  

<!--endform-->
</section>