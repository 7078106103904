<div style="margin-top: 70px;"></div>
<p>inlinetabletest works!</p>
<form [formGroup]="dynamicForm" (ngSubmit)="onSubmit()">
    <div class="card m-3">
        <h5 class="card-header">Angular 8 Dynamic Reactive Forms Example</h5>
        <!-- <div class="card-body">
            <div class="form-row">
                <div class="form-group">
                    <label>Number of Tickets</label>
                    <select formControlName="numberOfTickets" class="form-control" (change)="onChangeTickets($event)" [ngClass]="{ 'is-invalid': submitted && f.numberOfTickets.errors }">
                        <option value=""></option>
                        <option *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">{{i}}</option>
                    </select>
                    <div *ngIf="submitted && f.numberOfTickets.errors" class="invalid-feedback">
                        <div *ngIf="f.numberOfTickets.errors.required">Number of tickets is required</div>
                    </div>
                </div>
            </div>
        </div> -->
        <div *ngFor="let ticket of t.controls; let i = index" class="list-group list-group-flush">
          <!-- <div *ngFor="let data of numberOfTickets"> -->
            <div class="list-group-item">
                <h5 class="card-title">Form {{i+1}}</h5>
                <form [formGroup]="ticket" class="form-row">
                    <div class="form-group col-6">
                        <label>Name</label>
                        <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && ticket.controls.name.errors }" />
                        <div *ngIf="submitted && ticket.controls.name.errors" class="invalid-feedback">
                            <div *ngIf="ticket.controls.name.errors.required">Name is required</div>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label>Email</label>
                        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && ticket.controls.email.errors }" />
                        <div *ngIf="submitted && ticket.controls.email.errors" class="invalid-feedback">
                            <div *ngIf="ticket.controls.email.errors.required">Email is required</div>
                            <div *ngIf="ticket.controls.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </form>
            </div>
        <!-- </div> -->
        </div>
        <div class="card-footer text-center">
            <button class="btn btn-primary mr-1" (click)="onSave();">Save</button>
            <button class="btn btn-primary mr-1">Buy Tickets</button>
            <button class="btn btn-secondary mr-1" type="reset" (click)="onReset()">Reset</button>
            <button class="btn btn-secondary" type="button" (click)="onClear()">Clear</button>
        </div>
    </div>
</form>

<!-- credits -->
<!-- <div class="text-center">
    <p>
        <a href="https://jasonwatmore.com/post/2019/06/25/angular-8-dynamic-reactive-forms-example" target="_top">Angular 8 - Dynamic Reactive Forms Example</a>
    </p>
    <p>
        <a href="http://jasonwatmore.com" target="_top">JasonWatmore.com</a>
    </p>
</div> -->
