import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginResponse, LoginService } from '../service/Login.service';
import { RoleService } from '../service/Role.service';
import { UtilitiesService } from '../service/utility.service';
import * as moment from 'moment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  LoginResponse1: any;
  loading = false;
  submitted = false;
  returnUrl: string;
  LoginResponse: LoginResponse;
  RoleId: any;
  loggeduserid:any;
  currentItem :string;
  loginError:boolean;
  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private _utility:UtilitiesService,
      private _loginservice: LoginService,
      private _roleservice:RoleService,
     // private toastr: ToastrService
  ) {
      
  }

  ngOnInit() {
    this.loginError = false;
      this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required]
      });
     
      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
      if(this.loginForm.valid){
      this._loginservice.validateuser(this.loginForm.get('username').value,this.loginForm.get('password').value).subscribe(data =>{
        //   this.showSuccess("login is successful!!!")
        if(data.length !=0){
    //   setTimeout(() => {
                    // var x = (<HTMLInputElement>document.getElementById('snackbar'));
                    // x.innerText = 'Login is successful!!!';
                    // this._utility.SuccessToast();

    //             }, 1500);
          console.log(data);
          console.log(data[0].RoleId);
            localStorage.setItem("CurrentDateTime",moment().format("dddd, MMMM Do, YYYY, HH:mm:ss "));
            console.log(localStorage.getItem("CurrentDateTime"))

        // if(data[0].RoleId !='undefined'){
         setTimeout(() => {
            this._roleservice.getRole(data[0].RoleId).subscribe(data =>{
               
                console.log(data);
                 if(data[0].Name == 'Superadmin')
                 {
                     this.router.navigate(["Dashboard"]);
                 }
                //  else if(data[0].Name == 'admin')
                //  {
                //      this.router.navigate(["branch"]);
                //  }
                //  else if(data[0].Name == 'user')
                //  {
                //      this.router.navigate(["user"]);
                //  }
             })
          }, 1500);
             }else{
              this.loginError = true;
        // if(data[0].Name='undefined'){
        // var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
        // x.innerText = 'Login Creditional is Invalid !!!';
        // this._utility.ErrorToast1();
       }
      })
    }else{
        // var x = (<HTMLInputElement>document.getElementById('snackbarerror'));
        // x.innerText = 'Login Creditional can not be Empty !!!';
        // this._utility.ErrorToast1();
    }
  }


}
