<div class="portal-form" style="margin-top:62px;margin-bottom:0px;margin-left: -0px;margin-right: -0px;">
  <section> 
<div class="container  tab-content"> 
      <div class="card">
        <div class="card-header h4-style " style="background-color: white;"> 
         
          <div class="row" style="margin:0 0 0px">
            <div class="col-sm-10">
              <h3 style="margin-bottom: -5px;margin-top: 5px; margin-left: -15px;" > <b>Organisation View</b></h3>
            </div>
             <div class="col-sm-2">
              <button class="btn pull-right addnew" (click)="refresh();onClearAll();createOrganisation=false;this.isSave=false;" *ngIf="createOrganisation" title="Back to Organization">
              <i style="color: #E6800E;" class="fa fa-times"></i>
            </button>
            <button class="btn pull-right addnew" style="color: #E6800E ;font-size: 16px;font-family: open sans-serif;" *ngIf="!createOrganisation" title="Create Organization" (click)="createOrganisation=true;">
              <i style="color: #E6800E;" class="fa fa-plus"></i> Create New
            </button>
            </div>
            </div>
        </div>
        <div class="card-body1" *ngIf="!createOrganisation" style="padding:0px 0px 0px 0px">
          <div class="box" >
            <div style="height: 400px;overflow-x: auto;margin-top: 0px;">
              <table id="customers" class="table table-hover" style="width:100%;white-space: nowrap;overflow: hidden;font-family: open sans-serif; color:#777777;font-size: 16px;">
                <thead class="thead">
                  <tr>
                    <th></th>
                    <!-- <th>ID</th> -->
                    <th>Name</th>
                    <th>Code</th>
                    <th>GST</th>
                    <th>HO Code</th>
                    <th>Industry</th>
                    <!-- <th>Contact Person</th> -->
                    <th>Contact Number</th>
                    <th>Address</th>
                    <th>Building</th>
                    <!-- <th>Location</th>
                    <th>PIN</th>
                    <th>City</th>
                    <th>State</th>
                    <th>ContactNumber</th>
                    <th>Email</th> -->
                 
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of orgList; let i=index;">
                    <td>
                      <i  class="fa fa-edit" style="color:blue" (click)="getOrgbyid(data.OrganisationId)">
                      </i>
                    </td>
                    <!-- <td>{{i+1}}</td> -->
                    <td>{{data.Name}}</td>
                    <td>{{data.Code}}</td>
                    <td>{{data.GST}}</td>
                    <td>{{data.HeadOfficeCode}}</td>
                    <td>{{data.Industry}}</td>
                    <!-- <td>NaN</td> -->
                    <td>{{data.PrimaryContact}}</td>
                    <td>{{data.Address}}</td> 
                    <td>{{data.Building}}</td>
                    <!-- <td>{{data.Location}}</td>
                    <td>{{data.PIN}}</td>
                    <td>{{data.City}}</td>
                    <td>{{data.State}}</td>
                    <td>{{data.ContactNumber}}</td>
                    <td>{{data.Email}}</td> -->
                
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="createOrganisation">
       <form [formGroup]="OrganisationForm" autocomplete="off">
        
          <div class="row">
            <div class="col-sm-6">                
              <div class="card-body">
                  <h5 class="card-title">Organization Details</h5>                                    
                  <div class="form-group card-text pb-1 row">        
                      <label for="usr"  class="col-sm-12 col-lg-4  col-form-label text-right">
                        Organization Name<sup style="color: #e5800f; font-size: 14px;">*</sup>:</label>
                      <div class="col-sm-12 col-lg-8 border p-1">
                        <input type="text" class="form-control" id="usr" formControlName="Name" autocomplete="false" placeholder="Enter Organization Name">
                        <!-- <div class="validator"
                        *ngIf="OrganisationForm.controls['Name'].touched && !OrganisationForm.controls['Name'].valid">
                        <span *ngIf="OrganisationForm.controls['Name'].hasError('required') ">
                            Please Enter Organization Name 
                        </span>
                    </div> -->
                    <div *ngIf="OrganisationForm.errors?.identityRevealed && (OrganisationForm.touched || OrganisationForm.dirty)" class="cross-validation-error-message alert alert-danger">
                      Name cannot match alter ego.
                  </div>
                      </div>
                    </div>                                    
              </div>                
            </div>
            <div class="col-sm-6">
                 <div class="card-body">                                  
                </div>
             </div>
          </div>
    <!--2nd-row-->
    <div class="row">
      <div class="col-sm-6">
        <!-- <div class="card"> -->
          <div class="card-body">                                                
              <div class="form-group pb-1 card-text row">        
                  <label for="usr"  class="col-sm-12 col-lg-4 col-form-label text-right">
                    Organization Code<sup style="color: #e5800f; font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" id="usr" formControlName="Code" autocomplete="false" placeholder="Enter Organization Code">
                  <!-- <div class="validator"
                  *ngIf="OrganisationForm.controls['Code'].touched && !OrganisationForm.controls['Code'].valid">
                  <span *ngIf="OrganisationForm.controls['Code'].hasError('required') ">
                      Please Enter Organization Code
                  </span>
              </div> -->
                </div> 
                </div>                                
          </div>
        <!-- </div> -->
      </div>
      <div class="col-sm-6">
           <div class="card-body"> 
               <div class="form-group pb-1 card-text row">
                  <label for="usr"  class="col-sm-12 col-lg-4 col-form-label text-right">
                      GST<sup style="color: #e5800f;font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" id="usr" pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"  formControlName="GST" autocomplete="false" placeholder="Enter GST">
                </div>
                </div> 
                                   
          </div>
       </div>
    </div>
    <!--2nd-col--->
    <div class="row">
      <div class="col-sm-6">
                 <div class="card-body">                                              
              <div class="form-group card-text pb-1 row">        
                <label for="usr"  class="col-sm-12 col-lg-4 col-form-label text-right">
                  Head Office Code:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" disabled id="usr" formControlName="HeadOfficeCode" autocomplete="false" placeholder="Enter Head Office Code">
                </div>   
                </div>                               
          </div>
             </div>
      <div class="col-sm-6">
           <div class="card-body">                           
              <div class="form-group pb-1 card-text row">
                  <label for="sel1" class="col-sm-12 col-lg-4 pt-2 text-right fn-14">
                     Industry <sup style="color: #e5800f;font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <select class="form-control pl-2" id="sel1" formControlName="Industry" name="Select">
                      <option>Select Industry</option>
                      <option>Banking</option>
                      <option>Manufacturing</option>
                      <option>Chitfunds</option>
                  </select>
                  </div>   
                </div>                                     
          </div>
       </div>
    </div>
    <!--3rd-row-->
    
    <!---4th-row-->
    <div class="row">
      <div class="col-sm-6">
      
          <div class="card-body">
              <h5 class="card-title">Contact Details</h5>                                
              <div class="form-group pb-1 card-text row">        
                  <label for="usr"  class="col-sm-12 col-lg-4 text-right col-form-label">
                      Contact Person<sup style="color: #e5800f;font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" id="usr" formControlName="ContactPerson" placeholder="Enter Contact Person" name="phone" autocomplete="false" required>  
                  </div> 
                </div> 
                             
          </div>
      
      </div>
      <div class="col-sm-6">
           <div class="card-body"> 
              <h5>&nbsp;</h5>                           
              <div class="form-group pb-1 card-text row">
                  <label for="usr"  class="col-sm-12 col-lg-4 text-right col-form-label">
                      Contact Number<sup style="color: #e5800f;font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                    <input type="text" class="form-control" (keypress)="validateNumber($event);" minlength="10" maxlength="10" id="usr" formControlName="ContactNumber" placeholder="Enter Contact Number" autocomplete="false" name="phone" required> 
                    </div> 
                </div>                                     
          </div>
       </div>
    </div>
    <!--2nd-col--->
    <div class="row">
      <div class="col-sm-6">
        <!-- <div class="card"> -->
          <div class="card-body">
                                         
              <div class="form-group pb-1 card-text row">        
                  <label for="usr"  class="col-sm-12 col-lg-4 text-right col-form-label">Primary Contact : </label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" (keypress)="validateNumber($event);" id="usr" minlength="10" maxlength="10" formControlName="PrimaryContact" placeholder="Enter Primary Contact" autocomplete="false" name="phone" required>  
                  </div>
                </div> 
                          
          </div>
        <!-- </div> -->
      </div>
      <div class="col-sm-6">
           <div class="card-body"> 
                                      
              <div class="form-group pb-1 card-text row">
                  <label for="email"  class="col-sm-12 col-lg-4 text-right col-form-label">Email :</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="email" class="form-control" id="email"  pattern="^[^ ]+@[^ ]+\.[a-z]{2,6}$" formControlName="Email" placeholder="Enter Email" autocomplete="false" name="email">
                </div> 
                </div>                            
          </div>
       </div>
    </div>
    <!--5th-row-->
    <div class="row">
      <div class="col-sm-6">
        <!-- <div class="card"> -->
          <div class="card-body">
              <h5 class="card-title">Address Details</h5>                                              
              <div class="form-group pb-1 card-text row">        
                  <label for="usr" class="col-sm-12 col-lg-4 text-right col-form-label">
                      Address<sup style="color: #e5800f;font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" id="usr" formControlName="Address" placeholder="Enter Address" autocomplete="false">
                </div>
                </div>                              
          </div>
        <!-- </div> -->
      </div>
      <div class="col-sm-6">
           <div class="card-body"> 
              <h5>&nbsp;</h5>                            
              <div class="form-group pb-1 card-text row">
                  <label for="usr"  class="col-sm-12 col-lg-4 text-right col-form-label">Building:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" id="usr" formControlName="Building" placeholder="Enter Building" autocomplete="false">
                </div> 
                </div> 
                                   
          </div>
       </div>
    </div>
    <!--2nd-col--->
    <div class="row">
      <div class="col-sm-6">
        <!-- <div class="card"> -->
          <div class="card-body">                         
              <div class="form-group pb-1 card-text row">        
                  <label for="usr" class="col-sm-12 col-lg-4 text-right col-form-label">
                      PIN Code<sup style="color: #e5800f;font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" (keypress)="validateNumber($event);" id="usr" maxlength="6" minlength="6" formControlName="PIN" autocomplete="false" placeholder="Enter PIN Code">
                </div>
                </div>                           
          </div>
        <!-- </div> -->
      </div>
      <div class="col-sm-6">
           <div class="card-body">                                    
              <div class="form-group pb-1 card-text row">
                  <label for="usr"  class="col-sm-12 col-lg-4 text-right col-form-label">
                      Location<sup style="color: #e5800f;font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" id="usr" formControlName="Location" autocomplete="false" placeholder="Enter Location">
                  </div>
                </div> 
                                
          </div>
       </div>
    </div>
    <!--3-col-->
    <div class="row">
      <div class="col-sm-6">
        <!-- <div class="card"> -->
          <div class="card-body">                         
              <div class="form-group pb-1 card-text row">        
                  <label for="sel1" class="col-sm-12 col-lg-4 text-right pt-2 fn-14">
                      State<sup style="color: #e5800f;font-size: 14px;">*</sup>:</label>
                      <div class="col-sm-12 col-lg-8 border p-1">
                      <select class="form-control pl-2" id="sel1" formControlName="State" name="sellist1">
                      <option>Select State</option>
                      <option>Telangana</option>
                      <option>Maharashtra</option>
                      <option>Karnataka</option>
                      </select>
                  </div>
                </div>                               
          </div>
        <!-- </div> -->
      </div>
      <div class="col-sm-6">
           <div class="card-body">                    
              <div class="form-group pb-1 card-text row">
                  <label for="sel1" class="col-sm-12 col-lg-4 text-right fn-14 pt-2">
                      City<sup style="color: #e5800f; font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <select class="form-control pl-2" id="sel1" formControlName="City" name="sellist1">
                      <option>Select City</option>
                      <option>Hyderabad</option>
                      <option>Pune</option>
                      <option>Bangalore</option>
                  </select>
                  </div>    
                </div>                                     
          </div>
       </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <!-- <div class="card"> -->
          <div class="card-body">
              <h5 class="card-title">Header Details</h5>                                              
              <div class="form-group pb-1 card-text row">        
                  <label for="usr" class="col-sm-12 col-lg-4 text-right col-form-label">
                      URL Name<sup style="color: #e5800f;font-size: 14px;">*</sup>:</label>
                  <div class="col-sm-12 col-lg-8 border p-1">
                  <input type="text" class="form-control" id="URLName" formControlName="URLName" placeholder="Enter URL Name" autocomplete="false">
                </div>
                </div>                              
          </div>
        <!-- </div> -->
      </div>
      <div class="col-sm-6">
        <div class="card-body"> 
           <h5>&nbsp;</h5>                            
           <div class="form-group pb-1 card-text row">
               <label for="usr"  class="col-sm-12 col-lg-4 text-right col-form-label">Description:</label>
               <div class="col-sm-12 col-lg-8 border p-1">
               <input type="text" class="form-control" id="Desc" formControlName="Desc" placeholder="Enter Description" autocomplete="false">
             </div> 
             </div> 
                                
       </div>
    </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card-body"> 
         <div class="form-group pb-1 card-text row" style="margin-left: 100px;">       
         <form id="file-upload-form" class="uploader text-left">
           <input id="file-upload" type="file" (change)="readUrl($event)" #UploadFileInput name="fileUpload" accept="image/*" style="display: none;" />
           <label for="file-upload" *ngIf="!url" id="file-drag" class="labelimg">
           <img id="file-image" src="../../assets/img/uplaod.png" height="34" width="34" alt="Preview">
           <div id="response" class="hidden">
           <div id="messages"></div>
           </div>
           </label>
           <img [src]="url" *ngIf="url" height="27" width="27" (change)="readUrl($event)">
           <img [src]="imagebyId" height="34" width="34" *ngIf="imagebyId" (change)="readUrl($event)">
           </form> 
           </div>              
       </div>
    </div>
    
    </div>
    <!--form-end   [disabled]='!OrganisationForm.valid'-->
    <div class="d-flex justify-content-end mt-4 mb-4 card-body">
      <button type="save" (click)="onClearAll()"  class="btn btn-outline btn-clear mr-3">Clear All</button>
    <button type="save" (click)="onSave()" *ngIf=" isSave" class="btn btn-save">Save</button>
    <button type="save" (click)="onUpdate(OrganisationForm.value);"  *ngIf="isUpdate" class="btn btn-save">Update</button>
 </div>
       </form>
        </div>
       
   </div>  
   <div class="row pull-right" style="float:right;margin:15px">
    <button (click)="goBack();" class="btn-save rounded mt-2 ml-2">Back</button>
  </div>
</div>

<div id="snackbar"></div>
<div id="snackbarerror"></div>
  </section>  
 </div>