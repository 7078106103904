import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { apiurl } from '../../environments/environment';
@Injectable({
providedIn: 'root'
})
export class AutocompleteService {
//_apiurl: string = 'https://etgarage.in/';
_apiurl: string = apiurl;
constructor(private _http:HttpClient) { }

getOrganizationDetails(){
return this._http.get<{posts:any}>(this._apiurl+'/Organisation/getOrganisationAutoComplete').pipe(map((postData)=>{
return postData.posts.map(post =>{
return{
id: post._id,
name: post.OrganisationName,
};
})
}));
}

getBranchDetails(){
    return this._http.get<{posts:any}>(this._apiurl+'/Branch/getBranchAutoComplete').pipe(map((postData)=>{
    return postData.posts.map(post =>{
    return{
    id: post._id,
    name: post.Name,
    };
    })
    }));
    }


    getRoleDetails(){
        return this._http.get<{posts:any}>(this._apiurl+'/Role/getRoleAutoComplete').pipe(map((postData)=>{
        return postData.posts.map(post =>{
        return{
        id: post._id,
        name: post.Name,
        };
        })
        }));
        }


        getTemplateSearchDetails(){
            return this._http.get<{posts:any}>(this._apiurl+'/Template/getTemplateAutoComplete').pipe(map((postData)=>{
            return postData.posts.map(post =>{
            return{
            id: post._id,
            TemplateName: post.TemplateName,
            // NumberOfTemplate : post.NumberOfTemplate,
            // Category: post.Category,
            // Payload : post.Payload,
            };
            })
            }));
            }
}