import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IndustryService } from '../service/industry.service';
import { UtilitiesService } from '../service/utility.service';
import { imageToBase64 } from '../../../node_modules/image-to-base64';
import { TemplateService } from '../service/template.service';
import { CategoryService } from '../service/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.css'],
})
export class IndustryComponent implements OnInit {
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
  isRibbon:boolean=false;
  industryForm: FormGroup;
  addedited: boolean = false;
  isUpdate: boolean = false;
  isSave: boolean = true;
  title = 'industry';
  mySubscription;
  addeditedCategory: boolean = false;
  CategoryForm: FormGroup;
  constructor(
    private _formbuilder: FormBuilder,
    private _router: Router,
    private _template: TemplateService,
    private _category: CategoryService,
    private routes: ActivatedRoute,
    private _utility: UtilitiesService,
    private _industry: IndustryService,
    private spinner: NgxSpinnerService
  ) {
    this.createForm();
    this.isRibbon=false;
    this.createCategoryForm();
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this._router.navigated = false;
      }
    });
  }
  ngOnDestroy() {
    console.log('app ngOnDestroy');
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
  createForm() {
    this.industryForm = this._formbuilder.group({
      Id: [''],
      Code: [''],
      Name: ['', Validators.required],
      Description: ['', Validators.required],
      Icon: [''],
      CreatedBy: [1],
      UpdatedBy: [1],
    });
  }

  createCategoryForm() {
    this.CategoryForm = this._formbuilder.group({
      Id: [''],
      CategoryName: ['', Validators.required],
      IndustryId: ['', Validators.required],
      Description: [''],
      CreatedBy: [1],
      UpdatedBy: [1],
    });
  }

  ngOnInit() {
     /** spinner starts on init */
    //  this.spinner.show();
    this.getIndustryDtl();
    //this.getIndustrySearch('B');
    //this.getTemplateByCategory('Banking');
    // this.getTemplateByCategory('Gold Loan');
    

    //  setTimeout(() => {
      
    //    this.spinner.hide();
    //  }, 6000);
   
  }
  IndustryList: any;
  getIndustryDtl() {
    this.spinner.show();
    this._industry.getIndustryDtl().subscribe((data) => {
      console.log(data);
      this.IndustryList = data;
      this.spinner.hide();
    });
  }
  imagebyId: any;
  getIndustrybyid(id) {
    this.isUpdate = true;
    this.isSave = false;
    // this.addedited =true;
    this._industry.getIndustryById(id).subscribe((data) => {
      console.log(data);
      this.industryForm.setValue(data[0]);
      this.imagebyId = data[0].Icon;
    });
  }
  //get Category by Industry Id
  selectedIndustryId: any;
  LoanCAtegoryList: any;
  getCategoryByIndustryId(id) {
    console.log(id);
    this.selectedIndustryId = id;
    this._category.getCategoryByIndustryId(id).subscribe((data) => {
      console.log(data);
      this.LoanCAtegoryList = data;
    });
  }

  onSaveCategory() {
    this.CategoryForm.patchValue({
      IndustryId: this.selectedIndustryId,
    });
    console.log(this.CategoryForm.value);
    //console.log(e);
    var postdata = {
      CategoryName: (<HTMLInputElement>document.getElementById('CategoryName'))
        .value,
      IndustryId: (<HTMLInputElement>document.getElementById('IndustryId'))
        .value,
      Description: (<HTMLInputElement>document.getElementById('Description'))
        .value,
    };
    console.log(postdata);
    if (this.CategoryForm.valid) {
      this._category
        .createCategory(this.CategoryForm.value)
        .subscribe((data) => {
          console.log(data);
          this.addedited = false;
          var x = <HTMLInputElement>document.getElementById('snackbar');
          x.innerText = 'Category Created Sucessfully';
          this._utility.SuccessToast();

          setTimeout(() => {
            this.refresh();
          }, 2000);
        });
    } else {
      const invalid = [];
      const controls = this.CategoryForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
          var x = <HTMLInputElement>document.getElementById('snackbarerror');
          x.innerText = 'Please Enter ' + invalid[0] + '';
          this._utility.ErrorToast1();
        }
      }
      return invalid;
    }
  }

  onSave() {
    // console.log(this.industryForm.value);
    this.industryForm.patchValue({
      Icon: this.url,
    });

    if (this.industryForm.valid) {
     
      this._industry
        .checkDuplicateIndustries(this.industryForm.get('Name').value)
        .subscribe((data) => {
          this.spinner.show();
          if (data['posts'].length == 0) {
            this._industry
              .createIndustry(this.industryForm.value)
              .subscribe((data) => {
               
                console.log(data);
                this.addedited = false;
                var x = <HTMLInputElement>document.getElementById('snackbar');
                x.innerText = 'Industry Created Sucessfully';
                this._utility.SuccessToast();
              
                setTimeout(() => {
                  this.refresh();
                  
                }, 2000);
                this.spinner.hide();
              });
          } else {
            var x = <HTMLInputElement>document.getElementById('snackbarerror');
            x.innerText = 'This Industry Already Existed';
            this._utility.ErrorToast1();
          }
        });

      // this._industry.createIndustry(this.industryForm.value)
      //   .subscribe(data => {
      //     console.log(data);
      //     this.addedited = false;
      //     var x = (<HTMLInputElement>document.getElementById('snackbar'));
      //     x.innerText = 'Industry Created Sucessfully';
      //     this._utility.SuccessToast();

      //     setTimeout(() => {
      //       this.refresh();
      //     }, 2000);

      //   })
    } else {
      const invalid = [];
      const controls = this.industryForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
          var x = <HTMLInputElement>document.getElementById('snackbarerror');
          x.innerText = 'Please Enter ' + invalid[0] + '';
          this._utility.ErrorToast1();
        }
      }
      return invalid;
    }
  }
  //(<HTMLInputElement>document.getElementById('file-upload')).value,
  onUpdate(e) {
    console.log(e);
    console.log(e.Icon.name);
    this.industryForm.patchValue({
      Icon: (<HTMLInputElement>document.getElementById('file-upload')).value,
    });
    if (this.industryForm.valid) {
      this._industry.UpdateIndustry(e).subscribe(
        (data) => {
          console.log(data);
          var x = <HTMLInputElement>document.getElementById('snackbar');
          x.innerText = 'Industry Updated Sucessfully';
          this._utility.SuccessToast();
          //this._router.navigate(["/branch"]);
          setTimeout(() => {
            this.refresh();
          }, 2000);
          //this.refresh();

          this.isUpdate = false;
          this.isSave = true;
          this.addedited = false;
          //his.BranchForm.reset
        },
        (err) => {
          var x = <HTMLInputElement>document.getElementById('snackbarerror');
          x.innerText = 'An Error Occured' + err.error.toString();
          this._utility.ErrorToast1();
        }
      );
    } else {
      const invalid = [];
      const controls = this.industryForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
          var x = <HTMLInputElement>document.getElementById('snackbarerror');
          x.innerText = 'Please Enter ' + invalid[0] + '';
          this._utility.ErrorToast1();
        }
      }
      return invalid;
    }
  }

  onClearAll() {
    this.industryForm.reset();
    // this.CategoryForm.reset();
    this.url = '';
  }
  onClearAll1() {
    (<HTMLInputElement>document.getElementById('CategoryName')).value = '';
    (<HTMLInputElement>document.getElementById('Description')).value = '';
  }
  goToItems() {
    this._router.navigate(['/template'], { relativeTo: this.routes });
  }
  refresh() {
    //debugger;
    this.industryForm.reset();
    //  window.location.reload();
    this._router
      .navigateByUrl('/industry//miscellaneous/not-found', {
        skipLocationChange: true,
      })
      .then(() => this._router.navigate(['/industry']));

    console.log('refresh');
  }

  validateNumber(event: any) {
    this._utility.validateNumber(event);
  }
  fileInputLabel: string;
  onFileSelect(event) {
    const file = event.target.files[0];
    console.log(file);
    this.fileInputLabel = file.name;
    this.industryForm.get('Icon').setValue(file);
  }
  url: any;
  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result;
        console.log(this.url);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  //search Box test
  myFunction() {
    var input, filter, table, tr, a, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    table = document.getElementById('customers');
    tr = table.getElementsByTagName('tr');
    for (i = 0; i < tr.length; i++) {
      a = tr[i].getElementsByTagName('a')[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = '';
      } else {
        tr[i].style.display = 'none';
      }
    }
  }

  text: any = '';
  selectedString = '';
  onKeyUp(x) {
    // appending the updated value to the variable
    console.log(x.target.value);
    this.text += x.target.value + ' | ';
    this.selectedString = x.target.value.toString();
    console.log(this.selectedString);
    this.getIndustrySearch(this.selectedString);
  }
  filterdata: any;
  getIndustrySearch(name) {
    //this._industry.getIndustrySearch(name).subscribe((data) => {
      this._industry.getIndustrySearch1(name).subscribe((data) => {
      console.log(data);
      this.filterdata = data;
    });
  }

  TemplateByCatList: any;
  getTemplateByCategory(categ) {
    this._template.getTemplateByCategory(categ).subscribe((data) => {
      console.log(data);
      this.TemplateByCatList = data;
    });
  }
  // ribbon
  //#region robbon
  iconColor= 'Templates icon1.png'
  configicon='setting.png';
  masterIcon='data-icon.svg';
  apiIcon='api.png';
  blockChainIcon='blockchain.png';
  billingIcon='Billing-icon.svg';
 
  click1(){
    this.iconColor='Templates icon.png';
    console.log('color');
  }

  clickonConfiguration(){
    this.configicon='Configuration icon.png';
    console.log('color');
  }
  clickMaster(){
    this.masterIcon='data icon.png';
    console.log('color');
  }
  clickApi(){
    this.apiIcon='API icon.png';
    console.log('color');
  }
  clickBlockChain(){
    this.blockChainIcon='Blockchain icon.png';
    console.log('color');
  }
  clickBilling(){
    this.billingIcon='Billing icon.png';
    console.log('color');
  }


  onChangeRibon(){
    // this.iconColor= 'as.png';
    this.configicon='setting.png';
    this.masterIcon='data-icon.svg';
    this.apiIcon='api.png';
    this.blockChainIcon='blockchain.png';
    this.billingIcon='Billing-icon.svg';
  }

  onChangeconfig(){
      this.iconColor= 'Templates icon1.png';
      // this.configicon='setting.png';
      this.masterIcon='data-icon.svg';
      this.apiIcon='api.png';
      this.blockChainIcon='blockchain.png';
      this.billingIcon='Billing-icon.svg';
  }
  onChangeMaster(){
    this.iconColor= 'Templates icon1.png';
    this.configicon='setting.png';
    // this.masterIcon='data-icon.svg';
    this.apiIcon='api.png';
    this.blockChainIcon='blockchain.png';
    this.billingIcon='Billing-icon.svg';
}
onChangeApi(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  // this.apiIcon='api.png';
  this.blockChainIcon='blockchain.png';
  this.billingIcon='Billing-icon.svg';
}
onChangeBlockChain(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  this.apiIcon='api.png';
  // this.blockChainIcon='blockchain.png';
  this.billingIcon='Billing-icon.svg';
}
onChangebilling(){
  this.iconColor= 'Templates icon1.png';
  this.configicon='setting.png';
  this.masterIcon='data-icon.svg';
  this.apiIcon='api.png';
  this.blockChainIcon='blockchain.png';
  // this.billingIcon='Billing-icon.svg';
}

  //#endregion ribbon
}
