import { Component, OnInit,ViewChild } from '@angular/core';
import { Chart, ChartType,ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective,Color, Label } from 'ng2-charts';
import { ActivatedRoute, Router } from '@angular/router';
import { PanchratanService } from '../service/panchratan.service';
import * as moment from 'moment';
@Component({
  selector: 'app-panchratan',
  templateUrl: './panchratan.component.html',
  styleUrls: ['./panchratan.component.css']
})
export class PanchratanComponent implements OnInit {
  public lineChartData: ChartDataSets[];
  public lineChartLabels: Label[];
  public lineChartColors: Color[];
  public lineChartLegend :any;
    public lineChartType:any;
    public lineChartPlugins :any;
    QRCodeVal:any;
    PlantHeight = [];
    PlantDate = [];
    chartReady:Boolean = false;
    User:any;
    UserImage:any;
    public lineChartOptions: any;
    TransplantationDate:any;
    FTransplantationDate:any;
    FGrowthMonitoringDate:any;
    FGrowthMonitoringHarvestDate:any;
    FPackageDate:any;
    BoolUserImage:boolean =false;
    CropName:any;
    VarietyName:any;
    VerifyLink:any;
  constructor(  private route: ActivatedRoute,private _service: PanchratanService) { }
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  ngOnInit() {

  
  
    let qrcode = this.route.snapshot.params.QRCode;
    console.log(qrcode)
    this.QRCodeVal = qrcode;
    if (this.QRCodeVal != '') {
      this._service.getBlockChainDataDetails(this.QRCodeVal)
      .subscribe((data) => {
        var index = data['posts']['childblockchaintriggers'].length;
        this.VerifyLink='https://dev.trst01.in/verifyqldb/'+this.QRCodeVal;
        //this.VerifyLink='http://localhost:4200/verifyqldb/'+this.QRCodeVal;
        // if(index == 0)
        // {
        //   this.VerifyLink = data['posts']['blockchainurl'];
        // }
        // else
        // {
        //   this.VerifyLink = data['posts']['childblockchaintriggers'][index-1].blockchainurl
          
        // }
        for ( let i = 0; i < data['posts']['childblockchaintriggers'].length; i++ )
        {
          let Docarr = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
          console.log(Docarr)
          for (let f = 0; f < Docarr.length; f++) {
                      
            var index = Docarr[f].indexOf(':');
            var Key = Docarr[f].substring(0, index);
            var Value = Docarr[f].substring( index + 1,Docarr[f].length );
            if(Key == "CropName")
            {
              this.CropName =Value;
            }
            if(Key == "VarietyName")
            {
              this.VarietyName = Value;
            }
            if(Key == "TransplantationDate")
            {
                this.TransplantationDate = Value;
                this.FTransplantationDate = moment(Value).format('DD-MM-YYYY')
            }
            if(Key == "GrowthMonitoring")
            {
              var GMJson =JSON.parse(Value)
              console.log(GMJson)
              for(let a=0;a<GMJson.length;a++)
              {
                if(a== 0)
                {
                  this.PlantHeight.push(0)
                  this.PlantDate.push("1")
                }
               
               this.PlantHeight.push(GMJson[a]["PlantHeight"])
               console.log(moment(this.TransplantationDate).format('DD-MM-YYYY'))
               console.log(moment(GMJson[a]["CreatedDate"]).format('DD-MM-YYYY'))
               var dt1 = new Date(this.TransplantationDate);
               var dt2 = new Date(GMJson[a]["CreatedDate"]);
              
              //console.log(Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24)))
              var day =Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
              // var week;
              // if (day >= 1 && day <= 7) {
              //   week = "1W" 
              // }
              // else if (day >= 8 && day <= 14) {
              //   week = "2W" 
              // }
              // else if (day >= 15 && day <= 21) {
              //   week = "3W" 
              // }
              // else if (day >= 22 && day <= 28) {
              //   week = "4W" 
              // }
              // else if (day >= 29 && day <= 31) {
              //   week = "5W" 
              // }
               //this.PlantDate.push(moment(GMJson[a]["CreatedDate"]).format('DD-MM-YYYY'))
               this.PlantDate.push(day)
               if(GMJson.length -1 == a)
               {
                 this.getLinechart(this.PlantHeight,this.PlantDate) 
                 this.FGrowthMonitoringDate = moment(GMJson[a]["CreatedDate"]).format('DD-MM-YYYY')
               } 
              }
            }
            if(Key == "GrowthMonitoringHarvest")
            {
              var GMHJson =JSON.parse(Value)
              console.log(GMHJson)
              for(let a=0;a<GMHJson.length;a++)
              {
                if(GMHJson.length -1 == a)
                {
                    this.User = GMHJson[a]["HarvestAddedUser"]
                    this.UserImage = GMHJson[a]["UserImage"]
                    this.BoolUserImage =true;
                    this.FGrowthMonitoringHarvestDate = moment(GMHJson[a]["HarvestDate"]).format('DD-MM-YYYY')
                    this.FPackageDate = moment(GMHJson[a]["PackageDate"]).format('DD-MM-YYYY')
                    console.log(this.FTransplantationDate)
                    console.log(this.FGrowthMonitoringDate)
                    console.log(this.FGrowthMonitoringHarvestDate)
                    console.log(this.FPackageDate)
                }
              }
            }
          }
        }
      });
 
    }
  }

  getLinechart(PlantHeight,PlantDate)
  {
    this.chartReady = true;
    this.lineChartOptions= {
      responsive: true,
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          font: {
            size: 15,
          }
        }
      },
      scales: {
              xAxes: [{
  
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                  // display: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Days',
                color:'#31AE7A'
              },
              ticks: {
                maxRotation: 0
             }
            }, ],
            yAxes: [{
              //display: false
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
                //display: false,
            },
           
              scaleLabel: {
                display: true,
                labelString: 'Plant Height(cm)'
              }
            }]
            },
            ticks: {
              stepSize: 2,
              beginAtZero: true
           },
      pan: {
            enabled: true,
            mode: 'xy'
          },
      zoom: {
            enabled: true,
            mode: 'xy',
            limits: {
              max: 20,
               min: 20
            }
          },
    }
    this.lineChartData = [
      { data: PlantHeight},
    ];
    this.lineChartLabels = PlantDate;
    
    this.lineChartColors= [
      {
        borderColor: '#1B8F5F',
        backgroundColor: '#EAF7F1',
      },
    ];
     this.lineChartLegend = false;
     this.lineChartType = 'line';
    this.lineChartPlugins = [];
   
  }
}
