<!-- <div style="height:16px"> -->
  <section class="Home-page">
<nav class="navbar navbar-expand-lg navbar-light bg fixed-top">
  <a class="navbar-brand logo" href="#">
      <img src="/assets/img/logo-latest.png">
  </a>
  <!-- <a class="navbar-brand" href="#">Navbar</a> -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
    
    <div class="form-inline my-2 my-lg-0">
      <ul class="navbar-nav pt-2">
        <li class="nav-item active">
          <a class="nav-link" href="#">{{currentdate}}</a>
        </li>
        <li class="nav-item active">
            <a class="nav-link" href="#"><i class="fa fa-user-circle nav-user-icon" aria-hidden="true"></i></a>
          </li>    
          <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
             Admin
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <!-- <li><a class="dropdown-item" [routerLink]="['/industry/']" ><i class="fa fa-user dropdown-icons pr-2" aria-hidden="true"></i>Industry</a></li> -->
            <li><a class="dropdown-item" [routerLink]="['/profile/']" ><i class="fa fa-user dropdown-icons pr-2" aria-hidden="true"></i>Profile</a></li>
            <li><a class="dropdown-item"  [routerLink]="['/setting/']"><i class="fa fa-cog dropdown-icons pr-2" aria-hidden="true"></i>Settings</a></li>
            <li><a class="dropdown-item" href="#"><i class="fa fa-key dropdown-icons pr-2" aria-hidden="true"></i>Change Password</a></li>
            <li><a class="dropdown-item" [routerLink]="['/']"><i class="fa fa-sign-out dropdown-icons pr-2" aria-hidden="true"></i>Logout</a></li>
            </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
  </section>
  <!-- </div> -->