
export const environment = {
  production: false
};

//DEV
export const apiurl = "https://dev.trst01.in/api";
export const apiurldonatekart = "https://dev.trst01.in/donatekartapi"

//LOCAL
//  export const apiurl = "http://localhost:4000";
//  export const apiurldonatekart = "http://localhost:7000"

//PROD
// export const apiurl = "https://trst01.in/api";
// export const apiurldonatekart = "https://trst01.in/donatekartapi"


