<div class="container-fluid" style="padding: 0px !important;">
    <div class="card col-md-6" style="padding: 0px !important;">
<nav class="navbar navbar-expand-lg navbar-light bg fixed-top" style="padding: 0px !important;">
    <img src="../assets/img/image@2x.png" style="width: 100%;height: 100px;" />
</nav>
</div>
</div>
<div class="container-fluid" style="padding: 0px !important;margin-top: 101px;">
    <div class="card col-md-6" style="padding: 0px !important;">
        <!-- <div class="card-header" style="padding:0px !important;">
            <img src="../assets/img/PHeader.png" style="width: 100%;height: 100px;" />
        </div> -->
        <!-- <span
            style="text-align: center;font-size: 24px;font-family: Monotype Corsiva;color: #1E3978;padding-top:10px">Our
            mangoes are safe to eat</span> -->
        <div class="card-body" style="padding: 0px !important;">
            <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divTimeline">
                <!-- <div class="card-header" style="padding: 0px;">
                    <img src="../assets/img/vector.png" style="width: 100%;height: 100px;" />
                </div> -->
                <div class="card-header" style="text-align: right;font-size: 20px;font-weight: bold;background-color: #ffc107;padding-right: 10px;padding-left: 10px;">
                    <img src="../assets/img/Polimeraaslogo2.png" width="100px" height="60px" />
                    <span style="float: left;padding-top: 21px;font-style: oblique;">Our mangoes are safe to eat</span>
                </div>
                <div class="card-body" style="padding: 0px;">
                    <section class="timeline-area">
                        <div  class="tt div0 div">
                            <span style="font-weight: bold;font-size: 16px;">Origin of the Mango</span><br>
                            <span><img src="../assets/img/location.png"  (click)="OriginPopup()"/></span>
                                    <span style="color:#060026;font: normal normal medium 14px/18px Roboto;">Ulavapadu,
                                        AP</span>
                        </div>
                        <div  class="tt div1 div">
                            <span style="font-weight: bold;font-size: 16px;">Harvest</span><br>
                            <span style="color:#060026;font: normal normal medium 14px/18px Roboto;">
                                {{DynamicFarmer}}</span><br>
                            <span>{{DynamicHarvestedDate}}</span>
                        </div>
                        <div  class="tt div2 div">
                            <span style="font-weight: bold;font-size: 16px;">Ripening Center</span><br>
                            <span><img src="../assets/img/location.png" (click)="RipeningPopup()"/></span><span
                                            style="color:#060026;font: normal normal medium 14px/18px Roboto;">Kandukur Cold
                                            Storage, AP</span><br>
                                        <span>{{DynamicRipingDate}}</span>
                        </div>
                        <div  class="tt div3 div">
                            <span style="font-weight: bold;font-size: 16px;">Polimeras Store</span><br>
                            <span><img src="../assets/img/location.png" (click)="PolimeraasPopup()"/></span><span
                                            style="color:#060026;font: normal normal medium 14px/18px Roboto;">13, Himayat
                                            Nagar Rd, Gandhi Kuteer Colony, Narayanguda, Hyderabad, Telangana
                                            500029</span><br>
                                        <span>{{DynamicReachedStoreon}}</span>
                        </div>
                    </section>
                    <!-- <section class="main-timeline-section">
                        <div class="timeline-start"></div>
                        <div class="conference-center-line"></div>
                        <div class="conference-timeline-content">
                            
                            <div class="timeline-article content-right-container">
                              
                                <div class="meta-date" style="top:70%"><img src="../assets/img/OriginoftheMango.png"
                                    style="width: 40px;" /></div>
                                <div class="content-box" style="top:68%">
                                  
                                    <div class="title-description">
                                        <span style="font-weight: bold;font-size: 16px;">Origin of the Mango</span><br>
                                    <span><img src="../assets/img/location.png"  (click)="OriginPopup()"/></span>
                                    <span style="color:#060026;font: normal normal medium 14px/18px Roboto;">Ulavapadu,
                                        AP</span>
                                    </div>
                                </div>
                            </div>
        
                           
                          
                            <div class="timeline-article content-right-container">
                              
                                <div class="meta-date" style="top: 30px;"><img src="../assets/img/Harvet.png" style="width: 40px;" /></div>
                                <div class="content-box" style="top:29px">
                                  
                                    <div class="title-description">
                                        <span style="font-weight: bold;font-size: 16px;">Harvest</span><br>
                                    <span style="color:#060026;font: normal normal medium 14px/18px Roboto;">
                                        {{DynamicFarmer}}</span><br>
                                    <span>{{DynamicHarvestedDate}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline-article content-right-container">
                               
                                <div class="meta-date" style="top: 0px;"><img src="../assets/img/RipeningCenter.png"
                                    style="width: 40px;" /></div>
                                <div class="content-box" style="top:2px">
                                  
                                    <div class="title-description">
                                        <span style="font-weight: bold;font-size: 16px;">Ripening Center</span><br>
                                        <span><img src="../assets/img/location.png" (click)="RipeningPopup()"/></span><span
                                            style="color:#060026;font: normal normal medium 14px/18px Roboto;">Kandukur Cold
                                            Storage, AP</span><br>
                                        <span>{{DynamicRipingDate}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="timeline-article content-right-container">
                               
                                <div class="meta-date" style="top: 0px;"><img src="../assets/img/PolimerasStore.png"
                                    style="width: 40px;" /></div>
                                <div class="content-box" style="top:2px" >
                                  
                                    <div class="title-description">
                                        <span style="font-weight: bold;font-size: 16px;">Polimeras Store</span><br>
                                        <span><img src="../assets/img/location.png" (click)="PolimeraasPopup()"/></span><span
                                            style="color:#060026;font: normal normal medium 14px/18px Roboto;">13, Himayat
                                            Nagar Rd, Gandhi Kuteer Colony, Narayanguda, Hyderabad, Telangana
                                            500029</span><br>
                                        <span>{{DynamicReachedStoreon}}</span>
                                    </div>
                                </div>
                            </div>
                           
                            
                        </div>
                           <div class="timeline-end"></div>
            
                      </section> -->
                      <br>
                     
                      <p style="font-style: italic;font-size:12px;text-align: center;margin-top: 32px;">Verified on TRST01 cloud <a href="{{VerifyLink}}" target="_blank" style="color: #1f3978;text-decoration: underline;font-size:12px">Blockchain</a> Platform.<br> For More Details <a
                        style="color: #EA8039;font-style: italic;font-size:12px;text-decoration: underline;" (click)="History()">click here</a></p>
                    <!-- <ul class="timeline">

                        <li class="timeline-inverted">
                            <div class="timeline-badge"><img src="../assets/img/OriginoftheMango.png"
                                    style="width: 40px;" /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading" style="padding-top:10px;padding-left: 10px;">
                                    <span style="font-weight: bold;font-size: 16px;">Origin of the Mango</span><br>
                                    <span><img src="../assets/img/location.png" /></span>
                                    <span style="color:#060026;font: normal normal medium 14px/18px Roboto;">Ulavapadu,
                                        AP</span>
                                </div>

                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-badge"><img src="../assets/img/Harvet.png" style="width: 40px;" />
                            </div>
                            <div class="timeline-panel">
                                <div class="timeline-heading" style="padding-top:10px;padding-left: 10px;">
                                    <span style="font-weight: bold;font-size: 16px;">Harvest</span><br>
                                    <span style="color:#060026;font: normal normal medium 14px/18px Roboto;">
                                        {{DynamicFarmer}}</span><br>
                                    <span>{{DynamicHarvestedDate}}</span>
                                </div>

                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-badge"><img src="../assets/img/RipeningCenter.png"
                                    style="width: 40px;" /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading" style="padding-top:10px;padding-left: 10px;">
                                    <span style="font-weight: bold;font-size: 16px;">Ripening Center</span><br>
                                    <span><img src="../assets/img/location.png" /></span><span
                                        style="color:#060026;font: normal normal medium 14px/18px Roboto;">Kandukur Cold
                                        Storage, AP</span><br>
                                    <span>{{DynamicRipingDate}}</span>
                                </div>

                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-badge"><img src="../assets/img/PolimerasStore.png"
                                    style="width: 40px;" /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading" style="padding-top:10px;padding-left: 10px;">
                                    <span style="font-weight: bold;font-size: 16px;">Polimeras Store</span><br>
                                    <span><img src="../assets/img/location.png" /></span><span
                                        style="color:#060026;font: normal normal medium 14px/18px Roboto;">13, Himayat
                                        Nagar Rd, Gandhi Kuteer Colony, Narayanguda, Hyderabad, Telangana
                                        500029</span><br>
                                    <span>{{DynamicReachedStoreon}}</span>
                                </div>

                            </div>
                        </li>
                    </ul> -->
                    <!-- <p style="font-style: italic;font-size:12px;text-align: center;">Verified on TRST01 cloud Blockchain Platform.<br> For More Details <a
                            style="color: #EA8039;font-style: italic;font-size:12px;" (click)="History()">click here</a></p> -->
                  
                </div>
            </div>
            <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;margin-bottom: 20px;" *ngIf="divFormpicture">
                <div class="card-header" style="background-color: #fff;"><span
                        style="font-weight: bold;font-size: 15px;">Farm Pictures</span></div>
                <div class="card-body">
                    <ul class="list-group list-group-horizontal" style="list-style: none;overflow-x:scroll;padding-right: 10px;">
                        <li>
                            <img src="https://etgdocstorage.s3.ap-south-1.amazonaws.com/Organisation/Icons/1619701263204.jpeg"
                                style="width:150px;height:120px;padding-right: 10px;" />
                        </li>
                        <li>
                            <img src="https://etgdocstorage.s3.ap-south-1.amazonaws.com/Organisation/Icons/1619527419607.jpeg"
                                style="width:150px;height:120px;" />
                        </li>
                    </ul>
                </div>

            </div>
            <div class="card" style="margin:10px;box-shadow: 0px 3px 6px #00000029;" *ngIf="divHistory">
                <div class="card-header"
                style="text-align: left;background-color: #fff;font-weight: bold;font-size: 16px;">
                <i class="fa fa-reply" style="font-size: 20px;padding: 3px;"  (click)="back()"></i>
            </div>
                <!-- <div class="card-header"
                    style="text-align: left;background-color: #f7f6f3;font-weight: bold;font-size: 16px;"><img
                        src="../assets/img/back.png" style="width: 19px;height: 16px;"  (click)="back()"/> Back
                </div> -->
                <div class="card-body">
                    <p style="text-align: justify;">
                        Big Size, Rich Aroma, and the Unique Taste make Ulavapadu Banginapalli a sought out after mango in the summer season.Ulavapadu Banginapalli variety is the most famous and best-seller among all mango varieties in Andhra Pradesh and Telangana.The pulp is fibreless, firm, yellow with a sweet taste
                    </p>
                    <div class="card-body">
                        <div class="table-responsive-sm" >
                          <table class="table" style="font-family: open sans-serif" >
                            <thead>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Variety</td>
                                <td>:</td>
                                <td>{{DynamicVariety}}</td>
                              </tr>
                             
                              <tr>
                                <td style="white-space: nowrap;">Origin of the Mango</td>
                                <td>:</td>
                                <td style="white-space: normal !important;text-align: justify;">Ulavapadu, Prakasam, AP</td>
                              </tr>
                              <tr>
                                <td style="white-space: nowrap;">Method of ripening</td>
                                <td>:</td>
                                <td style="white-space: normal !important;"> Govt. prescribed natural ripening</td>
                              </tr>
                             
                              <tr>
                                <td style="white-space: nowrap;">License No</td>
                                <td>:</td>
                                <td style="white-space: normal !important;word-break: break-all;">U51909TG2020PTC138380</td>
                              </tr>
                             
                              <tr>
                                <td style="white-space: nowrap;">Batch Code</td>
                                <td>:</td>
                                <td>{{DynamicBatchCode}}</td>
                              </tr>
                              <tr>
                                <td style="white-space: nowrap;">Quality Certificate</td>
                                <td>:</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                </div>
            </div>
            <div id="myModal" class="modal" style="display: block !important;" *ngIf="ModalOrigin">
                <span style="float: left;padding: 5px;padding-left: 5px;"><a href="http://maps.google.co.in/maps?q=15.1681,80.0074" target="_blank"><img src="../assets/img/GoogleMap.png"  style="width: 23px;height:27px;"/></a></span>
                <span class="close" style="float: right;text-align: end;padding: 5px;font-size: 30px;color: #fff;" (click)="ModalOrigin=false">&times;</span>
                <img class="modal-content" >
                <agm-map 
  [latitude]="15.1681"
  [longitude]="80.0074"
  [zoom]="zoom"
  [disableDefaultUI]="false"
  [zoomControl]="false">

  <agm-marker 
  [latitude]="15.1681"
  [longitude]="80.0074"
  [label]="Ulavapadu">


</agm-marker>
</agm-map>
<!-- <a href="http://maps.google.co.in/maps?q=15.1681,80.0074" target="_blank">Go To Google Maps</a> -->
              </div>
              <div id="myModal" class="modal" style="display: block !important;" *ngIf="MdalRipingCenter">
                <span style="float: left;padding: 5px;padding-left: 5px;"><a href="http://maps.google.co.in/maps?q=15.2197,79.9025" target="_blank"><img src="../assets/img/GoogleMap.png"  style="width: 23px;height:27px;"/></a></span>
                <span class="close" style="float: right;text-align: end;padding: 5px;font-size: 30px;color: #fff;" (click)="MdalRipingCenter=false">&times;</span>
                <img class="modal-content" >
                <agm-map 
  [latitude]="15.2197"
  [longitude]="79.9025"
  [zoom]="zoom"
  [disableDefaultUI]="false"
  [zoomControl]="false">

  <agm-marker 
  [latitude]="15.2197"
  [longitude]="79.9025"
  [label]="Ulavapadu">


</agm-marker>
</agm-map>
              </div>
              <div id="myModal" class="modal" style="display: block !important;" *ngIf="ModalPolimeraas">
                <span style="float: left;padding: 5px;padding-left: 5px;"><a href="http://maps.google.co.in/maps?q=17.3997,78.4894" target="_blank"><img src="../assets/img/GoogleMap.png"  style="width: 23px;height:27px;"/></a></span>
                <span class="close"style="float: right;text-align: end;padding: 5px;font-size: 30px;color: #fff;" (click)="ModalPolimeraas=false">&times;</span>
                <img class="modal-content" >
                <agm-map 
  [latitude]="17.3997"
  [longitude]="78.4894"
  [zoom]="zoom"
  [disableDefaultUI]="false"
  [zoomControl]="false">

  <agm-marker 
  [latitude]="17.3997"
  [longitude]="78.4894"
  [label]="Ulavapadu">


</agm-marker>
</agm-map>
<!-- <a href="http://maps.google.co.in/maps?q=17.3997,78.4894" target="_blank">Go To Google Maps</a> -->
              </div>
        </div>
        
    </div>

</div>
<div class="container-fluid" style="padding: 0px !important;">
    <div class="card col-md-6" style="padding: 0px !important;">
    <!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
        <div class="d-flex" style="width: 100%;">
            <img
                src="../assets/img/footer@2x.png" style="width: 100%;height: 63px;" />
        </div>
      </nav> -->
      <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
        <div class="d-flex" style="width: 100%;">
            <div class="mr-auto pt-3">
                <p class="ft-text" style="color: white;font-size: 10px;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
            </div>
            <div class="pt-3">
             <p class="ft-pwr pr-1" style="font-size: 10px;">Powered by</p> 
           </div>
            <div class="pt-1 logo-ft ">
                <img src="/assets/img/TRST001.jpeg">
            </div>
        </div>
      </nav>
      <!-- <nav class="navbar navbar-expand-sm ft-bg navbar-dark fixed-bottom">
        <div class="d-flex" style="width: 100%;">
            <div class="mr-auto pt-3">
                <p class="ft-text" style="color: white;"> &copy;  2021  TRST01. All Rights Reserved</p>                   
            </div>
            <div class="pt-3">
             <p class="ft-pwr pr-1">Powered by</p> 
           </div>
            <div class="pt-1 logo-ft ">
               <img src="/assets/img/footer-logo.png">
            </div>
        </div>
        
      </nav> -->
    </div>
</div>