import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { apiurl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
 //_apiurl: string = 'https://etgarage.in/api';
  _apiurl: string = apiurl;
  constructor(private _http:HttpClient,private _router:Router) { }

  getOrganizationDetails(){
    return this._http.get<{posts:any}>(this._apiurl+'/Organisation/GetOrganisation').pipe(map((postData)=>{
      return postData.posts.map(post =>{
        return{
            Id:post._id,
            Name: post.OrganisationName,
            Code: post.OrganisationCode,
            Address: post.Address,
            Building: post.Building,
            Location: post.Location,
            PIN: post.PIN,
            City: post.City,
            State: post.State,
            Industry: post.Industry,
            Category: post.Category,
            PrimaryContact: post.PrimaryContact,
            ContactNumber: post.ContactNumber,
            Email: post.Email,
            GST: post.GST,
            HeadOfficeCode: post.HeadOfficeCode,
            CreatedBy:post.CreatedBy,
            UpdatedBy: post.UpdatedBy,
            OrganisationId:post.OrganisationId
        };
      })
    }));
  }

getOrgById(id:any){
    return this._http.get<{posts:any}>(this._apiurl+'/Organisation/GetOrganisation/'+id).pipe(map((postData)=>{
        return postData.posts.map(post =>{
          return{
              Id:post._id,
              Name: post.OrganisationName,
              Code: post.OrganisationCode,
              Address: post.Address,
              Building: post.Building,
              Location: post.Location,
              PIN: post.PIN,
              City: post.City,
              State: post.State,
              Industry: post.Industry,
              Category: post.Category,
              ContactPerson:post.ContactPerson,
              PrimaryContact: post.PrimaryContact,
              ContactNumber: post.PrimaryContact,
              Email: post.Email,
              GST: post.GST,
              HeadOfficeCode: post.HeadOfficeCode,
              CreatedBy:post.CreatedBy,
              UpdatedBy: post.UpdatedBy,
              URLName:post.URLName,
              Desc:post.Desc,
              Icon:post.Icon
          };
        })
      }));

}


  createOrganization(Organization: any) {
    return this._http.post(this._apiurl+'/Organisation/Org', Organization);
  }

  UpdateOrganization(Organization: any) {
    return this._http.put(this._apiurl+'/Organisation/updateOrganisation', Organization);
  }

 
}