import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StandardizedLandingpageService } from '../service/StandardizedLandingpage.service';
import * as moment from 'moment';
import _, { map } from 'underscore';
import { KeyValue } from '@angular/common';
@Component({
  selector: 'app-chitfund',
  templateUrl: './chitfund.component.html',
  styleUrls: ['./chitfund.component.css'],
})
export class ChitfundComponent implements OnInit {
  cardChitDetails: boolean = true;
  cardPersonalDetails: boolean = true;
  cardLedgerDetails: boolean = true;
  cardEventTable: boolean = true;
  innercardChitDetails: boolean = false;
  innercardPersonalDetails: boolean = false;
  innercardLedgerDetails: boolean = false;
  innercardEventTable: boolean = false;
  QRCodeVal: string;
  AllItems: any;
  AllForm: any;
  AllKeyValue = [];
  ChitDocumentData: any;
  PersonalDocumentData: any;
  LedgerDocumentData: any;
  EventTableData:any;
  constructor(
    private route: ActivatedRoute,
    private _service: StandardizedLandingpageService
  ) {}

  ngOnInit() {
    let URLName = this.route.snapshot.params.Name;
    let qrcode = this.route.snapshot.params.QRCode;
    this.QRCodeVal = qrcode;
    if (this.QRCodeVal != '') {
      this._service.getMobile(qrcode).subscribe((data) => {
        this._service
          .getOrgById(data['posts'][0].ClientID)
          .subscribe((data1) => {
            if (data1[0].URLName == URLName) {
              console.log(data1[0].Icon);

              this._service
                .getBlockChainDataDetails(this.QRCodeVal)
                .subscribe((data) => {
                  console.log(data);

                  if (data['posts']['childqrcodes'].length == 0) {
                    let innerChitData =[];
                    let innerPersonalData = [];
                    let innerLedgerData =[];
                    let innerEventTableData =[];
                    //Child QRCodes
                    var ParentQRCode = data['posts']['parentqrcode'].uniqueid
                    this._service.getBlockChainDataDetails(ParentQRCode).subscribe((Parentdata) => {
                      for ( let i = 0; i < Parentdata['posts']['childblockchaintriggers'].length; i++ )
                       {
                        let obj = {};
                        let ChitDocArrData = [];
                        let EventTable =[];
                        let Docarr = Parentdata['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
                        var datearr = Parentdata['posts']['childblockchaintriggers'][ i ].blockchaintimestamp.split(' ');
                        let date:string;
                        if(i ==0)
                        {
                          
                          for (let f = 0; f < Docarr.length; f++) {
                            obj = {};
                            var index = Docarr[f].indexOf(':');
                            var Key = Docarr[f].substring(0, index);
                            var Value = Docarr[f].substring( index + 1,Docarr[f].length );
                            date = Value;
                            if(Key == 'Chit Comm date')
                            {
   //PSO Crteation
   EventTable = [];
   var objActivitiy = { Key: 'Activitiy', Value: 'PSO Creation' };
   EventTable.push(objActivitiy)
   var objDescription = { Key: 'Description', Value: 'PSO created by sub-registrar, RajendraNagar, Hyderabad' };
   EventTable.push(objDescription)
   var dateformat = datearr[1].split('/');
   var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
   var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
   var objDate = {};
   objDate = { Key: 'Updated on', Value: dateUTC1 };
   EventTable.push(objDate);
   var objVerify = {};
   objVerify = { Key: 'Verify', Value: data['posts']['childblockchaintriggers'][i].blockchainurl };
   EventTable.push(objVerify);
   innerEventTableData.push(EventTable)
   //Chit Commencement
   EventTable = [];
    var objActivitiy = { Key: 'Activitiy', Value: date };
   EventTable.push(objActivitiy)
   var objDescription = { Key: 'Description', Value: 'Created Chit Commencement date' };
   EventTable.push(objDescription)
   var dateformat = datearr[1].split('/');
   var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
   var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
   var objDate = {};
   objDate = { Key: 'Updated on', Value: dateUTC1 };
   EventTable.push(objDate);
   var objVerify = {};
   objVerify = { Key: 'Verify', Value: data['posts']['childblockchaintriggers'][i].blockchainurl };
   EventTable.push(objVerify);
   innerEventTableData.push(EventTable)
   this.EventTableData = innerEventTableData
   console.log(innerEventTableData)
                            }
                          }
                         
                        }
                        
                        if(Parentdata['posts']['childblockchaintriggers'].length-1 == i)
                        {

                          for (let k = 0; k < Docarr.length; k++) {
                            obj = {};
                            var index = Docarr[k].indexOf(':');
                            var Key = Docarr[k].substring(0, index);
                            var Value = Docarr[k].substring(  index + 1, Docarr[k].length );
                            date = Value;
                            if(Value != "Chit details")
                            {
                              obj = { Key: Key, Value: Value };
                              ChitDocArrData.push(obj);
                              if(Key == 'Last auction')
                              {
 //for Last Auction
 EventTable = [];
 var objActivitiy = { Key: 'Activitiy', Value: date };
 EventTable.push(objActivitiy)
 var objDescription = { Key: 'Description', Value: 'Last auction amount' };
 EventTable.push(objDescription)
 var dateformat = datearr[1].split('/');
 var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
 var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
 var objDate = {};
 objDate = { Key: 'Updated on', Value: dateUTC1 };
 EventTable.push(objDate);
 var objVerify = {};
 objVerify = { Key: 'Verify', Value: data['posts']['childblockchaintriggers'][i].blockchainurl };
 EventTable.push(objVerify);
 innerEventTableData.push(EventTable)
 this.EventTableData = innerEventTableData
 console.log( this.EventTableData)
                              }
                            }
                            if(Docarr.length-1 ==k)
                            {
                              var dateformat = datearr[1].split('/');
                              var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                              var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
                              var objDate = {};
                              objDate = { Key: 'Updated on', Value: dateUTC1 };
                              ChitDocArrData.push(objDate);
                              var objVerify = {};
                              objVerify = { Key: 'Verify', Value: data['posts']['childblockchaintriggers'][i].blockchainurl };
                              ChitDocArrData.push(objVerify);
                             
                             

                            }
                          }
                          console.log(ChitDocArrData)
                          innerChitData.push(ChitDocArrData)
                  
                          ChitDocArrData = [];

                          this.ChitDocumentData = innerChitData;
                          console.log(this.ChitDocumentData)
                          let PersonalDocArrData = [];
                            let LedgerDocArrData = [];
                          for ( let j = 0; j < data['posts']['childblockchaintriggers'].length; j++ )
                          {
                            let Docarr = data['posts']['childblockchaintriggers'][j].blockchaindata.split('||');
                            var datearr = data['posts']['childblockchaintriggers'][ j ].blockchaintimestamp.split(' ');
                            if(Docarr[0] == "Form:Personal details")
                                {
                                  if(PersonalDocArrData.length == 0)
                                  {
                                    for (let a = 0; a < Docarr.length; a++) {
                                      obj = {};
                                      var index = Docarr[a].indexOf(':');
                                      var Key = Docarr[a].substring(0, index);
                                      var Value = Docarr[a].substring(index + 1, Docarr[a].length);
                                      if(Value != "Personal details")
                                      {
                                        
                                        if(Key != 'PSO No')
                                        {
                                          if(Key != 'Member id')
                                          {
                                            obj = { Key: Key, Value: Value };
                                            PersonalDocArrData.push(obj);
                                            if(Docarr.length-1 ==a)
                                            {
                                              var dateformat = datearr[1].split('/');
                                              var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                                              var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
                                              var objDate = {};
                                              objDate = { Key: 'Updated on', Value: dateUTC1 };
                                              PersonalDocArrData.push(objDate);
                                              var objVerify = {};
                                              objVerify = { Key: 'Verify', Value: data['posts']['childblockchaintriggers'][j].blockchainurl };
                                              PersonalDocArrData.push(objVerify);
                                            }
                                          }
                                          
                                        }
                                        
                                      }
                                     
                                    }
                                    console.log(PersonalDocArrData)
                                    innerPersonalData.push(PersonalDocArrData)
                                    //PersonalDocArrData = [];
                                  }
                                  else{
                                    
                                    for (let a = 0; a < Docarr.length; a++) {
                                      obj = {};
                                      var index = Docarr[a].indexOf(':');
                                      var Key = Docarr[a].substring(0, index);
                                      var Value = Docarr[a].substring(index + 1, Docarr[a].length);
                                      
                                      if(Value != "Personal details")
                                      {
                                        if(Key != 'PSO No')
                                        {
                                          if(Key != 'Member id')
                                          {
                                            if(a >0)
                                            {
                                               if(PersonalDocArrData[a-1].Key == Key)
                                               {
                                                   PersonalDocArrData[a-1].Value =Value;
                                               }
                                               if(Docarr.length-1 ==a)
                                               {
                                                 console.log(PersonalDocArrData)
                                                 var dateformat = datearr[1].split('/');
                                                 var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                                                 var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
                                                 PersonalDocArrData[Docarr.length-1].Value = dateUTC1
                                                 PersonalDocArrData[Docarr.length].Value = data['posts']['childblockchaintriggers'][j].blockchainurl
                                                  
                                               }
                                            }
                                          }
                                         
                                        }
                                        
                                      
                                      }
                                    }
                                  }
                                }
                            else if(Docarr[0] == "Form:Ledger details") {
                              var Dataindex = Docarr[1].indexOf(':');
                              var DataValue = Docarr[1].substring(Dataindex + 1, Docarr[1].length);
                            
                              var Transindex = Docarr[3].indexOf(':');
                              var TransValue = Docarr[3].substring(Transindex + 1, Docarr[3].length);
                             
                              var Particularindex = Docarr[4].indexOf(':');
                              var ParticularValue = Docarr[4].substring(Particularindex + 1, Docarr[4].length);
                              
                              var Debitindex = Docarr[5].indexOf(':');
                              var DebitValue = Docarr[5].substring(Debitindex + 1, Docarr[5].length);
                             
                              var Creditindex = Docarr[6].indexOf(':');
                              var CreditValue = Docarr[6].substring(Creditindex + 1, Docarr[6].length);
                             

                              var Transaction = ParticularValue + "/" +TransValue;
                              var amount;
                              var color='';
                              var debit;
                              var credit;
                              console.log(DebitValue)
                              console.log(CreditValue)
                              if(DebitValue == '0' || CreditValue == '0')
                              {
                                debit = '';
                                credit = '';
                              }
                             if(DebitValue != '0'){
                               debit = 'Rs.' +DebitValue;
                                color = 'red';
                              }
                             if(CreditValue != '0'){
                              credit = 'Rs.' +CreditValue;
                               color = 'green';
                              }
                              obj ={};
                              obj= {V1:DataValue,V2:Transaction,V3:debit,V4:color,V5:credit};
                              LedgerDocArrData.push(obj);
                              innerLedgerData.push(LedgerDocArrData)
                              LedgerDocArrData = [];
                              // for (let c = 0; c < Docarr.length; c++) {
                              //   obj = {};
                              //   var index = Docarr[c].indexOf(':');
                              //   var Key = Docarr[c].substring(0, index);
                              //   var Value = Docarr[c].substring(index + 1, Docarr[c].length);
                              //   if(Value != "Ledger details")
                              //   {
                              //     obj = { Key: Key, Value: Value };
                              //     LedgerDocArrData.push(obj);
                              //     if(Docarr.length-1 ==c)
                              //     {
                              //       var dateformat = datearr[1].split('/');
                              //       var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                              //       var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY');
                              //       var objDate = {};
                              //       objDate = { Key: 'Updated on', Value: dateUTC1 };
                              //       LedgerDocArrData.push(objDate);
                              //       var objVerify = {};
                              //       objVerify = { Key: 'Verify', Value: data['posts']['childblockchaintriggers'][j].blockchainurl };
                              //       LedgerDocArrData.push(objVerify);
                              //     }
                              //   }
                               
                              // }
                             // innerLedgerData.push(LedgerDocArrData)
                              //LedgerDocArrData = [];
                            }
                            if(Parentdata['posts']['childblockchaintriggers'].length-1 ==i)
                                {
                                  if(data['posts']['childblockchaintriggers'].length-1 == j)
                                  {
                                    this.PersonalDocumentData = innerPersonalData
                                    this.LedgerDocumentData = innerLedgerData
                                    console.log(this.PersonalDocumentData)
                                    console.log(this.LedgerDocumentData)
                                  }
                                }
                          }
                        }
                      }
                    });
                  } 
                  else {
                   
                    let innerChitData =[];
                    let innerPersonalData = [];
                    let innerLedgerData =[];
                    let innerEventTableData =[];
                    for ( let i = 0; i < data['posts']['childblockchaintriggers'].length; i++) {
                      let obj = {};
                      let ChitDocArrData = [];
                      let EventTable = [];
                      let Docarr = data['posts']['childblockchaintriggers'][ i ].blockchaindata.split('||');
                      var datearr = data['posts']['childblockchaintriggers'][ i ].blockchaintimestamp.split(' ');
                      let date:string;
                      if(i ==0)
                      {
                        
                        for (let f = 0; f < Docarr.length; f++) {
                          obj = {};
                          var index = Docarr[f].indexOf(':');
                          var Key = Docarr[f].substring(0, index);
                          var Value = Docarr[f].substring( index + 1,Docarr[f].length );
                          date = Value;
                          if(Key == 'Chit Comm Date')
                          {
 //PSO Crteation
 EventTable = [];
 var objActivitiy = { Key: 'Activitiy', Value: 'PSO Creation' };
 EventTable.push(objActivitiy)
 var objDescription = { Key: 'Description', Value: 'PSO created by sub-registrar, RajendraNagar, Hyderabad' };
 EventTable.push(objDescription)
 var dateformat = datearr[1].split('/');
 var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
 var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
 var objDate = {};
 objDate = { Key: 'Updated On', Value: dateUTC1 };
 EventTable.push(objDate);
 var objVerify = {};
 objVerify = { Key: 'Verify', Value: data['posts']['childblockchaintriggers'][i].blockchainurl };
 EventTable.push(objVerify);
 innerEventTableData.push(EventTable)
 //Chit Commencement
 EventTable = [];

  var objActivitiy = { Key: 'Activitiy', Value: date };
 EventTable.push(objActivitiy)
 var objDescription = { Key: 'Description', Value: 'Created Chit Commencement Date' };
 EventTable.push(objDescription)
 var dateformat = datearr[1].split('/');
 var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
 var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
 var objDate = {};
 objDate = { Key: 'Updated On', Value: dateUTC1 };
 EventTable.push(objDate);
 var objVerify = {};
 objVerify = { Key: 'Verify', Value: data['posts']['childblockchaintriggers'][i].blockchainurl };
 EventTable.push(objVerify);
 innerEventTableData.push(EventTable)
 this.EventTableData = innerEventTableData
 console.log(innerEventTableData)
                          }
                        }
                       
                      }
                          
                        if(data['posts']['childblockchaintriggers'].length-1 == i)
                        {
                          for (let k = 0; k < Docarr.length; k++) {
                            obj = {};
                            var index = Docarr[k].indexOf(':');
                            var Key = Docarr[k].substring(0, index);
                            var Value = Docarr[k].substring(
                              index + 1,
                              Docarr[k].length
                            );
                            if(Value != "Chit Details")
                            {
                              obj = { Key: Key, Value: Value };
                              ChitDocArrData.push(obj);

                              if(Docarr.length-1 ==k)
                              {
                                var dateformat = datearr[1].split('/');
                                var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                                var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
                                var objDate = {};
                                objDate = { Key: 'Updated On', Value: dateUTC1 };
                                ChitDocArrData.push(objDate);
                                var objVerify = {};
                                objVerify = { Key: 'Verify', Value: data['posts']['childblockchaintriggers'][i].blockchainurl };
                                ChitDocArrData.push(objVerify);
                              }
                            }
                          }
                          console.log(ChitDocArrData)
                          innerChitData.push(ChitDocArrData)
                  
                          ChitDocArrData = [];

                          this.ChitDocumentData = innerChitData;
                          console.log(this.ChitDocumentData)
                          for(let j=0;j<data['posts']['childqrcodes'].length;j++)
                          {
                            let PersonalDocArrData = [];
                            let LedgerDocArrData = [];
                            this._service.getBlockChainDataDetails(data['posts']['childqrcodes'][j].uniqueid).subscribe((Childdata) => {
                              console.log(Childdata)
                              for ( let a = 0; a < Childdata['posts']['childblockchaintriggers'].length; a++ )
                               {
                                let Docarr = Childdata['posts']['childblockchaintriggers'][a].blockchaindata.split('||');
                                var datearr = Childdata['posts']['childblockchaintriggers'][a].blockchaintimestamp.split(' ');
                                if(Docarr[0] == "Form:Personal Details")
                                {
                                  if(PersonalDocArrData.length == 0)
                                  {
                                    for (let d = 0; d < Docarr.length; d++) {
                                      obj = {};
                                      var index = Docarr[d].indexOf(':');
                                      var Key = Docarr[d].substring(0, index);
                                      var Value = Docarr[d].substring(index + 1, Docarr[d].length);
                                      if(Value != "Personal Details")
                                      {
                                        obj = { Key: Key, Value: Value };
                                        PersonalDocArrData.push(obj);
                                        if(Docarr.length-1 ==d)
                                        {
                                          var dateformat = datearr[1].split('/');
                                          var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                                          var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
                                          var objDate = {};
                                          objDate = { Key: 'Updated On', Value: dateUTC1 };
                                          PersonalDocArrData.push(objDate);
                                          var objVerify = {};
                                          objVerify = { Key: 'Verify', Value: Childdata['posts']['childblockchaintriggers'][a].blockchainurl };
                                          PersonalDocArrData.push(objVerify);
                                        }
                                      }
                                     
                                    }
                                    innerPersonalData.push(PersonalDocArrData)
                                    //PersonalDocArrData = [];
                                  }
                                  else{
                                    
                                    for (let e = 0; e < Docarr.length; e++) {
                                      obj = {};
                                      var index = Docarr[e].indexOf(':');
                                      var Key = Docarr[e].substring(0, index);
                                      var Value = Docarr[e].substring(index + 1, Docarr[e].length);
                                      
                                      if(Value != "Personal Details")
                                      {
                                        if(e >0)
                                        {
                                           if(PersonalDocArrData[e-1].Key == Key)
                                           {
                                               PersonalDocArrData[e-1].Value =Value;
                                           }
                                           if(Docarr.length-1 ==e)
                                           {
                                             console.log(PersonalDocArrData)
                                             var dateformat = datearr[1].split('/');
                                             var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                                             var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
                                             PersonalDocArrData[Docarr.length-1].Value = dateUTC1
                                             PersonalDocArrData[Docarr.length].Value = Childdata['posts']['childblockchaintriggers'][a].blockchainurl
                                              
                                           }
                                        }
                                      
                                      }
                                    }
                                  }
                                }
                            else if(Docarr[0] == "Form:Ledger Details") {
                              for (let c = 0; c < Docarr.length; c++) {
                                obj = {};
                                var index = Docarr[c].indexOf(':');
                                var Key = Docarr[c].substring(0, index);
                                var Value = Docarr[c].substring(index + 1, Docarr[c].length);
                                if(Value != "Ledger Details")
                                {
                                  obj = { Key: Key, Value: Value };
                                  LedgerDocArrData.push(obj);
                                  if(Docarr.length-1 ==c)
                                  {
                                    var dateformat = datearr[1].split('/');
                                    var dateUTC = new Date( dateformat[2] + '-' +  dateformat[1] +  '-' +  dateformat[0] +  ' ' +  datearr[0] +   ' UTC' );
                                    var dateUTC1 = moment(dateUTC).format('DD-MMM-YYYY h:mm:ss');
                                    var objDate = {};
                                    objDate = { Key: 'Updated On', Value: dateUTC1 };
                                    LedgerDocArrData.push(objDate);
                                    var objVerify = {};
                                    objVerify = { Key: 'Verify', Value: Childdata['posts']['childblockchaintriggers'][a].blockchainurl };
                                    LedgerDocArrData.push(objVerify);
                                  }
                                }
                               
                              }
                              innerLedgerData.push(LedgerDocArrData)
                              LedgerDocArrData = [];
                            }
                                if(Childdata['posts']['childblockchaintriggers'].length-1 ==a)
                                {
                                  if(data['posts']['childqrcodes'].length-1 == j)
                                  {
                                    this.PersonalDocumentData = innerPersonalData
                                    this.LedgerDocumentData = innerLedgerData
                                 
                                    console.log(this.PersonalDocumentData)
                                    console.log(this.LedgerDocumentData)
                                  }
                                }
                               
                              }
                            });
                          }
                        }
                     
                    }
                  }
                });
            }
          });
      });
    }
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  OnClickChitDetails() {
    this.cardChitDetails = false;
    this.cardPersonalDetails = false;
    this.cardLedgerDetails = false;
    this.cardEventTable = false;
    this.innercardChitDetails = true;
    this.innercardPersonalDetails = false;
    this.innercardLedgerDetails = false;
    this.innercardEventTable = false;
  }

  OnClickPersonalDetails() {
    this.cardChitDetails = false;
    this.cardPersonalDetails = false;
    this.cardLedgerDetails = false;
    this.cardEventTable = false;
    this.innercardChitDetails = false;
    this.innercardPersonalDetails = true;
    this.innercardLedgerDetails = false;
    this.innercardEventTable = false;
  }

  OnClickLedgerDetails() {
    this.cardChitDetails = false;
    this.cardPersonalDetails = false;
    this.cardLedgerDetails = false;
    this.cardEventTable = false;
    this.innercardChitDetails = false;
    this.innercardPersonalDetails = false;
    this.innercardLedgerDetails = true;
    this.innercardEventTable = false;
  }

  OnClickEventTable() {
    this.cardChitDetails = false;
    this.cardPersonalDetails = false;
    this.cardLedgerDetails = false;
    this.cardEventTable = false;
    this.innercardChitDetails = false;
    this.innercardPersonalDetails = false;
    this.innercardLedgerDetails = false;
    this.innercardEventTable = true;
  }
  back() {
    this.cardChitDetails = true;
    this.cardPersonalDetails = true;
    this.cardLedgerDetails = true;
    this.cardEventTable = true;
    this.innercardChitDetails = false;
    this.innercardPersonalDetails = false;
    this.innercardLedgerDetails = false;
    this.innercardEventTable = false;
  }
}
